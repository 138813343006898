import React, { Component } from 'react';
import { RichUtils } from 'draft-js';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';

@withTranslation(['translation_navigation'], {wait: true})
class QuoteButton extends Component {
  constructor (props) {
    super(props);
  }

  blockTypeIsActive = () => {
    const { getEditorState } = this.props;
    const editorState = getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === 'blockquote';
  };

  onClick = e => {
    e.preventDefault();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    setEditorState(RichUtils.toggleBlockType(editorState, 'blockquote'));
  };

  render () {
    let active = this.blockTypeIsActive();
    return (
      <span
        className="button"
        onMouseDown={this.onClick}
        title={this.props.description}
        data-active={active.toString()}
      ><FontIcon>format_quote</FontIcon></span>
    );
  }
}

export default QuoteButton;
