import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import {Button} from 'react-md';
import PasswordPrime from './PasswordPrime';
import { initiateEditProfile, editProfile } from '../../actions/profile';
import {withTranslation} from 'react-i18next';
import styles from '../../styles/project_detail.scss';
import stylesDrawer from '../../styles/drawer_inner.scss';
import passwordStyle from '../../styles/password_change.scss';
import { ProcessStatus } from '../../utils/Constants';
import {
  addToast,
  removeToast
} from '../../actions/globalSnackbar';

@withTranslation('change_password', {wait: true})
class ChangeProfilePassword extends Component {
  constructor (props) {
    super(props);

    this.state = {
      oldPasswordFilled: false,
      newPasswordFilled: false,
      newPasswordConfirmed: false
    };
  }

  componentDidUpdate () {
    const { editingProfile, addToast, removeToast, closeDrawer, t } = this.props;
    if (editingProfile === ProcessStatus.FINISHED) {
      this.props.initiateEditProfile();
      closeDrawer();
      addToast(
        t('password_changed'),
        {
          children: 'ok',
          onClick: () => {
            removeToast();
          }
        },
        true
      );
    } else if (editingProfile === ProcessStatus.FAILED) {
      this.props.initiateEditProfile();
      addToast(
        t('password_change_failed'),
        {
          children: 'ok',
          onClick: () => {
            removeToast();
          }
        },
        true
      );
    }
  }

  handleSubmit = data => {
    let user = this.props.user;
    const {old_password, new_password} = data;
    user['old_password'] = old_password;
    user['new_password'] = new_password;
    const dataOfInterest = user;
    const {oldPasswordFilled, newPasswordFilled, newPasswordConfirmed} = this.state;

    if (oldPasswordFilled && newPasswordFilled && newPasswordConfirmed) {
      this.props.editProfile(dataOfInterest);
    }
  };

  setFormFields = ({...args}) => {
    this.setState({
      ...args
    });
  };

  render () {
    const {
      oldPasswordFilled,
      newPasswordFilled,
      newPasswordConfirmed
    } = this.state;

    const { t } = this.props;

    return (
      <div>
        <Form
          mutators={{
            setFieldData,

            setCollectedInfos: (args, state) => {
              state.formState.values = {...state.formState.values, ...args[0]};
            },
            changeValue: (args, state, utils) => {
              utils.changeValue(state, args[0].name, () => args[0].value);
            },
            changeValues: (args, state, utils) => {
              args.map(arg => utils.changeValue(state, arg.name, () => arg.value));
            }
          }}
          onSubmit={this.handleSubmit}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <div className={'drawer drawerInner marginTop editDrawer noXOverflow'}>
                <div className={styles.autoHeight}>
                  <FormSpy formFields={{oldPasswordFilled, newPasswordFilled, newPasswordConfirmed}}
                    setFormFields={this.setFormFields} subscription={{values: true}} component={PasswordPrime}/>
                </div>
                <div className={'buttonsRightWrapper paddingLeftRight buttonPassword'}>
                  <Button children={ t('change_password') } type="submit" flat primary swapTheming/>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['auth', 'user']),
    editingProfile: state.getIn(['profile', 'editingProfile'])
  };
}

export default connect(
  mapStateToProps,
  {
    editProfile,
    initiateEditProfile,
    addToast,
    removeToast
  }
)(ChangeProfilePassword);
