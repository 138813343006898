import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import {ProcessStatus} from '../../../utils/Constants';

import {deleteClient} from '../../../actions/clients';
import {removeDialog} from '../../../actions/globalDialog';

@withTranslation('general', {wait: true})
class DeleteClientWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let deletingClient = nextProps.deletingClient;

    if (deletingClient == ProcessStatus.FINISHED && deletingClient != this.props.deletingClient) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    this.props.deleteClient(this.props.clientId);
  };

  render () {
    const {deletingClient, t} = this.props;

    if (deletingClient == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t('cancel') }
        </Button>
        <Button flat onClick={this.delete}>
          { t('delete') }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    deletingClient: state.getIn(['clients', 'deletingClient']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {deleteClient, removeDialog}
)(DeleteClientWarning);
