import React, {useState, useEffect} from 'react';
import ContentEditor from './ContentEditor';
import {sortableHandle} from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import styles from '../../styles/storyboards/site.scss';
import Tooltip from '@material-ui/core/Tooltip';
import CompareView from './CompareView';
import {useDispatch, useSelector} from 'react-redux';
import {fetchHistory} from '../../actions/storyboard';


//---------------------------------------------------------------
function getTextBlockTitle (textBlocks) {
  return textBlocks.find(textBLock => textBLock.type_id === 1);
}
function getTextBlockDescription (textBlocks) {
  return textBlocks.find(textBLock => textBLock.type_id === 3);
}
function getTextBlockContents (textBlocks) {
  return textBlocks.filter(textBlock => {
    if (textBlock.type_id === 4) {
      return textBlock.latest_text;
    }
  });
}


function textBlockShallowEqual (objA, objB) {
  // console.log('historyShallowEqual', {objA, objB});
  return true;
}
//---------------------------------------------------------------


export default function TextBlock (props) {
  const {site_id, id} = props;
  const viewMode = useSelector(state => state.getIn(['storyboard', 'viewMode']));
  const compareMode = useSelector(state => state.getIn(['storyboard', 'compareMode']));

  //-----------------------------------------------
  const dispatch = useDispatch();

  /*
  const history = useSelector(state => state.getIn([
    'storyboard', 'history', 'sites', {site_id}, 'textBlocks', {id}]), historyShallowEqual);
  */

  const textBlock = useSelector((state) => {
    let site = state.getIn(['storyboard', 'chapter']).find(site => {
      return (site.id === props.siteId);
    });
    let content = null;

    switch (props.type) {
      case 'title':
        content = getTextBlockTitle(site.text_blocks);
        break;
      case 'description':
        content = getTextBlockDescription(site.text_blocks);
        break;
      case 'contents':
        content = getTextBlockContents(site.text_blocks)[props.textBlockIndex];
        break;
      default: break;
    }
    return content;
  }, textBlockShallowEqual);

  useEffect(() => {
    // console.log('TextBlock: fetchHistory textBlock', textBlock);
    // dispatch(fetchHistory(textBlock.id));
  }, [textBlock]);

  useEffect(() => {
    // console.log('TextBlock: in useEffect: history', history);
  }, [history]);
  //-----------------------------------------------


  // const DragHandle = sortableHandle(() =>
  //   <Tooltip title="Move textitem" placement="bottom">
  //     <span className='handle'>
  //       <DragHandleIcon
  //         onMouseDown={() => {
  //           console.log('onMouseDown');
  //           setSiteInMoveMode(true);
  //         }}
  //         onMouseUp={() => {
  //           setSiteInMoveMode(false);
  //         }}
  //       />
  //     </span>
  //   </Tooltip>
  // );

  if (viewMode === 0) {
    // console.log('1. --> viewMode 1, textBlock.history =', textBlock.history);
    // console.log('2. --> viewMode 1, compareMode =', compareMode);

    return (
      <ContentEditor
        {...props}
        textBlock={textBlock}
      />
    );
  } else if (viewMode === 1) {
    // console.log('3. --> viewMode 1, textBlock.history =', textBlock.history);
    // console.log('4. --> viewMode 1, compareMode =', compareMode);
    // if (textBlock.history && textBlock.history.length > 0) {
    if (true) {
      return (
        <CompareView
          {...props}
          compareMode={compareMode}
          textBlock={textBlock}
        />
      );
    }
    return (<div>No History yet</div>);
  }
  return (<div>No TextBlock yet</div>);
}
