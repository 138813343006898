import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';

import {fetchTextScopes} from '../../../actions/textScopes';

import LoadingBar from '../../general/DumbComponent';
import {renderSelectField} from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
import {withTranslation} from 'react-i18next';

@withTranslation(['project', 'general', 'projectselection'], {wait: true})
class ProjectTranslation extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    this.props.fetchTextScopes();
  }


  render () {
    const {textScopes, editMode, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const projectselectionLNS = 'projectselection'; // projectselectionLanguageNamespaceSource

    if (textScopes.size == 0) {
      return (
        <LoadingBar
          show="true"
          wrapperClassname={styles.loadingBarWrapper}/>
      );
    }

    let normalizedTextScopes = textScopes.get('text_scope') ? textScopes.get('text_scope') : textScopes;
    let textScopesWithLabels = normalizedTextScopes.map(item => (
      {value: item, label:t(`${projectselectionLNS}:${'textscopes_' + item}`)}
    ));

    return (
      <div className={''}>
        <div className={styles.header}>
          <h2 className={'headName'}>{ t(`${generalLNS}:translation`) }</h2>
        </div>


        <div className="editableElements">
          <Field
            id="text_scope"
            name="text_scope"
            label={ t('text_scope') }
            type="text"
            helpText={ t(`${generalLNS}:not_yet_editable`) }
            className={styles.formField}
            menuItems={textScopesWithLabels}
            disabled={!editMode}
            component={renderSelectField}
          />
        </div>


        <div className="notEditableElements"></div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    textScopes: state.getIn(['textScopes', 'textScopes'])
  };
}

export default connect(
  mapStateToProps, {fetchTextScopes})(ProjectTranslation);

