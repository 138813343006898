import { List, fromJS } from 'immutable';

import { ContactsAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import { generateGuid } from '../utils/HelperFunctions';

const initialState = fromJS({
  contacts: List(),
  fetchingContacts: ProcessStatus.INITIAL,
  addingContact: ProcessStatus.INITIAL,
  fetchingContact: ProcessStatus.INITIAL,
  editingContact: ProcessStatus.INITIAL,
  deletingContact: ProcessStatus.INITIAL,
  updateToken: '',
  error: null
});
let index = 0;
let contact = null;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactsAction.FETCH_CONTACTS:
      return state.set('fetchingContacts', ProcessStatus.STARTED);
    case ContactsAction.FETCH_CONTACTS_FULFILLED:
      return state
        .set('fetchingContacts', ProcessStatus.FINISHED)
        .set('contacts', List(action.contacts));
    case ContactsAction.FETCH_CONTACTS_REJECTED:
      return state.set('fetchingContacts', ProcessStatus.FAILED).set('error', action.payload);
    case ContactsAction.ADD_CONTACT:
      return state.set('addingContact', ProcessStatus.STARTED);
    case ContactsAction.ADD_CONTACT_FULFILLED:
      if (state.get('contacts').size !== 0) {
        return state
          .set('addingContact', ProcessStatus.FINISHED)
          .set('contacts', state.get('contacts').push(action.contact));
      }
      return state.set('addingContact', ProcessStatus.FINISHED);
    case ContactsAction.ADD_CONTACT_REJECTED:
      return state.set('addingContact', ProcessStatus.FAILED).set('error', action.payload);
    case ContactsAction.DELETE_CONTACT:
      return state.set('deletingContact', ProcessStatus.STARTED);
    case ContactsAction.DELETE_CONTACT_FULFILLED:
      state = state.set('deletingContact', ProcessStatus.FINISHED);
      index = state.get('contacts').findIndex(function (obj) {
        return obj.id === action.contactId;
      });
      if (index > -1) {
        state = state.set('contacts', state.get('contacts').delete(index));
      }
      return state;
    case ContactsAction.DELETE_CONTACT_REJECTED:
      return state.set('deletingContact', ProcessStatus.FAILED).set('error', action.payload);
    case ContactsAction.EDIT_CONTACT:
      return state.set('editingContact', ProcessStatus.STARTED);
    case ContactsAction.EDIT_CONTACT_FULFILLED:
      index = state.get('contacts').findIndex(function (contact) {
        return contact.id === action.contact.id;
      });
      if (index > -1) {
        return state
          .set('editingContact', ProcessStatus.FINISHED)
          .set('contacts', state.get('contacts').set(index, action.contact))
          .set('updateToken', generateGuid());
      }
      return state.set('editingContact', ProcessStatus.FINISHED);
    case ContactsAction.EDIT_CONTACT_REJECTED:
      return state.set('editingContact', ProcessStatus.FAILED).set('error', action.payload);

    default:
      return state;
  }
};


export { initialState, reducer as contacts };
