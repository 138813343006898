import React from 'react';
import {convertDraftRawToHtmlString} from './helper/convertingForEditorWorks';
import stylesEditor from '../../translationTool/draftEditorAdditional/style/mainEditorStyle.scss';

export default function RawDraftToHtml (props) {
  const createMarkup = () => {
    // console.log('props.raw', props.raw);
    // let content = props.raw;
    // let text = content.blocks[0].text;
    // console.log('text', text);
    // let newTextString = text
    //   .replace(/\t/g, '*t*')
    //   .replace(/\n/g, '<br/>')
    //   .replace(/\s/g, '*s*')
    // ;
    // content.blocks[0].text = newTextString;
    // let html = convertDraftRawToHtmlString(content);
    let html = convertDraftRawToHtmlString(props.raw);
    // return {__html: html.replace(/[*t*]/g, '\t').replace(/[*s*]/g, '<span>&nbsp;</span>')};
    return {__html: html};
  };

  return (
    <div
      className={stylesEditor.stylesOnNoneEditor}
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
}
