import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';

import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';

import ContactPrime from './ContactPrime';

import {editContact} from '../../../actions/contacts';

import styles from '../../../styles/project_detail.scss';

@withTranslation(['contacts', 'general'], {wait: true})
class EditContact extends Component {
  constructor (props) {
    super(props);

    this.state = {
      editMode: false,
      contact: null
    };
  }

  componentWillMount () {
    const {contacts, contactId} = this.props;

    if (contacts.size >= 0) {
      this.setState({'contact': contacts.find(contact => contact.id == contactId)});
    }
  }

  handleSubmit = (contact) => {
    this.props.editContact(contact);
    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({editMode: !this.state.editMode});
  };

  render () {
    const {editingContact, t} = this.props;
    const {editMode, contact} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    // if (false) {
    //     return (
    //         <LoadingBar show='true'/>
    //     );
    // }

    let initialValues = contact;

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingContact == ProcessStatus.STARTED && (
                <LoadingBar
                  show="true"
                  wrapperClassname={styles.loadingBarWrapper}
                  message={ t(`${generalLNS}:updating_contact`) }
                />
              )}
              <div className={styles.buttonsRightWrapper + ' ' + styles.buttonsRightWrapperInTab}>
                <Button
                  title={ t(`${generalLNS}:edit_mode`) }
                  className={styles.editButton + ' ' + styles.editButtonFilled}
                  style={{display: !editMode ? 'block' : 'none'}}
                  onClick={this.switchEditMode}
                  icon
                  primary
                  swapTheming>
                  mode_edit
                </Button>

                <Button
                  children={ t(`${generalLNS}:save_all_edits`) }
                  type="submit"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formSubmitButton}
                  disabled={editingContact == ProcessStatus.STARTED}
                  flat
                  primary
                  swapTheming
                />

                <Button
                  children={ t(`${generalLNS}:cancel`) }
                  type="button"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formCancelButton}
                  onClick={this.switchEditMode}
                  disabled={editingContact == ProcessStatus.STARTED}
                  flat
                  primary
                />
              </div>
              <div className={styles.flexHorizontalWrapper}>
                <FormSpy
                  subscription={{values: true}}
                  editMode={editMode}
                  component={ContactPrime}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    contacts: state.getIn(['contacts', 'contacts']),
    editingContact: state.getIn(['contacts', 'editingContact'])
  };
}

export default connect(
  mapStateToProps,
  {editContact}
)(EditContact);
