import {List, fromJS} from 'immutable';

import {ClientsAction} from '../utils/ActionTypes';
import {ProcessStatus} from '../utils/Constants';
import {generateGuid} from '../utils/HelperFunctions';


const initialState = fromJS({
  clients: List([]),
  editingClient: ProcessStatus.INITIAL,
  addingClient: ProcessStatus.INITIAL,
  selectedClient: null,
  fetchingClients: ProcessStatus.INITIAL,
  fetchingClient: ProcessStatus.INITIAL,
  deletingClient: ProcessStatus.INITIAL,
  linkingClientToUser: ProcessStatus.INITIAL,
  linkingClientToExportType: ProcessStatus.INITIAL,
  error: null
});

const reducer = (state = initialState, action) => {
  let client = null;
  let clients = null;
  let index = -1;
  // let parentIndex = -1;
  switch (action.type) {
    case ClientsAction.FETCH_CLIENTS:
      return state.set('fetchingClients', ProcessStatus.STARTED);
    case ClientsAction.FETCH_CLIENTS_FULFILLED:
      return state.set('fetchingClients', ProcessStatus.FINISHED).set('clients', List(action.clients));
    case ClientsAction.FETCH_CLIENTS_REJECTED:
      return state.set('fetchingClients', ProcessStatus.FAILED).set('error', action.payload);
    case ClientsAction.SELECT_CLIENT:
      client = state.get('clients').find(function (obj) {
        return obj.id === action.clientId;
      });
      if (typeof client === 'undefined') {
        client = null;
      }
      return state.set('selectedClient', client);
    case ClientsAction.FETCH_CLIENT:
      return state.set('fetchingClient', ProcessStatus.STARTED);
    case ClientsAction.FETCH_CLIENT_FULFILLED:
      return state.set('fetchingClient', ProcessStatus.FINISHED).set('selectedClient', action.client);
    case ClientsAction.FETCH_CLIENT_REJECTED:
      return state.set('fetchingClient', ProcessStatus.FAILED).set('error', action.payload);
    case ClientsAction.ADD_CLIENT:
      return state.set('addingClient', ProcessStatus.STARTED);
    case ClientsAction.ADD_CLIENT_FULFILLED:
      return state.set('addingClient', ProcessStatus.FINISHED).set('clients', state.get('clients').push(action.client));
    case ClientsAction.ADD_CLIENT_REJECTED:
      return state.set('addingClient', ProcessStatus.FAILED).set('error', action.payload);
    case ClientsAction.DELETE_CLIENT:
      return state.set('deletingClient', ProcessStatus.STARTED);
    case ClientsAction.DELETE_CLIENT_FULFILLED:
      state = state.set('deletingClient', ProcessStatus.FINISHED);
      index = state.get('clients').findIndex(function (obj) {
        return obj.id === action.clientId;
      });
      if (index > -1) {
        state = state.set('deletingClient', ProcessStatus.FINISHED).set('clients', state.get('clients').delete(index));
      }
      return state;
    case ClientsAction.DELETE_CLIENT_REJECTED:
      return state.set('deletingClient', ProcessStatus.REJECTED).set('error', action.payload);
    case ClientsAction.EDIT_CLIENT:
      return state.set('editingClient', ProcessStatus.STARTED);
    case ClientsAction.EDIT_CLIENT_FULFILLED:
      clients = state.get('clients');
      index = clients.findIndex(client => client.id === action.client.id);

      if (index > -1) {
        clients = clients.set(index, action.client);
        state = state.set('clients', clients)
          .set('selectedClient', action.client)
          .set('updateToken', generateGuid());
      }
      return state.set('editingClient', ProcessStatus.FINISHED);
    /*
    client = action.client;
    index = state.get('clients').findIndex(function (obj) {
        return obj.id === client.id;
    });
    parentIndex = state.get('clients').findIndex(function (obj) {
        return obj.id === Number(client.parent_id);
    });
    if( parentIndex > -1) {
        client.parent=state.get('clients').get(parentIndex);
    }
    if( index > -1) {
        let selectedClient = state.get('selectedClient');
        if(selectedClient && selectedClient.id===client.id){
            state = state.set('selectedClient', client);
        }
        return state.set('editingClient', ProcessStatus.FINISHED).set('clients', state.get('clients').set(index, client));
    }
    break;
    */
    case ClientsAction.EDIT_CLIENT_REJECTED:
      // TODO This has to be changed
      // state.set('editingClient', false).set('error', action.payload).set('clients', state.get('clients').set(client.id, () => client));
      return state.set('editingClient', ProcessStatus.FAILED).set('error', action.payload);

    case ClientsAction.LINK_CLIENT_TO_USER: {
      return state.set('linkingClientToUser', ProcessStatus.STARTED);
    }
    case ClientsAction.LINK_CLIENT_TO_USER_FULFILLED: {
      client = state.get('selectedClient');
      client.users = action.data;
      return state.set('linkingClientToUser', ProcessStatus.FINISHED)
        .set('selectedClient', client)
        .set('updateToken', generateGuid());
    }
    case ClientsAction.LINK_CLIENT_TO_USER_REJECTED: {
      return state.set('linkingClientToUser', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE: {
      return state.set('linkingClientToExportType', ProcessStatus.STARTED);
    }
    case ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE_FULFILLED: {
      client = state.get('selectedClient');
      client.export_types = action.data;
      return state.set('linkingClientToExportType', ProcessStatus.FINISHED)
        .set('selectedClient', client)
        .set('updateToken', generateGuid());
    }
    case ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE_REJECTED: {
      return state.set('linkingClientToExportType', ProcessStatus.FAILED)
        .set('error', action.payload);
    }

    default:
      return state;
  }
};

export {initialState, reducer as clients};
