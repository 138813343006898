import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction,
  defaultPrepareFiltersFunction
} from './defaultFunctions';
import {aclRole} from '../utils/Permissions';

/**
 * Roles Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'name',
      translation_id: 'name',
      // name: 'Name',
      component: 'roleName'
    },
    {
      id: 'actions',
      translation_id: 'actions',
      // name: 'Actions',
      textAlign: 'right',
      permissions: [aclRole.delete, aclRole.update],
      sortable: 'false'
    }
  ],

  filters: [
    {
      translation_id: 'name',
      // label: 'Name',
      primaryText: 'Name',
      id: 'name'
    }
  ],

  prepareFilters: defaultPrepareFiltersFunction,
  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name'],

  initRowsPerPage: 12
};

export default model;
