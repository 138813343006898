import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import {withTranslation} from 'react-i18next';
import {renderTextField} from '../../general/BasicFormComponents';
import ObjectAutocomplete from '../../general/ObjectAutocomplete';
import {fetchContacts} from '../../../actions/contacts';
import styles from '../../../styles/translation_detail.scss';
import { truequired } from '../../../utils/ValidationRules';
import {sortAlphabetically} from '../../../utils/ChipHelperFunctions';
import LoadingBar from '../../project/addtranslation/TranslationPrime';

@withTranslation(['contacts', 'general', 'project'], {wait: true})
class ContactPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      countryOptions: null
    };
  }

  componentWillMount () {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {countries} = nextProps;
    if (countries.size > 0) {
      this.update(nextProps, countries);
    }
  }

  update = props => {
    const { countries } = props;
    let countryOptions = this.formatCountryOptions(countries)
      .toArray()
      .sort((a, b) => sortAlphabetically(a, b));

    this.setState({
      countryOptions: countryOptions
    });
  };

  formatCountryOptions = (countries) => {
    let label;
    let countriesList = countries.map(country => {
      label = country.cc == null ? country.name : country.cc + ' - ' + country.name;
      return {value: country.id, label: label};
    });

    return countriesList;
  };

  render () {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const className = 'md-grid ' + styles.translationPrime;
    const {countryOptions} = this.state;

    if (!countryOptions) {
      console.log('countryOptions: ', countryOptions);
      return (
        <LoadingBar
          show="true"
          wrapperClassname={styles.translationPrime}
          message={ t(`${generalLNS}:submit`) }
        />
      );
    }

    return (
      <div className={className}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{ t('add_contact') }</h2>
          </div>
        </div>

        <div className={'formContentWrapper'}>
          <Field
            id="project_leader_name"
            name="project_leader_name"
            label={ t('project_leader') }
            type="text"
            required
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="pl_phone_number"
            name="pl_phone_number"
            label={ t('phone') }
            type="text"
            required
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="pl_email"
            name="pl_email"
            label={ t(`${generalLNS}:email`) }
            type="text"
            required
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="country.value"
            name="country.label"
            label={ t(`${generalLNS}:country`) }
            placeholder={ t('project:country_placeholder') }
            helpText={ t('project:country_help') }
            errorTextForNotFound={ t('project:country_error_not_found') }
            errorTextForDisabled={ t('project:country_error_disabled') }
            type="text"
            required
            changeValues={this.props.form.mutators.changeValues}
            validate={truequired}
            options={countryOptions}
            className="md-cell md-cell--12"
            component={ObjectAutocomplete}
          />
          <Field
            id="company_name"
            name="company_name"
            label={ t('company_name') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_adress_line_1"
            name="cp_adress_line_1"
            label={ t('address_line_1') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_adress_line_2"
            name="cp_adress_line_2"
            label={ t('address_line_2') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_city"
            name="cp_city"
            label={ t('city') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_zip"
            name="cp_zip"
            label={ t('zip') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_state"
            name="cp_state"
            label={ t('state') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="cp_url"
            name="cp_url"
            label={ t('url') }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    contacts: state.getIn(['contacts', 'contacts']),
    languages: state.getIn(['languages', 'languages']),
    countries: state.getIn(['languages', 'countries'])
  };
}

export default connect(
  mapStateToProps,
  {fetchContacts}
)(ContactPrime);
