import {ajax} from 'rxjs/observable/dom/ajax';

import {StoryboardAction} from '../utils/ActionTypes';

import {
  fetchChapterFulfilled,
  fetchChapterRejected,
  addTextBlockFulfilled,
  addTextBlockRejected,
  updateTextBlockFulfilled,
  updateTextBlockRejected,
  deleteTextBlockFulfilled,
  deleteTextBlockRejected,
  fetchStoryboardFulfilled,
  fetchStoryboardRejected,
  fetchStoryboardsFulfilled,
  fetchStoryboardsRejected,
  fetchHistoryFulfilled,
  fetchHistoryRejected,
  updateSiteFulfilled,
  updateSiteRejected,
  lockTextBlockRejected,
  lockTextBlockFulfilled,
  unlockTextBlockFulfilled,
  unlockTextBlockRejected,
  addSiteFulfilled,
  addSiteRejected,
  deleteSiteFulfilled,
  deleteSiteRejected
} from '../actions/storyboard';

import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';

const fetchChapterEpic = (action$, store) => {
  console.log('fetchChapterEpic', action$);
  return action$.ofType(StoryboardAction.FETCH_CHAPTER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/chapter/${action.payload}`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res)
        .map(fetchChapterFulfilled)
        .catch(fetchChapterRejected);
    });
};

const addTextBlockEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.ADD_TEXT_BLOCK)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock`;
      let headers = getHttpAuthHeaders(store, url);
      // const socketId = window.Echo.socketId();
      // console.log('The socket Id is the next one: ', socketId);
      // headers['X-Socket-ID']=socketId;
      return ajax({url, method: 'POST', headers, body: action.payload})
        .map(res => res.response)
        .map(addTextBlockFulfilled)
        .catch(addTextBlockRejected);
    });
};

const updateTextBlockEpic = (action$, store) => {
  console.log('######## updateTextBlockEpic', action$);
  return action$.ofType(StoryboardAction.UPDATE_TEXT_BLOCK)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock/${action.id}`;
      let headers = getHttpAuthHeaders(store, url);
      console.log('updateTextBlockEpic', action.payload);
      return ajax({url, method: 'PUT', headers, body: action.payload})
        .map(res => res.response)
        .map(updateTextBlockFulfilled)
        .catch(updateTextBlockRejected);
    });
};

const lockTextBlockEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.LOCK_TEXT_BLOCK)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock/${action.id}/lock`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers, body: action.payload})
        .map(res => res.response)
        .map(lockTextBlockFulfilled)
        .catch(lockTextBlockRejected);
    });
};

const unlockTextBlockEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.UNLOCK_TEXT_BLOCK)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock/${action.id}/unlock`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers, body: action.payload})
        .map(res => res.response)
        .map(unlockTextBlockFulfilled)
        .catch(unlockTextBlockRejected);
    });
};

const deleteTextBlockEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.DELETE_TEXT_BLOCK)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock/${action.id}`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'DELETE', headers})
        .map(res => res.response)
        .map(deleteTextBlockFulfilled)
        .catch(deleteTextBlockRejected);
    });
};

const fetchStoryboardEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.FETCH_STORYBOARD)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/${action.payload}`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res)
        .map(fetchStoryboardFulfilled)
        .catch(fetchStoryboardRejected);
    });
};

const fetchStoryboardsEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.FETCH_STORYBOARDS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res)
        .map(fetchStoryboardsFulfilled)
        .catch(fetchStoryboardsRejected);
    });
};

const fetchHistoryEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.FETCH_HISTORY)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/textblock/${action.payload}/history`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res)
        .map(fetchHistoryFulfilled)
        .catch(fetchHistoryRejected);
    });
};

const updateSiteEpic = (action$, store) => {
  console.log('######## updateSiteEpic', action$);
  return action$.ofType(StoryboardAction.UPDATE_SITE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/site/${action.id}`;
      let headers = getHttpAuthHeaders(store, url);
      console.log('######## updateSiteEpic action', action);
      let body = {
        data: JSON.stringify(action.payload)
      };
      return ajax({url, method: 'PUT', headers, body: body})
        .map(res => res.response)
        .map(updateSiteFulfilled)
        .catch(updateSiteRejected);
    });
};

const addSiteEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.ADD_SITE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}storyboard/site`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers, body: action.payload})
        .map(res => res.response)
        .map(addSiteFulfilled)
        .catch(addSiteRejected);
    });
};

const deleteSiteEpic = (action$, store) => {
  return action$.ofType(StoryboardAction.DELETE_SITE)
    .debounceTime(500)
    .mergeMap((action) => {
      console.log('deleteSiteEpic mergeMap action', action);
      let url = `${baseApiUrl}storyboard/site/${action.id}`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'DELETE', headers})
        .map(res => res.response)
        .map(deleteSiteFulfilled)
        .catch(deleteSiteRejected);
    });
};

export {
  fetchChapterEpic,
  addTextBlockEpic,
  updateTextBlockEpic,
  deleteTextBlockEpic,
  fetchStoryboardEpic,
  fetchStoryboardsEpic,
  fetchHistoryEpic,
  updateSiteEpic,
  lockTextBlockEpic,
  unlockTextBlockEpic,
  addSiteEpic,
  deleteSiteEpic
};
