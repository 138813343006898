import React from 'react';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';
import styles from '../../../styles/translationTool/translationTool.scss';
import stylesEditor from '../../translationTool/draftEditorAdditional/style/mainEditorStyle.scss';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import {convertToRaw, convertFromRaw} from 'draft-js';
import {DraftailEditor, createEditorStateFromRaw} from 'draftail';
import {
  entityTypes,
  blockTypes,
  inlineStyles
} from '../../translationTool/draftEditorAdditional/EditorTypes';
import {
  entityTypes as entityTypesSB,
  blockTypes as blockTypesSB,
  inlineStyles as inlineStylesSB
} from './EditorTypesNormal';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';


@withTranslation(['translation_navigation', 'general'], {wait: true})
class RichTextEditorDraft extends React.Component {
  constructor (props) {
    super(props);

    const {t} = this.props;
    this.state = {
      isIE11: true,
      initialText: this.props.initialText,
      rawEditorOpen: false,
      contentChanged: false,
      editorState: createEditorStateFromRaw(this.props.initialContextForDraft),
      descriptions: {
        bold: t('bold_text'),
        superscript: t('superscript_text'),
        subscript: t('subscript_text'),
        softBreak: t('soft_break'),
        nobreak: t('nobreak_text'),
        quote: t('quote_text'),
        list: t('list_text'),
        indent: t('indent_text'),
        decrease: t('decrease_text'),
        link: t('link_text'),
        mail: t('mail_text'),
        shy: t('shy_text'),
        zwsp: t('zwsp_text'),
        undo: t('undo'),
        redo: t('redo')
      },
      staticToolBarHidden: true
    };

    // Editor-Plugins:
    this.toolbarPlugin = createToolbarPlugin();
    this.inlineToolbarPlugin = createInlineToolbarPlugin();
    this.undoPlugin = createUndoPlugin({
      undoContent: <span className="material-icons">undo</span>,
      redoContent: <span className="material-icons">redo</span>
    });
    this.editorPlugins = [
      this.toolbarPlugin,           // Toolbar über Editor und daneben
      this.inlineToolbarPlugin,     // HOVER-TOOLBAR
      this.undoPlugin
    ];
    this.editorId = Math.random().toString(16).slice(2);
    this.editorWrapper = React.createRef();
    this.editorDraft = React.createRef();
    this.editorToolBar = null;
  }

  resizeToolbar = e => {
    console.log('this.editorToolBar', this.editorToolBar, e);
  };

  componentDidMount () {
    window.addEventListener('wheel', this.updateStaticMenuPosition, false);

    // setTimeout(() => {
    //   console.log('this.editorDraft.current', this.editorWrapper.current);
    //   this.editorToolBar = this.editorWrapper.current.querySelector('.Draftail-Toolbar');
    //   console.log('this.editorToolBar', this.editorToolBar);
    //   window.addEventListener('resize', this.resizeToolbar);
    // }, 3000);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.active !== this.props.active) {
      if (this.props.active) {
        setTimeout(() => {
          this.setState({
            staticToolBarHidden: !this.props.active
          });
        }, 50);
      } else {
        this.setState({
          staticToolBarHidden: !this.props.active
        });
      }
    }
  }

  focusEditor = () => {
    setTimeout(this.editorDraft.focus, 50);
  };

  call (change) {
    this.setState({
      value: this.state.value.change().call(change).value
    });
  }

  hideRawEditor = () => {
    this.setState({rawEditorOpen: false});
  };

  // onSave = content => { // alt
  //   console.log('+++++++++++++++++++++++++++++++++ ONSAVE +++++++++++++++++++++++++++++++');
  //   const convertedContent = convertFromRaw(content);
  //   this.props.save(convertedContent);
  // };

  onChangeEditor = editorSt => {
    console.log('+++++++++++++++++++++++++++++++++ onChangeEditor +++++++++++++++++++++++++++++++');
    this.props.save(
      convertToRaw(editorSt.getCurrentContent())
    );
    this.setState({editorState: editorSt});
  };

  actionOnEditorBlur = () => {
    this.props.onEditorBlur(
      // convertToRaw(this.state.editorState.getCurrentContent())
    );
  };

  renderEditorWithDraftToolbar = () => {
    const {
      storyboardOnly,
      onEditorFocus
    } = this.props;

    return (
      <div
        className={'editor ' + stylesEditor.drafttailEditor}
        contentchanged={this.props.editorHasChanges.toString()}
        ref={this.editorWrapper}
      >
        <DraftailEditor
          ref={this.editorDraft}
          // onSave={this.onSave}

          onChange={this.onChangeEditor}
          editorState={this.state.editorState}

          placeholder={null}
          stripPastedStyles={false}
          textAlignment={null}
          textDirectionality={null}
          enableLineBreak={{
            description: 'Soft line break',
            icon: <FontIcon>keyboard_return</FontIcon>
          }}
          showUndoControl={{description: 'Undo last change'}}
          showRedoControl={{description: 'Redo last change'}}
          maxListNesting={10}
          inlineStyles={storyboardOnly ? inlineStylesSB : inlineStyles}
          blockTypes={storyboardOnly ? blockTypesSB : blockTypes}
          entityTypes={storyboardOnly ? entityTypesSB : entityTypes}
          stateSaveInterval={250}
          plugins={this.editorPlugins}
          onFocus={onEditorFocus}
          onBlur={this.actionOnEditorBlur}
        />
      </div>
    );
  };

  render () {
    const {
      showLinkDialog,
      showMailLinkDialog
    } = this.state;

    return (
      <div>
        <div
          className={styles.rteWrapper}
          data-invisible={showLinkDialog || showMailLinkDialog}
        >
          {this.renderEditorWithDraftToolbar()}
        </div>
      </div>
    );
  }
}

export default RichTextEditorDraft;
