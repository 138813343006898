import {Map, fromJS} from 'immutable';

import {StructureAction} from '../utils/ActionTypes';
import {ProcessStatus} from '../utils/Constants';

import {generateGuid, treeToList, filterTexts, filterList} from '../utils/HelperFunctions';

const initialState = fromJS({
  structure: {},
  fetchingStructure: ProcessStatus.INITIAL,
  flattenedStructure: [],
  matchingIds: [],
  selectedItem: Map(),
  selectingItem: ProcessStatus.INITIAL,
  settingItemStatus: ProcessStatus.INITIAL,
  settingItemVisibility: ProcessStatus.INITIAL,
  updateToken: ''
});

const reducer = (state = initialState, action) => {
  let structure = {};

  switch (action.type) {
    case StructureAction.FETCH_STRUCTURE:
      return state.set('fetchingStructure', ProcessStatus.STARTED);
    case StructureAction.FETCH_STRUCTURE_FULFILLED:
      return state
        .set('fetchingStructure', ProcessStatus.FINISHED)
        .set('structure', action.data[0])
        .set('updateToken', generateGuid());
    case StructureAction.FETCH_STRUCTURE_REJECTED:
      return state.set('fetchingStructure', ProcessStatus.FAILED);
    case StructureAction.FILTER_STRUCTURE:
      return filterStructure(state, action.texts, action.filterText);
    case StructureAction.SELECT_ITEM:
      return state.set('selectingItem', ProcessStatus.STARTED);
    case StructureAction.SELECT_ITEM_FULFILLED:
      return state
        .set('selectingItem', ProcessStatus.FINISHED)
        .set('selectedItem', Map(action.data));
    case StructureAction.SELECT_ITEM_REJECTED:
      return state.set('selectingItem', ProcessStatus.FAILED);
    case StructureAction.SET_ITEM_STATUS:
      return state.set('settingItemStatus', ProcessStatus.STARTED);
    case StructureAction.SET_ITEM_STATUS_FULFILLED:
      structure = state.get('structure');
      findAndUpdateNodeRecursively(structure.childs, action.data, updateStatus);
      return state
        .set('settingItemStatus', ProcessStatus.FINISHED)
        .set('updateToken', generateGuid());
    case StructureAction.SET_ITEM_STATUS_REJECTED:
      return state.set('settingItemStatus', ProcessStatus.FAILED);
    case StructureAction.SET_ITEM_VISIBILITY:
      return state.set('settingItemVisibility', ProcessStatus.STARTED);
    case StructureAction.SET_ITEM_VISIBILITY_FULFILLED:
      structure = state.get('structure');
      findAndUpdateNodeRecursively(structure.childs, action.data, updateVisibility);
      return state
        .set('settingItemVisibility', ProcessStatus.FINISHED)
        .set('updateToken', generateGuid());
    case StructureAction.SET_ITEM_VISIBILITY_REJECTED:
      return state.set('settingItemVisibility', ProcessStatus.FAILED);

    default:
      return state;
  }
};

const findAndUpdateNodeRecursively = (parent, action, updateFunction) => {
  return parent.find(child =>
    child.id == action.item_id
      ? updateFunction(child, action)
      : findAndUpdateNodeRecursively(child.childs, action, updateFunction)
  );
};

const updateStatus = (node, action) => {
  node.status = action.item_status;
  return node;
};

const updateVisibility = (node, action) => {
  let translationId = action.translation_id;
  let visible = action.visible;

  let notAvailableIn = node.not_available_in != null ? node.not_available_in.toString() : '';
  let indexOfFirstComma = -1;
  let firstId = '';

  if (!visible) {
    if (notAvailableIn == '') {
      notAvailableIn = translationId;
    } else {
      notAvailableIn = notAvailableIn.toString().concat(',', translationId);
    }
  } else {
    indexOfFirstComma = notAvailableIn.indexOf(',');
    if (indexOfFirstComma > 0) {
      firstId = notAvailableIn.substr(0, indexOfFirstComma);
      if (firstId == translationId) {
        notAvailableIn = notAvailableIn.replace(firstId.concat(','), '');
      } else {
        if (notAvailableIn.indexOf(','.concat(translationId))) {
          notAvailableIn = notAvailableIn.replace(','.concat(translationId), '');
        } else {
          console.log('A. DAS SOLLTE NIE VORKOMMEN');
        }
      }
    } else {
      if (notAvailableIn == translationId) {
        notAvailableIn = null;
      } else {
        console.log('B. DAS SOLLTE NIE VORKOMMEN');
      }
    }
  }
  node.not_available_in = notAvailableIn;
  node.visible = visible;

  return node;
};

const filterStructure = (state, texts, filterText) => {
  // const filteredIds = filterTexts(texts, filterText);
  const list = treeToList(state.get('structure'), 'childs');
  const filteredIds = filterStructureList(list, filterText);

  const matchingIds = filterList(list, filteredIds).reduce((flatList, item) => {
    return flatList.concat(item.collectedIds);
  }, []);

  return state.set('flattenedStructure', list).set('matchingIds', matchingIds);
};


const filterStructureList = (list, filter = '') => {
  let ids = list.filter(item =>
    item.external_id.toLowerCase().includes(filter.toLowerCase()) ||
    item.name.toLowerCase().includes(filter.toLowerCase())
  ).map(item => item.external_id);

  return ids;
};

export {initialState, reducer as structure};
