/**
 * [nb][/nb] - no break element
 * [zwsp /] - zero width space
 * [shy /] - shy element
 * [entity:* /] - entity to specified character (example: [entity:amp /] => &)
 * [b][/b] - bold element
 * [br] - line breaks and break element
 * [sub][/sub] - sub text element
 * [sup][/sup] - sup text element
 * [*][*]list[/*][/**] - list element
 * [quote]quote[/quote] - quote element
 * [link=href]link[/link] - link element
 * [mail=address]text[/mail] - link element
 * [amp /] - ampersand element
 *
 *
 * BESCHREIBUNG:
 *
 * fromHtmlRegexp           -->  Such-Regex im Editor (Nach manuellem Einfügen)
 * fromHtmlAssembler        -->  End-String zum Speichern
 *
 * toHtmlRegexp             -->  Such-Regex im Editor (initial, was vom Server kommt)
 * toHtmlAssembler          -->  End-String im Editor (initial, was angezeigt wird)
 *
 * toStyledTagsAssembler    -->  Tag-Anzeigen String
 */

const parsers = [
  {
    type: 'nb',
    description: 'No-breaks',
    // 'fromHtmlRegexp': /(.*)<span class="nobreak">(.*)<\/span>(.*)/,

    fromHtmlRegexp: /(.*)<span class="nobreak"(.*)>(.*)<\/span>(.*)/,
    // 'fromHtmlAssembler': '$1[nb]$2[/nb]$3',
    fromHtmlAssembler: '$1[nb]$3[/nb]$4',

    toHtmlRegexp: /(.*)\[nb\](.*)\[\/nb\](.*)/,
    toHtmlAssembler: '$1<span class="nobreak" title="nb">$2</span>$3',

    toStyledTagsAssembler: '$1<span class="nobr styled-tag">$2</span>$3',
    parseBackToEntity: '$1<span class="nobreak" title="nb">$2</span>$3'
  },
  {
    type: 'zwsp',
    description: 'Zero-width space',

    // 'fromHtmlRegexp': /&#8203;/,
    // fromHtmlRegexp: /(.*)<span class="zwsp(.*)"(.*)>(.*)<\/span>(.*)/,
    fromHtmlRegexp: /(.*)<span class="zwsp(.*)"(.*)>(((?!<span)[\s\S])*)<\/span>(.*)/,
    fromHtmlAssembler: '$1[zwsp /]$6',

    toHtmlRegexp: /(.*)\[zwsp \/\](.*)/,
    toHtmlAssembler: '$1<span class="zwsp">|</span>$2',

    toStyledTagsAssembler: '$1<span class="zwsp styled-tag"></span>$2',
    parseBackToEntity: '$1&#8203;$5'
  },
  {
    type: 'shy',
    description: 'Shy (soft hyphen)',

    // HTML PARSEN:
    // 'fromHtmlRegexp': /&shy;/,
    // fromHtmlRegexp: /(.*)<span class="shy(.*)"(.*)>(.*)<\/span>(.*)/,
    fromHtmlRegexp: /(.*)<span class="shy(.*)"(.*)>(((?!<span)[\s\S])*)<\/span>(.*)/,
    fromHtmlAssembler: '$1[shy /]$6',

    // CODE Parsen:
    toHtmlRegexp: /(.*)\[shy \/\](.*)/,
    toHtmlAssembler: '$1<span class="shy">-</span>$2',

    toStyledTagsAssembler: '$1<span class="shy styled-tag " data="toStyledTagsAssembler"></span>$2',

    parseBackToEntity: '$1&shy;$5'
  },
  {
    type: 'line-break',
    description: 'Line breaks',

    fromHtmlRegexp: /<br>|<br\/>|<br \/>/,
    fromHtmlAssembler: '[br]',

    toHtmlRegexp: /\[br\]/,
    toHtmlAssembler: '<br />',

    toStyledTagsAssembler: '<span class="break styled-tag"></span>',
    parseBackToEntity: '<br />'
  },
  {
    type: 'bold',
    description: 'Bold & strong Text',

    fromHtmlRegexp: /(.*)(?:<b>|<strong>)(.*)(?:<\/b>|<\/strong>)(.*)/,
    fromHtmlAssembler: '$1[b]$2[/b]$3',

    toHtmlRegexp: /(.*)\[b\](.*)\[\/b\](.*)/,
    toHtmlAssembler: '$1<b>$2</b>$3',

    toStyledTagsAssembler: '$1<span class="bold styled-tag">$2</span>$3',
    parseBackToEntity: '$1<b>$2</b>$3'
  },
  {
    type: 'sub',
    description: 'Subtext',

    fromHtmlRegexp: /(.*)<sub>(.*)<\/sub>(.*)/,
    fromHtmlAssembler: '$1[sub]$2[/sub]$3',

    toHtmlRegexp: /(.*)\[sub\](.*)\[\/sub\](.*)/,
    toHtmlAssembler: '$1<sub>$2</sub>$3',

    toStyledTagsAssembler: '$1<span class="sub styled-tag">$2</span>$3',
    parseBackToEntity: '$1<sub>$2</sub>$3'
  },
  {
    type: 'sup',
    description: 'Supertext',

    fromHtmlRegexp: /(.*)<sup>(.*)<\/sup>(.*)/,
    fromHtmlAssembler: '$1[sup]$2[/sup]$3',

    toHtmlRegexp: /(.*)\[sup\](.*)\[\/sup\](.*)/,
    toHtmlAssembler: '$1<sup>$2</sup>$3',

    toStyledTagsAssembler: '$1<span class="sup styled-tag">$2</span>$3',
    parseBackToEntity: '$1<sup>$2</sup>$3'
  },
  // {
  //   type: 'list',
  //   description: 'Lists (top-level element only)',
  //
  //   fromHtmlRegexp: /(.*)<ul>(.*)<\/ul>(.*)/,
  //   fromHtmlAssembler: '$1[**]$2[/**]$3',
  //
  //   toHtmlRegexp: /(.*)\[\*\*\](.*)\[\/\*\*\](.*)/,
  //   toHtmlAssembler: '$1<ul>$2</ul>$3',
  //
  //   toStyledTagsAssembler: '$1<span class="ulelement styled-tag">$2</span>$3',
  //   parseBackToEntity: '$1<ul>$2</ul>$3'
  // },
  // {
  //   type: 'list-item',
  //   description: 'List items',
  //
  //   fromHtmlRegexp: /(.*)<li>(.*)<\/li>(.*)/,
  //   fromHtmlAssembler: '$1[*]$2[/*]$3',
  //
  //   toHtmlRegexp: /(.*)\[\*\](.*)\[\/\*\](.*)/,
  //   toHtmlAssembler: '$1<li>$2</li>$3',
  //
  //   toStyledTagsAssembler: '$1<span class="listitem styled-tag">$2</span>$3',
  //   parseBackToEntity: '$1<li>$2</li>$3'
  // },
  {
    type: 'list-start',
    description: 'Lists (top-level element only)',

    fromHtmlRegexp: /<ul>/,
    fromHtmlAssembler: '[**]',

    toHtmlRegexp: /\[\*\*\]/,
    toHtmlAssembler: '<ul>',

    toStyledTagsAssembler: '<span class="ulelement styled-tag">',
    parseBackToEntity: '<ul>'
  },
  {
    type: 'list-end',
    description: 'Lists (top-level element only)',

    fromHtmlRegexp: /<\/ul>/,
    fromHtmlAssembler: '[/**]',

    toHtmlRegexp: /\[\/\*\*\]/,
    toHtmlAssembler: '</ul>',

    toStyledTagsAssembler: '</span>',
    parseBackToEntity: '</ul>'
  },
  {
    type: 'list-item-start',
    description: 'List items',

    fromHtmlRegexp: /<li>/,
    fromHtmlAssembler: '[*]',

    toHtmlRegexp: /\[\*\]/,
    toHtmlAssembler: '<li>',

    toStyledTagsAssembler: '<span class="listitem styled-tag">',
    parseBackToEntity: '<li>'
  },
  {
    type: 'list-item-end',
    description: 'List items',

    fromHtmlRegexp: /<\/li>/,
    fromHtmlAssembler: '[/*]',

    toHtmlRegexp: /\[\/\*\]/,
    toHtmlAssembler: '</li>',

    toStyledTagsAssembler: '</span>',
    parseBackToEntity: '</li>'
  },
  {
    type: 'quote',
    description: 'Quote / Blockquote',

    fromHtmlRegexp: /(.*)<blockquote>(.*)<\/blockquote>(.*)/,
    fromHtmlAssembler: '$1[quote]$2[/quote]$3',

    toHtmlRegexp: /(.*)\[quote\](.*)\[\/quote\](.*)/,
    toHtmlAssembler: '$1<blockquote>$2</blockquote>$3',

    toStyledTagsAssembler: '$1<span class="blockquote styled-tag">$2</span>$3',
    parseBackToEntity: '$1<blockquote>$2</blockquote>$3'
  },
  {
    type: 'link',
    description: 'Links',

    // fromHtmlRegexp: /(.*)<a href="(?!mailto:)(.*)">(.*)<\/a>(.*)/,
    // fromHtmlAssembler: '$1[link=$2]$3[/link]$4',
    fromHtmlRegexp: /(.*)<a href="((?!mailto:)("|\s|title|(?!")[^"]*)).*">(.*)<\/a>(.*)/,
    fromHtmlAssembler: '$1[link=$2]$4[/link]$5',

    toHtmlRegexp: /(.*)\[link=(.*)\](.*)\[\/link\](.*)/,
    toHtmlAssembler: '$1<a href="$2" title="link:$2">$3</a>$4',

    toStyledTagsAssembler:
      '$1<span class="ancorstart styled-tag"></span><span class="ancorlink styled-tag">' +
      '$2</span>$3<span class="ancorend styled-tag"></span>$4',
    parseBackToEntity: '$1<a href="$2" title="link:$2">$3</a>$4'
  },
  {
    type: 'mail',
    description: 'Mail / Mailto',

    // fromHtmlRegexp: /(.*)<a href="mailto:(.*)">(.*)<\/a>(.*)/,
    // fromHtmlAssembler: '$1[mail=$2]$3[/mail]$4',
    fromHtmlRegexp: /(.*)<a href="mailto:(("|\s|title|(?!")[^"]*)).*">(.*)<\/a>(.*)/,
    fromHtmlAssembler: '$1[mail=$2]$4[/mail]$5',

    toHtmlRegexp: /(.*)\[mail=(.*)\](.*)\[\/mail\](.*)/,
    toHtmlAssembler: '$1<a href="mailto:$2" title="mailto:$2">$3</a>$4',

    toStyledTagsAssembler:
      '$1<span class="mailtostart styled-tag"></span><span class="mailtolink styled-tag">' +
      '$2</span>$3<span class="mailtoend styled-tag"></span>$4',
    parseBackToEntity: '$1<a href="mailto:$2" title="mailto:$2">$3</a>$4'
  },
  {
    type: 'ampersand',
    description: 'Ampersand',

    fromHtmlRegexp: /&amp;/,
    fromHtmlAssembler: '[amp /]',

    toHtmlRegexp: /\[amp ?\/\]/,
    toHtmlAssembler: '&amp;',

    toStyledTagsAssembler: '<span class="amp styled-tag"></span>',
    parseBackToEntity: '$1&amp;$2'
  },
  {
    type: 'single quote',
    description: 'Single Quote ()  ==> wird nicht genutzt',
    fromHtmlRegexp: /&#x27;/,
    fromHtmlAssembler: '\'',
    toStyledTagsAssembler: '<span class="singlequote styled-tag"></span>'
  }
];

// function isFunction(functionToCheck) {
//   return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
// }

const parseHTML = string => {
  // if (isFunction(parser.fromHtmlRegexp)) {
  //
  // }
  parsers.forEach(parser => {
    while (parser.fromHtmlRegexp && parser.fromHtmlRegexp.test(string)) {
      string = string.replace(parser.fromHtmlRegexp, parser.fromHtmlAssembler);
    }
  });
  return string;
};

const parseBackWithEntities = string => {
  parsers.forEach(parser => {
    while (parser.fromHtmlRegexp && parser.fromHtmlRegexp.test(string)) {
      string = string.replace(parser.fromHtmlRegexp, parser.parseBackToEntity);
    }
  });
  return string;
};

const replaceRecursive = (string, pattern, replacement) => {
  let returnString = string;
  while (pattern.test(returnString)) {
    returnString = returnString.replace(pattern, replacement);
  }
  return returnString;
};

const parseCode = string => {
  parsers.forEach(parser => {
    while (parser.toHtmlRegexp && parser.toHtmlRegexp.test(string)) {
      string = string.replace(parser.toHtmlRegexp, parser.toHtmlAssembler);
    }
  });
  // let listRegex = /<li>(((?!<ul>|<\/li>)[\s\S])*)(((?!<\/li>)[\s\S])*(<ul>[\s\S]*<\/ul>)([\s\S]*))+(<\/li>)/g;
  let listRegex = /([.\s\S]*?)<\/li><ul>([.\s\S]*?)<\/ul>([.\s\S]*?)/g;
  string = replaceRecursive(string, listRegex, '$1<ul>$2</ul></li>$3');

  return string;
};

const parseTags = string => {
  parsers.forEach(parser => {
    while (parser.toHtmlRegexp && parser.toHtmlRegexp.test(string)) {
      string = string.replace(parser.toHtmlRegexp, parser.toStyledTagsAssembler);
    }
  });
  return string;
};

const stripWrapper = string => {
  return string.replace(/<p>([\s\S]*)<\/p>/, '$1');
};

const stripAllTags = string => {
  return string.replace(/<\/p>([\b \b]|[\n])*?<p>/g, '<br>').replace(/<p>|<\/p>/g, '');
};

const stripHtmlAndInsertNewLine = string => {
  return string.replace(/<\/p>/g, '\n').replace(/<[^>]+>/g, '');
};


/* Kann keine Verschachtelungen! */
const transformNobreak = input => {
  let output = '';
  let pos = 0;
  while (pos <= input.length) {
    let x = input.indexOf('[nb]', pos);
    let y = input.indexOf('[/nb]', pos);
    if (x >= 0 && y > x) {
      output += input.substring(pos, x);
      output += input.substring(x, y + 1).replace(/\s/g, '&nbsp;');
    } else {
      return output + input.substring(pos, input.length);
    }
    pos = y + 1;
  }
  return output;
};


const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const markSearchTerm = (string, searchTerm) => {
  if (searchTerm === '') {
    return string;
  }

  let escapedSearchTerm = escapeRegExp(searchTerm);

  const fromRegexp = new RegExp('(' + escapedSearchTerm + ')', 'ig');
  const toRecipe = '<span class="mark-search">$1</span>';
  return string.replace(fromRegexp, toRecipe);
};

/* Wird vorerst nicht benutzt */
const getElementInDom = (targetDocument, collectionId, textId) => {
  return targetDocument.querySelectorAll(
    '[collection-id="' + collectionId + '"][text-id="' + textId + '"]'
  );
};

export {
  parseCode,
  parseTags,
  parseHTML,
  parseBackWithEntities,
  stripWrapper,
  stripAllTags,
  stripHtmlAndInsertNewLine,
  transformNobreak,
  markSearchTerm,
  getElementInDom
};
