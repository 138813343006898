import React, {Component} from 'react';
import {connect} from 'react-redux';
import {List} from 'immutable';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import AsyncSwitch from '../../general/AsyncSwitch';
import LoadingBar from '../../general/DumbComponent';
import {ProcessStatus} from '../../../utils/Constants';
import {fetchPermissions} from '../../../actions/permissions';
import {
  fetchRolePermissions,
  changeRolePermissions,
  resetRolePermissions
} from '../../../actions/roles';

import {Divider, List as RMList, Subheader, ListItemControl} from 'react-md';

import styles from '../../../styles/role_permissions.scss';

@withTranslation(['roles', 'general'], {wait: true})
class RolePermissions extends Component {
  constructor (props) {
    super(props);

    this.state = {
      listOfChapters: null,
      processing: false
    };
  }

  componentWillMount () {
    const {permissions, fetchingPermissions, match} = this.props;

    if (permissions.size === 0 && fetchingPermissions == ProcessStatus.INITIAL) {
      this.props.fetchPermissions();
    } else {
      let roleId = parseInt(match.params.roleId);
      this.props.fetchRolePermissions(roleId);
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {permissions, fetchingPermissions, fetchingRolePermissions, match} = nextProps;

    if (
      fetchingPermissions == ProcessStatus.FINISHED &&
      fetchingPermissions != this.props.fetchingPermissions
    ) {
      let roleId = parseInt(match.params.roleId);
      this.props.fetchRolePermissions(roleId);
    } else if (
      fetchingRolePermissions == ProcessStatus.FINISHED &&
      fetchingRolePermissions != this.props.fetchingRolePermissions
    ) {
      this.createRowLists(permissions);
    }
  }

  componentWillUnmount () {
    this.props.resetRolePermissions();
  }

  createRowLists = permissions => {
    let listOfChapters = [];
    let chapter = [];

    permissions.map(permission => {
      chapter.push(permission);

      listOfChapters.push({
        list: chapter
      });
      chapter = [];
    });

    this.setState({
      listOfChapters: List(listOfChapters)
    });
  };

  handleChange = (permissionId, allow) => {
    const {changingPermission} = this.props;

    if (changingPermission != ProcessStatus.STARTED) {
      let roleId = parseInt(this.props.match.params.roleId);
      this.props.changeRolePermissions(roleId, permissionId, allow);
    }

    this.setState({processing: true});
  };

  /* eslint-disable no-param-reassign */
  renderPermissionsList = permissions => {
    const { t } = this.props;
    const {processing} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    permissions = permissions.map(permission => {
      if (permission.category == null) {
        permission.category = t(`${generalLNS}:other`);
      }
      return permission;
    });

    permissions = permissions.sort((a, b) => {
      return a.category.localeCompare(b.category);
    });

    let currentCategory = null;
    let extra = null;
    let firstTime = true;

    let className = 'md-cell md-paper md-paper--1 md-cell--12 ' + styles.boxShadowNone;

    return (
      <RMList className={className}>
        {permissions.map((permission, index) => {
          if (permission.category != currentCategory) {
            currentCategory = permission.category;

            if (firstTime) {
              firstTime = false;
              extra = <Subheader key={'subheader' + index} primaryText={permission.category}/>;
            } else {
              extra = [
                <Divider key={'divider' + index} inset/>,
                <Subheader key={'subheader' + index} primaryText={permission.category}/>
              ];
            }
          } else {
            extra = null;
          }

          let activeIndex = this.props.rolePermissions.findIndex(rolePermission => {
            return permission.id === rolePermission.id;
          });
          permission.active = activeIndex !== -1;

          return [
            extra,
            <ListItemControl
              key={'listItemControl' + permission.id}
              secondaryAction={
                <AsyncSwitch
                  key={'switch' + permission.id}
                  id={permission.id}
                  name={permission.name}
                  label={permission.display_name}
                  aria-label={'Active'}
                  checked={permission.active}
                  value={permission.active}
                  disabled={processing}
                  tabIndex={-1}
                  onChange={() => this.handleChange(permission.id, !permission.active)}
                  // labelBefore
                />
              }
            />
          ];
        })}
      </RMList>
    );
  };

  /* eslint-enable no-param-reassign */

  render () {
    const {permissions, fetchingPermissions, fetchingRolePermissions, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const {listOfChapters} = this.state;

    if (
      fetchingPermissions == ProcessStatus.STARTED ||
      fetchingRolePermissions == ProcessStatus.STARTED
    ) {
      return <LoadingBar show="true" message={ t(`${generalLNS}:loading_bar_roles`) }/>;
    }
    if (listOfChapters == null) {
      return <div>{ t(`${generalLNS}:no_permissions_found`) }</div>;
    }

    return this.renderPermissionsList(permissions);
  }
}

function mapStateToProps (state) {
  return {
    permissions: state.getIn(['permissions', 'permissions']),
    fetchingPermissions: state.getIn(['permissions', 'fetchingPermissions']),
    rolePermissions: state.getIn(['roles', 'permissions']),
    fetchingRolePermissions: state.getIn(['roles', 'fetchingRolePermissions']),
    changingPermission: state.getIn(['roles', 'changingPermission'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {fetchPermissions, fetchRolePermissions, changeRolePermissions, resetRolePermissions}
  )(RolePermissions)
);
