import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardActions, CardText, CardTitle, Button } from 'react-md';
import {withTranslation} from 'react-i18next';
import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';


@withTranslation(['project', 'general'], {wait: true})
class ImportUpload extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    console.log('componentWillMount, this.props = ', this.props);
    // this.validateProject();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    console.log('componentWillReceiveProps, nextProps = ', nextProps);

    /*
        if (nextProps.validatingProject == ProcessStatus.FINISHED &&
            nextProps.validatingProject != this.props.validatingProject) {
            this.props.onChange("validated");
        }
        */
  }

  validateProject = () => {
    console.log('validateProject', this.props);
    /*
        const {uploadedFile} = this.props;

        this.props.validateProject({
            package_type: 'jsbeam_1_0',
            uuid: uploadedFile.uuid,
            filename: uploadedFile.name,
        });
        */
  };

  discardProject = () => {
    console.log('discardProject', this.props);
    /*
        //console.log("discardProject");
        this.props.onChange("");
        this.props.resetCollectedInfos();
        */
  };

  render () {
    // const {collectedInfos, uploadedFile, importingProject, error} = this.props;
    const {collectedInfos, importingProject, error, values, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let errorMessage = t(`${generalLNS}:error`);

    console.log('error = ', error);

    if (error && error.message) {
      console.log('error.message = ', error.message);
      errorMessage = error.message;
    }

    // if(error && error.notify && error.notify.error) {
    //   errorMessage = error.notify.error;
    // }

    let identifier = collectedInfos.get('identifier') || '';
    let version = collectedInfos.get('version') || '';

    let subtitle = collectedInfos.size > 0 ? identifier + ' ' + version : '';


    return (
      <Card className="md-block-centered">
        <CardTitle
          title={ t(`${generalLNS}:title`) }
          // title={this.props.values.name}
          // title={values.name}
          subtitle={subtitle}
        />
        <CardText>
          {importingProject == ProcessStatus.STARTED && (
            <LoadingBar show="true" message={ t('importing_project') }/>
          )}
          {importingProject == ProcessStatus.FINISHED && (
            <div>
              <p>{ t('project_imported') }</p>
            </div>
          )}
          {importingProject == ProcessStatus.FAILED && (
            <div>
              <p>{ t('import_failed') }: {errorMessage}</p>
            </div>
          )}
        </CardText>
        {importingProject == ProcessStatus.FAILED && (
          <CardActions>
            <Button key="discard" flat onClick={this.discardProject}>
              { t(`${generalLNS}:discard`) }
            </Button>
            <Button key="try_again" flat onClick={this.validateProject}>
              { t(`${generalLNS}:try_again`) }
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    importingProject: state.getIn(['projects', 'importingProject']),
    collectedInfos: state.getIn(['projects', 'collectedInfos']),
    error: state.getIn(['projects', 'error'])
  };
}

export default connect(
  mapStateToProps,
  {
    // validateProject,
  }
)(ImportUpload);
