// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n.drawer.editDrawer {\n  overflow-y: auto;\n  height: calc(100vh - 64px);\n}\n\n.drawer.marginTop {\n  margin-top: 10px;\n}\n\n.drawer .paddingLeftRight {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.drawer .head {\n  position: relative;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  padding: 0 20px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n\n.drawer .body {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: auto;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.drawer .headlines {\n  flex-grow: 2;\n  flex-shrink: 1;\n  flex-basis: auto;\n  display: flex;\n  flex-direction: column;\n}\n\n.drawer .headlines[class*=\"no-margin\"] h2 {\n  margin-top: 0;\n}\n\n.drawer .headlines h2 {\n  font-size: 32px;\n  margin-top: 20px;\n  font-weight: bold;\n}\n\n.drawer .headlines h4 {\n  color: #8e9295;\n}\n", ""]);
// Exports
exports.locals = {
	"drawer": "drawer",
	"editDrawer": "editDrawer",
	"marginTop": "marginTop",
	"paddingLeftRight": "paddingLeftRight",
	"head": "head",
	"body": "body",
	"headlines": "headlines"
};
module.exports = exports;
