import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import {renderSwitch, renderTextField} from '../../general/BasicFormComponents';
import {fetchClients} from '../../../actions/clients';
import styles from '../../../styles/translation_detail.scss';
import {withTranslation} from 'react-i18next';

@withTranslation(['clients', 'general' /* , 'types' */], {wait: true})
class ClientPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  componentWillMount () {
  }

  UNSAFE_componentWillReceiveProps () {
  }

  render () {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const typesLNS = 'types'; // generalLanguageNamespaceSource
    const className = 'md-grid ' + styles.translationPrime;

    return (
      <div className={className}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{ t('add_client') }</h2>
          </div>
        </div>

        <div className={'formContentWrapper'}>
          <Field
            id="name"
            name="name"
            label={ t(`${generalLNS}:name`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="description"
            name="description"
            label={ t(`${generalLNS}:description`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="parent_id"
            name="parent_id"
            label={ t(`${generalLNS}:parent_id`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="active"
            name="active"
            label={ t(`${generalLNS}:active`) }
            type="switch"
            className="md-cell md-cell--12"
            component={renderSwitch}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    clients: state.getIn(['clients', 'clients'])
  };
}

export default connect(
  mapStateToProps,
  {fetchClients}
)(ClientPrime);
