import * as Rx from 'rxjs';

import { ProfileAction } from '../utils/ActionTypes';

const initiateEditProfile = () => {
  return {
    type: ProfileAction.EDIT_PROFILE_INITIATED
  };
};

const editProfile = user => {
  return {
    type: ProfileAction.EDIT_PROFILE,
    user: user
  };
};

const editProfileFulfilled = user => {
  return {
    type: ProfileAction.EDIT_PROFILE_FULFILLED,
    user: user
  };
};

const editProfileRejected = error => {
  return Rx.Observable.of({
    type: ProfileAction.EDIT_PROFILE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export { initiateEditProfile, editProfile, editProfileFulfilled, editProfileRejected };
