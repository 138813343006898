import { GlobalDialogAction } from '../utils/ActionTypes';
import { NotificationType } from '../utils/Constants';

const showDialog = (dialogType, message, title = null, duration = 5000) => {
  return {
    type: GlobalDialogAction.SHOW_DIALOG,
    dialogType: dialogType,
    message: message,
    title: title,
    duration: duration
  };
};

const showError = message => {
  let title = message.title || 'Error';
  return showDialog(NotificationType.ERROR, message, title);
};

const showWarning = message => {
  let title = message.title || 'Warning';
  return showDialog(NotificationType.WARNING, message, title);
};

const removeDialog = identifier => {
  console.log('removeDialog', identifier);

  return {
    type: GlobalDialogAction.REMOVE_DIALOG,
    identifier: identifier
  };
};

export { showDialog, showError, showWarning, removeDialog };
