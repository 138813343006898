import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {diffLines, formatLines} from 'unidiff';
import {parseDiff, Diff, Hunk} from 'react-diff-view';
import {convertDraftRawToHtmlString} from './editor/helper/convertingForEditorWorks';
import { stripHtmlAndInsertNewLine } from '../../utils/RTEParser';
import 'react-diff-view/style/index.css';

import tokenize from './tokenize';

const EMPTY_HUNKS = [];

const renderToken = (token, defaultRender, i) => {
  switch (token.type) {
    case 'space':
      // console.log(token);
      return (
        <span key={i} className="space">
          {token.children && token.children.map((token, i) => renderToken(token, defaultRender, i))}
        </span>
      );
    default:
      return defaultRender(token, i);
  }
};

export default function DiffView (props) {
  const [{type, hunks}, setDiff] = useState('');

  const tokens = useMemo(() => tokenize(hunks), [hunks]);

  useEffect(() => {
    parseText();
  }, []);

  useEffect(() => {
    parseText();
  }, [props.old, props.new]);

  const parseText = () => {
    const oldHtml = convertDraftRawToHtmlString(props.old);
    const newHtml = convertDraftRawToHtmlString(props.new);

    const oldText = stripHtmlAndInsertNewLine(oldHtml);
    const newText = stripHtmlAndInsertNewLine(newHtml);

    const diff = formatLines(diffLines(oldText, newText), {context: 3});

    const [diffText] = parseDiff(diff, {nearbySequences: 'zip'});

    setDiff(diffText);
  };

  return (
    <div className={'diff-view-wrapper'}>
      <main>
        {type &&
          <Diff
            viewType='split'
            diffType={type}
            hunks={hunks || EMPTY_HUNKS}
            tokens={tokens}
            renderToken={renderToken}
          >
            {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
          </Diff>
        }
      </main>
    </div>
  );
}

