import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {Autocomplete} from 'react-md';

class ChipAllocation extends Component {
  constructor (props) {
    super(props);

    this.state = {
      allItems: [],
      selectedItems: [],
      remainingItems: []
    };
  }

  componentWillMount () {
    this.updateData(this.props.items, this.props.input.value);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      (nextProps.items.size >= 0 && nextProps.items !== this.props.items) ||
      nextProps.input.value !== this.props.input.value
    ) {
      this.updateData(nextProps.items, nextProps.input.value);
    }
  }

  updateData = (items, inputValue) => {
    let selectedItems = [];
    let remainingItems = [];

    let sortedItems = items.sort((a, b) => {
      let nameA = a.get('name');
      let nameB = b.get('name');

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    let allItems = sortedItems.map(item => item.get('id'));

    allItems.map(id => {
      let isUserAlreadySelected = false;
      if (inputValue) {
        inputValue.map(value => {
          if (id == value.get('id')) {
            isUserAlreadySelected = true;
          }
        });
      }
      isUserAlreadySelected ? selectedItems.push(id) : remainingItems.push(id);
    });

    this.setState({
      allItems: allItems,
      selectedItems: selectedItems,
      remainingItems: remainingItems
    });
  };

  setNextItem = (selectedItems, nextItem) => {
    let items = this.props.items.filter(item => selectedItems.indexOf(item.get('id')) >= 0);

    if (this.props.asynchron) {
      items = items.map(item => {
        let it = item;
        if (it.get('id') == nextItem.get('id')) {
          it = it.set('unconfirmed', true);
        }
        return it;
      });
    }
    this.props.input.onChange(items);
  };

  addItem = id => {
    const selectedItems = [...this.state.selectedItems, id];
    let item = this.props.items.find(item => item.get('id') == id);

    if (this.props.onAdd) {
      this.props.onAdd(item);
    }
    this.setNextItem(selectedItems, item);
  };

  removeItem = item => {
    let id = item.get('id');
    const selectedItems = this.state.selectedItems;

    if (!this.props.asynchron) {
      selectedItems.splice(selectedItems.indexOf(id), 1);
    }

    if (this.props.onRemove) {
      this.props.onRemove(item);
    }

    this.setNextItem(selectedItems, item);
  };

  handleChipClick = event => {
    const {editable, locked} = this.props;

    if (editable && !locked) {
      let item = event.item;
      this.removeItem(item);
    }
  };

  handleChipUpdate = data => {
    let item = this.props.items.find(item => item.get('id') == data.get('id'));

    if (this.props.onUpdate) {
      this.props.onUpdate(data);
    }

    this.setNextItem(this.state.selectedItems, item);
  };

  render () {
    const {
      items,
      label,
      editable,
      locked,
      t
    } = this.props;
    const {selectedItems, remainingItems} = this.state;
    const Chip = this.props.chip;

    const Chips = selectedItems.map(id => {
      let item = items.find(item => {
        return parseInt(item.get('id')) === id;
      });

      return (
        <Chip
          key={id}
          item={item}
          editable={editable}
          removable={!locked}
          onClick={this.handleChipClick}
          onUpdate={this.handleChipUpdate}
        />
      );
    });
    const autocompleteData = remainingItems.map(id => {
      let item = items.find(item => item.get('id') === id);
      return item.toJSON();
    });

    return (
      <CSSTransition component="div" classNames="opacity" timeout={{exit: 150, enter: 150}}>
        <div>
          {editable &&
            <Autocomplete
              key="autocomplete"
              id="items-autocomplete"
              label={label}
              data={autocompleteData}
              dataLabel="name"
              dataValue="id"
              onAutocomplete={this.addItem}
              placeholder={this.props.placeholder}
              clearOnAutocomplete
              deleteKeys="id"
              disabled={this.props.locked}
              required={this.props.required}
              // style={{display: this.props.locked ? 'none' : ''}}
            />
          }
          <div style={{paddingTop: '20px', maxWidth: '450px'}}>
            {Chips}
          </div>
        </div>
      </CSSTransition>
    );
  }

  static propTypes = {
    chip: PropTypes.func.isRequired
  };

  static defaultProps = {
    editable: true
  };
}

export default connect()(ChipAllocation);
