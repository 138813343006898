import * as Rx from 'rxjs';

import { UploaderAction } from '../utils/ActionTypes';

const discardUpload = uuid => {
  return {
    type: UploaderAction.DISCARD_UPLOAD,
    uuid: uuid
  };
};

const discardUploadFulfilled = uuid => {
  return {
    type: UploaderAction.DISCARD_UPLOAD_FULFILLED,
    uuid: uuid
  };
};

const discardUploadRejected = error => {
  return Rx.Observable.of({
    type: UploaderAction.DISCARD_UPLOAD_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export { discardUpload, discardUploadFulfilled, discardUploadRejected };
