// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.notificationWrapper {\n  position: fixed;\n  margin-top: 3%;\n  right: 6%;\n  z-index: 1000;\n}\n\n.notification {\n  position: relative;\n  margin: 10px 0px 0px;\n  right: 0px;\n  height: 109px;\n  border-radius: 10px;\n  width: 250px;\n}\n\n.success {\n  border-top: 2px solid #369cc7;\n  background-color: #e8f0f4;\n  color: #41555d;\n}\n\n.success .notification_message {\n  text-align: center;\n  padding-top: 20px;\n}\n\n.neutral {\n  border-top: 2px solid #369cc7;\n  background-color: #e8f0f4;\n  color: #41555d;\n}\n\n.warning {\n  border-top: 2px solid #ebad1a;\n  background-color: #f9f6f0;\n  color: #5a5343;\n}\n\n.error {\n  border-top: 2px solid #ec3d3d;\n  background-color: #f4e9e9;\n  color: #412f2f;\n}\n\n.leave {\n  opacity: 1;\n}\n\n.leave.leave-active {\n  opacity: 0.01;\n  transition: opacity 300ms ease-in;\n}\n\n.enter {\n  opacity: 0.01;\n}\n\n.enter.enter-active {\n  opacity: 1;\n  transition: opacity .5s ease-in;\n}\n\n.error {\n  background-color: red;\n}\n", ""]);
// Exports
exports.locals = {
	"notificationWrapper": "notificationWrapper",
	"notification": "notification",
	"success": "success",
	"notification_message": "notification_message",
	"notificationMessage": "notification_message",
	"neutral": "neutral",
	"warning": "warning",
	"error": "error",
	"leave": "leave",
	"leave-active": "leave-active",
	"leaveActive": "leave-active",
	"enter": "enter",
	"enter-active": "enter-active",
	"enterActive": "enter-active"
};
module.exports = exports;
