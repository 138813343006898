import React, {Component} from 'react';
import textStyles from '../../../styles/text.scss';
import {withTranslation} from 'react-i18next';

@withTranslation('imprint', {wait: true})
class ImprintText extends Component {
  componentWillMount () {
  }

  renderTable (tableData) {
    return (
      <table className={textStyles.dataTable}>
        <tbody>
          {tableData.map(row => {
            return (
              <tr key={row.label}>
                <td>{row.label}</td>
                <td>{row.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render () {
    const { t } = this.props;
    const contactTable = [
      {label: t('contact_table_phone_headline'), value: '+49 (0)661-9494-0'},
      {label: t('contact_table_telefax_headline'), value: '+49 (0)661-9494-47'},
      {label: t('contact_table_info_mail_headline'), value: 'info@innovationmecom.de'},
      {label: t('contact_table_sales_mail_headline'), value: 'sales@innovationmecom.de'},
      {label: t('contact_table_jobs_mail_headline'), value: 'jobs@innovationmecom.de'}
    ];
    const procuraTable = [{label: t('procura_table_headline'), value: 'Martin Griebel'}];
    const lawTable = [
      {label: t('law_table_register_court_headline'), value: 'Amtsgericht Fulda'},
      {label: t('law_table_register_number_headline'), value: 'HRB 1248'},
      {label: t('law_table_vat_identification_number_headline'), value: 'DE 811690174'}
    ];
    return (
      <div className={textStyles.imprint}>
        <h3 className={textStyles.h3} style={{marginTop: '0', paddingTop: '0'}}>{ t('headline') }</h3>
        <h4>{ t('company_name') }</h4>
        <div dangerouslySetInnerHTML={{__html: t('content_company')}}></div>
        {this.renderTable(contactTable)}

        <h4>{ t('managing_directors_name') }</h4>
        <p>{ t('managing_directors') }</p>
        {this.renderTable(procuraTable)}
        {this.renderTable(lawTable)}
        <h4>{ t('responsible_for_content_name') }</h4>
        <p>{ t('responsible_for_content') }</p>
        <h4>{ t('limitation_of_liability_name') }</h4>
        <div dangerouslySetInnerHTML={{__html: t('limitation_of_liability')}}></div>
      </div>
    );
  }
}

export default ImprintText;
