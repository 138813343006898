import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CardTitle, CardText, Button} from 'react-md';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {fetchCroppedImage} from '../../actions/image';

import projectCardStyles from '../../styles/projects/project_card.scss';


@withTranslation(['project', 'general'], {wait: true})
class HighlightedProjectCard extends Component {

  componentDidMount () {
    this.props.fetchCroppedImage({projectId: this.props.project.id})
  }

  render () {
    const {project, t, projectImages} = this.props;
    const projectImage = projectImages.find(elem => elem.projectId === project.id);

    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const dummyPath = require('../../images/project-dummy.jpg');
    const dummyImage = new Image();
    dummyImage.src = dummyPath;

    let image = dummyPath;
    let title = '';
    let type = '';
    let text = '';
    if (project) {
      if ((projectImage !== undefined) && (projectImage.cropped !== undefined)){
        image = projectImage.cropped;
      }
      title = project ? project.name : 'Dummy-Title';
      type = project ? project.type : 'Dummy-Type';
      text = project ? project.description : 'Dummy-Text';
    }

    // var dummyWidth = dummyImage.naturalWidth;
    // var dummyHeight = dummyImage.naturalHeight;
    return (
      <div
        className={projectCardStyles.projectCard}
        onClick={e => {
          if (e.target.getAttribute('data-unpin') === 'true') {
            return false;
          }
          this.props.onClick(project.id);
        }}>
        <div
          className={projectCardStyles.imageWrapper}
          style={{backgroundImage: 'url(' + image + ')'}}
        >
          <img alt="project image" src={image}/>
        </div>
        <div className={projectCardStyles.cardBottom}>
          <CardText className={projectCardStyles.text}>
            {text} - {type}
          </CardText>
          <CardTitle className={projectCardStyles.title} title={title}/>
          <Button
            flat
            className={projectCardStyles.button}>{t(`${generalLNS}:show`)}</Button>
        </div>
        {
          this.props.removePinnedProject &&
          <Button icon primary className="heardPinnedProject">
            favorite
          </Button>
        }
        {
          this.props.removePinnedProject &&
          <Button
            icon
            primary
            className="removePinnedProjectBtn"
            data-unpin="true"
            title={'remove favorit'}
            onClick={e => {
              e.preventDefault();
              this.props.removePinnedProject(project.id);
            }}>
            close
          </Button>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectImages: state.getIn(['image', 'projectImages'])
  };
}
export default withRouter(connect(
  mapStateToProps,
  {
    fetchCroppedImage
  }
)(HighlightedProjectCard));
