import { TranslationToolAction } from '../utils/ActionTypes';

/* eslint-disable */
export default function () {
  return next => action => {
    let serverData, browserData, itemData, dataSection, element, contentCompareWith, contentFrom, contentTo;
    const propExceptions = [];
    switch (action.type) {
      case TranslationToolAction.FETCH_TRANSLATIONS_FULFILLED:
        serverData = action.translations.data;

        browserData = [];
        for (const section in serverData) {
          dataSection = {data: [], id: ''};
          if (!propExceptions.includes(section)) {
            for (const id in serverData[section]) {
              element = {from: {}, to: {}};

              itemData = serverData[section][id];

              if (itemData) {
                let index = 0;

                for (const key of Object.keys(itemData)) {
                  switch (index) {
                    case 0:
                      contentFrom = contentTo = itemData[key].text;
                      break;
                    case 1:
                      if (key.substr(0, 8) === "snapshot") {
                        contentCompareWith = itemData[key].text;
                      } else {
                        contentTo = itemData[key].text;
                      }
                      break;
                    default:
                      if (key.substr(0, 8) === "snapshot") {
                        contentCompareWith = itemData[key].text;
                      }
                  }
                  index++;
                }
              }

              if (contentCompareWith !== undefined) {
                element.compareWith = {id: id, content: contentCompareWith};
              }
              element.from = {id: id, content: contentFrom};
              element.to = {id: id, content: contentTo};
              dataSection.data.push(element);
            }
            dataSection.id = section;
            browserData.push(dataSection);
          }
        }
        action.translations = browserData;
        break;
      case TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED:
        serverData = action.translations.data;

        browserData = [];
        for (const section in serverData) {
          dataSection = {data: [], id: ''};
          if (!propExceptions.includes(section)) {
            for (const id in serverData[section]) {
              element = {from: {}, to: {}};

              itemData = serverData[section][id];

              if (itemData) {
                let index = 0;

                for (const key of Object.keys(itemData)) {
                  switch (index) {
                    case 0:
                      contentFrom = contentTo = itemData[key].text;
                      break;
                    case 1:
                      if (key.substr(0, 8) === "snapshot") {
                        contentCompareWith = itemData[key].text;
                      } else {
                        contentTo = itemData[key].text;
                      }
                      break;
                    default:
                      if (key.substr(0, 8) === "snapshot") {
                        contentCompareWith = itemData[key].text;
                      }
                  }
                  index++;
                }
              }

              if (contentCompareWith !== undefined) {
                element.compareWith = {id: id, content: contentCompareWith};
              }
              element.from = {id: id, content: contentFrom};
              element.to = {id: id, content: contentTo};
              dataSection.data.push(element);
            }
            dataSection.id = section;
            browserData.push(dataSection);
          }
        }
        action.translations = browserData;
        break;
      case TranslationToolAction.SAVE_TRANSLATION_SUCCESS:
        serverData = action.translation;
        browserData = {};
        browserData = {
          section: serverData.collection,
          id: serverData.textfield_id,
          value: serverData.text,
          textsForLocalStorage: serverData.textsForLocalStorage
        };
        action.translation = browserData;
        break;
      default:
        break;
    }
    next(action);
  };
}
/* eslint-enable */
