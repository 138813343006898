import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import store from '../utils/store';
import {isAuthenticated, PrivateRoute, RedirectToLogin} from './AuthHelper';
import {Routes} from '../utils/Constants';
import {aclResources} from '../utils/Permissions';
import NavigationContentWrapper from '../components/layout/NavigationContentWrapper';
import NotificationSystem from '../components/general/NotificationSystem';
import Dashboard from '../components/layout/Dashboard';
import ListProjects from '../components/projects/ListProjects';
import Project from '../components/project/Project';
import ListStoryboards from '../components/storyboards/ListStoryboards';
import Storyboard from '../components/storyboard/Storyboard';
import TranslationView from '../components/wbtViewer/TranslationView';
import EditProfileContainer from '../components/profile/EditProfileContainer';
import CpNotFound from '../components/layout/NotFound';
import LoginContainer from '../components/auth/LoginContainer';
import LogoutScreen from '../components/auth/LogoutScreen';
import ListClients from '../components/clients/ListClients';
import ListUsers from './../components/users/ListUsers';
import Imprint from '../components/general/Imprint';
import Privacy from '../components/general/Privacy';
import ListPermissions from '../components/permissions/ListPermissions';
import ListRoles from '../components/roles/ListRoles';
import ListContacts from '../components/contacts/ListContacts';
import TranslationTool from '../components/translationTool/TranslationTool';
import {userFromStore, impersonatedUserFromStore} from '../actions/auth';
import { cryptedStore } from './CryptedStore';
import GlobalSnackbar from '../components/general/GlobalSnackbar';
import i18n from './i18n';

class LayoutWrapper extends Component {
  componentWillMount () {
    if (!isAuthenticated(store) && cryptedStore.get('user')) {
      let user = cryptedStore.get('user');
      store.dispatch(userFromStore(user));
      i18n.changeLanguage(user.languagecode, (err) => {
        if (err) return console.log('something went wrong loading', err);
      });
      if (user.loggedin_as) {
        let impersonatedUser = cryptedStore.get('impersonatedUser');
        store.dispatch(impersonatedUserFromStore(impersonatedUser));
      }
    }
  }

  render () {
    const ncw = (
      <NavigationContentWrapper>
        <Switch>
          <PrivateRoute exact path='/' push to={'/home'} component={Redirect}/>
          {/*
          <PrivateRoute exact path='/' push to={'/projects'} component={Redirect}/>
          */}
          <Route path='/login' component={LoginContainer}/>
          <Route path='/logout' component={LogoutScreen}/>

          <PrivateRoute path='/home' component={Dashboard}/>
          <PrivateRoute path='/projects' component={ProjectsRoutes}/>
          <PrivateRoute path='/storyboards' component={StoryboardRoutes}/>

          <PrivateRoute
            path='/profile' component={EditProfileContainer}
            withPermissions={[aclResources.profile]}/>

          <PrivateRoute
            path='/clients' component={ClientsRoutes}
            withPermissions={[aclResources.clients]}/>

          <PrivateRoute
            path='/users' component={UsersRoutes}
            withPermissions={[aclResources.users]}/>

          <PrivateRoute
            path='/permissions' component={PermissionsRoutes}
            withPermissions={[aclResources.permissions]}/>

          <PrivateRoute path='/roles' component={RolesRoutes} withPermissions={[aclResources.roles]}/>

          <PrivateRoute path='/contacts' component={ContactsRoutes} withPermissions={[aclResources.contacts]}/>


          <PrivateRoute exact path='/imprint' component={Imprint}/>
          <PrivateRoute exact path='/privacy' component={Privacy}/>

          {/* <PrivateRoute path='/test' component={TestComponent}/> */}
          <Route path='/*' component={CpNotFound}/>
        </Switch>
      </NavigationContentWrapper>
    );

    return (
      <div id="app-wrapper">
        {!isAuthenticated(store) ?
          <div>
            <Switch>
              <Route path='/login/password-reset/:token' component={LoginContainer}/>
              <Route path='/login/password-reset' component={LoginContainer}/>
              <Route path='/login/:token' component={LoginContainer}/>
              <Route path='/login' component={LoginContainer}/>
              <RedirectToLogin path='/'/>
            </Switch>
            <GlobalSnackbar />
          </div>
          :
          ncw
        }
      </div>
    );
  }
}

const ProjectsRoutes = () =>
  <Switch>
    {/* <Route exact path={Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOTATROOT} component={Project}/>*/}
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.EDIT_SNAPSHOT} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOT} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.ADD} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.IMPORT} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.IMPORT_PDF} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.EXPORT} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.EXPORT_PDF} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.EDIT} component={Project}/>
    <Route exact path={Routes.PROJECTS.EDIT} component={Project}/>
    <Route exact path={Routes.PROJECTS.UPDATE} component={Project}/>
    <Route exact path={Routes.PROJECTS.TRANSLATIONS.VIEW} component={Project}/>
    <Route exact path={Routes.PROJECTS.IMPORT} component={ListProjects}/>
    <Route exact path='/projects/:projectId' component={Project}/>
    <Route exact path='/projects' component={ListProjects}/>
  </Switch>;

const StoryboardRoutes = () =>
  <Switch>
    {/* <Route exact path='/storyboards/:projectId' component={Storyboard}/>*/}
    <Route exact path='/storyboards' component={ListStoryboards}/>
  </Switch>;

const ClientsRoutes = () =>
  <Switch>
    <Route path={Routes.CLIENTS.ADD} component={ListClients}/>
    <Route path={Routes.CLIENTS.EDIT} component={ListClients}/>
    <Route exact path='/clients' component={ListClients}/>
  </Switch>;

const UsersRoutes = () =>
  <Switch>
    <Route path={Routes.USERS.ADD} component={ListUsers}/>
    <Route path={Routes.USERS.EDIT} component={ListUsers}/>
    <Route exact path='/users' component={ListUsers}/>
  </Switch>;

const PermissionsRoutes = () =>
  <Switch>
    <Route path={Routes.PERMISSIONS.ADD} component={ListPermissions}/>
    <Route path={Routes.PERMISSIONS.EDIT} component={ListPermissions}/>
    <Route exact path='/permissions' component={ListPermissions}/>
  </Switch>;

const RolesRoutes = () =>
  <Switch>
    <Route path={Routes.ROLES.ADD} component={ListRoles}/>
    <Route path={Routes.ROLES.EDIT} component={ListRoles}/>
    <Route exact path='/roles' component={ListRoles}/>
  </Switch>;

const ContactsRoutes = () =>
  <Switch>
    <Route path={Routes.CONTACTS.ADD} component={ListContacts}/>
    <Route path={Routes.CONTACTS.EDIT} component={ListContacts}/>
    <Route exact path='/contacts' component={ListContacts}/>
  </Switch>;

class TranslationViewWrapper extends Component {
  render () {
    return (
      <div id="app-wrapper">
        <PrivateRoute
          exact path='/projects/translate/:projectId/:translationId/compare-with/:sourcesnapshotId?'
          component={TranslationView}/>
        <PrivateRoute
          exact path='/projects/translate/:projectId/:translationId/:sourceLanguageId/compare-with/:sourcesnapshotId?'
          component={TranslationView}/>
        <PrivateRoute
          exact path='/projects/translate/:projectId/:translationId/:sourceLanguageId?'
          component={TranslationView}/>
      </div>
    );
  }
}

// class StoryboardViewWrapper extends Component {
//   render () {
//     return (
//       <div id="app-wrapper">
//         <PrivateRoute
//           exact path='/storyboards/:projectId'
//           component={Storyboard}/>
//       </div>
//     );
//   }
// }

class TranslationToolWrapper extends Component {
  render () {
    return (
      <div id="app-wrapper">
        {/* <PrivateRoute
          exact path='/projects/translationtool/:viewId'
          component={*/}<TranslationTool/>
      </div>
    );
  }
}

// if you change route, you must restart the whole application (yarn start)
// we need it to create a new map for the application
export const createRoutes = () => {
  return (
    <div>
      <NotificationSystem/>
      <Switch>
        <PrivateRoute
          path='/projects/translate/:projectId/:translationId/'
          component={TranslationViewWrapper}
        />
        <PrivateRoute
          path='/storyboards/:storyboardId/:chapterId'
          component={Storyboard}
        />
        <PrivateRoute
          path='/storyboards/:storyboardId'
          component={Storyboard}
        />
        <PrivateRoute
          path='/projects/translationtool/:viewId'
          component={TranslationToolWrapper}
        />
        <Route path='/' component={LayoutWrapper}/>
      </Switch>
    </div>
  );
};
