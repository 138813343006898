import {fromJS, List, Map} from 'immutable';
import {ImageAction, StatusAction, StoryboardAction} from '../utils/ActionTypes';
import {ProcessStatus, ViewMode, CompareMode} from '../utils/Constants';

const initialState = fromJS({
  storyboards: List(),
  storyboard: null,
  currentChapter: null,
  currentChapterCollection: '',
  currentParentChapter: null,
  currentChapterId: '',
  chapter: null,
  chapterStructure: null,
  addingSite: ProcessStatus.INITIAL,
  deletingSite: ProcessStatus.INITIAL,
  fetchingChapter: ProcessStatus.INITIAL,
  fetchingStoryboard: ProcessStatus.INITIAL,
  addingTextBlock: ProcessStatus.INITIAL,
  textBlock: null,
  updatingTextBlock: ProcessStatus.INITIAL,
  deletingTextBlock: ProcessStatus.INITIAL,
  updatedTextBlock: null,
  currentContentEditorId: null,
  viewMode: ViewMode.COMPOSE,
  compareMode: CompareMode.UNIFIED,
  fetchingHistory: ProcessStatus.INITIAL,
  history: null,
  questionDialogObject: null
});

const reducer = (state = initialState, action) => {
  let textBlock;
  let siteId;
  let storyboards;
  let storyboard;
  let chapter;
  let chapterStructure;
  let index;
  let chapterIndex;
  let siteIndex;
  let textBlockId;
  let currentSiteIndex;

  console.log('REDUCER action', action);

  switch (action.type) {
    case StoryboardAction.FETCH_STORYBOARDS_FULFILLED:
      storyboards = action.payload;
      return state.set('storyboards', List(storyboards));


    case StoryboardAction.FETCH_STORYBOARD:
      return state.set('fetchingStoryboard', ProcessStatus.STARTED);


    case StoryboardAction.FETCH_STORYBOARD_FULFILLED:
      storyboard = action.payload;
      return state.set('storyboard', storyboard).set('fetchingStoryboard', ProcessStatus.FINISHED);


    case StoryboardAction.FETCH_STORYBOARD_REJECTED:
      return state.set('fetchingStoryboard', ProcessStatus.FAILED);


    case StoryboardAction.FETCH_CHAPTER:
      return state.set('fetchingChapter', ProcessStatus.STARTED);


    case StoryboardAction.FETCH_CHAPTER_REJECTED:
      return state.set('fetchingChapter', ProcessStatus.FAILED);


    case StoryboardAction.FETCH_CHAPTER_FULFILLED:
      console.log('FETCH_CHAPTER_FULFILLED', action.payload);
      chapter = action.payload;
      chapterStructure = List(chapter.sites.map(
        site => {
          return (
            {
              siteId: site.id,
              siteName: site.collection,
              siteStructure: site.text_blocks
            }
          );
        }));

      return state
        .set('fetchingChapter', ProcessStatus.FINISHED)
        .set('chapter', List(chapter.sites))
        .set('chapterStructure', chapterStructure)
        .set('currentChapterCollection', chapter.collection)
        .set('currentParentChapter', chapter.parentChapter)
        .set('currentChapterId', chapter.id);


    case StoryboardAction.ADD_TEXT_BLOCK:
      return state.set('addingTextBlock', ProcessStatus.STARTED);

    case StoryboardAction.ADD_TEXT_BLOCK_REJECTED:
      return state.set('addingTextBlock', ProcessStatus.FAILED);

    case StoryboardAction.ADD_TEXT_BLOCK_FULFILLED:
      textBlock = action.payload;
      textBlock.history = [];
      textBlock.history_count = 0;
      console.log('ADD_TEXT_BLOCK_FULFILLED', textBlock);

      // textBlock.content = textBlock.history[0].content;
      // textBlock.text_item.permanently = true;

      siteId = Number(textBlock.site_id);
      siteIndex = state.get('chapter').findIndex((obj) => obj.id === siteId);
      if (siteIndex >= 0) {
        const site = state.get('chapter').get(siteIndex);
        const newSite = JSON.parse(JSON.stringify(site));
        console.log('newSite', newSite);
        newSite.sorting.push(textBlock.id);
        newSite.text_blocks.push(textBlock);
        newSite.text_blocks.latest_text = textBlock.text_item;
        console.log('S T O R Y B O A R D, siteIndex =', siteIndex, 'newSite =', newSite);
        return state.set('chapter', state.get('chapter').set(siteIndex, newSite))
          .set('addingTextBlock', ProcessStatus.FINISHED);
      }
      return state;

    // case StoryboardAction.LOCK_TEXT_BLOCK:
    case StoryboardAction.UPDATE_TEXT_BLOCK:
      return state.set('updatingTextBlock', ProcessStatus.STARTED);

    case StoryboardAction.UPDATE_TEXT_BLOCK_REJECTED:
      return state.set('updatingTextBlock', ProcessStatus.FAILED);

    case StoryboardAction.UNLOCK_TEXT_BLOCK_FULFILLED:
      console.log('StoryboardAction.UNLOCK_TEXT_BLOCK_FULFILLED', action.payload);
      currentSiteIndex = state.get('chapter').findIndex(site => site.id === Number(action.payload.site_id));

      if (currentSiteIndex >= 0) {
        let currentSite = state.getIn(['chapter', currentSiteIndex]);

        let currentTextblockIndex = -1;
        currentTextblockIndex = currentSite.text_blocks.findIndex(
          textblock => textblock.id === action.payload.id
        );

        if (currentTextblockIndex >= 0) {
          textBlock = state.getIn([
            'chapter',
            currentSiteIndex,
            'text_blocks',
            currentTextblockIndex
          ]);
          console.log('Until here everything is fine');

          return state
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'latest_text'
            ], action.payload.text_item)
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'lock'
            ], null)

            .set('updatingTextBlock', ProcessStatus.FINISHED)
            .set('updatedTextBlock', action.payload);
        }
        return state;
      }
      return state;

    case StoryboardAction.LOCK_TEXT_BLOCK_FULFILLED:
      currentSiteIndex = state.get('chapter').findIndex(site => site.id === Number(action.payload.site_id));

      if (currentSiteIndex >= 0) {
        let currentSite = state.getIn(['chapter', currentSiteIndex]);

        let currentTextblockIndex = -1;
        currentTextblockIndex = currentSite.text_blocks.findIndex(
          textblock => textblock.id === action.payload.id
        );

        if (currentTextblockIndex >= 0) {
          textBlock = state.getIn([
            'chapter',
            currentSiteIndex,
            'text_blocks',
            currentTextblockIndex
          ]);
          console.log('Until here everything is fine');

          return state
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'latest_text'
            ], action.payload.text_item)
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'lock'
            ], action.payload.lock)
            .set('updatingTextBlock', ProcessStatus.FINISHED)
            .set('updatedTextBlock', action.payload);
        }
        return state;
      }
      return state;

    case StoryboardAction.UPDATE_TEXT_BLOCK_FULFILLED:
      console.log('1. UPDATE_TEXT_BLOCK_FULFILLED action', action.payload);
      currentSiteIndex = state.get('chapter').findIndex(site => site.id === Number(action.payload.site_id));

      if (currentSiteIndex >= 0) {
        let currentSite = state.getIn(['chapter', currentSiteIndex]);

        let currentTextblockIndex = -1;
        currentTextblockIndex = currentSite.text_blocks.findIndex(
          textblock => textblock.id === action.payload.id
        );

        if (currentTextblockIndex >= 0) {
          textBlock = state.getIn([
            'chapter',
            currentSiteIndex,
            'text_blocks',
            currentTextblockIndex
          ]);
          console.log('Until here everything is fine');

          return state
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'latest_text'
            ], action.payload.text_item)
          // .setIn([ // TODO: @j.hernandez: irgendwie ist das hier falsch, wahrscheinlich ist es besser, wenn es außerhalb von update_text_block ist
            //   'chapter',
            //   currentSiteIndex,
            //   'text_blocks',
            //   currentTextblockIndex,
            //   'lock'
            // ], action.payload.text_item.lock)
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'history'
            ], [action.payload.text_item, ...textBlock.history])
            .setIn([
              'chapter',
              currentSiteIndex,
              'text_blocks',
              currentTextblockIndex,
              'history_count'
            ], textBlock.history_count + 1)
            .set('updatingTextBlock', ProcessStatus.FINISHED)
            .set('updatedTextBlock', action.payload);
        }
        return state;
      }
      return state;


    case StoryboardAction.DELETE_TEXT_BLOCK:
      return state.set('deletingTextBlock', ProcessStatus.STARTED);


    case StoryboardAction.DELETE_TEXT_BLOCK_REJECTED:
      return state.set('deletingTextBlock', ProcessStatus.FAILED);


    case StoryboardAction.DELETE_TEXT_BLOCK_FULFILLED:
      textBlockId = Number(action.payload.id);
      siteId = Number(action.payload.site_id);
      chapterIndex = state.get('chapter').findIndex(obj => obj.id === siteId);
      if (chapterIndex >= 0) {
        const site = state.get('chapter').get(chapterIndex);
        const newSite = JSON.parse(JSON.stringify(site));
        newSite.text_blocks = newSite.text_blocks.filter(obj => obj.id !== textBlockId);
        // state.get('chapter').set(chapterIndex, newSite);
        return state
          .set('chapter', state.get('chapter').set(chapterIndex, newSite))
          .set('deletingTextBlock', ProcessStatus.FINISHED);
      }
      return state;


    case StoryboardAction.SET_CURRENT_CHAPTER:
      return state.set('currentChapter', action.payload);

    case StoryboardAction.SET_VIEW_MODE:
      return state.set('viewMode', action.payload);

    case StoryboardAction.SET_COMPARE_MODE:
      return state.set('compareMode', action.payload);

    case StoryboardAction.SET_CURRENT_EDITOR_ID:
      console.log('reducers/contentEditor:: SET_CURRENT_EDITOR_ID!', action.id);
      return state.set('currentContentEditorId', action.id);

    case StoryboardAction.FETCH_HISTORY:
      return state.set('fetchingHistory', ProcessStatus.STARTED);


    case StoryboardAction.FETCH_HISTORY_REJECTED:
      return state.set('fetchingHistory', ProcessStatus.FAILED);


    case StoryboardAction.FETCH_HISTORY_FULFILLED:
      return state
        .set('fetchingHistory', ProcessStatus.FINISHED)
        .set('history', action.payload);

    case StoryboardAction.UPDATE_SITE:
      return state.set('updatingSite', ProcessStatus.STARTED);

    case StoryboardAction.UPDATE_SITE_REJECTED:
      return state.set('updatingSite', ProcessStatus.FAILED);

    case StoryboardAction.UPDATE_SITE_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      let newSiteFromServer = action.payload;
      siteId = Number(newSiteFromServer.id);
      siteIndex = state.get('chapter').findIndex((obj) => obj.id === siteId);
      if (siteIndex >= 0) {
        const site = state.get('chapter').get(siteIndex);
        const newSite = JSON.parse(JSON.stringify(site));
        newSite.sorting = newSiteFromServer.sorting;
        return state
          .set('chapter', state.get('chapter').set(siteIndex, newSite))
          .set('updatingSite', ProcessStatus.FINISHED);
      }

      return state
        .set('updatingSite', ProcessStatus.FINISHED);

    case StoryboardAction.ADD_SITE:
      return state.set('addingSite', ProcessStatus.STARTED);

    case StoryboardAction.ADD_SITE_REJECTED:
      return state.set('addingSite', ProcessStatus.FAILED);

    case StoryboardAction.ADD_SITE_FULFILLED:
      console.log('StoryboardAction.ADD_SITE_FULFILLED', action.payload);
      // eslint-disable-next-line no-case-declarations
      let chapterStructure = state.get('chapterStructure');
      // eslint-disable-next-line no-case-declarations
      let newSite = action.payload.newSite;
      // eslint-disable-next-line no-case-declarations
      let newSiteForStructure = {
        siteId: newSite.id,
        siteName: newSite.collection,
        siteStructure: newSite.text_blocks
      };
      return state
        .set('chapterStructure', chapterStructure.set(chapterStructure.size, newSiteForStructure))
        .set('chapter', state.get('chapter').set(chapterStructure.size, newSite))
        .set('addingSite', ProcessStatus.FINISHED);

    case StoryboardAction.DELETE_SITE:
      return state.set('deletingSite', ProcessStatus.STARTED);

    case StoryboardAction.DELETE_SITE_REJECTED:
      return state.set('deletingSite', ProcessStatus.FAILED);

    case StoryboardAction.DELETE_SITE_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      let siteId = Number(action.payload.removedSite);
      siteIndex = state.get('chapter').findIndex(obj => obj.id === siteId);

      if (siteIndex >= 0) {
        return state
          .set('chapterStructure', state.get('chapterStructure').pop(siteIndex))
          .set('chapter', state.get('chapter').pop(siteIndex))
          .set('deletingSite', ProcessStatus.FINISHED);
      }
      return state;

    case StoryboardAction.OPEN_STORYBOARD_QUESTION_DIALOG:
      return state.set('questionDialogObject', action.payload);

    case StoryboardAction.CLOSE_STORYBOARD_QUESTION_DIALOG:
      return state.set('questionDialogObject', null);

    default:
      return state;
  }
};

export {initialState, reducer as storyboard};
