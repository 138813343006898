import React, {useState, useEffect} from 'react';
import {diffWords} from 'diff';
import {convertDraftRawToHtmlString} from './editor/helper/convertingForEditorWorks';
import {stripHtmlAndInsertNewLine} from '../../utils/RTEParser';
import '../../styles/storyboards/diffView.scss';


const createHighlight = (compareWith, from) => {
  let element = null;
  let className = '';

  let diff = diffWords(compareWith.toString(), from.toString());

  return (
    <div className="diff-view">
      {diff.map((part, index) => {
        element = null;

        className = part.added ?
          'added'
          :
          part.removed ? 'removed' : 'unchanged';

        if (className === 'removed') {
          element = (<span key={index} className="removed">{part.value}</span>);
        } else if (className === 'added') {
          element = (<span key={index} className="added">{part.value}</span>);
        } else if (className === 'unchanged') {
          element = (<span key={index} className="unchanged">{part.value}</span>);
        }
        return element;
      })}
    </div>
  );
};


export default function InlineDiffView (props) {
  const [diff, setDiff] = useState('');

  useEffect(() => {
    parseText();
  }, []);

  useEffect(() => {
    parseText();
  }, [props.old, props.new]);

  const parseText = () => {
    const oldHtml = convertDraftRawToHtmlString(props.old);
    const newHtml = convertDraftRawToHtmlString(props.new);

    const oldText = stripHtmlAndInsertNewLine(oldHtml);
    const newText = stripHtmlAndInsertNewLine(newHtml);

    const diffText = createHighlight(oldText, newText);
    setDiff(diffText);
  };

  return (
    <div className={'diff-view-wrapper'}>
      {diff}
    </div>
  );
}
