import { LanguagesAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchLanguages = () => {
  return {
    type: LanguagesAction.FETCH_LANGUAGES,
    data: {}
  };
};

const fetchLanguagesFulfilled = languages => {
  return {
    type: LanguagesAction.FETCH_LANGUAGES_FULFILLED,
    languages: languages,
    receivedAt: Date.now()
  };
};

const fetchLanguagesRejected = error => {
  return Rx.Observable.of({
    type: LanguagesAction.FETCH_LANGUAGES_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export { fetchLanguages, fetchLanguagesFulfilled, fetchLanguagesRejected };
