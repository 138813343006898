// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.questionDialog .MuiDialog-paperScrollPaper {\n  min-width: 350px;\n}\n\n.questionDialog p {\n  margin: 0;\n}\n\n.questionDialog button {\n  color: #ff5900;\n  border-radius: 0;\n}\n\n.questionDialog button:hover {\n  background-color: rgba(255, 89, 0, 0.08);\n}\n", ""]);
// Exports
exports.locals = {
	"questionDialog": "questionDialog",
	"MuiDialog-paperScrollPaper": "MuiDialog-paperScrollPaper",
	"muiDialogPaperScrollPaper": "MuiDialog-paperScrollPaper"
};
module.exports = exports;
