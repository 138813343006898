import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ProcessStatus} from '../../../utils/Constants';
import {ExpansionList} from 'react-md';
import AdditionalContentPanelPDF from './AdditionalContentPanelPDF';
import AdditionalContentPanelVO from './AdditionalContentPanelVO';
import styles from '../../../styles/projects/translationoverview/general.scss';
import {withTranslation} from 'react-i18next';

import {
  // fetchAdditionalContents,
  // updateAdditionalContents,
  fetchPdfFiles,
  fetchVoFiles
} from '../../../actions/additionalContents';

import {fetchProjectAdditionalContentCollections} from '../../../actions/projects';
import {Col, Row} from 'react-grid-system';

@withTranslation(['additional_content', 'general'], {wait: true})
class TranslationAdditionalContent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      pdfPaths: [],
      pdfPathsOther: [],
      voPaths: [],
      voPathsOther: []
    };
  }

  UNSAFE_componentWillMount () {
    this.props.fetchPdfFiles(this.props.projectId);
    this.props.fetchVoFiles(this.props.projectId);
    this.props.fetchProjectAdditionalContentCollections({
      projectId: this.props.projectId,
      translationId: this.props.translationId
    });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    /* this.props.fetchProjectAdditionalContentCollections({
      projectId: this.props.projectId,
      translationId: this.props.translationId
    }); */
    const {
      fetchingPdfFiles,
      pdfFiles,
      projectIdentifier,
      voFiles,
      fetchingVoFiles,
      translationCode
    } = nextProps;
    if (fetchingPdfFiles === ProcessStatus.FINISHED) {
      let newPdfArray = [];
      let newPdfArrayOther = [];
      if (pdfFiles && pdfFiles.length > 0) {
        let old = pdfFiles;
        for (var k = 0; k < old.length; k++) {
          let item = old[k];
          let newItem = item.split(projectIdentifier)[1].substr();
          newItem = newItem.substr(1, newItem.length - 1);
          newItem = newItem.replace(/([\\])/g, '/');
          let slashArray = newItem.split('/');
          let itemName = slashArray[slashArray.length - 1];
          let itemNameOnly = itemName.replace('.pdf', '');
          let itemNameArray = itemNameOnly.split('_');
          let languageCode = itemNameArray[itemNameArray.length - 1];
          let path = newItem.replace(itemName, '');
          if (languageCode === translationCode) {
            newPdfArray.push({
              name: {
                name: itemName,
                path: path
              },
              preview: ENV.API_URL + '/packages/' + projectIdentifier + '/' + newItem,
              pdfActions: {
                name: itemName,
                path: ENV.API_URL + '/packages/' + projectIdentifier + '/' + path
              }
            });
          } else {
            newPdfArrayOther.push({
              name: {
                name: itemName,
                path: path
              },
              preview: ENV.API_URL + '/packages/' + projectIdentifier + '/' + newItem,
              pdfActions: {
                name: itemName,
                path: ENV.API_URL + '/packages/' + projectIdentifier + '/' + path
              }
            });
          }
        }
        this.setState({
          pdfPaths: newPdfArray,
          pdfPathsOther: newPdfArrayOther
        });
      }
    }


    if (fetchingVoFiles === ProcessStatus.FINISHED) {
      let newVoArray = [];
      let newVoArrayOther = [];
      if (voFiles && voFiles.length > 0) {
        let old = voFiles;
        for (var kk = 0; kk < old.length; kk++) {
          let item = old[kk];
          let newItem = item.split(projectIdentifier)[1].substr();
          newItem = newItem.substr(1, newItem.length - 1);
          newItem = newItem.replace(/([\\])/g, '/');
          let slashArray = newItem.split('/');
          let itemName = slashArray[slashArray.length - 1];

          let itemNameOnly = itemName.replace('.mp3', '');
          let itemNameArray = itemNameOnly.split('_');
          let languageCode = itemNameArray[itemNameArray.length - 1];
          let path = newItem.replace(itemName, '');

          if (languageCode === translationCode) {
            newVoArray.push({
              name: {
                name: itemName,
                path: path
              },
              player: ENV.API_URL + '/packages/' + projectIdentifier + '/' + newItem,
              voActions: {
                name: itemName,
                path: ENV.API_URL + '/packages/' + projectIdentifier + '/' + path
              }
            });
          } else {
            newVoArrayOther.push({
              name: {
                name: itemName,
                path: path
              },
              player: ENV.API_URL + '/packages/' + projectIdentifier + '/' + newItem,
              voActions: {
                name: itemName,
                path: ENV.API_URL + '/packages/' + projectIdentifier + '/' + path
              }
            });
          }
        }
        this.setState({
          voPaths: newVoArray,
          voPathsOther: newVoArrayOther
        });
      }
    }
  }

  render () {
    const {
      projectId,
      translationId,
      baseUrl,
      existingAdditionalContent
    } = this.props;
    const {
      pdfPaths,
      pdfPathsOther,
      voPaths,
      voPathsOther
    } = this.state;

    // console.log('pdfPaths', pdfPaths);
    // console.log('voPaths', voPaths);
    // console.log('!!! TAC !!! existingAdditionalContent', existingAdditionalContent);
    return (
      <div>
        {
          pdfPaths && pdfPaths.length > 0 &&
          <div className={'innerWrapperBorderTop borderTop optional'}>
            <Row>
              <Col xs={12}>
                <ExpansionList className={styles.expansionList}>
                  <AdditionalContentPanelPDF
                    onChange={null}
                    projectId={projectId}
                    translationId={translationId}
                    additionalContentPdf={pdfPaths}
                    additionalContentPdfOther={pdfPathsOther}
                    existingAdditionalContent={existingAdditionalContent}
                    baseUrl={baseUrl}
                  />
                </ExpansionList>
              </Col>
            </Row>
          </div>
        }
        {
          voPaths && voPaths.length > 0 &&
          <div className={'innerWrapperBorderTop borderTop optional'}>
            <Row>
              <Col xs={12}>
                <ExpansionList className={styles.expansionList}>
                  <AdditionalContentPanelVO
                    onChange={null}
                    projectId={projectId}
                    translationId={translationId}
                    additionalContentVo={voPaths}
                    additionalContentVoOther={voPathsOther}
                    // baseUrl={baseUrl}
                  />
                </ExpansionList>
              </Col>
            </Row>
          </div>
        }

        {/*
        <div className={'innerWrapperBorderTop borderTop optional'}>
          <Row>
            <Col xs={12}>
              <ExpansionList className={styles.expansionList}>
                <AdditionalContentPanel key={key} onChange={null} additionalContentPdf={pdfPaths}/>
              </ExpansionList>
            </Col>
          </Row>
        </div>
        */}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingPdfFiles: state.getIn(['additionalContents', 'fetchingPdfFiles']),
    fetchingVoFiles: state.getIn(['additionalContents', 'fetchingVoFiles']),
    pdfFiles: state.getIn(['additionalContents', 'pdfFiles']),
    voFiles: state.getIn(['additionalContents', 'voFiles']),
    existingAdditionalContent: state.getIn(['projects', 'existingAdditionalContent'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchPdfFiles,
      fetchVoFiles,
      fetchProjectAdditionalContentCollections
    }
  )(TranslationAdditionalContent)
);
