import React from 'react';
import styles from '../../styles/theme_button.scss';
import {useTranslation} from 'react-i18next';

export default function ThemeSwitchBtn (props) {
  const {t} = useTranslation(props.translation);
  const {
    isDark
  } = props;

  const themeUrl = isDark
    ? 'url(' + require('../../images/theme_on.svg') + ')'
    : 'url(' + require('../../images/theme_off_dark.svg') + ')';

  // eslint-disable-next-line no-prototype-builtins
  const inner = (props.hasOwnProperty('newIcon') && props.newIcon)
    ? <svg id="ico-theme-switcher" viewBox="0 0 20 20" style={{
      color: isDark ? '#ffffff' : '#000000',
      position: 'relative',
      height: '26px',
      width: '26px'
    }}>
      <path
        fill="currentColor"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10
            10 10 10-4.48 10-10S15.52 0 10 0zm0
            18V2c4.41 0 8 3.59 8 8s-3.59 8-8 8z" />
    </svg>
    : <span style={{backgroundImage: themeUrl}} />;

  const onClick = e => {
    console.log('onClick e', e);
    props.toggleTheme();
  };

  // eslint-disable-next-line no-prototype-builtins
  if (props.hasOwnProperty('hasHelp')) {
    return (
      <button
        help-tool={props.hasHelp}
        title={isDark ? t('general:theme_btn_off') : t('general:theme_btn_on')}
        onClick={onClick}
        className={isDark ? styles.themeButton + ' dark' : styles.themeButton}
      >{ inner }</button>
    );
  }

  return (
    <button
      title={isDark ? t('theme_btn_off') : t('theme_btn_on')}
      onClick={onClick}
      className={isDark ? styles.themeButton + ' dark' : styles.themeButton}
    >{ inner }</button>
  );
}
