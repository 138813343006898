import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'react-md';
import {withTranslation} from 'react-i18next';
import UserPrime from './UserPrime';
import { addUser } from '../../../actions/users';
import styles from '../../../styles/project_detail.scss';
import {cryptedStore} from '../../../utils/CryptedStore';
import {ProcessStatus} from '../../../utils/Constants';
import {
  addToast,
  removeToast
} from '../../../actions/globalSnackbar';
import ActionResponseButton from '../../general/ActionResponseButton';


@withTranslation(['users', 'general'], {wait: true})
class AddUser extends Component {
  constructor (props) {
    super(props);

    this.state = {
      userWithoutMail: false,
      inviteToMe: false,
      success: false
    };
  }

  shuffle = a => {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  };

  generatePassword = () => {
    // Math.random().toString(36).slice(-10) // https://stackoverflow.com/a/9719815
    let chars = 'yxcvbnmasdfghjklpoiuztrewqYXCVBNMASDFGHJKLPOIUZTREWQ1234567890!?&#*_-<>$%=+';
    let charArray = Array.from(chars);
    let pa = this.shuffle(charArray);
    let password = pa.slice(0, 10).toString().replace(/,/g, '');
    console.log('password', password);
    return password;
  };

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      addingUser,
      errorObject,
      t
    } = nextProps;

    if (addingUser === ProcessStatus.FAILED) {
      this.setState({submitClicked: false});
      let e = null;
      if (errorObject) {
        e = JSON.parse(JSON.stringify(errorObject));
      }
      this.props.addToast(
        (e && e.notify && e.notify.warning)
          ? (`${t('general:warning')}: ` + e.notify.warning[0])
          : t('general:warning') + ': ' + t('add_user_error'),
        {
          children: 'ok',
          onClick: () => {
            this.props.removeToast();
          }
        },
        true
      );
    } else if (addingUser === ProcessStatus.FINISHED) {
      if (!this.state.success) { // prevent infinity loop
        this.setState({success: true});
        this.props.addToast(
          t('add_user_success'),
          {
            children: 'ok',
            onClick: () => {
              this.props.removeToast();
            }
          },
          true
        );
        this.props.closeDrawer();
      }
    }
  }

  handleUserWithoutMail = () => {
    this.setState({userWithoutMail: !this.state.userWithoutMail});
  };

  handleInviteToMe = () => {
    this.setState({inviteToMe: !this.state.inviteToMe});
  };

  handleSubmit = data => {
    let clients = data.detailed_clients;

    let required = ['username', 'name', 'detailed_clients', 'role_id', 'languagecode', 'email'];
    let requiredSpecial = ['username', 'name', 'detailed_clients', 'role_id', 'languagecode'];

    let requiredFailed = false;
    if (this.state.userWithoutMail) {
      for (let ii = 0; ii < requiredSpecial.length; ii++) {
        let fieldd = requiredSpecial[ii];
        if (!data.hasOwnProperty(fieldd) || data[fieldd] === '') {
          requiredFailed = true;
          break;
        }
      }
    } else { // NORMAL
      for (let i = 0; i < required.length; i++) {
        let field = required[i];
        if (!data.hasOwnProperty(field) || data[field] === '') {
          requiredFailed = true;
          break;
        }
      }
    }
    if (requiredFailed) {
      this.props.addToast(
        (e && e.notify && e.notify.warning)
          ? (`${t('general:warning')}: ` + e.notify.warning[0])
          : t('general:warning') + ': ' + t('add_user_required_failed'),
        {
          children: 'ok',
          onClick: () => {
            this.props.removeToast();
          }
        },
        true
      );
      return;
    }


    if (!data.error && clients) {
      let formattedClients = clients.map(client => client.get('id'));
      data['clients'] = formattedClients;
      delete data.detailed_clients;
      delete data.error;

      let isSuper = data['is_super'];
      data['is_super'] = isSuper ? 1 : 0;

      data.active = +data.active; // true false to 0 1
      data.invite = +data.invite; // true false to 0 1
      data.password = this.generatePassword();

      if (this.state.userWithoutMail) {
        data.invite = 0;
        data.email = null;
        data.receivermail = this.props.user.email;
      }

      // wird gehändelt in:
      // mangoolu-api\app\Http\Controllers\UserController.php
      // Zeile 81
      if (this.state.inviteToMe) {
        data.invitetome = 1;
        data.invite = 0; // wenn inviteToMe => kein User-Invite
        data.receivermail = this.props.user.email; // MAil des erstell-Users mitsenden
      }
      this.props.addUser(data);
    }
  };

  validate = values => {
    const errors = {};

    if (!values.languagecode) {
      errors.languagecode = true;
    }
    if (!values.role_id) {
      errors.role_id = true;
    }
    return errors;
  };

  render () {
    const { userWithoutMail, inviteToMe } = this.state;
    const { t, addingUser } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{username: '', active: true, invite: true}}
        validate={this.validate}
        render={({handleSubmit}) => (
          <form
            onSubmit={handleSubmit}
            className={'drawer paddingLeftRight marginTop editDrawer'}
          >
            <FormSpy
              subscription={{values: true}}
              component={UserPrime}
              handleUserWithoutMail={this.handleUserWithoutMail}
              handleInviteToMe={this.handleInviteToMe}
              inviteToMe={inviteToMe}
              userWithoutMail={userWithoutMail}
            />
            <div className={styles.buttonsRightWrapper}>
              <ActionResponseButton
                title={''}
                defaulticon=""
                type="submit"
                loading={addingUser === ProcessStatus.STARTED}
                finished={addingUser === ProcessStatus.FINISHED}
                text={t(`${generalLNS}:submit`)}
                deactivated={false}
                onClick={null}
              />
            </div>
          </form>
        )}
      />
    );
  }
}

function mapStateToProps (state) {
  const users = state.get('users');
  return {
    fetchingLanguages: state.getIn(['languages', 'fetchingLanguages']),
    user: state.getIn(['auth', 'user']),
    addingUser: users.get('addingUser'),
    errorObject: users.get('error')
  };
}

export default connect(
  mapStateToProps,
  {
    addUser,
    addToast,
    removeToast
  }
)(AddUser);
