import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {setForceApproval} from '../../../actions/projects';
import {Switch, FontIcon, Button} from 'react-md';
import {ProcessStatus, Scope} from '../../../utils/Constants';
import styles from '../../../styles/projects/translationoverview/general.scss';
import {aclFilter} from '../../../utils/ACL';
import {aclTranslation} from '../../../utils/Permissions';
import ApproveLanguageVersionWarning from '../dialog/ApproveLanguageVersionWarning';
import {showWarning} from '../../../actions/globalDialog';

@withTranslation(['project', 'general'], {wait: true})
class Approval extends Component {
  constructor (props) {
    super(props);
    const { t } = this.props;
    this.state = {
      isChecked: true,
      forcedApproval: {
        id: 'forcedApproval',
        active: false,
        displayName: t('text_approved')
      }
    };
  }

  componentWillMount () {
    const {status} = this.props;

    let isActive = status.status == 'approved';
    this.setState({forcedApproval: {...this.state.forcedApproval, active: isActive}});
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {status, settingForceApproval} = nextProps;

    let isActive = status.status == 'approved';

    if (settingForceApproval === ProcessStatus.FINISHED || settingForceApproval === ProcessStatus.FAILED) {
      this.setState({
        isChecked: isActive,
        forcedApproval: {...this.state.forcedApproval, active: isActive}});
    }
  }

  showApproveLanguageVersionWarning = () => {
    const {projectId, translationId, translationName, showWarning, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text = t('warning_approve_language_version') + ' ' + translationName + '?';

    let component =
      <ApproveLanguageVersionWarning
        projectId={projectId}
        translationId={translationId}
      />;

    showWarning({title: title, text: text, actions: [], component: component, isCancellable: false});
  };


  handleUnapprove = () => {
    const {projectId, translationId} = this.props;
    this.setState({isChecked: false});

    setTimeout(() => {
      this.props.setForceApproval({projectId, translationId, status: false});
    }, 150);
  };

  render () {
    const {t, translationName} = this.props;
    const {isChecked, forcedApproval} = this.state;

    const switchStyle = {
      display: 'inline-block',
      marginLeft: '12px',
      flexGrow: '1',
      flexShrink: '0',
      flexBasis: 'auto',
      height: 'auto'
    };

    return (
      <div className={styles.approval}>
        {
          (!forcedApproval.active && this.props.i18n.language !== 'de') &&
          aclFilter(
            <div className={'buttonWrapper'}>
              <Button
                children={`${t('approve')} ${translationName}`}
                primary swapTheming raised
                onClick={this.showApproveLanguageVersionWarning}
              />
            </div>
          )([], [aclTranslation.approve_text], Scope.TRANSLATION)
        }
        {
          (!forcedApproval.active && this.props.i18n.language === 'de') &&
          aclFilter(
            <div className={'buttonWrapper'}>
              <Button
                children={`${translationName} ${t('approve')}`}
                primary swapTheming raised
                onClick={this.showApproveLanguageVersionWarning}
              />
            </div>
          )([], [aclTranslation.approve_text], Scope.TRANSLATION)
        }
        {
          forcedApproval.active &&
          <div className={styles.approved}>

            {aclFilter(
              <div className={styles.approvalSwitch}>
                <Switch
                  style={switchStyle}
                  key={'forcedApproval'}
                  id={'forcedApproval'}
                  name={'forcedApproval'}
                  aria-label={'forcedApproval'}
                  checked={isChecked}
                  onChange={this.handleUnapprove}
                />
              </div>
            )([], [aclTranslation.unapprove_text], Scope.TRANSLATION)}
            <div style={{
              'display': 'flex',
              'justifyContent': 'flex-end',
              'flexGrow': '1',
              'flexShrink': '0'
            }}>
              <span>{t('language_already_approved')}</span>
            </div>
            <FontIcon
            >done</FontIcon>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    settingForceApproval: state.getIn(['projects', 'settingForceApproval'])
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    setForceApproval,
    showWarning
  }
)(Approval));
