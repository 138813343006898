import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';

import { linkUserToClient } from '../../../actions/users';
import { fetchClients } from '../../../actions/clients';
import RelationBase from '../../general/RelationBase';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';


@withTranslation(['users', 'general'], {wait: true})
class UserClients extends Component {
  linkSourceToTarget = (mode, clientId, roleId) => {
    this.props.linkUserToClient({
      mode: mode,
      user_id: this.props.user.id,
      client_id: clientId,
      role_id: roleId,
      property_type: 'users_x_clients'
    });
  };

  render () {
    const {user, clients, fetchingClients, linkingUserToClient, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <RelationBase
        fetchTargets={this.props.fetchClients}
        fetchingTargets={fetchingClients}
        label={ t(`${generalLNS}:clients`) }
        source={user}
        sourceTargets={user.clients}
        targets={clients}
        chip={RoleAvatarChip}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingUserToClient}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['users', 'selectedUser']),
    clients: state.getIn(['clients', 'clients']),
    fetchingClients: state.getIn(['clients', 'fetchingClients']),
    linkingUserToClient: state.getIn(['users', 'linkingUserToClient']),
    updateToken: state.getIn(['users', 'updateToken'])
  };
}

export default connect(
  mapStateToProps,
  {linkUserToClient, fetchClients}
)(UserClients);
