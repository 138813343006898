export const map = { _: {},
  login:
   { 'password-reset': { __param: ':token' }, __param: ':token' },
  logout: {},
  home: {},
  projects: { __param: ':projectId' },
  storyboards: { __param: ':projectId' },
  clients: {},
  users: {},
  permissions: {},
  roles: {},
  contacts: {},
  imprint: {},
  privacy: {},
  test: {},
  '*': {},
  'Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOTATROOT': {},
  'Routes.PROJECTS.TRANSLATIONS.EDIT_SNAPSHOT': {},
  'Routes.PROJECTS.TRANSLATIONS.ADD_SNAPSHOT': {},
  'Routes.PROJECTS.TRANSLATIONS.ADD': {},
  'Routes.PROJECTS.TRANSLATIONS.IMPORT': {},
  'Routes.PROJECTS.TRANSLATIONS.IMPORT_PDF': {},
  'Routes.PROJECTS.TRANSLATIONS.EXPORT': {},
  'Routes.PROJECTS.TRANSLATIONS.EXPORT_PDF': {},
  'Routes.PROJECTS.TRANSLATIONS.EDIT': {},
  'Routes.PROJECTS.EDIT': {},
  'Routes.PROJECTS.UPDATE': {},
  'Routes.PROJECTS.TRANSLATIONS.VIEW': {},
  'Routes.PROJECTS.IMPORT': {},
  'Routes.CLIENTS.ADD': {},
  'Routes.CLIENTS.EDIT': {},
  'Routes.USERS.ADD': {},
  'Routes.USERS.EDIT': {},
  'Routes.PERMISSIONS.ADD': {},
  'Routes.PERMISSIONS.EDIT': {},
  'Routes.ROLES.ADD': {},
  'Routes.ROLES.EDIT': {},
  'Routes.CONTACTS.ADD': {},
  'Routes.CONTACTS.EDIT': {} };