import * as Rx from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';
import { TypesAction } from '../utils/ActionTypes';
import { receiveTypes } from '../actions/types';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchTypesEpic = (action$, store) => {
  return action$
    .ofType(TypesAction.FETCH_TYPES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/projects/type';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map(res => res.data)
        .map(receiveTypes)
        .catch(error =>
          Rx.Observable.of({
            type: TypesAction.FETCH_TYPES_REJECTED,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

export { fetchTypesEpic };
