import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ExpansionList } from 'react-md';
import SnapshotPanel from '../translationoverview/SnapshotPanel';
import {fromJS} from 'immutable';
import {
  fetchSnapshots
} from '../../../actions/snapshots';
import styles from '../../../styles/projects/translationoverview/general.scss';

class TranslationSnapshots extends Component {
  componentWillMount () {
    this.props.fetchSnapshots(
      this.props.projectId,
      this.props.translationId
    );
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.translationId != this.props.translationId) {
      this.props.fetchSnapshots(
        nextProps.projectId,
        nextProps.translationId
      );
    }
  }

  render () {
    const {key, onChange, snapshots, fetchingSnapshots} = this.props;
    const s = fromJS(snapshots).toArray();

    return (
      <div>
        <ExpansionList className={styles.expansionList}>
          <SnapshotPanel
            key={key}
            onChange={onChange}
            fetchingSnapshots={fetchingSnapshots}
            snapshots={s}/>
        </ExpansionList>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslationStatistics: state.getIn(['projects', 'fetchingTranslationStatistics']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation']),
    snapshots: state.getIn(['snapshots', 'snapshots']),
    fetchingSnapshots: state.getIn(['snapshots', 'fetchingSnapshots'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchSnapshots
    }
  )(TranslationSnapshots)
);
