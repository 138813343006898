import { FilterType } from '../utils/Constants';
import i18next from 'i18next';

const reduceFilterOptions = (data, propName) => {
  let label = null;

  return data.reduce(
    (acc, item) => {
      if (item[propName] != null && !acc.map(item => item.primaryText).includes(item[propName])) {
        if (propName == 'active') {
          if (item[propName] === 1) {
            label = 'active';
          } else if (item[propName] === 0) {
            label = 'inactive';
          }
          // label = item[propName] ? "true" : "false";
        } else {
          label = item[propName];
        }
        acc.push({label: label, primaryText: item[propName], value: item[propName]});
        return acc;
      }
      return acc;
    },
    [{label: i18next.t('general:all'), primaryText: 'all', value: FilterType.ALL}]
  );
};

const createFilterFunction = (appliedFilters = []) => {
  appliedFilters = appliedFilters.filter(filter => filter.value != FilterType.ALL);

  return row => {
    if (appliedFilters.length === 0) {
      return true;
    }
    let match = true;
    for (let filter of appliedFilters) {
      if (row[filter.type] !== filter.value) {
        match = false;
      }
    }
    return match;
  };
};

const createSearchFunction = (searchTerm = '', columns = ['name']) => {
  return row => {
    if (searchTerm === '' || columns.length === 0) {
      return true;
    }
    let searchStr = '';
    for (let column of columns) {
      searchStr += row[column].toLowerCase();
    }
    return searchStr.includes(searchTerm.toLowerCase());
  };
};

const createSortFunction = (sortType, sortOrder) => {
  return (a, b) => {
    if (sortType instanceof Array) {
      sortType.map(attr => {
        a = a[attr];
      });
      sortType.map(attr => {
        b = b[attr];
      });
      if (a < b) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a > b) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (a[sortType] < b[sortType]) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (a[sortType] > b[sortType]) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  };
};

const prepareFiltersFunction = (availableFilters, filtersToUse, data = [], proxies = []) => {
  // console.log("prepareFiltersFunction", availableFilters, filtersToUse, data, proxies);

  let preparedFilters = [];
  for (let filter of availableFilters.filter(current => filtersToUse.includes(current.id))) {
    filter.options = reduceFilterOptions(data, filter.id);
    if (filter.hasOwnProperty('proxy')) {
      for (let filterOption of filter.options) {
        if (filterOption.value == FilterType.ALL) {
          continue;
        }
        const option = proxies[filter.proxy].find(row => {
          return row.id === filterOption.value;
        });

        if (option) {
          filterOption.label = option[filter.proxyProp];
        }
      }
    }
    preparedFilters.push(filter);
  }
  return preparedFilters;
};

export {
  reduceFilterOptions,
  createFilterFunction as defaultCreateFilterFunction,
  createSearchFunction as defaultCreateSearchFunction,
  createSortFunction as defaultCreateSortFunction,
  prepareFiltersFunction as defaultPrepareFiltersFunction
};
