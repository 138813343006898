// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.historyToolbar {\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 100vw;\n  height: 100vh;\n  z-index: 50000;\n  background-color: transparent;\n  pointer-events: none;\n}\n\n.historyToolbar > div {\n  height: 100%;\n  width: 100%;\n  position: relative;\n}\n\n.historyToolbar > div .layover {\n  pointer-events: all;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n}\n\n.historyToolbar > div .historyToolbarInner {\n  pointer-events: all;\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  overflow: auto;\n  padding: 5px;\n  max-width: 90vw;\n  transform: translateX(110%) translateZ(0);\n  transition: transform 0.3s ease 0.3s;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n}\n\n.historyToolbar > div .historyToolbarInner.open {\n  transform: translateX(0%) translateZ(0);\n}\n\n.historyToolbar > div .historyToolbarInner .closeHistory {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n}\n\n.historyToolbar > div .historyToolbarInner h4 {\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  padding-bottom: 10px;\n}\n\n.historyToolbar > div .historyToolbarInner .cardScrollWrapper {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: auto;\n  overflow: auto;\n  padding: 20px 20px 10px 20px;\n}\n\n.historyToolbar > div .historyToolbarInner .MuiButton-textPrimary {\n  color: #ff5900 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"historyToolbar": "historyToolbar",
	"layover": "layover",
	"historyToolbarInner": "historyToolbarInner",
	"open": "open",
	"closeHistory": "closeHistory",
	"cardScrollWrapper": "cardScrollWrapper",
	"MuiButton-textPrimary": "MuiButton-textPrimary",
	"muiButtonTextPrimary": "MuiButton-textPrimary"
};
module.exports = exports;
