import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import {withTranslation} from 'react-i18next';

import { renderUploader, renderSelectionGroup } from '../../general/BasicFormComponents';
import { resetCollectedInfos } from '../../../actions/projects';
import { required } from '../../../utils/ValidationRules';
import ValidateUpload from './ValidateUpload';

const UPLOADED_FILE = 'uploadedFile';
const renderValidateUpload = ({input, meta: {touched, error}, ...custom}) => {
  return (
    <ValidateUpload
      id={input.name}
      value={input.value}
      error={touched && error}
      {...custom}
      onChange={input.onChange}
    />
  );
};

@withTranslation(['project', 'general'], {wait: true})
class DecideProjectCreationMode extends Component {
  constructor (props) {
    super(props);

    this.state = {
      uuidFromCollectedInfos: null
    };
  }
  t = this.props.t;
  radioControls = [
    {
      label: this.t('create_empty_project'),
      value: 'emptyProject'
    },
    {
      label: this.t('upload_and_check_project_package'),
      value: 'zip'
    }
  ];

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      nextProps.collectedInfos.size > 0 &&
      this.state.uuidFromCollectedInfos != nextProps.values[UPLOADED_FILE]
    ) {
      this.setState({uuidFromCollectedInfos: nextProps.values[UPLOADED_FILE]});

      console.log('BOB', nextProps.values[UPLOADED_FILE]);

      nextProps.form.mutators.setCollectedInfos(nextProps.collectedInfos.toObject());
      // nextProps.form.mutators.setFieldData("firstName", {data:"PETER PARKER"})
      // nextProps.form.mutators.changeValue({name:"firstName", value:"PETER PARKER"});
    } else if (nextProps.collectedInfos.size == 0 && this.props.collectedInfos.size > 0) {
      this.setState({uuidFromCollectedInfos: null});
    }
  }

  handleDelete = uuid => {
    console.log('ist deleted', uuid);
    this.props.resetCollectedInfos();
  };

  render () {
    const {values, t} = this.props;

    return (
      <div>
        <Field
          name="projectCreationMode"
          label={ t('select_project_creation_mode') }
          type="radio"
          className="md-cell md-cell--12"
          component={renderSelectionGroup}
          controls={this.radioControls}
        />
        {values.projectCreationMode == 'zip' && (
          <div>
            <Field
              validate={required}
              onDelete={this.handleDelete}
              errorText={ t('hello_again') }
              id={UPLOADED_FILE}
              name={UPLOADED_FILE}
              type="text"
              className="md-cell md-cell--12"
              component={renderUploader}
            />
            {values[UPLOADED_FILE] && (
              <Field
                uploadedFile={values[UPLOADED_FILE]}
                validate={required}
                id="extract_path"
                name="extractPath"
                type="text"
                className="md-cell md-cell--12"
                component={renderValidateUpload}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    collectedInfos: state.getIn(['projects', 'collectedInfos'])
  };
}

export default connect(
  mapStateToProps,
  {resetCollectedInfos}
)(DecideProjectCreationMode);
