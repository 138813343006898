import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  FontIcon
// Media,
// Grid,
// Cell,
// Card,
// CardTitle,
// DataTable,
// Avatar,
// TableHeader,
// TableRow,
// TableColumn,
// TableBody
} from 'react-md';
import styles from '../../styles/dashboard.scss';
import listStyles from '../../styles/list.scss';
import LayoutContainer from './LayoutContainer';

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import {useTheme, createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';


import {useTranslation} from 'react-i18next';
import useReactRouter from 'use-react-router';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

function createData (title, text, author) {
  return {title, text, author};
}

const rows = [
  createData('wichtiges bla1', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.'),
  createData('wichtiges bla2', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.'),
  createData('wichtiges bla3', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.'),
  createData('wichtiges bla4', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.'),
  createData('wichtiges bla5', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.'),
  createData('wichtiges bla6', 'lkdsjf dsflds fsdfjsdlfsd fsdf', 'c.v.')
];

export default function Dashboard (props) {
  const {t} = useTranslation('general');
  const dummyImage = require('../../images/project-dummy.jpg');
  const projectsblurred = require('../../images/projectsblurred.png');

  const classes = useStyles();

  // const router = #useReactRouter();
  const {
    history
    // location,
    // match
  } = useReactRouter();

  // console.log('getDarkTheme');
  // console.log('localStorage.getItem(\'mangoolu_darkmode\')', localStorage.getItem('mangoolu_darkmode'));
  let darkTheme = false;


  // const [isDark, setIsDark] = useState(false);
  // if (darkTheme) {
  //   setIsDark(darkTheme);
  // }

  // useEffect(() => {
  //   setIsDark(
  //     JSON.parse(localStorage.getItem('mangoolu_darkmode')).darkTheme
  //   );
  // }, [JSON.parse(localStorage.getItem('mangoolu_darkmode')).darkTheme]);

  // useEffect(() => {
  //   const nextPaletteType = JSON.parse(localStorage.getItem('mangoolu_darkmode')).darkTheme;
  //
  //   dispatch({
  //     type: 'CHANGE',
  //     payload: { paletteType: nextPaletteType }
  //   });
  // }, []);

  // const dispatch = React.useContext(DispatchContext);


  // console.log('mangoolu_darkmode',
  //   JSON.parse(localStorage.getItem('mangoolu_darkmode')).darkMode);
  // console.log('isDark', isDark);

  const theme = createMuiTheme({
    palette: {
      type: darkTheme ? 'dark' : 'light'
    }
  });

  // const [theme] = useState(
  //   JSON.parse(localStorage.getItem('mangoolu_darkmode')).darkTheme
  // );
  //
  // useEffect(() => {
  //   window.addEventListener('storage', () => {this.updateFromLocalStorage(this.props);});
  // });


  // console.log('props', props);

  const dashboardContent = (
    <div className={listStyles.list}>
      <LayoutContainer width='small'>
        <div className={styles.home}>

          {/*
          <Paper className={classes.paper} square={true}>

            <Typography style={{padding: '15px'}} variant="h5" gutterBottom>
              Benachrichtigungen ({rows.length})
            </Typography>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Titel</TableCell>
                  <TableCell align="left">Text</TableCell>
                  <TableCell align="right">Autor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">{row.text}</TableCell>
                    <TableCell align="right">{row.author}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          */}

          {/*
          <Grid className="grid-example">
            <Cell size={12}>
              <h5>Benachrichtigungen ({exampleNotifications.length})</h5>
              <div className="scrollWrapper">
                <DataTable baseId="menu-table">
                  <TableHeader>
                    <TableRow>
                      <TableColumn>Title</TableColumn>
                      <TableColumn grow>Text</TableColumn>
                      <TableColumn>Autor</TableColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {exampleNotifications.map(({title, text, author}, i) => (
                      <TableRow key={`${title}-${author}`.replace(/\s+/g, '-')}>
                        <TableColumn>{title}</TableColumn>

                        <TableColumn>{text}</TableColumn>
                        <TableColumn>
                          <span style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <Avatar suffix={suffixes[i]}>{author.charAt(0)}</Avatar>
                            <span style={{padding: '0 0 0 15px'}}>{author}</span>
                          </span>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </DataTable>
              </div>
            </Cell>
          </Grid>
          */}


          <Grid
            container
            justify="center"
            className={'grid-example'}
            spacing={2}
          >
            <Grid item xs={6}>
              <Paper
                className={'dashboardLink'}
                style={{padding: '15px', display: 'flex', alignItems: 'center'}}
                onClick={() => {history.push('/projects');}}
                square={true}
                elevation={12}>
                {/* <Avatar><EventNoteIcon /></Avatar>*/}
                <FontIcon className={'iconInLink'}>event_note</FontIcon>
                <Typography variant="h5" component="h3" style={{paddingLeft: '0px'}} >
                  {t('projects')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <ThemeProvider theme={theme}>
                <Paper
                  className={'dashboardLink'}
                  style={{padding: '15px', display: 'flex', alignItems: 'center'}}
                  onClick={() => {history.push('/storyboards');}}
                  square={true}
                  elevation={12}>
                  {/* <Avatar><PlaylistAddIcon /></Avatar>*/}
                  <FontIcon className={'iconInLink'}>post_add</FontIcon>
                  <Typography variant="h5" component="h3" style={{paddingLeft: '0px'}} >
                    {t('storyboard') + ' (Beta)'}
                  </Typography>
                </Paper>
              </ThemeProvider>
            </Grid>
          </Grid>

          {/*
          <Grid className="grid-example">
            <Cell size={6}>
              <Card component={Link} className={'dashboardLink'} to={'/projects'}>
                <CardTitle title={
                  <>
                    <FontIcon className={'iconInLink'}>event_note</FontIcon>
                    {t('projects')}
                  </>
                }/>
                <Media aspectRatio="16-9">
                  <img src={projectsblurred} alt={''}/>
                </Media>
              </Card>
            </Cell>
            <Cell size={6}>
              <Card component={Link} className={'dashboardLink'} to={'/storyboard'}>
                <CardTitle title={
                  <>
                    <FontIcon className={'iconInLink'}>post_add</FontIcon>
                    {t('storyboard') + ' (Beta)'}
                  </>
                }/>
                <Media aspectRatio="16-9">
                  <img src={dummyImage} alt={''}/>
                </Media>
              </Card>
            </Cell>
          </Grid>
          */}


        </div>
      </LayoutContainer>
    </div>
  );


  return (
    <>
      {dashboardContent}
    </>
  );
}
