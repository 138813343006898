import {combineEpics} from 'redux-observable';

import {
  loginEpic,
  loginAsUserEpic,
  logoutAsUserEpic,
  logoutEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  editUserSettingsEpic,
  updateStoreUserEpic,
  getUsernameByTokenEpic
} from '../epics/auth';

import {
  fetchClientsEpic,
  fetchClientEpic,
  addClientEpic,
  editClientEpic,
  deleteClientEpic,
  linkClientToUserEpic,
  linkClientToExportTypeEpic
} from '../epics/clients';

import {
  fetchUsersEpic,
  fetchUserEpic,
  addUserEpic,
  editUserEpic,
  deleteUserEpic,
  linkUserToProjectEpic,
  linkUserToTranslationEpic,
  linkUserToClientEpic,
  inviteUserEpic,
  updateConnectedPropertyEpic
} from '../epics/users';

import {
  fetchProjectsEpic,
  fetchProjectEpic,
  fetchProjectAdditionalContentCollectionsEpic,
  importProjectEpic,
  editProjectEpic,
  deleteProjectEpic,
  validateProjectEpic,
  validateUpdateEpic,
  importUpdateEpic,
  addTranslationEpic,
  importTranslationEpic,
  importPDFTranslationEpic,
  validateTranslationEpic,
  validatePDFTranslationEpic,
  editTranslationEpic,
  deleteTranslationEpic,
  linkProjectToUserEpic,
  linkTranslationToUserEpic,
  linkProjectToExportTypeEpic,
  fetchAvailableExportsEpic,
  exportTranslationEpic,
  fetchTranslationEpic,
  fetchTranslationStatisticsEpic,
  setForceApprovalEpic,
  fetchConfigEpic
} from '../epics/projects';

import {
  fetchAdditionalContentsEpic,
  updateAdditionalContentsEpic,
  fetchPdfFilesEpic,
  fetchVoFilesEpic,
  addAdditionalContentsEpic
} from '../epics/additionalContents';

import {
  fetchSnapshotsEpic,
  addSnapshotEpic,
  editSnapshotEpic,
  deleteSnapshotEpic
} from '../epics/snapshots';

import {
  fetchSourceSnapshotsEpic
} from '../epics/sourceSnapshots';

import {
  fetchProjectEnumDefaultsEpic,
  fetchTranslationEnumDefaultsEpic
} from '../epics/enumDefaults';

import {
  discardUploadEpic
} from '../epics/uploader';

import {
  fetchContactsEpic,
  addContactEpic,
  editContactEpic,
  deleteContactEpic
} from '../epics/contacts';

import {
  fetchPermissionsEpic,
  addPermissionEpic,
  editPermissionEpic,
  deletePermissionEpic
} from '../epics/permissions';

import {
  fetchRolesEpic,
  addRoleEpic,
  editRoleEpic,
  deleteRoleEpic,
  fetchRolePermissionsEpic,
  changeRolePermissionsEpic
} from '../epics/roles';

import {
  fetchAllTextsEpic,
  fetchAllTextsGroupedByCollectionEpic,
  fetchSourceEpic
} from '../epics/wbtViewer';

import {
  fetchTranslationsEpic,
  fetchAdditionalContentTranslationsEpic,
  saveTranslationEpic
} from '../epics/translationTool';

import {
  fetchStructureEpic,
  selectItemEpic,
  setItemStatusEpic
} from '../epics/structure';

import {
  addImageEpic,
  fetchImageEpic,
  fetchCroppedImageEpic,
  fetchFullImageEpic,
  deleteImageEpic,
  editImageEpic
} from '../epics/image';

import {fetchLanguagesEpic} from '../epics/languages';
import {fetchTypesEpic} from '../epics/types';
import {fetchEngineTypesEpic} from '../epics/engineTypes';
import {fetchExportTypesEpic} from '../epics/exportTypes';
import {fetchTextScopesEpic} from '../epics/textScopes';
import {fetchStatusEpic} from '../epics/status';
import {editProfileEpic} from '../epics/profile';
import {availableTranslationsEpic} from '../epics/i18n';
import {fetchViewportsEpic} from '../epics/viewports';

import {
  fetchChapterEpic,
  addTextBlockEpic,
  lockTextBlockEpic,
  unlockTextBlockEpic,
  updateTextBlockEpic,
  deleteTextBlockEpic,
  fetchStoryboardEpic,
  fetchStoryboardsEpic,
  fetchHistoryEpic,
  updateSiteEpic,
  addSiteEpic,
  deleteSiteEpic
} from '../epics/storyboard';

export default combineEpics(
  fetchUsersEpic,
  fetchUserEpic,
  addUserEpic,
  deleteUserEpic,
  editUserEpic,
  linkUserToProjectEpic,
  linkUserToTranslationEpic,
  linkUserToClientEpic,
  inviteUserEpic,
  updateConnectedPropertyEpic,
  loginEpic,
  loginAsUserEpic,
  logoutAsUserEpic,
  logoutEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  editUserSettingsEpic,
  updateStoreUserEpic,
  getUsernameByTokenEpic,
  fetchClientsEpic,
  fetchClientEpic,
  addClientEpic,
  editClientEpic,
  deleteClientEpic,
  linkClientToExportTypeEpic,
  linkClientToUserEpic,
  fetchProjectsEpic,
  fetchProjectEpic,
  fetchProjectAdditionalContentCollectionsEpic,
  importProjectEpic,
  editProjectEpic,
  deleteProjectEpic,
  validateProjectEpic,
  validateUpdateEpic,
  importUpdateEpic,
  addTranslationEpic,
  importTranslationEpic,
  importPDFTranslationEpic,
  validateTranslationEpic,
  validatePDFTranslationEpic,
  editTranslationEpic,
  deleteTranslationEpic,
  linkProjectToUserEpic,
  linkTranslationToUserEpic,
  linkProjectToExportTypeEpic,
  fetchAvailableExportsEpic,
  exportTranslationEpic,
  fetchTranslationEpic,
  fetchAdditionalContentTranslationsEpic,
  fetchTranslationStatisticsEpic,
  setForceApprovalEpic,
  fetchAdditionalContentsEpic,
  updateAdditionalContentsEpic,
  fetchPdfFilesEpic,
  fetchVoFilesEpic,
  addAdditionalContentsEpic,
  fetchSnapshotsEpic,
  addSnapshotEpic,
  editSnapshotEpic,
  deleteSnapshotEpic,
  fetchSourceSnapshotsEpic,
  fetchPermissionsEpic,
  addPermissionEpic,
  editPermissionEpic,
  deletePermissionEpic,
  fetchViewportsEpic,
  fetchRolesEpic,
  addRoleEpic,
  editRoleEpic,
  deleteRoleEpic,
  fetchRolePermissionsEpic,
  changeRolePermissionsEpic,
  fetchLanguagesEpic,
  fetchTypesEpic,
  fetchEngineTypesEpic,
  fetchExportTypesEpic,
  fetchTextScopesEpic,
  fetchStatusEpic,
  fetchProjectEnumDefaultsEpic,
  fetchTranslationEnumDefaultsEpic,
  discardUploadEpic,
  fetchContactsEpic,
  addContactEpic,
  editContactEpic,
  deleteContactEpic,
  editProfileEpic,
  availableTranslationsEpic,
  fetchAllTextsEpic,
  fetchAllTextsGroupedByCollectionEpic,
  fetchSourceEpic,
  fetchTranslationsEpic,
  saveTranslationEpic,
  fetchStructureEpic,
  selectItemEpic,
  setItemStatusEpic,
  addImageEpic,
  fetchImageEpic,
  fetchFullImageEpic,
  fetchCroppedImageEpic,
  deleteImageEpic,
  editImageEpic,
  fetchConfigEpic,
  fetchChapterEpic,
  addTextBlockEpic,
  lockTextBlockEpic,
  unlockTextBlockEpic,
  updateTextBlockEpic,
  deleteTextBlockEpic,
  fetchStoryboardEpic,
  fetchStoryboardsEpic,
  fetchHistoryEpic,
  updateSiteEpic,
  addSiteEpic,
  deleteSiteEpic
);
