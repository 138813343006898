import React from 'react';

const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  ul: 'ul_list',
  ol: 'ol_list',
  li: 'list_item'
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
  strong: 'bold',
  b: 'bold',
  sub: 'subscript',
  sup: 'superscript',
  span_nobreak: 'span_nobreak'
};

const INLINE_TAGS = {
  a: 'link',
  shy: 'shy',
  zwsp: 'zwsp'
  /* a_mailto: 'mailto' */
};

const getType = (tagList, element) => {
  let type = tagList[element.tagName.toLowerCase()];
  if (type === 'link') {
    type = 'link';
    if (element.getAttribute('href').includes('mailto:')) {
      type = 'mail';
    }
  }
  if (!type && element.className) {
    type = tagList[element.tagName.toLowerCase() + '_' + element.className.toLowerCase()];
  }
  return type;
};

const getTypeBlock = element => {
  let type = BLOCK_TAGS[element.tagName.toLowerCase()];
  if (!type && element.className) {
    type = BLOCK_TAGS[element.tagName.toLowerCase() + '_' + element.className.toLowerCase()];
  }
  return type;
};
const getTypeMark = element => {
  let type = MARK_TAGS[element.tagName.toLowerCase()];
  if (!type && element.className) {
    type = MARK_TAGS[element.tagName.toLowerCase() + '_' + element.className.toLowerCase()];
  }
  if (element.className) {
    if (element.className.indexOf('shy') !== -1) {
      type = null;
    }
    if (element.className.indexOf('zwsp') !== -1) {
      type = null;
    }
  }
  return type;
};
const getTypeInline = element => {
  let type = INLINE_TAGS[element.tagName.toLowerCase()];
  if (type === 'link') {
    type = 'link';
    if (element.getAttribute('href').includes('mailto:')) {
      type = 'mail';
    }
  }
  if (element.className) {
    if (element.className.indexOf('shy') !== -1) {
      type = 'shy';
      // console.log('span_shy');
    }
    if (element.className.indexOf('zwsp') !== -1) {
      type = 'zwsp';
      // console.log('span_zwsp');
    }
  }

  if (!type && element.className) {
    type = INLINE_TAGS[element.tagName.toLowerCase() + '_' + element.className.toLowerCase()];
  }

  // console.log('getTypeInline', type);
  return type;
};

const rules = [
  // Blocks
  {
    deserialize (el, next) {
      const type = getTypeBlock(el);
      if (!type) return;
      // console.log('BBBBBBBBBBBBBBBBBBB BLOCKS deserialize', el);
      return {
        object: 'block',
        type: type,
        nodes: next(el.childNodes)
      };
    },
    serialize (object, children) {
      if (object.object != 'block') {
        return;
      }
      // console.log('BBBBBBBBBBBBBBBBBBBBB BLOCKS serialize', object.type, object.toJSON());
      switch (object.type) {
        // case 'code': return <pre><code>{children}</code></pre>;
        // case 'paragraph': return <p>{children}</p>;
        case 'quote':
          // console.log('################################################################################################# quote');
          return <blockquote>{children}</blockquote>;
        case 'ul_list':
          return <ul>{children}</ul>;
        case 'ol_list':
          return <ol>{children}</ol>;
        case 'list_item':
          return <li>{children}</li>;
        case 'numbered-list':
          return <ol>{children}</ol>;
        case 'paragraph':
          return <p>{children}</p>;
        case 'code':
          return (
            <pre>
              <code>{children}</code>
            </pre>
          );
        default:
          break;
      }
    }
  },

  // Marks
  {
    deserialize (el, next) {
      const type = getTypeMark(el);
      if (!type) return;
      // console.log('MMMMMMMMMMMMMMMMMMMMM MARKS deserialize', el);
      return {
        object: 'mark',
        type: type,
        nodes: next(el.childNodes)
      };
    },
    serialize (object, children) {
      if (object.object != 'mark') return;
      // console.log('MMMMMMMMMMMMMMMMMMMMM MARKS serialize', object.type, object.toJSON());
      switch (object.type) {
        case 'bold':
          return <strong>{children}</strong>;
        case 'subscript':
          return <sub>{children}</sub>;
        case 'superscript':
          return <sup>{children}</sup>;
        case 'span_nobreak':
          return (
            <span className="nobreak" title="no break">
              {children}
            </span>
          );
        // case 'span_shy':
        //   return <span className="shy">{children}</span>;
        // case 'span_zwsp':
        //   return <span className="zwsp">{children}</span>;
        default:
          break;
      }
    }
  },

  // Inline
  {
    deserialize (el, next) {
      const type = getTypeInline(el);
      if (!type) return;
      // console.log('IIIIIIIIIIIIIIIIIIIIIIIII INLINE deserialize', el);
      if (type == 'link') {
        return {
          object: 'inline',
          type: type,
          nodes: next(el.childNodes),
          data: {href: el.getAttribute('href')}
        };
      } else if (type == 'mail') {
        var m = el.getAttribute('href').replace('mailto:', '');
        return {
          object: 'inline',
          type: type,
          nodes: next(el.childNodes),
          data: {mail: m}
        };
      } else if (type == 'shy') {
        // console.log('---type shy', el, next);
        return {
          object: 'inline',
          type: type,
          nodes: next(el.childNodes),
          data: {}
        };
      } else if (type == 'zwsp') {
        return {
          object: 'inline',
          type: type,
          nodes: next(el.childNodes),
          data: {}
        };
      }

      return {
        object: 'inline',
        type: type,
        nodes: next(el.childNodes),
        data: {}
      };
    },
    serialize (object, children) {
      if (object.object != 'inline') return;
      // console.log('IIIIIIIIIIIIIIIIIIIIIIIII INLINE serialize', object.type, object.toJSON());
      switch (object.type) {
        case 'link': {
          const {data} = object;
          const href = data.get('href');
          return <a href={href}>{children}</a>;
        }
        case 'mail': {
          const {data} = object;
          const mail = 'mailto:' + data.get('mail');
          return <a href={mail}>{children}</a>;
        }
        case 'shy':
          return <span className="shy">-</span>;
        case 'zwsp':
          return <span className="zwsp">|</span>;
        default:
          break;
      }
    }
  }
];

export default rules;
export { BLOCK_TAGS, MARK_TAGS, INLINE_TAGS, getType, getTypeBlock, getTypeMark, getTypeInline };
