import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Echo from 'laravel-echo';
import {
  addTextBlockFulfilled,
  updateTextBlockFulfilled,
  deleteTextBlockFulfilled,
  lockTextBlockFulfilled,
  unlockTextBlockFulfilled
} from '../actions/storyboard';
import {baseApiUrl, getAuthHeadersWithToken} from '../utils/AuthHelper';

export default function WebsocketConnection (props) {
  const user = useSelector(state => state.getIn(['auth', 'user']));
  const token = useSelector(state => state.getIn(['auth', 'token']));
  const dispatch = useDispatch();

  useEffect(() => {
    subscribe();

    return function cleanup () {
      if (window.Echo) {
        console.log('Leaving channel');
        window.Echo.leaveChannel('storyboard');
      }
    };
  }, [token]);

  function subscribe () {
    if (token) {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'websocketkey',
        authEndpoint: `${baseApiUrl}broadcasting/auth`,
        auth: {
          headers: getAuthHeadersWithToken(token, user, `${baseApiUrl}broadcasting/auth`)
        },
        disableStats: true,
        wsHost: ENV.WEBSOCKET_URL,
        wsPort: 6001
      });

      console.log('This is the store', user, token, window.Echo);
      window.Echo.private('storyboard')
        .listen('.text.added', (e) => {
          const textBlock = e.textBlock;
          console.log('this comes from the channel', e);
          dispatch(addTextBlockFulfilled(textBlock));
        })
        .listen('.text.changed', (e) => {
          const textBlock = e.textBlock;
          console.log('this comes from the channel', textBlock);
          dispatch(updateTextBlockFulfilled(textBlock));
        })
        .listen('.text.deleted', (e) => {
          const textBlock = e.textBlock;
          console.log('this comes from the channel', textBlock);
          dispatch(deleteTextBlockFulfilled(textBlock));
        })
        .listen('.text.locked', (e) => {
          const textBlock = e.textBlock.data;
          dispatch(lockTextBlockFulfilled(textBlock));
          console.log('Text block locked:', textBlock);
        })
        .listen('.text.unlocked', (e) => {
          const textBlock = e.textBlock.data;
          dispatch(unlockTextBlockFulfilled(textBlock));
          console.log('Text block unlocked:', textBlock);
        });
    }

  }


  return <div>{props.children}</div>;
}
