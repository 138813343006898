import React from 'react';
import {connect} from 'react-redux';

class StaticImage extends React.Component {
  render () {
    const {croppedImg, alt} = this.props;
    return <img className={'imageContainer'} src={croppedImg} alt={alt}/>;
  }
}

function mapStateToProps (state) {
  return {
    croppedImg: state.getIn(['image', 'croppedImage'])
  };
}

export default connect(mapStateToProps)(StaticImage);
