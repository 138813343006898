import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';
import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import UserPrime from './UserPrime';
import UserTimestamps from './UserTimestamps';
import {editUser} from '../../../actions/users';
import styles from '../../../styles/project_detail.scss';


@withTranslation(['users', 'general'], {wait: true})
class EditUser extends Component {
  constructor (props) {
    super(props);

    this.state = {
      editMode: false,
      translation: null,
      isSuperIsAvailable: false
    };
  }

  componentWillMount () {
    const {user} = this.props;
    if (user) {
      this.setState({
        isSuperIsAvailable: user.clients.some(client => client.id === 1)
      });
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {updateToken, user} = nextProps;
    if (updateToken !== this.props.updateToken) {
      this.setState({
        isSuperIsAvailable: user.clients.some(client => client.id === 1)
      });
    }
  }

  handleSubmit = (data) => {
    data.role_id = data.globalrole;
    let isSuper = data['is_super'];
    data['is_super'] = isSuper ? 1 : 0;
    data.avatar_location = '';
    delete data.permissions;
    delete data.translations;
    delete data.clients;
    delete data.globalrole;
    delete data.roles;
    delete data.projects;
    delete data.settings;
    this.props.editUser(data);
    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({editMode: !this.state.editMode});
  };

  render () {
    const {user, editingUser, t} = this.props;
    const {editMode, isSuperIsAvailable} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let userWithGlobalRole = user;
    userWithGlobalRole.globalrole = user.roles[0].id;

    return (
      <Form
        mutators={{
          setFieldData,
          setCollectedInfos: (args, state) => {
            state.formState.values = {...state.formState.values, ...args[0]};
          },
          changeValue: (args, state, utils) => {
            utils.changeValue(state, args[0].name, () => args[0].value);
          },
          changeValues: (args, state, utils) => {
            args.map(arg => utils.changeValue(state, arg.name, () => arg.value));
          }
        }}
        onSubmit={this.handleSubmit}
        initialValues={userWithGlobalRole}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingUser == ProcessStatus.STARTED &&
              <LoadingBar
                show="true"
                wrapperClassname={styles.loadingBarWrapper}
                message={t(`${generalLNS}:updating_project`)}/>
              }
              <div className={styles.buttonsRightWrapper + ' ' + styles.buttonsRightWrapperInTab}>
                <Button
                  title={t(`${generalLNS}:edit_mode`)}
                  className={styles.editButton + ' ' + styles.editButtonFilled}
                  style={{display: !editMode ? 'block' : 'none'}}
                  onClick={this.switchEditMode}
                  icon primary swapTheming>mode_edit</Button>

                <Button
                  children={t(`${generalLNS}:save_all_edits`)}
                  type="submit"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formSubmitButton}
                  flat primary swapTheming/>

                <Button
                  children={t(`${generalLNS}:cancel`)}
                  type="button"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formCancelButton}
                  onClick={this.switchEditMode}
                  flat primary/>
              </div>
              <div className={styles.flexHorizontalWrapper}>
                <FormSpy
                  subscription={{values: true}}
                  editMode={editMode}
                  isSuperIsAvailable={isSuperIsAvailable}
                  component={UserPrime}
                />
                <FormSpy
                  subscription={{values: true}}
                  editMode={editMode}
                  component={UserTimestamps}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}


function mapStateToProps (state) {
  return {
    user: state.getIn(['users', 'selectedUser']),
    editingUser: state.getIn(['users', 'editingUser']),
    updateToken: state.getIn(['users', 'updateToken'])
  };
}

export default connect(mapStateToProps, {editUser})(EditUser);
