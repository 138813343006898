import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FontIcon} from 'react-md';
import styles from '../../../styles/help.scss';
import {Routes} from '../../../utils/Constants';
import {matchPath} from 'react-router';
import {withTranslation} from 'react-i18next';
import {editUserSettings} from '../../../actions/auth';
import helpTexts from './helpTexts';


@withTranslation('help', {wait: true})
class GlobalHelp extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      isOpened: false,
      isFocused: false,
      currentStep: 0,
      textScope: null,
      highLightPositions: [],
      textPositions: [],
      defaultPadding: 2
    };
    this.helpElements = [];
  }

  componentWillMount () {
    this.mounted = true;

    document.addEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });

    this.waitingTillUpdate();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {show, location} = nextProps;

    if (show !== this.state.isOpened) {
      this.update(nextProps);
    } else if (location.pathname !== this.props.location.pathname) {
      this.waitingTillUpdate();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    document.removeEventListener('keydown', (e) => {
      this.handleKeyDown(e);
    });
  }

  waitingTillUpdate = () => {
    setTimeout(() => {
      this.update(this.props);
    }, 3000);
  };

  update = props => {
    const {user, show} = props;
    const match = this.checkMatch(props);

    if (match) {
      if (show) {
        this.initTextsAndPositions(match);
      }
      if (user) {
        let wasShown = this.initUserSettings(user, match);

        if (!wasShown) {
          this.initTextsAndPositions(match);
          this.editUserSettings(user, match);
        }
      }
    }
  };

  checkMatch = props => {
    const {location} = props;

    let routeMatched = null;
    let match = null;

    helpTexts.map(helpText => {
      match = matchPath(location.pathname, {path: helpText.route});

      if (match && match.path) {
        if (routeMatched == null || helpText.route.length > routeMatched.length) {
          routeMatched = helpText.route;
        }
      }
    });
    return routeMatched;
  };

  initUserSettings = (user, match) => {
    let helpShownFromSettings = _.get(user, 'settings.wasShown');
    let wasShown = false;
    if (helpShownFromSettings) {
      wasShown = helpShownFromSettings.includes(match);
    }
    return wasShown;
  };

  editUserSettings = (user, match) => {
    let wasShown = _.get(user, 'settings.wasShown');

    if (wasShown) {
      wasShown.push(match);
    } else {
      wasShown = [match];
    }
    this.props.editUserSettings(user.id, {wasShown: wasShown});
  };

  getTextScope = match => {
    var scope = {};

    let i = helpTexts.findIndex(helpText => helpText.route === match);

    let code = helpTexts[i].textCode;
    let s = helpTexts[i].steps;
    let array = [];
    for (var ii = 0; ii < this.helpElements.length; ii++) {
      let attributeId = parseInt(this.helpElements[ii].getAttribute('help-tool'));
      for (var iii = 0; iii < s.length; iii++) {
        if (attributeId === s[iii].helpId) {
          let entry = {};
          let id = s[iii].helpId;
          entry.id = id;
          entry.headline = code + '_' + id + '_headline';
          entry.text = code + '_' + id + '_text';
          entry.text = entry.text.replace();
          entry.padding = s[iii].hasOwnProperty('padding') ? s[iii].padding : this.state.defaultPadding;
          array.push(entry);
          break;
        }
      }
    }
    scope = array;
    return scope;
  };

  getAllHelpElements = () => {
    var allHelpTextElements = Array.prototype.slice.call(document.querySelectorAll('[help-tool]'));

    // AUSNAHME FÜR STARTSEITE:
    if (
      this.props.location.pathname === Routes.HOME
      || this.props.location.pathname === Routes.HOME + '/' // ausnahme für zusätzlichen Slash
    ) { // help-project-tool
      var allNavElements = Array.prototype.slice.call(document.querySelectorAll('[help-project-tool]'));

      for (var jj = 0; jj < allNavElements.length; jj++) {
        allNavElements[jj].setAttribute('help-tool', allNavElements[jj].getAttribute('help-project-tool'));
        allNavElements[jj].removeAttribute('help-project-tool');
        allHelpTextElements.push(allNavElements[jj]);
      }
    }

    allHelpTextElements.sort(function (a, b) {
      let aa = parseInt(a.getAttribute('help-tool'));
      let bb = parseInt(b.getAttribute('help-tool'));
      if (aa < bb) {
        return -1;
      }
      if (aa > bb) {
        return 1;
      }
      return 0;
    });
    this.helpElements = allHelpTextElements;
  };

  initTextsAndPositions = (match) => {
    const {defaultPadding} = this.state;
    this.getAllHelpElements();
    const textScope = this.getTextScope(match);

    if (textScope.length !== this.helpElements.length) {
      console.warn(
        'GLOBALHELP WARNING: textScope und help-tool-elemente stimmen nicht überein',
        textScope.length,
        this.helpElements.length
      );
      this.prepareClose();
      return false;
    }
    let highLightPositions = [];
    let textPositions = [];

    setTimeout(() => {
      if (this.mounted) {
        // for (var j = 0; j < this.helpElements.length; j++) {
        for (var j = 0; j < textScope.length; j++) {
          let rect = this.helpElements[j].getBoundingClientRect();
          let helpPopupRect = this.helpPopup.getBoundingClientRect();
          let padding = defaultPadding;

          if (textScope[j].hasOwnProperty('padding')) {
            padding = textScope[j].padding;
          }
          let l = ((rect.left / helpPopupRect.width) * 100) - padding;
          let t = ((rect.top / helpPopupRect.height) * 100) - padding;
          let w = ((rect.width / helpPopupRect.width) * 100) + (padding * 2);
          let h = ((rect.height / helpPopupRect.height) * 100) + (padding * 2);

          highLightPositions.push({
            left: l + '%',
            top: t + '%',
            width: w + '%',
            height: h + '%'
          });

          // TEXT-POSITION:

          let textTop = 0;
          let textBottom = 0;
          let textLeft = 0;
          let textRight = 0;
          let textWidth = 0;
          let textMaxWidth = '500px';
          let textMaxHeight = 0;
          let textAlignRight = false;
          let textPadding = false;


          if (w > 40) {  // große WIDTH und kleine HöHE
            if (h > 60) {       // große HEIGHT
              textTop = t;
              textBottom = 'auto';
              textMaxHeight = h;
              textPadding = true;

              if (l > 45) {     // RECHTS
                // TEXT LINKS, RECHTSBÜNDIG
                textLeft = 'auto';
                textRight = 100 - l;
                textWidth = l;
                textAlignRight = true;
              } else {          // LINKS
                // TEXT RECHTS, LINKSBÜNDIG
                textLeft = l + w;
                textRight = 'auto';
                textWidth = 100 - l - w;
                textAlignRight = false;
              }
            } else {
              textLeft = l;
              textWidth = 'auto';
              textRight = 'auto';
              textMaxWidth = w;

              if (t > 30) {
                // TEXT OBEN, LINKSBÜNDIG
                textTop = 'auto';
                textBottom = 100 - t;
                textMaxHeight = t;
                textAlignRight = false;
              } else { // kleiner 50
                // TEXT UNTEN, LINKSBÜNDIG
                textTop = t + h;
                textBottom = 'auto';
                textMaxHeight = 100 - t - h;
                textAlignRight = false;
              }
            }
          } else { // kleine width
            if (h > 50) {       // große HEIGHT
              textTop = t;
              textBottom = 'auto';
              textMaxHeight = h;
              textPadding = true;

              if (l > 45) {     // RECHTS
                // TEXT LINKS, RECHTSBÜNDIG
                textLeft = 'auto';
                textRight = 100 - l;
                textWidth = l;
                textAlignRight = true;
              } else {          // LINKS
                // TEXT RECHTS, LINKSBÜNDIG
                textLeft = l + w;
                textRight = 'auto';
                textWidth = 100 - l - w;
                textAlignRight = false;
              }
            } else {            // KLEINE HÖHE UND BREITE
              if (l > 45) {     // RECHTS
                textLeft = 'auto';
                textWidth = l;
                textRight = 100 - l;
                textAlignRight = true;
                textPadding = true;

                if (t > 40) {   // UNTEN RECHTS
                  // TEXT LINKS, UNTEN-RECHTS BÜNDIG
                  textTop = 'auto';
                  textBottom = 100 - t - h;
                  textMaxHeight = 100 - (100 - t - h);
                } else {        // OBEN RECHTS
                  // TEXT LINKS, UNTEN-RECHTS BÜNDIG
                  textTop = t;
                  textBottom = 'auto';
                  textMaxHeight = 100 - t;
                }
              } else {
                textLeft = l + w;
                textRight = 'auto';
                textWidth = 100 - w - l;
                textAlignRight = false;
                textPadding = true;

                if (t > 40) {   // LINKS
                  // TEXT LINKS, UNTEN-LINKS BÜNDIG
                  textTop = 'auto';
                  textBottom = 100 - t - h;
                  textMaxHeight = 100 - (100 - t - h);
                } else {        // OBEN LINKS
                  // TEXT LINKS, UNTEN-LINKS BÜNDIG
                  textTop = t;
                  textBottom = 'auto';
                  textMaxHeight = 100 - t;
                }
              }
            }
          }

          if (typeof textBottom !== 'string' && textBottom < 0) {
            textBottom = 0;
          }
          if (typeof textTop !== 'string' && textTop < 0) {
            textTop = 0;
          }
          if (typeof textLeft !== 'string' && textLeft < 0) {
            textLeft = 0;
          }
          if (typeof textRight !== 'string' && textRight < 0) {
            textRight = 0;
          }

          textPositions.push({
            left: typeof textLeft !== 'string' ? textLeft + '%' : textLeft,
            right: typeof textRight !== 'string' ? textRight + '%' : textRight,
            top: typeof textTop !== 'string' ? textTop + '%' : textTop,
            bottom: typeof textBottom !== 'string' ? textBottom + '%' : textBottom,
            width: typeof textWidth !== 'string' ? textWidth + '%' : textWidth,
            maxWidth: typeof textMaxWidth !== 'string' ? textMaxWidth + '%' : textMaxWidth,
            maxHeight: typeof textMaxHeight !== 'string' ? textMaxHeight + '%' : textMaxHeight,
            textAlign: textAlignRight ? 'right' : 'left',
            padding: textPadding
          });
        }

        this.setState({
          currentStep: 0,
          isOpened: true,
          highLightPositions: highLightPositions,
          textPositions: textPositions,
          textScope: textScope
        });
        setTimeout(() => {
          this.setState({isFocused: true});
        }, 600);
      }
    }, 100);
  };

  handleKeyDown = e => {
    // console.log('e.which', e.which);
    // if (e.ctrlKey && e.which === 27) {
    if (e.which === 27) {
      this.prepareClose();
    } else if (e.which === 39) {
      this.nextStep();
    } else if (e.which === 37) {
      this.prevStep();
    }
  };

  prepareClose = () => {
    this.setState({
      isOpened: false,
      isFocused: false
    }, () => {
      if (this.props.hasOwnProperty('show') && this.props.show) {
        setTimeout(() => {
          /*
          =======
                setTimeout(() => {
                  this.setState({
                    isFocused: false,
                    currentStep: 0,
                    textScope: null,
                    highLightPositions: [],
                    textPositions: []
                  });
                  if (this.props.hasOwnProperty('show') && this.props.show) {
          >>>>>>> c53d72980055233e332648dc3ce0a9d80ff8f883
          */
          this.close();
        }, 400);
      }
    });
  };

  close = () => {
    this.props.close();
  };

  nextStep = () => {
    const {textScope} = this.state;
    let step = this.state.currentStep;
    let isUpdated = false;

    if (textScope) {
      if (step === (textScope.length - 1)) {
        this.prepareClose();
        setTimeout(() => {
          this.setState({currentStep: 0});
        }, 1000);
      } else {
        if (step < (textScope.length - 1)) {
          step++;
          isUpdated = true;
        }
      }
      if (isUpdated) {
        this.setState({isFocused: false});
        setTimeout(() => {
          this.setState({currentStep: step});
          setTimeout(() => {
            this.setState({isFocused: true});
          }, 300);
        }, 300);
      }
    }
  };

  prevStep = () => {
    let step = this.state.currentStep;
    let isUpdated = false;

    if (step > 0) {
      step--;
      isUpdated = true;
    }

    if (isUpdated) {
      this.setState({isFocused: false});
      setTimeout(() => {
        this.setState({currentStep: step});
        setTimeout(() => {
          this.setState({isFocused: true});
        }, 300);
      }, 300);
    }
  };

  render () {
    const {
      t
    } = this.props;
    const {
      isOpened,
      isFocused,
      currentStep,
      textPositions,
      textScope,
      highLightPositions
    } = this.state;

    if (textScope === null) {
      // console.warn('GLOBALHELP WARNING textScope', textScope);
    }

    let highlightStyle = {
      top: '0%',
      left: '0%',
      width: '100%',
      height: '100%'
    };

    if (highLightPositions.length > 0) {
      highlightStyle = {
        top: highLightPositions[currentStep].top,
        left: highLightPositions[currentStep].left,
        width: highLightPositions[currentStep].width,
        height: highLightPositions[currentStep].height
      };
    }

    return (
      <div
        className={styles.help}
        ref={div => (this.helpPopup = div)}
        is-visible={isOpened.toString()}
      >
        <div
          className={isFocused ? 'highLight focus' : 'highLight'}
          style={highlightStyle}
        ></div>

        {/* <div className="headbar">*/}
        {/* <div className="help-headline">Help</div>*/}
        {/* </div>*/}
        {textPositions.length > 0 && textScope &&
        <div
          className={!isFocused ? 'text-wrapper' : 'text-wrapper show'}
          has-padding={textPositions[currentStep].padding.toString()}
          is-align-right={(textPositions[currentStep].textAlign === 'right').toString()}
          style={{
            top: textPositions[currentStep].top,
            bottom: textPositions[currentStep].bottom,
            left: textPositions[currentStep].left,
            right: textPositions[currentStep].right,
            width: textPositions[currentStep].width,
            maxWidth: textPositions[currentStep].maxWidth,
            maxHeight: textPositions[currentStep].maxHeight,
            textAlign: textPositions[currentStep].textAlign
          }}
        >
          <div className="headline">
            {textScope && (t(textScope[currentStep].headline) !== '--') &&
            <span dangerouslySetInnerHTML={{__html: t(textScope[currentStep].headline)}}></span>
            }
          </div>
          {textScope && (t(textScope[currentStep].text) !== '--') &&
          <div className="text">
            <div dangerouslySetInnerHTML={{__html: t(textScope[currentStep].text)}}></div>
          </div>
          }
          <div className="nav">
            <div className="nav-inner">
              {
                currentStep !== 0 &&
                <button
                  className={'leftButton'}
                  title={t('previous_step')}
                  disabled={!isFocused || currentStep === 0}
                  onClick={this.prevStep}>
                  <FontIcon>chevron_left</FontIcon>
                </button>
              }

              {textScope &&
              <span className={'steps'}>{(currentStep + 1) + '/' + textScope.length}</span>
              }
              {textScope && (currentStep < (textScope.length - 1)) &&
              <button
                className={'rightButton'}
                title={t('next_step')}
                disabled={!isFocused || currentStep === textScope.length}
                onClick={this.nextStep}>
                <FontIcon>chevron_right</FontIcon>
              </button>
              }
              {textScope &&
              <button
                className="closeButton"
                title={t('close_help')}
                onClick={this.prepareClose}>
                <FontIcon>close</FontIcon>
              </button>
              }
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['auth', 'user']),
    impersonatedUser: state.getIn(['auth', 'impersonatedUser'])
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    editUserSettings
  }
)(GlobalHelp));
