import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';

import { linkUserToProject } from '../../../actions/users';
import { fetchProjects } from '../../../actions/projects';
import RelationBase from '../../general/RelationBase';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';

@withTranslation(['users', 'general'], {wait: true})
class UserProjects extends Component {
  linkSourceToTarget = (mode, projectId, roleId) => {
    this.props.linkUserToProject({
      mode: mode,
      user_id: this.props.user.id,
      project_id: projectId,
      role_id: roleId,
      property_type: 'users_x_projects'
    });
  };

  render () {
    const {user, projects, fetchingProjects, linkingUserToProject, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <RelationBase
        fetchTargets={this.props.fetchProjects}
        fetchingTargets={fetchingProjects}
        label={ t(`${generalLNS}:projects`) }
        source={user}
        sourceTargets={user.projects}
        targets={projects}
        chip={RoleAvatarChip}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingUserToProject}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['users', 'selectedUser']),
    projects: state.getIn(['projects', 'projects']),
    fetchingProjects: state.getIn(['projects', 'fetchingProjects']),
    linkingUserToProject: state.getIn(['users', 'linkingUserToProject']),
    updateToken: state.getIn(['users', 'updateToken'])
  };
}

export default connect(
  mapStateToProps,
  {linkUserToProject, fetchProjects}
)(UserProjects);
