import React, {Component} from 'react';
import LayoutContainer from '../layout/LayoutContainer';
import {Card} from 'react-md';
import ImprintText from '../general/texts/ImprintText';

class Imprint extends Component {
  render () {
    return (
      <LayoutContainer width="small">
        <Card style={{marginTop: '2rem', padding: '24px'}}>
          <ImprintText />
        </Card>
      </LayoutContainer>
    );
  }
}

export default Imprint;
