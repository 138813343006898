/** @jsx h */
/* eslint-disable */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHyperscript } from '@gitbook/slate-hyperscript';

const h = createHyperscript({
  blocks: {
    blockquote: 'quote',
    paragraph: 'paragraph',
    ul_list: 'ul_list',
    ol_list: 'ol_list',
    list_item: 'list_item'
  },
  inlines: {
    a: 'link',
    shy: 'shy',
    zwsp: 'zwsp'
  },
  marks: {
    strong: 'bold',
    b: 'bold',
    sub: 'subscript',
    sup: 'superscript',
    span_nobreak: 'span_nobreak'
  }
});

/*  OLD:
export default (
  <value>
    <document>
      <paragraph>
        This page is a basic exa<shy class="shy">-</shy>dfsdfsd<zwsp class="zwsp">-</zwsp>mple of Slate + <span_nobreak>slate-edit-list plugin</span_nobreak>.
        Press Enter in a list to create a new list item. Press Enter
        again to exit and <a href="kdsjflkds">link</a> Shift+Enter to create a paragraph in a list.
        The items at range are detected and highlighted, for
        demonstration purpose.
      </paragraph>
      <ul_list style={{ listStyleType: 'disc' }}>
        <list_item>
          <paragraph>First <strong>item</strong> in the list</paragraph>
        </list_item>
        <list_item>
          <paragraph>List item can contain blocks</paragraph>
          <paragraph>And another paragraph</paragraph>
        </list_item>
        <list_item>
          <paragraph>Third item in the list, with a nested list</paragraph>
          <ul_list style={{ listStyleType: 'decimal' }}>
            <list_item>
              <paragraph>First item in the nested list</paragraph>
            </list_item>
            <list_item>
              <paragraph>Second item in the nested list</paragraph>
            </list_item>
          </ul_list>
        </list_item>
      </ul_list>
      <paragraph>End paragraph</paragraph>
    </document>
  </value>
);
*/

/* NEUER INITAL VALUE für weniger overhead */
export default (
  <value>
    <document>
      <paragraph> </paragraph>
    </document>
  </value>
);
