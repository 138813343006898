import { List, fromJS } from 'immutable';

import { TranslationToolAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  translations: List([]),
  fetchingTranslations: ProcessStatus.INITIAL,
  editingTranslation: ProcessStatus.INITIAL,
  currentTranslation: null,
  textsForLocalStorage: null,
  areExpanded: true,
  areTagsShown: false,
  areAllTextsShown: false,
  areOnlyDifferencesShown: false,
  areDifferencesHighlighted: false,
  areOnlyEmptyShown: false,
  storage: {},
  savedTranslation: null,
  error: null
});
let index;
let tmpStorage = {};
let param;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TranslationToolAction.FETCH_TRANSLATIONS:
      return state.set('fetchingTranslations', ProcessStatus.STARTED);
    case TranslationToolAction.FETCH_TRANSLATIONS_FULFILLED:
      return state
        .set('fetchingTranslations', ProcessStatus.FINISHED)
        .set('translations', List(action.translations));
    case TranslationToolAction.FETCH_TRANSLATIONS_REJECTED:
      return state.set('fetchingTranslations', ProcessStatus.FAILED).set('error', action.payload);
    case TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS:
      return state.set('fetchingTranslations', ProcessStatus.STARTED);
    case TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED:
      return state
        .set('fetchingTranslations', ProcessStatus.FINISHED)
        .set('translations', List(action.translations));
    case TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_REJECTED:
      return state.set('fetchingTranslations', ProcessStatus.FAILED).set('error', action.payload);
    case TranslationToolAction.SAVE_TRANSLATION:
      return state.set('editingTranslation', ProcessStatus.STARTED);
    case TranslationToolAction.SAVE_TRANSLATION_SUCCESS:
      index = state.get('translations').findIndex(function (translation) {
        return translation.id === action.translation.section;
      });
      if (index > -1) {
        let section = state.get('translations').get(index);
        let fieldId = section.data.findIndex(function (field) {
          return field.to.id === action.translation.id;
        });
        if (fieldId > -1) {
          section.data[fieldId].to.content = action.translation.value;
          return state
            .set('textsForLocalStorage', action.translation.textsForLocalStorage)
            .set('editingTranslation', ProcessStatus.FINISHED)
            .set('savedTranslation', action.translation)
            .set('translations', state.get('translations').set(index, section));
        }
      }
      return state
        .set('textsForLocalStorage', action.textsForLocalStorage)
        .set('editingTranslation', ProcessStatus.FINISHED)
        .set('translations', List(action.translations));
    case TranslationToolAction.SAVE_TRANSLATION_ERROR:
      return state.set('editingTranslation', ProcessStatus.FAILED).set('error', action.payload);
    case TranslationToolAction.SET_ARE_EXPANDED:
      return state.set('areExpanded', action.value);
    case TranslationToolAction.SET_ARE_TAGS_SHOWN:
      return state.set('areTagsShown', action.value);
    case TranslationToolAction.SET_ARE_ALL_TEXTS_SHOWN:
      return state.set('areAllTextsShown', action.value);
    case TranslationToolAction.SET_ARE_ONLY_DIFFERENCES_SHOWN:
      return state.set('areOnlyDifferencesShown', action.value);
    case TranslationToolAction.SET_ARE_DIFFERENCES_HIGHLIGHTED:
      return state.set('areDifferencesHighlighted', action.value);
    case TranslationToolAction.SET_ARE_ONLY_EMPTY_SHOWN:
      return state.set('areOnlyEmptyShown', action.value);
    case TranslationToolAction.UPDATE_STORAGE:
      for (param in action.payload) {
        tmpStorage[param] = action.payload[param];
      }
      return state.set('storage', tmpStorage);
    case TranslationToolAction.SET_LOCAL_STORAGE:
      for (param in state.get('storage')) {
        localStorage.setItem(
          param,
          typeof state.get('storage')[param] === 'object'
            ? JSON.stringify(state.get('storage')[param])
            : state.get('storage')[param]
        );
      }
      return state;
    case TranslationToolAction.SET_EDITING_INITIAL_STATUS:
      return state
        .set('editingTranslation', ProcessStatus.INITIAL)
        .set('savedTranslation', null);
    default:
      return state;
  }
};

export { initialState, reducer as translationTool };
