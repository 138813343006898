import { PermissionsAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchPermissions = () => {
  return {
    type: PermissionsAction.FETCH_PERMISSIONS
  };
};

const fetchPermissionsFulfilled = permissions => {
  return {
    type: PermissionsAction.FETCH_PERMISSIONS_FULFILLED,
    permissions: permissions,
    receivedAt: Date.now()
  };
};

const fetchPermissionsRejected = error => {
  return Rx.Observable.of({
    type: PermissionsAction.FETCH_PERMISSIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const addPermission = permission => {
  return {
    type: PermissionsAction.ADD_PERMISSION,
    permission: permission
  };
};

const addPermissionFulfilled = data => {
  return {
    type: PermissionsAction.ADD_PERMISSION_FULFILLED,
    permission: data
  };
};

const addPermissionRejected = error => {
  return Rx.Observable.of({
    type: PermissionsAction.ADD_PERMISSION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const editPermission = permission => {
  return {
    type: PermissionsAction.EDIT_PERMISSION,
    permission: permission
  };
};

const editPermissionFulfilled = data => {
  return {
    type: PermissionsAction.EDIT_PERMISSION_FULFILLED,
    permission: data
  };
};

const editPermissionRejected = error => {
  return Rx.Observable.of({
    type: PermissionsAction.EDIT_PERMISSION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const deletePermission = permissionId => {
  return {
    type: PermissionsAction.DELETE_PERMISSION,
    permissionId: permissionId
  };
};

const deletePermissionFulfilled = permissionId => {
  return {
    type: PermissionsAction.DELETE_PERMISSION_FULFILLED,
    permissionId: permissionId
  };
};

const deletePermissionRejected = error => {
  return Rx.Observable.of({
    type: PermissionsAction.DELETE_PERMISSION_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export {
  fetchPermissions,
  fetchPermissionsFulfilled,
  fetchPermissionsRejected,
  addPermission,
  addPermissionFulfilled,
  addPermissionRejected,
  editPermission,
  editPermissionFulfilled,
  editPermissionRejected,
  deletePermission,
  deletePermissionFulfilled,
  deletePermissionRejected
};
