import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LayoutContainer from '../layout/LayoutContainer';

import {ClientsModel} from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import LoadingBar from '../general/DumbComponent';

import DeleteClientWarning from './dialog/DeleteClientWarning';

import {ProcessStatus, Routes} from '../../utils/Constants';
import {arrayHelper} from '../../utils/HelperFunctions';
import ContentDrawer from '../../utils/ContentDrawer';

import AddClient from './addclient/AddClient';
import EditClientContainer from './editclient/EditClientContainer';
import {fetchClients, deleteClient, editClient} from '../../actions/clients';
import {showWarning} from '../../actions/globalDialog';
import {hasPermission} from '../../utils/AuthHelper';
import {aclClient} from '../../utils/Permissions';

@withTranslation(['clients', 'general'], {wait: true})
class ListClients extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false
  };

  componentWillMount () {
    const {clients} = this.props;

    if (clients.size === 0) {
      this.props.fetchClients();
    }
  }

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({filters: newFilters});
  };

  handleSearchInput = (value) => {
    this.setState({searchTerm: value});
  };


  handleSearchReset = () => {
    this.setState({searchTerm: ''});
  };

  hitEnterToFocusOnTable = () => {
    this.setState({focusOnTable: true});
  };

  closeDrawer = () => {
    this.props.history.push('/clients');
    // this.props.history.push(Routes.PROJECTS.IMPORT_WARNING);
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom && custom.name) {
      switch (custom.name) {
        case 'edit':
          this.handleEdit(row.id);
          break;

        case 'delete':
          this.showDeleteWarning(row);
          break;
        default:
          break;
      }
    }
    if (cell && cell.id) {
      switch (cell.id) {
        case 'active':
          this.handleActive(row);
          break;
        default:
          break;
      }
    }
  };

  handleEdit = (id) => {
    this.props.history.push('clients/' + id + '/edit');
  };

  handleActive = (client) => {
    client.active = parseInt(+!client.active);
    this.props.editClient(client);
  };

  showDeleteWarning = (client) => {
    const { t, showWarning } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text = t('warning_delete_client') + ' ' + client.name + ' ?';

    let component = <DeleteClientWarning clientId={client.id}/>;

    showWarning({title: title, text: text, actions: [], component: component, isCancellable: false});
  };


  renderFiltersAndTable = () => {
    const {clients, match: {params: {clientId}}} = this.props;

    const filters = ClientsModel.prepareFilters.call(
      ClientsModel,  // focus
      ClientsModel.filters,
      ['company_name'],
      clients
    );

    let route = hasPermission(aclClient.create) ? Routes.CLIENTS.ADD : null;

    return (
      <React.Fragment>
        <ContentDrawer
          key="1"
          children={<AddClient closeDrawer={this.closeDrawer}/>}
          visible={this.props.match.path === Routes.CLIENTS.ADD}
          closeDrawer={this.closeDrawer}
        />
        <ContentDrawer
          key="2"
          children={<EditClientContainer clientId={clientId}/>}
          visible={this.props.match.path === Routes.CLIENTS.EDIT}
          closeDrawer={this.closeDrawer}
        />
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={route}
        />
        <CustomTable
          model={ClientsModel}
          rows={clients.toArray()}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.handleEdit}
        />
      </React.Fragment>
    );
  };


  render () {
    const {clients, fetchingClients} = this.props;

    if (fetchingClients == ProcessStatus.INITIAL ||
      fetchingClients == ProcessStatus.STARTED) {
      return <LayoutContainer>
        {/* <MangooluLoading show={true} />*/}
        <LoadingBar show={true}/>
      </LayoutContainer>;
    }
    if (clients == undefined || clients.size <= 0) {
      return <div>No clients found.</div>;
    }

    return (
      <LayoutContainer>
        {this.renderFiltersAndTable()}
      </LayoutContainer>
    );
  }
}


function mapStateToProps (state) {
  return {
    fetchingClients: state.getIn(['clients', 'fetchingClients']),
    // editingClient: state.getIn(['clients','editingClient']),
    clients: state.getIn(['clients', 'clients']),
    client: state.getIn(['auth', 'selectedClient'])
  };
}

export default connect(
  mapStateToProps,
  {
    fetchClients,
    deleteClient,
    editClient,
    showWarning
  })(ListClients);
