import React from 'react';
import {connect} from 'react-redux';
import StaticImage from './StaticImage';
import ImageEdit from './ImageEdit';

class ImageDisplay extends React.Component {
  render () {
    const {editMode, alt, src} = this.props;
    if (editMode) {
      return <ImageEdit alt={alt}/>;
    }
    return <StaticImage src={src} alt={alt}/>;
  }
}

function mapStateToProps (state) {
  return {
    editMode: state.getIn(['image', 'editMode'])
  };
}

export default connect(mapStateToProps)(ImageDisplay);
