import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Card, CardActions, CardText, CardTitle, Button} from 'react-md';

import LoadingBar from '../../general/DumbComponent';
import {ProcessStatus} from '../../../utils/Constants';

import {validateProject, resetCollectedInfos} from '../../../actions/projects';
import {withTranslation} from 'react-i18next';

@withTranslation(['project', 'general'], {wait: true})
class ValidateUpload extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    console.log('componentWillMount, this.props = ', this.props);
    this.validateProject();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    console.log('componentWillReceiveProps, nextProps = ', nextProps);

    if (
      nextProps.validatingUpdate == ProcessStatus.FINISHED &&
      nextProps.validatingUpdate != this.props.validatingUpdate
    ) {
      this.props.onChange(nextProps.uploadExtractPath);
    }
  }

  validateProject = () => {
    const {uploadedFile} = this.props;

    if (this.props.validateUpdate) {
      this.props.validateUpdate();
    } else {
      this.props.validateProject({
        package_type: 'jsbeam_1_0',
        uuid: uploadedFile.uuid,
        filename: uploadedFile.name
      });
    }
  };

  discardProject = () => {
    console.log('discardProject', this.props);
    // console.log("discardProject");
    // this.props.onChange('');
    this.props.onDiscard();
  };

  render () {
    const {collectedInfos, uploadedFile, validatingUpdate, error, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let errorMessage = t(`${generalLNS}:error`);

    if (error && error.notify && error.notify.error) {
      errorMessage = error.notify.error;
    }

    let identifier = collectedInfos.get('identifier') || '';
    let version = collectedInfos.get('version') || '';

    let subtitle = collectedInfos.size > 0 ? identifier + ' ' + version : '';

    return (
      <Card className="md-block-centered">
        <CardTitle title={uploadedFile.name} subtitle={subtitle}/>
        <CardText>
          {validatingUpdate == ProcessStatus.STARTED && (
            <LoadingBar show="true" message={ t('validating_project') }/>
          )}
          {validatingUpdate == ProcessStatus.FINISHED && (
            <div>
              <p>{ t('update_validated') }</p>
            </div>
          )}
          {validatingUpdate == ProcessStatus.FAILED && (
            <div>
              <p>{ t('validation_failed') }: {errorMessage}</p>
            </div>
          )}
        </CardText>
        {validatingUpdate == ProcessStatus.FAILED && (
          <CardActions>
            <Button key="discard" flat onClick={this.discardProject}>
              { t(`${generalLNS}:discard`) }
            </Button>
            <Button key="try_again" flat onClick={this.validateProject}>
              { t(`${generalLNS}:try_again`) }
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    validatingUpdate: state.getIn(['projects', 'validatingUpdate']),
    collectedInfos: state.getIn(['projects', 'collectedInfos']),
    uploadExtractPath: state.getIn(['projects', 'uploadExtractPath']),
    error: state.getIn(['projects', 'error'])
  };
}

export default connect(
  mapStateToProps,
  {
    validateProject,
    resetCollectedInfos
  }
)(ValidateUpload);
