import React, {
  useEffect,
  useState,
  useRef
} from 'react';
import styles from '../../styles/storyboards/sitesView.scss';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import LayoutContainer from '../layout/LayoutContainer';
import {ViewMode} from '../../utils/Constants';
import {CompareMode} from '../../utils/Constants';
import {useSelector} from 'react-redux';
import Paper from "@material-ui/core/Paper";


export default function SiteView (props) {
  const viewMode = useSelector(state => state.getIn(['storyboard', 'viewMode']));
  const compareMode = useSelector(state => state.getIn(['storyboard', 'compareMode']));

  const [horizontal, setHorizontal] = useState(false);
  const [horizontalSite, setHorizontalSite] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [translation, setTranslation] = useState(false);

  let scroll = useRef(null);


  let classes = styles.sitesView;

  if (horizontal) {
    classes = classes + ' horizontal';
  }
  if (translation) {
    classes = classes + ' translation';
  }

  let translationValue = 0;

  if (horizontal) {
    translationValue = 100 * horizontalSite * -1;
  }

  let switchBtnText = horizontal ? 'switch to one-pager' : 'switch to page-turner';

  let layoutContainerWidth = (viewMode === ViewMode.COMPARE && compareMode === CompareMode.SPLIT) ? 'big' : 'small';
  console.log('SitesView:: compareMode', compareMode, ' == ', CompareMode.SPLIT, compareMode === CompareMode.SPLIT);

  if (horizontal) {
    return (
      <div className={classes + ' horizontal'}>
        <div className="scroll" ref={scroll}>
          <LayoutContainer className={'layoutCon'} width={layoutContainerWidth}>
            <div
              className="site-wrapper"
              style={{
                transform: 'translateX(' + translationValue + '%) translateZ(0)'
              }}>
              {props.sites}
            </div>
          </LayoutContainer>
        </div>

        <Fab
          size="small"
          aria-label="left"
          className="left"
          onClick={() => {
            if (horizontalSite > 0) {
              let newSite = horizontalSite - 1;
              setHorizontalSite(newSite);
            }
          }}
        ><KeyboardArrowLeftIcon /></Fab>
        <Fab
          size="small"
          aria-label="switch"
          className="switch"
          onClick={() => {
            setHorizontal(!horizontal);
          }}
        ><ViewDayIcon /></Fab>
        <Fab
          className="right"
          size="small"
          aria-label="add"
          onClick={() => {
            if (horizontalSite < props.sites.length - 1) {
              let newSite = horizontalSite + 1;
              setHorizontalSite(newSite);
            }
          }}
        ><KeyboardArrowRightIcon /></Fab>
      </div>
    );
  }


  // ONE-PAGER
  return (
    <div className={classes}>
      <LayoutContainer className={'layoutCon elevation'} width={layoutContainerWidth}>
        <Paper square={true} elevation={0}>
          <div className="sitesViewInner">
            {props.children}
            <div className="scroll" ref={scroll}>
              <div
                className="site-wrapper"
                style={{
                  transform: 'translateX(' + translationValue + '%) translateZ(0)'
                }}>
                {props.sites}
              </div>
            </div>
          </div>
        </Paper>
      </LayoutContainer>
      {/*
      <Fab
        size="small"
        aria-label="switch"
        className="switch"
        onClick={() => {
          setHorizontal(!horizontal);
        }}
      >
        <ViewCarouselIcon />
      </Fab>
      */}
    </div>
  );
}
