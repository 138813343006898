import { NotificationsAction } from '../utils/ActionTypes';
import { NotificationType } from '../utils/Constants';

const showNotification = (notificationType, message, title = null, duration = 5000) => {
  return {
    type: NotificationsAction.SHOW_NOTIFICATION,
    notificationType: notificationType,
    message: message,
    title: title,
    duration: duration
  };
};

const showError = (message, title = 'Error') => {
  return showNotification(NotificationType.ERROR, message, title);
};

const showWarning = (message, title = 'Warning') => {
  return showNotification(NotificationType.WARNING, message, title);
};

const showSuccess = (message, title, data) => {
  return showNotification(NotificationType.SUCCESS, message, title);
};

const removeNotification = identifier => {
  return {
    type: NotificationsAction.REMOVE_NOTIFICATION,
    identifier: identifier
  };
};

export { showNotification, showError, showWarning, showSuccess, removeNotification };
