import React, {Component, createRef} from 'react';
import {connect, useSelector} from 'react-redux';
import {fetchChapter} from '../../actions/storyboard';
import SitesView from './SitesView.js';
import Site from './Site.js';
// import styles from '../../styles/storyboards/storyboard.scss';
// import ContentTools from '../translationTool/ContentTools';
// import {setEditingInitialStatus} from '../../actions/translationTool';
// import {showNotification} from '../../actions/notifications';
import {withTranslation} from 'react-i18next';
import MoreMenu from './MoreMenu';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import styles from '../../styles/storyboards/site.scss';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SortIcon from '@material-ui/icons/Sort';
import CreateIcon from '@material-ui/icons/Create';
import {ProcessStatus} from '../../utils/Constants';

function mapStateToProps (state) {
  return {
    // chapter = state.getIn(['storyboard', 'chapter']);
    chapterStructure: state.getIn(['storyboard', 'chapterStructure']),
    currentChapter: state.getIn(['storyboard', 'currentChapter']),
    currentChapterCollection: state.getIn(['storyboard', 'currentChapterCollection']),
    currentChapterId: state.getIn(['storyboard', 'currentChapterId']),
    currentParentChapter: state.getIn(['storyboard', 'currentParentChapter']),
    addingSite: state => state.getIn(['storyboard', 'addingSite'])
  };
}

@connect(
  mapStateToProps,
  {
    fetchChapter
  }
)

@withTranslation(['translation_navigation', 'general'], {wait: true})
class Chapter extends Component {
  constructor (props) {
    super(props);

    this.state = {
      moreMenuOpen: false
    };

    this.refMoreMenuBtn = createRef();
  }

  componentDidMount () {
    const chapterId = this.props.id;
    this.props.fetchChapter(chapterId);
  }

  componentDidUpdate (prevProps) {
    console.log('chapter componentDidUpdate', prevProps, this.props);
    const {currentChapter, addingSite} = this.props;
    if (addingSite !== prevProps.addingSite && addingSite === ProcessStatus.FINISHED) {
      console.log('addingSite finished', addingSite);
      this.props.fetchChapter(currentChapter);
    }
    if (currentChapter !== prevProps.currentChapter) {
      this.props.fetchChapter(currentChapter);
    }
  }

  render () {
    const {t, currentParentChapter} = this.props;

    if (!this.props.chapterStructure) {
      console.log('Chapter::', this.props);
      return null;
    }

    return (
      <div>
        <SitesView sites={this.props.chapterStructure.map((item, index) => {
          return (
            <Site
              key={index}
              siteId={item.siteId}
              siteIndex={index}
              isFirstSite={index === 0}
              isLastSite={index === this.props.chapterStructure.size - 1} // TODO: @m.orf: Auch einfügen von Seiten zwischen den vorhandenen Seite ermöglichen
            />
          );
        })}>
          <div className="chapterTitle">
            <div>
              {/* <span>{t('chapter')}&nbsp;&nbsp;{this.props.currentParentChapter.collection}</span>*/}
              <span>{this.props.currentParentChapter.collection}</span>
              <span style={{
                marginRight: '5px',
                color: '#ddd',
                fontWeight: '600',
                fontSize: '12px',
                textTransform: 'uppercase'
              }}>&nbsp;-&nbsp;{t('chapter')}</span>
              <MoreMenu>
                <h3
                  style={{
                    padding: '6px 16px',
                    margin: '0'
                  }}
                >{t('chapter')}&nbsp;{this.props.currentParentChapter.collection}</h3>
                <Button
                  variant=""
                  color="default"
                  startIcon={<DeleteIcon />}
                  // onClick={handleMoreMenuClose}
                >Löschen ***</Button>
                <Button
                  variant=""
                  color="default"
                  startIcon={<CreateIcon />}
                  onClick={() => {
                    // setSiteInMoveMode(true);
                    // handleMoreMenuClose();
                  }}
                >Umbennen ***</Button>
                <Button
                  variant=""
                  color="default"
                  startIcon={<SortIcon />}
                  onClick={() => {
                    // setSiteInMoveMode(true);
                    // handleMoreMenuClose();
                  }}
                >Unterkapitel sortieren ***</Button>
              </MoreMenu>
            </div>
            <div>
              {/* <span>{t('subchapter')}&nbsp;&nbsp;{this.props.currentChapterCollection}</span>*/}
              <span>{this.props.currentChapterCollection}</span>
              <span style={{
                marginRight: '5px',
                color: '#ddd',
                fontWeight: '600',
                fontSize: '12px',
                textTransform: 'uppercase'
              }}>&nbsp;-&nbsp;{t('subchapter')}</span>
              <MoreMenu>
                <h3
                  style={{
                    padding: '6px 16px',
                    margin: '0'
                  }}
                >{t('subchapter')}&nbsp;{this.props.currentChapterCollection}</h3>
                <Button
                  variant=""
                  color="default"
                  startIcon={<DeleteIcon />}
                  // onClick={handleMoreMenuClose}
                >Löschen ***</Button>
                <Button
                  variant=""
                  color="default"
                  startIcon={<CreateIcon />}
                  onClick={() => {
                    // setSiteInMoveMode(true);
                    // handleMoreMenuClose();
                  }}
                >Umbennen ***</Button>
                <Button
                  variant=""
                  color="default"
                  startIcon={<SortIcon />}
                  onClick={() => {
                    // setSiteInMoveMode(true);
                    // handleMoreMenuClose();
                  }}
                >Seiten sortieren ***</Button>
              </MoreMenu>
            </div>
          </div>
        </SitesView>
      </div>
    );
  }
}

export default Chapter;
