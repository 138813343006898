import { List, fromJS } from 'immutable';

import {ErrorDialogAction} from '../utils/ActionTypes';

import { createErrorDialog } from '../utils/HelperFunctions';

const initialState = fromJS({
  dialogs: List()
});

const reducer = (state = initialState, action) => {
  let dialog = null;
  let updatedList = null;
  let index = null;

  if (action.error) {
    if (action.status === 401) {
      // dialog = createErrorDialog(action.type, action.payload.notify.error[0], action.status);
      dialog = createErrorDialog(action.type, null, action.status);
      updatedList = state.get('dialogs').push(dialog);
      return state.set('dialogs', updatedList);
    }
  }

  switch (action.type) {
    case ErrorDialogAction.REMOVE_ERROR_DIALOG:
      index = state.get('dialogs').findIndex(function (obj) {
        return obj.identifier === action.identifier;
      });
      if (index > -1) {
        state = state.set('dialogs', state.get('dialogs').delete(index));
      }
      return state;

    default:
      return state;
  }
};

export { initialState, reducer as errorDialog };
