import * as Rx from 'rxjs';

import { TranslationToolAction } from '../utils/ActionTypes';

const fetchTranslations = (data = {}) => {
  console.log('ACTION: fetchTranslations: ', data);
  return {
    type: TranslationToolAction.FETCH_TRANSLATIONS,
    data
  };
};

const fetchTranslationsFulfilled = translations => {
  return {
    type: TranslationToolAction.FETCH_TRANSLATIONS_FULFILLED,
    translations
  };
};

const fetchTranslationsRejected = error => {
  return Rx.Observable.of({
    type: TranslationToolAction.FETCH_TRANSLATIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const fetchAdditionalContentTranslations = (data = {}) => {
  console.log('ACTION: fetchAdditionalContentTranslations: ', data);
  return {
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS,
    data
  };
};

const fetchAdditionalContentTranslationsFulfilled = translations => {
  return {
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED,
    translations
  };
};

const fetchAdditionalContentTranslationsRejected = error => {
  return Rx.Observable.of({
    type: TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const saveTranslation = (data = {} /* , onSuccessCallback = () => {} */) => {
  return {
    type: TranslationToolAction.SAVE_TRANSLATION,
    data: data /* ,
    // onSuccessCallback: onSuccessCallback */
  };
};

const saveTranslationSuccess = translation => {
  return {
    type: TranslationToolAction.SAVE_TRANSLATION_SUCCESS,
    translation
  };
};

const saveTranslationError = error => {
  return Rx.Observable.of({
    type: TranslationToolAction.SAVE_TRANSLATION_ERROR,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const updateStorage = data => {
  return {
    type: TranslationToolAction.UPDATE_STORAGE,
    payload: data
  };
};

const setEditingInitialStatus = () => {
  return {
    type: TranslationToolAction.SET_EDITING_INITIAL_STATUS
  };
};

const setAreExpanded = value => {
  return {
    type: TranslationToolAction.SET_ARE_EXPANDED,
    value: value
  };
};

const setAreTagsShown = value => {
  return {
    type: TranslationToolAction.SET_ARE_TAGS_SHOWN,
    value: value
  };
};

const setAreAllTextsShown = value => {
  return {
    type: TranslationToolAction.SET_ARE_ALL_TEXTS_SHOWN,
    value: value
  };
};

const setAreOnlyDifferencesShown = value => {
  return {
    type: TranslationToolAction.SET_ARE_ONLY_DIFFERENCES_SHOWN,
    value: value
  };
};

const setAreDifferencesHighlighted = value => {
  return {
    type: TranslationToolAction.SET_ARE_DIFFERENCES_HIGHLIGHTED,
    value: value
  };
};

const setAreOnlyEmptyShown = value => {
  return {
    type: TranslationToolAction.SET_ARE_ONLY_EMPTY_SHOWN,
    value: value
  };
};

export {
  fetchTranslations,
  fetchTranslationsFulfilled,
  fetchTranslationsRejected,
  fetchAdditionalContentTranslations,
  fetchAdditionalContentTranslationsFulfilled,
  fetchAdditionalContentTranslationsRejected,
  saveTranslation,
  saveTranslationSuccess,
  saveTranslationError,
  updateStorage,
  setEditingInitialStatus,
  setAreExpanded,
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted,
  setAreOnlyEmptyShown
};
