import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import {withTranslation} from 'react-i18next';
import {renderTextField, renderSelectField} from '../general/BasicFormComponents';
import {fetchAvailableTranslations} from '../../actions/i18n';
import styles from '../../styles/profile_prime.scss';

@withTranslation(['profile', 'general'], {wait: true})
class ProfilePrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      formattedAvailableTranslations: []
    };
  }

  componentWillMount () {
    const {availableTranslations} = this.props;

    if (availableTranslations.size == 0) {
      this.props.fetchAvailableTranslations();
    } else {
      this.formatAvailableTranslations(availableTranslations);
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {availableTranslations} = nextProps;

    if (availableTranslations !== this.props.availableTranslations) {
      this.formatAvailableTranslations(availableTranslations);
    }
  }

  formatAvailableTranslations = availableTranslations => {
    let formattedAvailableTranslations = [];

    availableTranslations.mapEntries(([key, value]) => {
      formattedAvailableTranslations.push({'value': key,
        'label': key + ' | ' + value
      });
    });

    this.setState({formattedAvailableTranslations: formattedAvailableTranslations});
  };

  render () {
    const { t } = this.props;
    const { formattedAvailableTranslations } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'formContentWrapper body paddingLeftRight'}>
        <Field
          id="username"
          name="username"
          label={ t(`${generalLNS}:username`) }
          type="text"
          helpText={ t('username_help') }
          className="md-cell md-cell--12"
          disabled
          component={renderTextField}
        />
        <Field
          id="name"
          name="name"
          label={ t(`${generalLNS}:name`) }
          type="text"
          helpText={ t('name_help') }
          className="md-cell md-cell--12"
          required
          component={renderTextField}
        />
        <Field
          id="email"
          name="email"
          label={ t('associated_email') }
          type="text"
          helpText={ t('email_help') }
          className="md-cell md-cell--12"
          required
          component={renderTextField}
        />
        <Field
          id="languagecode"
          name="languagecode"
          label={ t(`${generalLNS}:language_code`) }
          type="text"
          className={'md-cell md-cell--12 ' + styles.languagecode}
          menuItems={formattedAvailableTranslations}
          required
          component={renderSelectField}
          helpText={ t('languagecode_help') }
          errorText={ t('languagecode_error') }
          // errorText={<span>A <em>real</em> value is required for this field</span>}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    availableTranslations: state.getIn(['i18n', 'availableTranslations'])
  };
}

export default connect(
  mapStateToProps,
  {fetchAvailableTranslations}
)(ProfilePrime);
