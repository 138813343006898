import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';
import {removeDialog} from '../../../../actions/globalDialog';

@withTranslation(['project', 'general'], {wait: true})
class WrongFormatWarning extends Component {
  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  render () {
    const {t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          {t(`${generalLNS}:cancel`)}
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {
    removeDialog
  }
)(WrongFormatWarning);
