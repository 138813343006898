import {StatusAction, StoryboardAction} from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchChapter = (id) => {
  return {
    type: StoryboardAction.FETCH_CHAPTER,
    payload: id
  };
};

const fetchChapterFulfilled = (chapter) => {
  return {
    type: StoryboardAction.FETCH_CHAPTER_FULFILLED,
    payload: chapter.data
  };
};

const fetchChapterRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.FETCH_CHAPTER_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const addTextBlock = (textBlock) => {
  return {
    type: StoryboardAction.ADD_TEXT_BLOCK,
    payload: textBlock
  };
};

const addTextBlockFulfilled = (response) => {
  return {
    type: StoryboardAction.ADD_TEXT_BLOCK_FULFILLED,
    payload: response.data
  };
};

const addTextBlockRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.ADD_TEXT_BLOCK_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const lockTextBlock = (id) => {
  return {
    type: StoryboardAction.LOCK_TEXT_BLOCK,
    id
  };
};


const lockTextBlockFulfilled = (textBlock) => {
  return {
    type: StoryboardAction.LOCK_TEXT_BLOCK_FULFILLED,
    payload: textBlock
  };
};

const lockTextBlockRejected = (error) => {
  return Rx.Observable.of({
    type: StoryboardAction.LOCK_TEXT_BLOCK_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const unlockTextBlock = (id) => {
  console.log('unlockTextBlock', id);
  return {
    type: StoryboardAction.UNLOCK_TEXT_BLOCK,
    id
  };
};


const unlockTextBlockFulfilled = (textBlock) => {
  return {
    type: StoryboardAction.UNLOCK_TEXT_BLOCK_FULFILLED,
    payload: textBlock
  };
};

const unlockTextBlockRejected = (error) => {
  return Rx.Observable.of({
    type: StoryboardAction.UNLOCK_TEXT_BLOCK_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const updateTextBlock = (id, textBlock) => {
  return {
    type: StoryboardAction.UPDATE_TEXT_BLOCK,
    payload: textBlock,
    id
  };
};

const updateTextBlockFulfilled = (textBlock) => {
  return {
    type: StoryboardAction.UPDATE_TEXT_BLOCK_FULFILLED,
    payload: textBlock.data
  };
};

const updateTextBlockRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.UPDATE_TEXT_BLOCK_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const deleteTextBlock = (id, siteId) => {
  console.log(id);
  return {
    type: StoryboardAction.DELETE_TEXT_BLOCK,
    id,
    siteId
  };
};

const deleteTextBlockFulfilled = (response) => {
  console.log('From the action: ', response);
  return {
    type: StoryboardAction.DELETE_TEXT_BLOCK_FULFILLED,
    payload: response.data
  };
};

const deleteTextBlockRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.DELETE_TEXT_BLOCK_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchStoryboard = (id) => {
  return {
    type: StoryboardAction.FETCH_STORYBOARD,
    payload: id
  };
};

const fetchStoryboardFulfilled = (storyboard) => {
  return {
    type: StoryboardAction.FETCH_STORYBOARD_FULFILLED,
    payload: storyboard.data
  };
};

const fetchStoryboardRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.FETCH_STORYBOARD_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const fetchStoryboards = () => {
  return {
    type: StoryboardAction.FETCH_STORYBOARDS
  };
};

const fetchStoryboardsFulfilled = (result) => {
  return {
    type: StoryboardAction.FETCH_STORYBOARDS_FULFILLED,
    payload: result.data
  };
};

const fetchStoryboardsRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.FETCH_STORYBOARDS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const setCurrentChapter = (id) => {
  return {
    type: StoryboardAction.SET_CURRENT_CHAPTER,
    payload: id
  };
};

const setViewMode = viewMode => {
  return {
    type: StoryboardAction.SET_VIEW_MODE,
    payload: viewMode
  };
};

const setCompareMode = compareMode => {
  return {
    type: StoryboardAction.SET_COMPARE_MODE,
    payload: compareMode
  };
};

const setCurrentEditorId = id => {
  console.log('########################### action setCurrentEditorId', id);
  console.trace();
  return {
    type: StoryboardAction.SET_CURRENT_EDITOR_ID,
    id: id
  };
};

const fetchHistory = (id) => {
  return {
    type: StoryboardAction.FETCH_HISTORY,
    payload: id
  };
};

const fetchHistoryFulfilled = (result) => {
  return {
    type: StoryboardAction.FETCH_HISTORY_FULFILLED,
    payload: result.data
  };
};

const fetchHistoryRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.FETCH_HISTORY_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const updateSite = (id, obj) => {
  return {
    type: StoryboardAction.UPDATE_SITE,
    payload: obj,
    id
  };
};

const updateSiteFulfilled = (obj) => {
  return {
    type: StoryboardAction.UPDATE_SITE_FULFILLED,
    payload: obj.data
  };
};

const updateSiteRejected = (error) => {
  console.log('updateSiteRejected', error);
  return Rx.Observable.of({
    type: StoryboardAction.UPDATE_SITE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const addSite = (site) => {
  console.log('ACTION: addSite', site);
  return {
    type: StoryboardAction.ADD_SITE,
    payload: site
  };
};

const addSiteFulfilled = (response) => {
  return {
    type: StoryboardAction.ADD_SITE_FULFILLED,
    payload: response.data
  };
};

const addSiteRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.ADD_SITE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const deleteSite = (siteId) => {
  return {
    type: StoryboardAction.DELETE_SITE,
    id: siteId
  };
};

const deleteSiteFulfilled = (response) => {
  return {
    type: StoryboardAction.DELETE_SITE_FULFILLED,
    payload: response.data
  };
};

const deleteSiteRejected = (error) => {
  console.log(error);
  return Rx.Observable.of({
    type: StoryboardAction.DELETE_SITE_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    status: error.status,
    error: true
  });
};

const openStoryboardQuestionDialog = (obj) => {
  console.log('openStoryboardQuestionDialog', obj);
  return {
    type: StoryboardAction.OPEN_STORYBOARD_QUESTION_DIALOG,
    payload: obj
  };
};

const closeStoryboardQuestionDialog = () => {
  return {
    type: StoryboardAction.CLOSE_STORYBOARD_QUESTION_DIALOG
  };
};


export {
  fetchChapter,
  fetchChapterFulfilled,
  fetchChapterRejected,
  addTextBlock,
  addTextBlockFulfilled,
  addTextBlockRejected,
  lockTextBlock,
  lockTextBlockFulfilled,
  lockTextBlockRejected,
  unlockTextBlock,
  unlockTextBlockFulfilled,
  unlockTextBlockRejected,
  updateTextBlock,
  updateTextBlockFulfilled,
  updateTextBlockRejected,
  deleteTextBlock,
  deleteTextBlockFulfilled,
  deleteTextBlockRejected,
  fetchStoryboard,
  fetchStoryboardFulfilled,
  fetchStoryboardRejected,
  fetchStoryboards,
  fetchStoryboardsFulfilled,
  fetchStoryboardsRejected,
  setCurrentChapter,
  setCurrentEditorId,
  fetchHistory,
  fetchHistoryFulfilled,
  fetchHistoryRejected,
  setViewMode,
  setCompareMode,
  updateSite,
  updateSiteFulfilled,
  updateSiteRejected,
  addSite,
  addSiteFulfilled,
  addSiteRejected,
  deleteSite,
  deleteSiteFulfilled,
  deleteSiteRejected,
  openStoryboardQuestionDialog,
  closeStoryboardQuestionDialog
};
