import _ from 'lodash';
// import React from 'react';
// import {RoleAttributes} from './Constants';
// import {Avatar} from 'react-md';

const randomString = (length) => {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const createNotification = (type, message, title) => {
  return {
    identifier: randomString(8),
    type: type,
    message: message,
    title: title
  };
};

const createDialog = (type, message, title, actions) => {
  return {
    identifier: randomString(8),
    type: type,
    message: message,
    title: title,
    actions: actions
  };
};

const createErrorDialog = (type, message, status) => {
  return {
    identifier: randomString(8),
    type: type,
    message: message,
    status: status
  };
};

const generateGuid = () => {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

const normalizePositiveNumber = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};

const normalizeDatePickerDate = date => {
  if (!date) {
    return date;
  }

  let parts = date.split('.');
  let year = parts[2];
  let month = parts[1];
  let day = parts[0];

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  let formattedDate = year + '-' + month + '-' + day;

  return formattedDate;
};

const changeUnixTimestampToDate = (date) => {
  var formattedDate = '-';
  if (date) {
    formattedDate = formatDate(date * 1000);
  }
  return formattedDate;
};

const changeDatetimeToDate = (date) => {
  var formattedDate = '-';
  if (date) {
    formattedDate = formatDate(date.replace(' ', 'T')); // IE11 fix
  }
  return formattedDate;
}

const formatDate = (date) => {
  var d = new Date(date);
  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();
  var year = d.getFullYear();
  // var hour = d.getHours();
  // var min = d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  // formattedDate = [year, month, day].join('-') + ' ' + [hour, min].join(':');
  if (isNaN(year)) {
    year = '0000';
  }
  if (isNaN(month)) {
    month = '00';
  }
  if (isNaN(day)) {
    day = '00';
  }
  let formattedDate = [year, month, day].join('-');
  return formattedDate;
};


const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (/* styleSheet.cssRules*/styleSheet.hasOwnProperty('rules')) { // for <style> elements
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
};

const arrayHelper = (arr, name, value) => {
  if (value === null) {
    return arr.filter(item => {
      if (item['type'] === name) {
        if (value === null) {
          return false;
        }
      }
      return true;
    });
  }
  let match = false;
  const newArr = arr
    .map(item => {
      if (item['type'] === name) {
        match = true;
        if (item[value] !== value) {
          return {type: name, value: value};
        }
      }
      return item;
    });
  return (match) ? newArr : newArr.concat({type: name, value: value});
};

// const test = (structure = {}, currentId = null) => {
//   /* No children? break! */
//   if (!structure.childs || structure.childs.length < 2) {
//     console.log('x1');
//     return null;
//   }
//
//   const currentIndex = _.findIndex(structure.childs, (item) => {
//     return item.external_id === currentId;
//   });
//
//   /* First element? break! */
//   if (currentIndex === 0) {
//     return null;
//   }
//
//   if (currentIndex === -1) {
//     console.log('x3');
//     return structure.childs.reverse()[0].external_id;
//   }
//
//   const previousElement = structure.childs[currentIndex - 1];
//
//   if (!previousElement) {
//     console.log('x4');
//     return structure.childs.reverse()[0].external_id;
//   }
//
//   const rec = getPrevPageId(previousElement, currentId);
//   if (rec) {
//     console.log('x5');
//     return rec;
//   }
//   console.log('x6');
//   return previousElement.external_id;
// };

const treeToList = (root, childrenIdentifier = 'children') => {
  function prepareNode (node, parentNode) {
    if (!node.external_id) {
      return node;
    }
    if (!node.collectedIds) {
      node.collectedIds = [];
    }
    if (node.collectedIds.indexOf(node.external_id) !== -1) {
      return node;
    }
    if (parentNode.collectedIds && parentNode.collectedIds.length > 0) {
      node.collectedIds.push(...[node.external_id, ...parentNode.collectedIds]);
    } else {
      node.collectedIds.push(node.external_id);
    }
    return node;
  }

  function convertTreeToList (root) {
    let stack = [];
    let array = [];
    // let hashMap = {};
    stack.push(root);
    while (stack.length !== 0) {
      const node = stack.pop();
      if (!node[childrenIdentifier] || node[childrenIdentifier].length === 0) {
        array.push(node);
      } else {
        // TODO: Find better way, childrenIdentifier is not sufficient to identify nodes with content
        if (node.content_type == 'site') {
          array.push(node);
        }

        for (let i = node[childrenIdentifier].length - 1; i >= 0; i--) {
          let childNode = prepareNode(node[childrenIdentifier][i], node);
          stack.push(childNode);
        }
      }
    }
    return array;
  }

  return convertTreeToList(root);
};

const getPageId = (structure, currentId, mode) => {
  if (!structure.childs) {
    return null;
  }

  const list = treeToList(structure, 'childs');

  if (mode === 'first') {
    return list[0].external_id;
  }

  const currentIndex = _.findIndex(list, (item) => {
    return item.collectedIds.includes(currentId);
  });

  let dir = (mode === 'prev') ? -1 : 1;
  if (mode === 'prev' && currentIndex <= 0) {
    return null;
  } else if (mode === 'next' && currentIndex === list.length - 1) {
    return null;
  }

  const previousElement = list[currentIndex + dir];

  return previousElement.external_id;
};

const getFirstPageId = (structure) => {
  return getPageId(structure, null, 'first');
};

const getPrevPageId = (structure, currentId) => {
  return getPageId(structure, currentId, 'prev');
};

const getNextPageId = (structure, currentId) => {
  return getPageId(structure, currentId, 'next');
};

const filterTexts = (texts, filter = '') => {
  if (filter.length === 0) {
    // return texts
    return [];
  }
  return Object.keys(texts).filter(key => {
    const item = texts[key];
    const str = Object.keys(item).reduce((str, ikey) => {
      return str + item[ikey];
    }, '');
    return str.includes(filter);
  });
};


const filterList = (list, siteIds = []) => {
  return list.filter(item => {
    for (let siteId of siteIds) {
      if (item.collectedIds.includes(siteId)) {
        return true;
      }
    }
    return false;
  });
};

// const formatRoleOptions = roles => {
//
//   const formattedRoles = roles.map(role => {
//     //https://github.com/mlaursen/react-md/issues/262
//     const menuItem = {
//       label: role.display_name,
//       value: role.id,
//       primaryText: 'label',
//       leftAvatar: this.createAvatar(role.id)
//     };
//     return menuItem;
//   });
//   return formattedRoles;
// };

// const createAvatar = roleId => {
//   let roleAttribute = RoleAttributes.find(roleAttribute => roleAttribute.id === parseInt(roleId));
//   if (roleAttribute == null) {
//     roleAttribute = RoleAttributes.find(roleAttribute => roleAttribute.className === 'viewer');
//   }
//
//   const className = [chipStyles.role, chipStyles[roleAttribute.className]].join(' ');
//
//   return (<Avatar className={className}>{roleAttribute.short}</Avatar>);
// };

const changeOccurringNullIntoEmptyString = (object, attributeName) => {
  if (object && object.hasOwnProperty(attributeName)) {
    object[attributeName] = object[attributeName] && object[attributeName] === 'null'
    || object[attributeName] === null ? '' : object[attributeName];
  }
  return object;
};

function checkMemorySizeOf (obj) {
  var bytes = 0;

  function sizeOf (obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          var objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === 'Object' || objClass === 'Array') {
            for (var key in obj) {
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
        default:
          break;
      }
    }
    return bytes;
  }

  // function formatByteSize (bytes) {
  //   if (bytes < 1024) return bytes + ' bytes';
  //   else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
  //   else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
  //   return (bytes / 1073741824).toFixed(3) + ' GB';
  // }
  // return formatByteSize(sizeOf(obj));

  let sizeString = (sizeOf(obj) / 1048576).toFixed(2) + ' MB (' + (sizeOf(obj) / 1024).toFixed(2) + ' KB)';
  let charLength = JSON.stringify(obj).length;

  console.warn('localStorage memory:', sizeString);
  console.warn('localStorage CHARSLENGTH ok?', charLength < 122070, '(' + charLength + '/122070)');
}

function camelCase (input) {
  return input.toLowerCase().replace(/-(.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
}

function queryString (str) {
  let params = {};
  let queries;
  let temp;
  let i;
  let l;
  let s = str.replace('?', '');
  queries = s.split('&');
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=');
    params[camelCase(temp[0])] = temp[1];
  }
  return params;
}


export {
  randomString,
  createNotification,
  createDialog,
  createErrorDialog,
  generateGuid,
  normalizePositiveNumber,
  copyStyles,
  normalizeDatePickerDate,
  changeDatetimeToDate,
  arrayHelper,
  treeToList,
  filterTexts,
  filterList,
  getFirstPageId,
  getPrevPageId,
  getNextPageId,
  changeOccurringNullIntoEmptyString,
  checkMemorySizeOf,
  camelCase,
  queryString
};
