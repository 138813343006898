import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SelectField} from 'react-md';
import SearchBox from './SearchBox';
import {NewButton} from '../general/BasicComponents';

import styles from '../../styles/filter_search.scss';
import {FilterType} from '../../utils/Constants';
import {withTranslation} from 'react-i18next';

@withTranslation(['table'], {wait: true})
class FilterSearchPanel extends Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  onChange = (value, index, event, {id}) => {
    this.props.handleFilter(id, value);
    this.setState({[id]: value});
  };

  renderFilter = filters => {
    if (filters.length === 0) {
      return false;
    }

    const { t } = this.props;

    return (
      <div className={styles.filters}>
        {filters.map(filter => {
          // let match = this.props.appliedFilters.find(applied => applied.type === filter.id);
          // const value = (match) ? match.value : null;

          const value =
            this.state[filter.id] !== undefined ? this.state[filter.id] : FilterType.ALL;

          return (
            <SelectField
              key={filter.id}
              id={filter.id}
              // label={filter.label}
              label={t(filter.translation_id)}
              placeholder={filter.placeholder ? filter.placeholder : undefined}
              className={styles.dropdown}
              menuItems={filter.options}
              onChange={this.onChange}
              simplifiedMenu={false}
              value={value}
              anchor={{
                x: SelectField.HorizontalAnchors.INNER_LEFT,
                y: SelectField.VerticalAnchors.BOTTOM
              }}
              // className={styles.dropdown}
            />
          );
        })}
      </div>
    );
  };

  renderSearchBox = () => {
    return (
      <div className={styles.searchWrapper}>
        <SearchBox
          searchTerm={this.props.searchTerm}
          handleSearchInput={this.props.handleSearchInput}
          handleSearchReset={this.props.handleSearchReset}
          hitEnterToFocus={this.props.hitEnterToFocus}
        />
      </div>
    );
  };

  render () {
    const {filters = [], searchBox = false, routeToAddNewItem} = this.props;
    return (
      <div className={styles.filterSearchWrapper}>
        {filters.length > 0 && this.renderFilter(filters)}
        {searchBox && this.renderSearchBox()}
        {routeToAddNewItem && <NewButton to={routeToAddNewItem} className={styles.addButton}/>}
      </div>
    );
  }
}

FilterSearchPanel.propTypes = {
  filters: PropTypes.array,
  appliedFilters: PropTypes.array,
  handleFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  searchBox: PropTypes.bool,
  handleSearchInput: PropTypes.func,
  handleSearchReset: PropTypes.func,
  hitEnterToFocus: PropTypes.func
};

export default FilterSearchPanel;
