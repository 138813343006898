import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class WizardBase extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };
  static Page = ({children}) => children;

  constructor (props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {}
    };
  }

  next = values =>
    this.setState(state => (
      {
        page: Math.min(state.page + 1, this.props.children.length - 1),
        values
      }
    ));

  previous = () =>
    this.setState(state => (
      {
        page: Math.max(state.page - 1, 0)
      }
    ));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const {children, onSubmit} = this.props;
    const {page} = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    }
    this.next(values);
  };
}
