// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.diff-view-wrapper {\n  display: flex;\n  flex-grow: 1;\n  padding: 10px;\n}\n\n.diff-view-wrapper tr {\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;\n}\n\n.diff-view-wrapper td {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n\n.diff-view-wrapper .diff-gutter-col {\n  width: 45px;\n}\n\n.diff-view-wrapper .diff-view span {\n  white-space: pre-line;\n}\n\n.diff-view-wrapper .diff-view .added {\n  background-color: #ddffdd;\n}\n\n.diff-view-wrapper .diff-view .removed {\n  background-color: #fee8e9;\n}\n\n.diff-view-wrapper .diff-view .removedWrapper {\n  color: #fea5a2;\n  text-decoration: line-through;\n}\n", ""]);
// Exports
exports.locals = {
	"diff-view-wrapper": "diff-view-wrapper",
	"diffViewWrapper": "diff-view-wrapper",
	"diff-gutter-col": "diff-gutter-col",
	"diffGutterCol": "diff-gutter-col",
	"diff-view": "diff-view",
	"diffView": "diff-view",
	"added": "added",
	"removed": "removed",
	"removedWrapper": "removedWrapper"
};
module.exports = exports;
