import { List, fromJS } from 'immutable';

import {HistorySelectAction} from '../utils/ActionTypes';

const new_config = fromJS(
  {
    'siteId_1':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 1,
            'historySelectId_1': 0
          }
      },
    'siteId_2':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 1,
            'historySelectId_1': 0
          },
        'textBlockId_1':
          {
            'historySelectId_0': 2,
            'historySelectId_1': 0
          }
      }
  });

const new_config_1 = fromJS(
  {
    'siteId_1':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 1
          }
      },
    'siteId_2':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 1
          },
        'textBlockId_1':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 2
          }
      }
  });


const initialState = fromJS({
  clients: List(),
  config: fromJS(
    {'siteId_1':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 1
          }
      },
    'siteId_2':
      {
        'textBlockId_0':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 1
          },
        'textBlockId_1':
          {
            'historySelectId_0': 0,
            'historySelectId_1': 2
          }
      }
    }
  ),
  isUpdated: false,
  error: null
});

let state = null;
let config = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY: {
      const {siteId, textBlockId, historySelectId, value} = action.payload;

      console.log('historySelect reducer:: siteId', siteId);
      console.log('historySelect reducer:: textBlockId', textBlockId);
      console.log('historySelect reducer:: historySelectId', historySelectId);
      console.log('historySelect reducer:: value', value);

      return state.setIn(['config', siteId, textBlockId, historySelectId], value);
    }
    case SET_NEW_CONFIG: {
      if (action.payload === 'version_1') {
        return state.set('config', new_config_1);
      }
      return state.set('config', new_config);
    }
    default: {
      return state;
    }
  }
};

export const SET_HISTORY = 'historySelect/setHistory';
export const SET_NEW_CONFIG = 'historySelect/setNewConfig';

export { initialState, reducer as historySelect };


