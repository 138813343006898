import {ajax} from 'rxjs/observable/dom/ajax';
import {TextScopesAction} from '../utils/ActionTypes';
import {fetchTextScopesFulfilled, fetchTextScopesRejected} from '../actions/textScopes';
import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';


const fetchTextScopesEpic = (action$, store) => {
  return action$.ofType(TextScopesAction.FETCH_TEXT_SCOPES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/projects/text_scope';
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchTextScopesFulfilled)
        .catch(fetchTextScopesRejected);
    });
};

export {fetchTextScopesEpic};
