import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FontIcon, Switch} from 'react-md';
import {withTranslation} from 'react-i18next';
// import { editProfile } from '../../actions/profile';
// import styles from '../../styles/project_detail.scss';
// import stylesDrawer from '../../styles/drawer_inner.scss';

@withTranslation(['profile', 'general'], {wait: true})
class Theme extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    return (
      <div>
        <div className="head" style={{marginTop: '1rem'}}>
          <div className="headlines">
            <small>{ t('dark_theme_is_default') }</small>
          </div>
        </div>
        <div style={{textAlign: 'left', padding: '0 20px'}}>
          <div
            style={{
              textAlign: 'left',
              padding: '0 20px',
              display: 'flex',
              marginTop: '1.5rem',
              alignItems: 'center'
            }}
            title={this.props.isDark
              ? t(`${generalLNS}:switch_to_light`)
              : t(`${generalLNS}:switch_to_dark`)
            }
          >
            <FontIcon
              secondary
              title={'switch skin'}
              style={{fontSize: 'px'}}
            >brightness_3</FontIcon>
            <Switch
              style={{
                display: 'inline-block',
                height: 'auto',
                marginTop: '0.25rem'
              }}
              id="switch-power"
              type="switch"
              label=""
              checked={!this.props.isDark}
              labelBefore={true}
              name="power"
              onChange={this.props.toggleTheme}
            />
            <FontIcon
              secondary
              title={'switch skin'}
              style={{fontSize: 'px'}}
            >brightness_high</FontIcon>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Theme);
