import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';
import PropTypes from 'prop-types';
import {Button, ExpansionPanel} from 'react-md';
import CustomTable from '../../general/CustomTable';
import {SnapshotModel} from '../../../tableModels';
import styles from '../../../styles/projects/expansionpanel/general.scss';
import {hasPermission} from '../../../utils/AuthHelper';
import {aclSnapshot} from '../../../utils/Permissions';
import DeleteSnapshotWarning from '../dialog/DeleteSnapshotWarning';
import {showWarning} from '../../../actions/globalDialog';


@withTranslation(['project', 'general' /* , 'types' */], {wait: true})
class SnapshotPanel extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };

  constructor (props) {
    super(props);

    this.state = {
      isExpanded: false
    };
  }

  handleRowClick = currentId => {
    console.log('SnapshotPanel ROW CLICK -> noch keine Funktion. ID: ', currentId);
  };

  handleExpandToggle = (isExpanded, name = this.state.name, selected = this.state.selected) => {
    this.setState({isExpanded: isExpanded, name: name, selected: selected});
  };

  handleSnapshotAdd = () => {
    this.changeRoute('/add-snapshot');
  };

  handleSnapshotEdit = snapshot => {
    this.changeRoute('/snapshots/' + snapshot.id + '/edit');
  };

  changeRoute = (action) => {
    const {
      match: {
        params: {
          projectId,
          translationId,
          sourceLanguageId
        }
      }
    } = this.props;

    let sourceLanguagePart = sourceLanguageId ? '/' + sourceLanguageId : '';

    this.props.history.push(
      '/projects/' + projectId +
      '/translations/' + translationId +
      sourceLanguagePart + action);
  };

  handleCellClick = (row, cell, idxSortedData, idxSequence, custom) => {
    if (custom.name) {
      switch (custom.name) {
        case 'edit':
          this.handleSnapshotEdit(row);
          break;
        case 'delete':
          this.showDeleteWarning(row);
          break;
        default:
          break;
      }
    }
  };

  showDeleteWarning = snapshot => {
    const {
      match: {
        params: {
          projectId,
          translationId
        }
      },
      showWarning,
      t
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title =  t(`${generalLNS}:warning`);
    let text = t('question_delete_snapshot') + ' ' + snapshot.name + ' ?';

    let component =
      <DeleteSnapshotWarning
        projectId={projectId}
        translationId={translationId}
        snapshotId={snapshot.id}
      />;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };

  render () {
    const {snapshots, fetchingSnapshots, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const batch = snapshots.length + ' ' + t('snapshots');

    return (
      <ExpansionPanel
        expanded={this.state.isExpanded}
        secondaryLabel={batch}
        onExpandToggle={this.handleExpandToggle}
        label={ t(`${generalLNS}:snapshots`) }
        footer={null}
        className={styles.expansionPanel}
        headerClassName={styles.expansionHeader}
        focused={this.props.focused}
        columnWidths={this.props.columnWidths}
      >
        <div
          className={'expansionSubHeader'}
          style={{position: 'relative'}}
        >
          {hasPermission(aclSnapshot.create) &&
            <Button
              onClick={this.handleSnapshotAdd}
              className={'addButton'}
              icon secondary swapTheming>
              add
            </Button>
          }
          { fetchingSnapshots == ProcessStatus.STARTED &&
            <div style={{
              position: 'absolute',
              bottom: '-10px',
              width: '100%'
            }}>
              <LoadingBar
                show={true}
                wrapperClassname={'translationLoader'}
                progressClassName={'translationProgress'}
              />
            </div>
          }
        </div>
        {
          snapshots.length === 0 &&
          <p>{ t('no_snapshots_for_selected_language') }</p>
        }
        {
          snapshots.length > 0 && fetchingSnapshots != ProcessStatus.STARTED &&
          <CustomTable
            rows={snapshots}
            model={SnapshotModel}
            onCellClick={this.handleCellClick}
            onRowClick={this.handleRowClick}
          />
        }
      </ExpansionPanel>
    );
  }
}

export default withRouter(
  connect(null, {showWarning})(SnapshotPanel)
);
