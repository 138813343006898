import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import { withTranslation } from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { deletePermission } from '../../../actions/permissions';
import { removeDialog } from '../../../actions/globalDialog';

@withTranslation(['permissions', 'general'], {wait: true})
class DeletePermissionWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let deletingPermission = nextProps.deletingPermission;

    if (
      deletingPermission == ProcessStatus.FINISHED &&
      deletingPermission != this.props.deletingPermission
    ) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    this.props.deletePermission(this.props.permissionId);
  };

  render () {
    const {deletingPermission, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingPermission == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t(`${generalLNS}:cancel`) }
        </Button>
        <Button flat onClick={this.delete}>
          { t(`${generalLNS}:delete`) }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    deletingPermission: state.getIn(['permissions', 'deletingPermission']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {deletePermission, removeDialog}
)(DeletePermissionWarning);
