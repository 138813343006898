import {
  convertToRaw,
  convertFromRaw
} from 'draft-js';
import {
  convertFromHTML,
  convertToHTML
} from 'draft-convert';
import {
  importerConfig as importerConfigSB,
  exporterConfig as exporterConfigSB
} from '../ImporterExporterConfigsNormal';

export function convertDraftRawToHtmlString (raw) {
  return raw
    ? convertToHTML(exporterConfigSB)(convertFromRaw(raw))
    : '';
}

export function convertHtmlStringToDraftRaw (html) {
  return html
    ? convertToRaw(convertFromHTML(importerConfigSB)(html))
    : '';
}
