import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';

import { Card, CardActions, CardText, CardTitle, Button } from 'react-md';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { validateProject, resetCollectedInfos } from '../../../actions/projects';

@withTranslation(['project', 'general'], {wait: true})
class ValidateUpload extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    console.log('componentWillMount, this.props = ', this.props);
    this.validateProject();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    console.log('componentWillReceiveProps, nextProps = ', nextProps);

    if (
      nextProps.validatingProject == ProcessStatus.FINISHED &&
      nextProps.validatingProject != this.props.validatingProject
    ) {
      this.props.onChange(nextProps.extractPath);
    }
  }

  validateProject = () => {
    const {uploadedFile} = this.props;

    this.props.validateProject({
      package_type: 'jsbeam_1_0',
      uuid: uploadedFile.uuid,
      filename: uploadedFile.name
    });
  };

  discardProject = () => {
    console.log('discardProject', this.props);
    // console.log("discardProject");
    this.props.onChange('');
    this.props.resetCollectedInfos();
  };

  render () {
    const {collectedInfos, uploadedFile, validatingProject, error, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let errorMessage = t(`${generalLNS}:error`);

    if (error && error.notify && error.notify.error) {
      errorMessage = error.notify.error;
    }

    let identifier = collectedInfos.get('identifier') || '';
    let version = collectedInfos.get('version') || '';

    let subtitle = collectedInfos.size > 0 ? identifier + ' ' + version : '';

    return (
      <Card className="md-block-centered">
        <CardTitle title={uploadedFile.name} subtitle={subtitle}/>
        <CardText>
          {validatingProject == ProcessStatus.STARTED && (
            <LoadingBar show="true" message={ t('validating_project') }/>
          )}
          {validatingProject == ProcessStatus.FINISHED && (
            <div>
              <p>{ t('project_validated') }</p>
            </div>
          )}
          {validatingProject == ProcessStatus.FAILED && (
            <div>
              <p>{ t('validation_failed') }: {errorMessage}</p>
            </div>
          )}
        </CardText>
        {validatingProject == ProcessStatus.FAILED && (
          <CardActions>
            <Button key="discard" flat onClick={this.discardProject}>
              { t(`${generalLNS}:discard`) }
            </Button>
            <Button key="try_again" flat onClick={this.validateProject}>
              { t(`${generalLNS}:try_again`) }
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    validatingProject: state.getIn(['projects', 'validatingProject']),
    collectedInfos: state.getIn(['projects', 'collectedInfos']),
    extractPath: state.getIn(['projects', 'extractPath']),
    error: state.getIn(['projects', 'error'])
  };
}

export default connect(
  mapStateToProps,
  {
    validateProject,
    resetCollectedInfos
  }
)(ValidateUpload);
