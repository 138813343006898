import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {DialogContainer} from 'react-md';
import {withTranslation} from 'react-i18next';
import {Redirect, withRouter} from 'react-router-dom';

import {logout} from '../../../actions/auth';
import {removeDialog} from '../../../actions/errorDialog';

import dialogStyles from '../../../styles/dialog.scss';
import {Routes} from '../../../utils/Constants';

@withTranslation('general', {wait: true})
class ErrorDialog extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      dialog: null,
      visible: false,
      title: '',
      text: '',
      component: null,
      actions: []
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { t } = this.props;
    let dialog = nextProps.dialogs.get(0);

    let actions = [];

    if (dialog) {
      switch (dialog.status) {
        case 401:
          actions = [{
            onClick: this.logout,
            primary: true,
            children: t('login_again')
          }];
          break;

        default:
          actions = [{
            onClick: this.closeDialog,
            primary: true,
            children: t('no_thanks')
          }];
      }


      this.setState({
        dialog: dialog,
        visible: true,
        title: dialog.title,
        // text: dialog.message,
        text: t('error_dialog_inactivity'),
        actions: actions
      });
    } else {
      this.setState({
        dialog: null,
        visible: false,
        title: '',
        text: '',
        component: null,
        actions: []
      });
    }
  }

  logout = () => {
    localStorage.removeItem('user');
    this.props.removeDialog(this.state.dialog.identifier);
    this.props.logout();
    return <Redirect
      to='/login'
    />;

  };

  closeDialog = () => {
    this.props.removeDialog(this.state.dialog.identifier);
  };

  render () {
    const {dialog, visible, title, text, actions} = this.state;

    if (dialog == null) {
      return null;
    }

    if (this.props.dialogs.get(0).message > 86400) { // 86400 -> 24h
      this.closeDialog();
      this.logout();
    }

    return (
      <DialogContainer
        id="import-translation-error"
        className={dialogStyles.dialogContainer}
        visible={visible}
        title={title}
        focusOnMount={false}
        onHide={this.closeDialog}
        aria-describedby="speed-boost-description"
        modal
        disableScrollLocking={true}
        actions={actions}>
        <p id="import-translation-error-description" className="md-color--secondary-text">
          {text}
        </p>
      </DialogContainer>
    );
  }
}

function mapStateToProps (state) {
  return {
    dialogs: state.getIn(['errorDialog', 'dialogs'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {removeDialog, logout}
  )(ErrorDialog)
);
