import { ExportTypesAction } from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchExportTypes = () => {
  return {
    type: ExportTypesAction.FETCH_EXPORT_TYPES,
    data: {}
  };
};

const fetchExportTypesFulfilled = exportTypes => {
  return {
    type: ExportTypesAction.FETCH_EXPORT_TYPES_FULFILLED,
    exportTypes: exportTypes,
    receivedAt: Date.now()
  };
};

const fetchExportTypesRejected = error => {
  return Rx.Observable.of({
    type: ExportTypesAction.FETCH_EXPORT_TYPES_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

export { fetchExportTypes, fetchExportTypesFulfilled, fetchExportTypesRejected };
