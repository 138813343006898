import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import {ProcessStatus} from '../../../utils/Constants';

import {deleteContact} from '../../../actions/contacts';
import {removeDialog} from '../../../actions/globalDialog';

@withTranslation(['contacts', 'general'], {wait: true})
class DeleteContactWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let deletingContact = nextProps.deletingContact;

    if (
      deletingContact == ProcessStatus.FINISHED &&
      deletingContact != this.props.deletingContact
    ) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    this.props.deleteContact(this.props.contactId);
  };

  render () {
    const {deletingContact, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingContact == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t(`${generalLNS}:cancel`) }
        </Button>
        <Button flat onClick={this.delete}>
          { t(`${generalLNS}:delete`) }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    deletingContact: state.getIn(['contacts', 'deletingContact']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {deleteContact, removeDialog}
)(DeleteContactWarning);
