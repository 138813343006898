import React, {Component} from 'react';
import {connect} from 'react-redux';

import {linkClientToExportType} from '../../../actions/clients';
import {fetchExportTypes} from '../../../actions/exportTypes';
import RelationBase from '../../general/RelationBase';
import NamedChip from '../../general/chip/NamedChip';
import {withTranslation} from 'react-i18next';
import {List, Map} from 'immutable';

@withTranslation(['exporttypes', 'clients'], {wait: true})
class ClientExportTypes extends Component {
  linkSourceToTarget = (mode, exportTypeId, roleId) => {
    this.props.linkClientToExportType({
      mode: mode,
      export_type_id: exportTypeId,
      client_id: this.props.client.id,
      role_id: roleId,
      property_type: 'clients_x_export_types'
    });
  };

  render () {
    const {client, exportTypes, fetchingExportTypes, linkingClientToExportType, t} = this.props;
    const clientsLNS = 'clients'; // clientsLanguageNamespaceSource
    let sourceTargets = null;

    if (client.export_types != null) {
      sourceTargets = client.export_types.map(exportType => {
        return {...exportType, name: t(exportType.name)};
      });
    }

    return (
      <RelationBase
        fetchTargets={this.props.fetchExportTypes}
        fetchingTargets={fetchingExportTypes}
        label={ t(`${clientsLNS}:export_types`) }
        source={client}
        sourceTargets={sourceTargets}
        targets={exportTypes}
        chip={NamedChip}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingClientToExportType}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    client: state.getIn(['clients', 'selectedClient']),
    exportTypes: state.getIn(['exportTypes', 'exportTypes']),
    fetchingExportTypes: state.getIn(['exportTypes', 'fetchingExportTypes']),
    linkingClientToExportType: state.getIn(['clients', 'linkingClientToExportType']),
    updateToken: state.getIn(['clients', 'updateToken'])
  };
}

export default connect(
  mapStateToProps,
  {linkClientToExportType, fetchExportTypes}
)(ClientExportTypes);
