import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import {
  Condition,
  renderSelectionGroup,
  renderTextField
} from '../../general/BasicFormComponents';
import { withTranslation } from 'react-i18next';

const LANGUAGE_FOR_TRANSLATION = 'language_for_translation';

@withTranslation(['project', 'general', 'export'], {wait: true})
class SelectPackageType extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    const {values, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const exportLNS = 'export'; // exportLanguageNamespaceSource
    const yesNo = [
      {
        id: 1,
        label: t(`${generalLNS}:yes`),
        value: 'yes'
      },
      {
        id: 2,
        label: t(`${generalLNS}:no`),
        value: 'no'
      }
    ];

    let packages = [];
    let max = 400;

    this.props.passedProps.map(availableExport => {
      let id = availableExport.id.toString();

      if (values.exportType == availableExport.type) {
        packages.push({
          id: id,
          label: t(`${exportLNS}:` + availableExport.name),
          value: id
        });
      }
    });

    /* this.props.availableExports.map(availableExport => {
      let id = availableExport.id.toString();

      if (values.exportType == availableExport.type) {
        packages.push({
          id: id,
          label: t(`${exportLNS}:` + availableExport.name),
          value: id
        });
      }
    }); */

    if (packages.length == 0) {
      console.log('!!!!! packages.length === 0');
      return null;
    }

    return (
      <div>
        {values.exportType !== LANGUAGE_FOR_TRANSLATION && (
          <div>
            <p>{ t('select_package_type') }</p>
            <Field
              name={values.exportType + '_package_type'}
              type="radio"
              component={renderSelectionGroup}
              controls={packages}
            />
          </div>
        )}
        {values.exportType === LANGUAGE_FOR_TRANSLATION && (
          <div>
            <p>{ t('question_make_snapshot') }</p>
            <Field name="snapshot" type="radio" component={renderSelectionGroup} controls={yesNo}/>
            <Condition when="snapshot" is="yes">
              <Field
                name="version"
                label={ t(`${generalLNS}:version`) }
                type="text"
                className="md-cell form-field"
                resize={{max: max}}
                required
                component={renderTextField}
              />
              <Field
                name="name"
                label={ t(`${generalLNS}:name`) }
                type="text"
                className="md-cell form-field"
                resize={{max: max}}
                required
                component={renderTextField}
              />
              <Field
                name="description"
                label={ t(`${generalLNS}:description`) }
                type="text"
                className="md-cell form-field"
                resize={{max: max}}
                rows={3}
                component={renderTextField}
              />
            </Condition>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    availableExports: state.getIn(['projects', 'availableExports'])
  };
}

export default connect(
  mapStateToProps,
  {}
)(SelectPackageType);
