import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, ExpansionPanel} from 'react-md';
import CustomTable from '../../general/CustomTable';
import {PdfFilesModel} from '../../../tableModels';
import styles from '../../../styles/projects/expansionpanel/general.scss';
import {aclFilter} from '../../../utils/ACL';
import {aclTranslation} from '../../../utils/Permissions';
import {Scope} from '../../../utils/constants/ACL';
import AsyncSwitch from '../../general/AsyncSwitch';
import {updateAdditionalContents} from '../../../actions/additionalContents';
import {ProcessStatus} from '../../../utils/Constants';
import {withRouter} from 'react-router';
import PortalWindow from '../../general/PortalWindowHooks';
import {checkMemorySizeOf} from '../../../utils/HelperFunctions';
import {fetchAllTexts, fetchAllTextsGroupedByCollection} from '../../../actions/wbtViewer';

@withTranslation(['additional_content', 'general'], {wait: true})
class AdditionalContentPanelPDF extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };
  constructor (props) {
    super(props);

    this.state = {
      isExpanded: false,
      isExpandedOther: false,
      additionalContentType: 'pdf',
      additionalContent: null,
      isTranslationWindowVisible: false,
      inSafeClosingMode: false,
      initiateSafeClosing: false,
      textsOnStage: null
      // , fetchTextsByCollection: null
    };
    this.id = 0;
    this.fetchTextsByCollection = null;
  }

  UNSAFE_componentWillMount () {
    const {additionalContents} = this.props;
    let acArray = additionalContents.toArray();
    this.setState({
      additionalContent: this.getAC(acArray),
      disabled: false
    });
    this.id = Math.floor(Math.random() * 10001);
    // TODO: fetch texts
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      updatingAdditionalContents,
      additionalContents
    } = nextProps;

    if (updatingAdditionalContents === ProcessStatus.STARTED) {
      this.setState({disabled: true});
    }
    if (updatingAdditionalContents !== this.props.updatingAdditionalContents
      && updatingAdditionalContents === ProcessStatus.FINISHED) {
      let acArray = additionalContents.toArray();
      this.setState({
        additionalContent: this.getAC(acArray),
        disabled: false
      });
    }
  }

  getAC = arrayFromStore => {
    let returnValue = -1;
    for (let i = 0; i < arrayFromStore.length; i++) {
      if (arrayFromStore[i].type === this.state.additionalContentType) {
        returnValue = arrayFromStore[i];
        break;
      }
    }
    return returnValue;
  };

  handleRowClick = currentId => { // tut noch nichts
    console.log('my handleSortClick, currentId = ', currentId);
  };

  handleExpandToggle = (isExpanded, name = this.state.name, selected = this.state.selected) => {
    this.setState({
      isExpanded: isExpanded,
      name: name,
      selected: selected
    });
  };

  handleExpandOtherToggle = (isExpandedOther, name = this.state.name, selected = this.state.selected) => {
    this.setState({
      isExpandedOther: isExpandedOther,
      name: name,
      selected: selected
    });
  };

  handleChangePdf = () => {
    console.log('handleChangePdf');
    const {projectId, translationId} = this.props;
    const {additionalContent} = this.state;
    this.props.updateAdditionalContents(projectId, translationId, additionalContent.id, {
      status: additionalContent.status === 'approved' ? 'not_approved' : 'approved'
    });
  };

  handleImport = () => {
    this.props.history.push(this.props.baseUrl + '/import-pdf-translation');
    console.log('PUSH: ', this.props.baseUrl + '/import-pdf-translation');
  };

  handleExport = () => {
    this.props.history.push(this.props.baseUrl + '/export-pdf-translation');
    // console.log('EXPORTING PDF TRANSLATION DOESN\'T WORK YET');
    console.log('PUSH: ', this.props.baseUrl + '/export-pdf-translation');
  };

  // TranslationTool part
  getLanguageTitle = id => {
    if (!this.props.project) {
      return;
    }
    let currentTranslation = null;
    this.props.project.translations.map(translation => {
      if (id === translation.id) {
        currentTranslation = translation;
        return;
      }
    });
    return currentTranslation.languagecode + ' - ' + currentTranslation.name;
  };

  getLanguageType = id => {
    const { project } = this.props;
    if (!project) {
      return;
    }
    const translation = project.translations.find(translation => Number(id) === translation.id);
    return translation.type;
  };

  handleTranslationButton = ({...param}) => {
    // const {translationId} = this.props;
    // const {sourceLanguageId} = this.props.match.params;

    console.log('handleTranslationButton (param.collection): ', param);
    if (param.collection) {
      /* this.setState({
        fetchTextsByCollection: param.collection
      }); */
      this.fetchTextsByCollection = param.collection;
    }

    /* this.props.fetchAllTexts(
      sourceLanguageId ? sourceLanguageId : null,
      translationId
    );
    this.props.fetchAllTextsGroupedByCollection(
      sourceLanguageId ? sourceLanguageId : null,
      translationId
    ); */

    if (this.state.isTranslationWindowVisible) {
      if (this.state.inSafeClosingMode) {
        this.setState({initiateSafeClosing: true});
      } else {
        this.setState({isTranslationWindowVisible: false});
      }
    } else {
      this.setLocalStorageForTranslationTool();
      this.setState({isTranslationWindowVisible: true, isTranslationToolAway: false});
    }
  };

  setLocalStorageForTranslationTool = () => {
    const {projectId, translationId} = this.props;
    // const {fetchTextsByCollection} = this.state;
    const {sourceLanguageId} = this.props.match.params;

    let languageTitleTranslation = this.getLanguageTitle(translationId);
    // const translationType = this.getLanguageType(translationId);
    // const sourceType = sourceLanguageId ? this.getLanguageType(sourceLanguageId) : translationType;

    let tos = [];
    if (this.state.textsOnStage) {
      for (let p = 0; p < this.state.textsOnStage.length; p++) {
        let item = this.state.textsOnStage[p];
        tos.push({
          c: item.collection,
          t: item.textfieldId
        });
      }
    }

    let obj = {
      id: this.id,
      startedFromProject: false,
      textsOnStage: tos,
      currentPageId: null,
      projectId: parseInt(projectId),
      translationId: parseInt(translationId),
      sourceLanguageId: parseInt(sourceLanguageId ? sourceLanguageId : translationId),
      scrollTo: {collection: '', textfield_id: ''},
      translationLanguageCode: languageTitleTranslation,
      sourceLanguageCode: sourceLanguageId
        ? this.getSourceTitle()
        : languageTitleTranslation,
      sourceSnapshotId: -1,
      date: new Date(),
      textWasSelected: false
    };
    // console.log('!!!!!!! fetchTextsByCollection', this.fetchTextsByCollection);
    if (this.fetchTextsByCollection) {
      obj.collection = this.fetchTextsByCollection;
      // console.log('opened PDF collection (obj): ', obj);
    }

    /* const values = queryString(this.props.location.search);
    if (values.sourcesnapshotId) {
      obj.sourceSnapshotId = parseInt(values.sourcesnapshotId);
    } */

    checkMemorySizeOf(obj);
    localStorage.setItem('openProject_' + this.id, JSON.stringify(obj));
  };

  removeLocalStorageForTranslationTool = () => {
    localStorage.removeItem('openProject_' + this.id); // LocalStorage bereinigen
  };

  resetSafeClosingInitiation = () => {
    this.setState({initiateSafeClosing: false});
  };

  handleWindowClosed = () => {
    this.setState({isTranslationWindowVisible: false});
    this.removeLocalStorageForTranslationTool();
  };

  render () {
    const {
      additionalContentPdf,
      t,
      additionalContentPdfOther,
      availableAdditionalContents,
      existingAdditionalContent
    } = this.props;
    const {
      isExpanded,
      isExpandedOther,
      disabled,
      additionalContent,
      isTranslationWindowVisible,
      inSafeClosingMode,
      initiateSafeClosing
      // , fetchTextsByCollection
    } = this.state;

    console.log('!!! fetchTextsByCollection: ', this.fetchTextsByCollection);
    // console.log('!!! availableAdditionalContents: ', availableAdditionalContents);
    // console.log('!!!!! existingAdditionalContent', existingAdditionalContent);
    const batch = additionalContentPdf.length + t('pdf_files');
    const batchOther = additionalContentPdfOther.length + t('pdf_files');
    const approved = additionalContent.status === 'approved';

    return (
      <ExpansionPanel
        expanded={isExpanded}
        onExpandToggle={this.handleExpandToggle}
        label={t('additionalContent_pdf')}
        secondaryLabel={batch}
        footer={null}
        className={styles.expansionPanel}
        headerClassName={styles.expansionHeader}
        focused={this.props.focused}
        columnWidths={this.props.columnWidths}
      >
        <div className={disabled ? 'actionRow disabled' : 'actionRow'}>
          {additionalContent &&
          [
            aclFilter(
              <Button
                title={t('import_translation')} // before change: "Import this translation"
                className={'hover-round-button'}
                onClick={this.handleImport}
                key={'import_pdf_translation'}
                icon>
                file_upload
              </Button>
            )([], [aclTranslation.import], Scope.TRANSLATION),
            aclFilter(
              <Button
                title={t('export_translation')}
                className={'hover-round-button'}
                onClick={this.handleExport}
                key={'export_pdf_translation'}
                icon>
                archive
              </Button>
            )([], [aclTranslation.export], Scope.TRANSLATION),
            <AsyncSwitch
              key={'activateAllPdfFiles'}
              id={'activateAllPdfFiles'}
              name={'activateAllPdfFiles'}
              label={t('general:all_approved')}
              labelBefore
              checked={approved}
              value={approved}
              disabled={disabled}
              onChange={this.handleChangePdf}
            />
          ]}
        </div>
        <CustomTable
          selected={this.state.selected}
          rows={additionalContentPdf}
          model={PdfFilesModel}
          existingAdditionalContent={existingAdditionalContent}
          handleTranslationButton={this.handleTranslationButton}
          // baseUrl={this.props.baseUrl}
          // projectId={this.props.projectId}
          languagecode={this.props.languagecode}
          translationId={this.props.translationId}
          onRowClick={null}
        />

        {aclFilter(
          <ul style={{padding: '20px 0 0 0', listStyle: 'none'}}>
            <ExpansionPanel
              expanded={isExpandedOther}
              onExpandToggle={this.handleExpandOtherToggle}
              label={ t('additionalContent_pdf_other') }
              secondaryLabel={batchOther}
              footer={null}
              className={styles.expansionPanel}
              headerClassName={styles.expansionHeader}
              focused={this.props.focused}
              columnWidths={this.props.columnWidths}
            >
              <CustomTable
                selected={this.state.selected}
                rows={additionalContentPdfOther}
                model={PdfFilesModel}
                translationId={this.props.translationId}
                onRowClick={null}
              />
            </ExpansionPanel>
          </ul>
        )([], [aclTranslation.read_additional_content_other], Scope.TRANSLATION)}
        {isTranslationWindowVisible && (
          <PortalWindow
            inSafeClosingMode={inSafeClosingMode}
            initiateSafeClosing={initiateSafeClosing}
            resetSafeClosingInitiation={this.resetSafeClosingInitiation}
            onWindowClosed={this.handleWindowClosed}
            translationViewId={this.id}
            collectionId={this.fetchTextsByCollection}
          >
            <div></div>
          </PortalWindow>
        )}
      </ExpansionPanel>
    );
  }
}

function mapStateToProps (state) {
  return {
    updatingAdditionalContents: state.getIn(['additionalContents', 'updatingAdditionalContents']),
    additionalContents: state.getIn(['additionalContents', 'additionalContents']),
    languagecode: state.getIn(['projects', 'translation', 'languagecode']),
    availableAdditionalContents: state.getIn(['projects', 'translation', 'additional_contents']),
    texts: state.getIn(['wbtViewer', 'textsGroupedByCollection'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateAdditionalContents,
      fetchAllTexts,
      fetchAllTextsGroupedByCollection
    }
  )(AdditionalContentPanelPDF)
);
