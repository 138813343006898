import { List, fromJS } from 'immutable';

import { PermissionsAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import { generateGuid } from '../utils/HelperFunctions';

const initialState = fromJS({
  permissions: List(),
  fetchingPermissions: ProcessStatus.INITIAL,
  addingPermission: ProcessStatus.INITIAL,
  fetchingPermission: ProcessStatus.INITIAL,
  editingPermission: ProcessStatus.INITIAL,
  deletingPermission: ProcessStatus.INITIAL,
  updateToken: '',
  error: null
});
let index = 0;
let permission = null;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PermissionsAction.FETCH_PERMISSIONS:
      return state.set('fetchingPermissions', ProcessStatus.STARTED);
    case PermissionsAction.FETCH_PERMISSIONS_FULFILLED:
      return state
        .set('fetchingPermissions', ProcessStatus.FINISHED)
        .set('permissions', List(action.permissions));
    case PermissionsAction.FETCH_PERMISSIONS_REJECTED:
      return state.set('fetchingPermissions', ProcessStatus.FAILED).set('error', action.payload);
    case PermissionsAction.ADD_PERMISSION:
      return state.set('addingPermission', ProcessStatus.STARTED);
    case PermissionsAction.ADD_PERMISSION_FULFILLED:
      return state
        .set('addingPermission', ProcessStatus.FINISHED)
        .set('permissions', state.get('permissions').push(action.permission));
    case PermissionsAction.ADD_PERMISSION_REJECTED:
      return state.set('addingPermission', ProcessStatus.FAILED).set('error', action.payload);
    case PermissionsAction.DELETE_PERMISSION:
      return state.set('deletingPermission', ProcessStatus.STARTED);
    case PermissionsAction.DELETE_PERMISSION_FULFILLED:
      state = state.set('deletingPermission', ProcessStatus.FINISHED);
      index = state.get('permissions').findIndex(function (obj) {
        return obj.id === action.permissionId;
      });
      if (index > -1) {
        state = state.set('permissions', state.get('permissions').delete(index));
      }
      return state;
    case PermissionsAction.DELETE_PERMISSION_REJECTED:
      return state.set('deletingPermission', ProcessStatus.FAILED).set('error', action.payload);
    case PermissionsAction.EDIT_PERMISSION:
      return state.set('editingPermission', ProcessStatus.STARTED);
    case PermissionsAction.EDIT_PERMISSION_FULFILLED:
      index = state.get('permissions').findIndex(function (permission) {
        return permission.id === action.permission.id;
      });

      permission = action.permission;

      if (permission.active) {
        permission.active = +permission.active; // TODO: true false to 1 0, why do we have to do this?
      }
      if (index > -1) {
        return state
          .set('editingPermission', ProcessStatus.FINISHED)
          .set('permissions', state.get('permissions').set(index, action.permission))
          .set('updateToken', generateGuid());
      }

      // TODO: Ist das richtig? Wenn es den Permission nicht gibt, sollte dann nicht einfach nichts gemacht werden?
      return state
        .set('editingPermission', ProcessStatus.FINISHED)
        .set('updateToken', generateGuid());
    case PermissionsAction.EDIT_PERMISSION_REJECTED:
      return state.set('editingPermission', ProcessStatus.FAILED).set('error', action.payload);

    default:
      return state;
  }
};

export { initialState, reducer as permissions };
