import React, {useEffect, useState} from 'react';
import RawDraftToHtml from './editor/RawDraftToHtml';
import {useSelector, useDispatch} from 'react-redux';
// import {SET_CURRENT_EDITOR} from '../../reducers/contentEditor';
import {lockTextBlock, unlockTextBlock, setCurrentEditorId} from '../../actions/storyboard';

function getTextBlockTitle (textBlocks) {
  return textBlocks.find(textBLock => textBLock.type_id === 1);
}
function getTextBlockDescription (textBlocks) {
  return textBlocks.find(textBLock => textBLock.type_id === 3);
}
function getTextBlockContents (textBlocks) {
  return textBlocks.filter(textBlock => {
    if (textBlock.type_id === 4) {
      return textBlock.latest_text;
    }
  });
}

export default function RenderHtmlOrEditor (props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.getIn(['auth', 'user']));
  const textBlock = useSelector((state) => {
    let site = state.getIn(['storyboard', 'chapter']).find(site => {
      return (site.id === props.siteId);
    });
    let content = null;
    switch (props.textBlockType) {
      case 'title':
        content = getTextBlockTitle(site.text_blocks);
        break;
      case 'description':
        content = getTextBlockDescription(site.text_blocks);
        break;
      case 'contents':
        content = getTextBlockContents(site.text_blocks)[props.textBlockIndex];
        break;
      default: break;
    }
    return content;
  });

  const [content, setContent] = useState(textBlock.latest_text.content);
  const [openLock, setOpenLock] = useState(false);

  useEffect(() => {
    console.log('textBlock.lock', textBlock.lock);
    console.log('textblock, user', props.id, user.id);
    if (textBlock.lock
      && textBlock.lock.text_block_id === props.id
      && textBlock.lock.creator === user.id
      && textBlock.id !== props.currentContentEditorId
    ) {
      console.log('UNLOCK FALSCH => ENTFERNEN');
      // dispatch(setCurrentEditorId(props.id));
      dispatch(unlockTextBlock(props.id));
    }
  }, [textBlock]);

  useEffect(() => {
    if (props.tempContent) {
      console.log('RENDERHTML OR EDITOR: props.tempContent', props.tempContent);
      setContent(props.tempContent);
      setOpenLock(true);
    }
  }, [props.tempContent]);

  const setEditor = () => {
    if (openLock) {
      return false;
    }
    // The content editor id is the same as the text block id
    if (props.currentContentEditorId && (props.currentContentEditorId !== -1)) {
      dispatch(unlockTextBlock(props.currentContentEditorId));
    }
    dispatch(lockTextBlock(textBlock.id));
    dispatch(setCurrentEditorId(props.id));
  };

  if (props.currentContentEditorId === props.id) {
    return (
      <div className={'htmlAndEditorWrapper'}>
        <div className="editorWrapper">
          {props.children}
        </div>
      </div>
    );
  }
  return (
    <div className={'htmlAndEditorWrapper'}>
      <div
        className={'rawToHtmlWrapper'}
        onClick={setEditor}>
        <RawDraftToHtml raw={content}/>
        {/* <RawDraftToHtml raw={textBlock}/>*/}
      </div>
    </div>
  );
}
