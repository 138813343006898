import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { deleteRole } from '../../../actions/roles';
import { removeDialog } from '../../../actions/globalDialog';


@withTranslation(['roles', 'general'], {wait: true})
class DeleteRoleWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let deletingRole = nextProps.deletingRole;

    if (deletingRole == ProcessStatus.FINISHED && deletingRole != this.props.deletingRole) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    this.props.deleteRole(this.props.roleId);
  };

  render () {
    const {deletingRole, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingRole == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t(`${generalLNS}:cancel`) }
        </Button>
        <Button flat onClick={this.delete}>
          { t(`${generalLNS}:delete`) }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    deletingRole: state.getIn(['roles', 'deletingRole']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {deleteRole, removeDialog}
)(DeleteRoleWarning);
