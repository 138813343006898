import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DialogContainer} from 'react-md';
import {withTranslation} from 'react-i18next';
// import HighlightedProject from '../projects/HighlightedProjectCard';
import {StoryboardModel} from '../../tableModels';
import FilterSearchPanel from '../general/FilterSearchPanel';
import CustomTable from '../general/CustomTable';
import {Routes, ProcessStatus} from '../../utils/Constants';
import MangooluLoading from '../general/MangooluLoading';
// import ImportProject from './importprojectwizard/ImportProjectWizard';
import {arrayHelper} from '../../utils/HelperFunctions';
import {fetchClients} from '../../actions/clients';
import {fetchStoryboards} from '../../actions/storyboard';
// import {fetchProjects, editProject} from '../../actions/projects';
import {defaultCreateSortFunction} from '../../tableModels/defaultFunctions';
import styles from '../../styles/list.scss';
import dialogStyles from '../../styles/dialog.scss';
// import ContentDrawer from '../../utils/ContentDrawer';
import LayoutContainer from '../layout/LayoutContainer';
import {hasPermission} from '../../utils/AuthHelper';
import {aclProject} from '../../utils/Permissions';
import {editUserSettings} from '../../actions/auth';

@withTranslation(['storyboard', 'project', 'general', 'projectselection'], {wait: true})
class ListStoryboards extends Component {
  state = {
    searchTerm: '',
    filters: [],
    focusOnTable: false,
    sortedStoryboards: []
    // highlightedProjects: [],
    // pinnedProjects: [],
    // pinnedFromUserSettings: false
  };

  componentWillMount () {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      // projects,
      // loggingOutAsUser,
      fetchingClients
    } = nextProps;

    if (
      fetchingClients === ProcessStatus.FAILED
      && fetchingClients !== ProcessStatus.FINISHED
    ) {
      this.props.fetchClients();
    }

    // if (loggingOutAsUser === ProcessStatus.FINISHED && loggingOutAsUser !== this.props.loggingOutAsUser) {
    //   this.init(nextProps);
    // } else {
    //   if (projects.size > 0) {
    //     this.sortProjects(projects);
    //   }
    // }
  }

  init = (props) => {
    const {
      clients,
      storyboards
    } = props;

    if (clients.size === 0) {
      this.props.fetchClients();
    }

    if (storyboards.size === 0) {
      this.props.fetchStoryboards();
    } else {
      this.sortStoryboards(storyboards);
    }

    // this.setPinnedProjectsIfAvailable();
  };

  // setPinnedProjectsIfAvailable = () => {
  //   // eslint-disable-next-line no-prototype-builtins
  //   if (this.props.user.settings.hasOwnProperty('pinnedProjects')) {
  //     if (JSON.stringify(this.state.pinnedProjects)
  //       !== JSON.stringify(this.props.user.settings.pinnedProjects)) {
  //       this.setState({
  //         pinnedProjects: this.props.user.settings.pinnedProjects
  //       });
  //     }
  //   }
  // };

  sortStoryboards = (storyboards) => {
    let sortFunction = defaultCreateSortFunction('updated_at', 'desc');
    const sortedStoryboards = storyboards
      .toArray()
      .sort(sortFunction);
    const highlightedProjects = sortedStoryboards.slice(0, 3);
    this.setState({sortedStoryboards: sortedStoryboards, highlightedProjects: highlightedProjects});
  };

  pinProject = id => {
    const {user} = this.props;
    const {pinnedProjects} = this.state;
    let pp = pinnedProjects;
    if (pp.length === 3) {
      pp.shift();
    }
    pp.push(id);
    this.setState({pinnedProjects: pp}, () => {
      this.props.editUserSettings(user.id, {pinnedProjects: pp});
    });
  };

  // unpinProject = id => {
  //   const {user} = this.props;
  //   const {pinnedProjects} = this.state;
  //   let pp = pinnedProjects;
  //   for (var o = 0; o < pp.length; o++) {
  //     if (pp[o] === id) {
  //       pp.splice(o, 1);
  //       break;
  //     }
  //   }
  //   this.setState({pinnedProjects: pp}, () => {
  //     this.props.editUserSettings(user.id, {pinnedProjects: pp});
  //   });
  // };

  closeForm = () => {
    this.props.history.push('/storyboards');
  };

  // updateForm = () => {
  //   this.props.history.push(Routes.PROJECTS.IMPORT);
  // };

  closeDrawer = () => {
    this.props.history.push('/storyboards');
  };

  handleCellClick = (row, cell) => {
    if (cell && cell.id) {
      switch (cell.id) {
        case 'active':
          this.handleActive(row);
          break;
        default:
          break;
      }
    }
  };

  handleActive = (project) => {
    project.active = parseInt(+!project.active);
    this.props.editProject(project);
  };

  handleFilter = (type, value) => {
    const newFilters = arrayHelper(this.state.filters, type, value);
    this.setState({filters: newFilters});
  };

  handleFilterReset = (type) => {
    console.log('handleFilterReset', type);
    // return () => this.props.filterByType(type);
  };

  handleSearchInput = (value) => {
    this.setState({searchTerm: value});
  };

  handleSearchReset = () => {
    this.setState({searchTerm: ''});
  };

  hitEnterToFocusOnTable = () => {
    this.setState({focusOnTable: true});
  };

  selectStoryboard = (id) => {
    // TODO: M.Orf: Wieder entfernen, wenn Dark-Mode implementiert
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (htmlHasClassDark) {
      document.querySelector('html').classList.remove('dark');
    }

    this.props.history.push('/storyboards/' + id);
  };

  // renderHighlightedProjects () {
  //   const {highlightedProjects} = this.state;
  //   if (highlightedProjects.length !== 0) {
  //     return (
  //       <>
  //         <h5 className={'project-highlight-headline'}>Last Updated:</h5>
  //         <div
  //           className={styles.projectRowMiddle}
  //           help-tool="2">
  //           {highlightedProjects.map((project, key) => (
  //             <HighlightedProject
  //               key={key}
  //               project={project}
  //               controlpanel={true}
  //               onClick={this.selectProject}
  //             />
  //           ))}
  //         </div>
  //       </>
  //     );
  //   }
  // }

  // renderPinnedProjects () {
  //   const {pinnedProjects} = this.state;
  //   const {projects} = this.props;
  //
  //   if (projects.size <= 0) {
  //     return null;
  //   }
  //
  //   let pinnedProjectsView = [];
  //   for (var o = 0; o < pinnedProjects.length; o++) {
  //     const project = projects.find(project => project.id === pinnedProjects[o]);
  //     pinnedProjectsView.push(project);
  //   }
  //
  //   return (
  //     <>
  //       <h5 className={'project-highlight-headline'}>Favorites:</h5>
  //       <div className={styles.projectRowMiddle}>
  //         {pinnedProjectsView.map((project, key) => (
  //           <HighlightedProject
  //             key={key}
  //             project={project}
  //             controlpanel={true}
  //             onClick={this.selectProject}
  //             removePinnedProject={this.unpinProject}
  //           />
  //         ))}
  //       </div>
  //     </>
  //   );
  // }

  renderFiltersAndTable = () => {
    const {clients, t, storyboards} = this.props;
    const projectselectionLNS = 'projectselection'; // projectselectionLanguageNamespaceSource

    if (storyboards.size <= 0){ //|| fetchingProjects == ProcessStatus.STARTED) {
      return <LayoutContainer>
        <MangooluLoading show={true}/>
      </LayoutContainer>;
    }


    // TODO: Verallgemeinern...
    const storyboardsWithProxies = storyboards.map(storyboard => {
      storyboard.proxies = [];
      const client = clients.find(client => {
        return client.id === storyboard.client_id;
      });
      storyboard.proxies.push({'client_id': client ? client.name : null});
      // TODO: Is this the correct approach for inject texts from locales?
      storyboard.type_label = t(`${projectselectionLNS}:${storyboard.type.name}`);

      return storyboard;
    });

    const filters = StoryboardModel.prepareFilters.call(
      StoryboardModel, // focus
      StoryboardModel.filters,
      ['client_id', 'type_label'],
      storyboardsWithProxies,
      {'clients': clients.toArray()}
    );

    let route = hasPermission(aclProject.create) ? Routes.PROJECTS.IMPORT : null;

    return (
      <div help-tool="1">
        <FilterSearchPanel
          filters={filters}
          appliedFilters={this.state.filters}
          handleFilter={this.handleFilter}
          handleFilterReset={this.handleFilterReset}
          searchBox={true}
          searchTerm={this.state.searchTerm}
          handleSearchInput={this.handleSearchInput}
          handleSearchReset={this.handleSearchReset}
          hitEnterToFocus={this.hitEnterToFocusOnTable}
          routeToAddNewItem={route}
        />
        <CustomTable
          model={StoryboardModel}
          rows={storyboardsWithProxies}
          searchTerm={this.state.searchTerm}
          appliedFilters={this.state.filters}
          focus={this.state.focusOnTable}
          onCellClick={this.handleCellClick}
          onRowClick={this.selectStoryboard}
          pinProject={this.pinProject}
          unpinProject={this.unpinProject}
          pinnedProjects={this.state.pinnedProjects}
        />
      </div>
    );
  };

  render () {
    const {
      clients,
      // projects,
      // fetchingProjects,
      t
    } = this.props;
    // const {
    //   sortedProjects,
    //   pinnedProjects
    // } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const dialogActions = [{
      onClick: this.closeForm,
      primary: true,
      children: t(`${generalLNS}:discard_from`)
    }, {
      onClick: this.updateForm,
      primary: true,
      children: t(`${generalLNS}:go_on`)
    }];

    return (
      <div className={styles.list}>
        <DialogContainer
          id="add-warning"
          className={dialogStyles.dialogContainer}
          visible={this.props.location.pathname == Routes.PROJECTS.IMPORT_WARNING}
          title={t(`${generalLNS}:warning`)}
          onHide={this.closeForm}
          aria-describedby="speed-boost-description"
          modal
          disableScrollLocking={true}
          actions={dialogActions}
        >
          <p id="speed-boost-description" className="md-color--secondary-text">
            {t('warning_unsaved_data')}
          </p>
        </DialogContainer>

        {/*
        <ContentDrawer
          key="1"
          children={<ImportProject/>}
          visible={this.props.match.path == Routes.PROJECTS.IMPORT}
          closeDrawer={this.closeDrawer}
        />
        */}

        <LayoutContainer width='small'>
          {/* <MangooluLoading show={true}/>*/}
          {/* {sortedProjects.length == 0 &&
          fetchingProjects === ProcessStatus.FINISHED && <div>No projects found.</div>}*/}
          {/* {pinnedProjects.length > 0 && this.renderPinnedProjects()}*/}
          {/* {sortedProjects.length > 0 && this.renderHighlightedProjects()}*/}
          { clients.toArray().length > 0 &&
            this.renderFiltersAndTable()
          }
        </LayoutContainer>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['auth', 'user']),
    clients: state.getIn(['clients', 'clients']),
    fetchingClients: state.getIn(['clients', 'fetchingClients']),
    // fetchingProjects: state.getIn(['projects', 'fetchingProjects']), // TODO
    storyboards: state.getIn(['storyboard', 'storyboards']),
    loggingOutAsUser: state.getIn(['auth', 'loggingOutAsUser'])
    // updateToken: state.getIn(['projects','updateToken'])
  };
}

export default connect(
  mapStateToProps,
  {
    fetchClients,
    fetchStoryboards,
    // editProject,// TODO
    editUserSettings
  })(ListStoryboards);
