import { Map, List, fromJS } from 'immutable';

import { EngineTypesAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  engineTypes: Map(),
  formattedEngineTypes: List([]),
  timestamp: 0,
  fetchingEngineTypes: ProcessStatus.INITIAL
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EngineTypesAction.FETCH_ENGINE_TYPES:
      return state.set('fetchingEngineTypes', ProcessStatus.STARTED);
    case EngineTypesAction.FETCH_ENGINE_TYPES_FULFILLED:
      return state
        .set('fetchingEngineTypes', ProcessStatus.FINISHED)
        .set('timestamp', Date.now())
        .set('engineTypes', Map(action.engineTypes))
        .set(
          'formattedEngineTypes',
          Map(action.engineTypes).map((key, value) => {
            return { value: key, label: value };
          })
        );
    case EngineTypesAction.FETCH_ENGINE_TYPES_REJECTED:
      return state.set('fetchingEngineTypes', ProcessStatus.FAILED).set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as engineTypes };
