import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';

import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';

import TranslationPrime from './TranslationPrime';
import TranslationTimestamps from './TranslationTimestamps';
// import TranslationProperties from './TranslationProperties';

import {editTranslation} from '../../../actions/projects';
import {fetchLanguages} from '../../../actions/languages';
import {fetchTranslationEnumDefaults} from '../../../actions/enumDefaults';

import styles from '../../../styles/project_detail.scss';
import {List} from 'immutable';
import {sortAlphabetically} from '../../../utils/ChipHelperFunctions';
import {aclFilter} from '../../../utils/ACL';
import {aclTranslation} from '../../../utils/Permissions';
// import stylesDrawer from '../../../styles/drawer_inner.scss';


@withTranslation(['project', 'general'], {wait: true})
class EditTranslation extends Component {
  constructor (props) {
    super(props);

    this.state = {
      editMode: false,
      translation: null,
      types: null,
      languageOptions: null,
      countryOptions: null,
      parentLanguageOptions: null
    };
  }


  componentWillMount () {
    const {
      project,
      languages,
      countries,
      enumDefaults,
      fetchingLanguages,
      fetchingTranslationEnumDefaults
    } = this.props;


    if (languages.size > 0) {
      this.safeUpdate(project, languages, countries, enumDefaults);
    }
    if (fetchingLanguages != ProcessStatus.STARTED
      && languages.size == 0) {
      this.props.fetchLanguages();
    }
    if (fetchingTranslationEnumDefaults != ProcessStatus.STARTED
      && enumDefaults.size == 0) {
      this.props.fetchTranslationEnumDefaults();
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      project,
      languages,
      countries,
      enumDefaults,
      editingTranslation,
      fetchingTranslationEnumDefaults
    } = nextProps;

    if (editingTranslation != ProcessStatus.STARTED) {
      this.safeUpdate(project, languages, countries, enumDefaults);
    }

    if (fetchingTranslationEnumDefaults != ProcessStatus.STARTED
      && enumDefaults.size == 0) {
      this.props.fetchTranslationEnumDefaults();
    }
  }

  handleSubmit = (translation) => {
    const {project_id, id, type, language, country, parent_id, translator} = translation;

    const translationData = {type, parent_id, translator};

    if (language) {
      if (translator == null || translator == undefined || translator == 'undefined' || translator == 'null') {
        translationData['translator'] = '';
      }

      let languageLabelExists = this.state.languageOptions.find(languageOption => {
        return (languageOption.label == language.label);
      });

      let countryLabelExists = this.state.countryOptions.find(countryOption => {
        return (countryOption.label == country.label);
      });

      if (languageLabelExists) {
        let lc = language.label.split(' - ')[0];

        // if there is a country label and it is not "Intl'"
        if (countryLabelExists && country.value != 1) {
          let cc = country.label.split(' - ')[0];
          translationData['languagecode'] = lc + '-' + cc;
        } else if (country == null || country.value == 1) {
          translationData['languagecode'] = lc;
        }
        this.props.editTranslation(project_id, id, translationData);
      }
    }
    this.switchEditMode();
  };

  switchEditMode = () => {
    console.log('switchEditMode', this);
    this.setState({editMode: !this.state.editMode});
  };


  safeUpdate = (project, languages, countries, enumDefaults) => {
    if (project !== null && project.translations !== null &&
      languages.size > 0 &&
      countries.size > 0 &&
      enumDefaults.size > 0) {
      let types = enumDefaults.get('type');
      let translationsList = List(project.translations);
      let languageOptions = this.formatLanguageOptions(languages).toArray().sort((a, b) => sortAlphabetically(a, b));
      let countryOptions = this.formatCountryOptions(countries).toArray().sort((a, b) => sortAlphabetically(a, b));
      let parentLanguageOptions = this.formatParentLanguageOptions(
        this.filterParentLanguageOptions(translationsList)
      ).toArray();

      this.setState({
        types: types,
        languageOptions: languageOptions,
        countryOptions: countryOptions,
        parentLanguageOptions: parentLanguageOptions
      });
    }
  };

  formatLanguageOptions = (languages) => {
    let label;
    let isDisabled = false;

    return languages.map((language) => {
      label = language.lc + ' - ' + language.name;
      return {value: language.id, label: label, disabled: isDisabled};
    });
  };

  formatCountryOptions = (countries) => {
    let label;
    let isDisabled = false;

    return countries.map((country) => {
      label = (country.cc == null) ? country.name : country.cc + ' - ' + country.name;
      return {value: country.id, label: label, disabled: isDisabled};
    });
  };

  filterParentLanguageOptions = (languages) => {
    return languages.filter(language => language.languagecode === 'de' || language.languagecode === 'en');
  };

  formatParentLanguageOptions = (languages) => {
    return languages.map((language) => {
      return {value: language.id, label: language.languagecode + ' - ' + language.name};
    });
  };

  render () {
    const {translation, languages, countries, editingTranslation, t} = this.props;
    const {editMode, languageOptions, countryOptions, parentLanguageOptions, types} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (types == null ||
      languageOptions == null ||
      countryOptions == null ||
      parentLanguageOptions == null
    ) {
      return (
        <LoadingBar show='true'/>
      );
    } else if (!languages) {
      return <div>{ t('no_languages_found') }</div>;
    }


    if (languages.size == 0) {
      return (
        <LoadingBar show='true'/>
      );
    }


    let lc = translation.get('languagecode');
    let cc = null;

    if (lc && lc.indexOf('-') > 0) {
      [lc, cc] = lc.split('-');
    }

    const currentLanguage = languages.find(language => language.lc == lc);
    const currentCountry = countries.find(country => country.cc == cc);

    const languageOption = languageOptions.find(languageOption => languageOption.value == currentLanguage.id);

    let initialValues = translation.toObject();
    initialValues['language'] = languageOption;

    if (currentCountry) {
      const countryOption = countryOptions.find(countryOption => countryOption.value == currentCountry.id);
      initialValues['country'] = countryOption;
    } else {
      initialValues['country'] = {value: 1, label: 'Intl\'', disabled: false};
    }

    return (
      <Form
        mutators={{
          setFieldData,
          setCollectedInfos: (args, state) => {
            state.formState.values = {...state.formState.values, ...args[0]};
          },
          changeValue: (args, state, utils) => {
            utils.changeValue(state, args[0].name, () => args[0].value);
          },
          changeValues: (args, state, utils) => {
            args.map(arg => utils.changeValue(state, arg.name, () => arg.value));
          }
        }}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingTranslation == ProcessStatus.STARTED &&
              <LoadingBar
                show="true"
                wrapperClassname={styles.loadingBarWrapper}
                message={ t(`${generalLNS}:updating_translations`) }/>
              }
              {aclFilter(
                <div className={styles.buttonsRightWrapper + ' ' + styles.buttonsRightWrapperInTab}>
                  <Button
                    title={ t(`${generalLNS}:edit_mode`) }
                    className={styles.editButton + ' ' + styles.editButtonFilled}
                    style={{display: !editMode ? 'block' : 'none'}}
                    onClick={this.switchEditMode}
                    icon primary swapTheming>mode_edit</Button>

                  <Button
                    children={ t(`${generalLNS}:save_all_edits`) }
                    type="submit"
                    style={{display: editMode ? 'block' : 'none'}}
                    className={styles.formSubmitButton}
                    disabled={editingTranslation == ProcessStatus.STARTED}
                    flat primary swapTheming/>

                  <Button
                    children={ t(`${generalLNS}:cancel`) }
                    type="button"
                    style={{display: editMode ? 'block' : 'none'}}
                    className={styles.formCancelButton}
                    onClick={this.switchEditMode}
                    disabled={editingTranslation == ProcessStatus.STARTED}
                    flat primary/>
                </div>
              )([], [aclTranslation.update])}
              <div className={styles.flexHorizontalWrapper}>
                <div className={'column'}>
                  <FormSpy
                    subscription={{values: true}}
                    editMode={editMode}
                    types={types}
                    languageOptions={languageOptions}
                    countryOptions={countryOptions}
                    parentLanguageOptions={parentLanguageOptions}
                    component={TranslationPrime}
                  />
                </div>
                <div className={'column'}>
                  <FormSpy
                    subscription={{values: true}}
                    editMode={editMode}
                    component={TranslationTimestamps}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}


function mapStateToProps (state) {
  let project = state.getIn(['projects', 'selectedProject']);
  let translations = project ? project.translations : [];

  return {
    languages: state.getIn(['languages', 'languages']),
    countries: state.getIn(['languages', 'countries']),
    translation: state.getIn(['projects', 'translation']),
    editingTranslation: state.getIn(['projects', 'editingTranslation']),
    fetchingTranslationsEnumDefaults: state.getIn(['enumDefaults', 'fetchingTranslationsEnumDefaults']),
    enumDefaults: state.getIn(['enumDefaults', 'translationEnumDefaults']),
    project: project,
    translations: translations
  };
}

export default connect(mapStateToProps, {
  editTranslation,
  fetchLanguages,
  fetchTranslationEnumDefaults
})(EditTranslation);
