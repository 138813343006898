import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontIcon, Button} from 'react-md';
import Search from '../general/search/Search';
import ControlMenu from './ControlMenu';
import LoadingBar from '../general/DumbComponent';
import SourceSnapshots from './SourceSnapshots';
import UnsavedTextFlag from './UnsavedTextFlag';
import {withTranslation} from 'react-i18next';
import {fetchProject, fetchTranslation} from '../../actions/projects';
// import {fetchAllTexts} from '../../actions/wbtViewer';
import {
  fetchTranslations,
  fetchAdditionalContentTranslations,
  setAreExpanded,
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted,
  setAreOnlyEmptyShown
} from '../../actions/translationTool';
import {showNotification} from '../../actions/notifications';
import {fetchSourceSnapshots} from '../../actions/sourceSnapshots';
import {ProcessStatus} from '../../utils/Constants';
import styles from '../../styles/translationTool/translationTool.scss';
import images from './translationTool/dataUrlImages';
import {setScrollTo} from '../../actions/fieldLevel';
import {performScrollToUnsavedText, performScrollToCollection} from '../../utils/ScrollManager';
import FieldSection from './FieldSection';

import { parseHTML } from '../../utils/RTEParser';
// import {checkMemorySizeOf} from '../../utils/HelperFunctions';

@withTranslation('translation_navigation', {wait: true})
class TranslationTool extends Component {
  state = {
    sliderValue: 1000,
    searchTerm: '',
    isSourceSnapshotBarVisible: false,
    projectId: 0,
    translationId: 0,
    sourceLanguageId: 0,
    sourceSnapshotId: -1,
    textsOnStage: 0,
    currentPageId: 0,
    hasNewScrollTo: false,
    firstScroll: true,
    translationLanguageCode: '',
    sourceLanguageCode: '',
    sourceSnapshotProjectId: -1,
    sourceSnapshotTranslationId: -1,
    shouldUpdateTexts: false,
    startedFromProject: false,
    darkMode: true,
    helpOpened: false,
    textUpdate: false,
    sections: [],
    firstFieldSectionIndex: 0,
    lastFieldSectionIndex: 20,
    maxItems: 20,
    scrollHandleTop: 0,
    scrollBarMoveStart: 0,
    scrollBarEl: null,
    scrollbarIsMoving: false,
    scrollBarMoveStartValues: {},
    moveSBH: 0,
    firstInitialisation: false,
    movingPositionHeadline: '',
    sourceType: '',
    translationType: ''
  };
  textWasSelected = false;
  root = null;
  translationToolWithCollection = false;

  componentWillMount () {
    if (!this.props.startedToolAsLayer) {
      this.root = document.querySelector('#root');
      this.root.setAttribute(
        'style',
        // 'overflow-y: scroll!important; min-width: 900px; height: 100vh;'
        'overflow-y: scroll!important; min-width: 900px; height: 100vh; width: calc(100vw + 17px); padding-right: 0px;'
      );
    }

    window.addEventListener('storage', () => {
      this.updateFromLocalStorage(this.props);
    });
    window.addEventListener('wheel', this.handleScroll);

    this.updateFromLocalStorageOnStart();
    this.checkOnDarkLightMode();
    this.updateSections(this.props);
  }

  componentDidMount () {
    const {projectId, translationId} = this.state;

    this.props.fetchProject(projectId);
    this.props.fetchTranslation(projectId, translationId);
    document.querySelector('html').classList.add('translation-tool');

    setTimeout(() => {
      if (this.props.scrollTo && this.state.firstScroll) {
        this.setState({
          firstScroll: false
        });
        this.getScrollingPositionHeadline();
      }
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      textsForLocalStorage,
      scrollTo,
      fetchingTranslations
    } = nextProps;

    if (textsForLocalStorage != this.props.textsForLocalStorage) {
      this.localDispatchTextUpdate(textsForLocalStorage);
    } else {
      this.updateSections(nextProps);
    }

    if (this.props.startedToolAsLayer && nextProps.inlineTranslationToolObject.textWasSelected) {
      // console.log('componentWillReceiveProps update!!!');
      this.updateFromLocalStorage(nextProps);
    }

    if (this.state.firstInitialisation === false) { // UPDATE
      // console.log('=================== this.textWasSelected', this.textWasSelected);
      if (fetchingTranslations === ProcessStatus.FINISHED
        && this.textWasSelected
        && scrollTo.hasOwnProperty('collection')
        && scrollTo.hasOwnProperty('textfield_id')) {
        // console.log('=================== drin SCROLL!!', scrollTo);
        this.props.setScrollTo({collection: '', textfield_id: ''});
        this.setStartItemToNewTextfield(scrollTo);
        let ref = 'ref_' + scrollTo.collection
          + '_' + scrollTo.textfield_id;
        performScrollToUnsavedText(ref);
        this.props.setScrollTo(scrollTo);
      }
    } else { // INITIAL START:
      if (fetchingTranslations === ProcessStatus.FINISHED && fetchingTranslations !== this.props.fetchingTranslations
        && this.textWasSelected) {
        setTimeout(() => {
          this.setStartItemToNewTextfield(this.state.firstInitialisation);
          let ref = 'ref_' + this.state.firstInitialisation.collection
            + '_' + this.state.firstInitialisation.textfield_id;
          performScrollToUnsavedText(ref);
          this.props.setScrollTo(
            this.state.firstInitialisation === false
              ? {collection: '', textfield_id: ''}
              : this.state.firstInitialisation
          );
          setTimeout(() => {
            this.setState({firstInitialisation: true});
          }, 150);
        }, 150);
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('storage', () => {
      this.updateFromLocalStorage(this.props);
    });
    window.removeEventListener('wheel', this.handleScroll);
  }

  checkOnDarkLightMode = () => {
    let mangooluMode = JSON.parse(localStorage.getItem('mangoolu_darkmode'));
    if (mangooluMode) {
      this.setTheme(mangooluMode.darkMode);
    } else {
      if (this.state.darkMode) {
        this.setTheme(this.state.darkMode);
      }
    }
  };

  setTheme = (dark) => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (dark) {
      if (!htmlHasClassDark) {
        document.querySelector('html').classList.add('dark');
      }
    } else {
      if (htmlHasClassDark) {
        document.querySelector('html').classList.remove('dark');
      }
    }
    this.setState({darkMode: dark});
  };

  fetchSourceSnapshots = localStorage => {
    const {
      projectId,
      sourceLanguageId,
      sourceSnapshotId
    } = localStorage;
    this.props.fetchSourceSnapshots(
      projectId,
      sourceLanguageId,
      sourceSnapshotId
    );
  };

  fetchTranslations = localStorage => {
    const {
      translationId,
      sourceLanguageId,
      sourceSnapshotId,
      currentPageId
    } = localStorage;

    let translationsData = {
      currentExternalId: currentPageId,
      targetTranslationId: translationId,
      sourceTranslationId: sourceLanguageId,
      compare_with: sourceSnapshotId
    };

    if (sourceSnapshotId == null || sourceSnapshotId == -1) {
      delete translationsData.compare_with;
    }

    // console.log('fetchTranslations (localstorage):', localStorage);
    if (localStorage.collection) {
      this.translationToolWithCollection = true;
      // console.log('fetchTranslations (localstorage.collection):', localStorage.collection);
      translationsData.collection = localStorage.collection;
      // translationsData = {...translationsData};
      // console.log('fetchAdditionalContentTranslations (translationsData):', translationsData);
      this.props.fetchAdditionalContentTranslations(translationsData);
      this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
      // this.props.fetchTranslations(translationsData);
    } else {
      // console.log('fetchTranslations (translationsData):', translationsData);
      this.props.fetchTranslations(translationsData);
    }
    // console.log('fetchTranslations (translationsData):', translationsData);

    this.setState({
      translationFetched: true,
      shouldUpdateTexts: false
    });
  };

  updateFromLocalStorageOnStart = () => {
    const {viewId} = this.props.match.params;
    let vid = this.props.startedToolAsLayer
      ? this.props.vid
      : viewId;
    let idsFromLocalStorage = this.props.startedToolAsLayer
      ? this.props.inlineTranslationToolObject
      : JSON.parse(localStorage.getItem('openProject_' + vid));

    if (this.state.currentLocalStorage !== idsFromLocalStorage && this.state.firstScroll) {
      let tos = [];
      for (let p = 0; p < idsFromLocalStorage.textsOnStage.length; p++) {
        let item = idsFromLocalStorage.textsOnStage[p];
        tos.push({
          collection: item.c,
          textfieldId: item.t
        });
      }

      this.fetchTranslations(idsFromLocalStorage);
      if (idsFromLocalStorage.sourceSnapshotId) {
        this.fetchSourceSnapshots(idsFromLocalStorage);
      }

      this.setState({
        currentLocalStorage: idsFromLocalStorage,
        id: idsFromLocalStorage.id,
        projectId: idsFromLocalStorage.projectId,
        translationId: idsFromLocalStorage.translationId,
        sourceLanguageId: idsFromLocalStorage.sourceLanguageId,
        sourceSnapshotId: idsFromLocalStorage.sourceSnapshotId,
        currentPageId: idsFromLocalStorage.currentPageId,
        textsOnStage: tos,
        translationLanguageCode: idsFromLocalStorage.translationLanguageCode,
        sourceLanguageCode: idsFromLocalStorage.sourceLanguageCode,
        sourceType: idsFromLocalStorage.sourceType,
        translationType: idsFromLocalStorage.translationType,
        startedFromProject: idsFromLocalStorage.startedFromProject,
        firstInitialisation: idsFromLocalStorage.textWasSelected
          ? idsFromLocalStorage.scrollTo
          : false
      });
      this.textWasSelected = idsFromLocalStorage.textWasSelected;
    }
  };

  updateFromLocalStorage = props => {
    const {viewId} = props.match.params;
    const {scrollTo} = props;

    let vid = props.startedToolAsLayer
      ? props.vid
      : viewId;
    let idsFromLocalStorage = props.startedToolAsLayer
      ? props.inlineTranslationToolObject
      : JSON.parse(localStorage.getItem('openProject_' + vid));

    // console.log('idsFromLocalStorage', idsFromLocalStorage);
    const mangooluTheme = JSON.parse(localStorage.getItem('mangoolu_darkmode'));

    if (mangooluTheme.darkMode !== this.state.darkMode) {
      this.setTheme(mangooluTheme.darkMode);
    }


    // SELECTED-UPDATE
    if (idsFromLocalStorage.textWasSelected) {
      if (idsFromLocalStorage.scrollTo
        && scrollTo.collection !== ''
        && scrollTo.textfield_id !== ''
        && (idsFromLocalStorage.scrollTo.collection !== scrollTo.collection
          || idsFromLocalStorage.scrollTo.textfield_id !== scrollTo.textfield_id)) {
        // console.log('!!!!!!!!!!!!!!!!! SELECTED-UPDATE', idsFromLocalStorage.scrollTo);
        this.setStartItemToNewTextfield(idsFromLocalStorage.scrollTo);
        let ref = 'ref_' + idsFromLocalStorage.scrollTo.collection
          + '_' + idsFromLocalStorage.scrollTo.textfield_id;
        performScrollToUnsavedText(ref);
        props.setScrollTo(idsFromLocalStorage.scrollTo);
      }
    } else { // SCROLL-UPDATE
      if (idsFromLocalStorage.currentPageId !== this.state.currentPageId) {
        // console.log('!!!!!!!!!!!!!!!!! SCROLL-UPDATE',
        // this.state.currentPageId + ' -> ' + idsFromLocalStorage.currentPageId);
        this.updateSections(props);
        this.setState({
          currentPageId: idsFromLocalStorage.currentPageId
        });
        setTimeout(() => {
          this.setStartItemToNewCollection(this.state.currentPageId);
          performScrollToCollection(this.state.currentPageId);
        }, 100);
      }
    }

    // TEXT-UPDATE:
    if (idsFromLocalStorage.textsOnStage) {
      if (idsFromLocalStorage.date !== this.state.date) {
        let tos = [];
        for (let p = 0; p < idsFromLocalStorage.textsOnStage.length; p++) {
          let item = idsFromLocalStorage.textsOnStage[p];
          tos.push({
            collection: item.c,
            textfieldId: item.t
          });
        }

        // console.log('!!!!!!!!!!!!!!!!! TEXT-UPDATE',
        //  this.state.textsOnStage.length + ' -> ' + tos.length, 'momentan: ' + JSON.stringify(props.scrollTo));

        props.setScrollTo({collection: '', textfield_id: ''});
        this.setStartItemToNewTextfield(idsFromLocalStorage.scrollTo);
        let ref = 'ref_' + idsFromLocalStorage.scrollTo.collection
          + '_' + idsFromLocalStorage.scrollTo.textfield_id;

        console.log('textsOnStage... tos =', tos);

        this.setState({
          shouldUpdateTexts: true,
          textsOnStage: tos
        }, function () {
          performScrollToUnsavedText(ref);
          props.setScrollTo(idsFromLocalStorage.scrollTo);
        });
      }
    }

    if (props.startedToolAsLayer && props.inlineTranslationToolObject.textWasSelected) {
      this.props.setOffTWS();
      setTimeout(() => {
        // console.log('UND NOCHMAL:');
        this.updateFromLocalStorage(props);
      }, 2000);
    }
  };

  setStartItemToNewCollection = collectionId => {
    const {sections} = this.state;
    let index = 0;
    for (let j = 0; j < sections.length; j++) {
      let s = sections[j];
      if (s.type !== 'div') {
        if (s.props.collectionId === collectionId) {
          index = j;
          break;
        }
      }
    }
    this.update(index);
  };

  setStartItemToNewTextfield = (scrollTo) => {
    if (!scrollTo.textfield_id || !scrollTo.collection) {
      return;
    }
    const {sections} = this.state;
    let index = 0;
    for (let j = 0; j < sections.length; j++) {
      let s = sections[j];
      if (s.type !== 'div') {
        if (s.props.collectionId === scrollTo.collection
          && s.props.from.id === scrollTo.textfield_id) {
          index = j;
          break;
        }
      }
    }
    this.update(index);
  };

  localDispatchTextUpdate = texts => {
    // console.log('TT localDispatchTextUpdate texts: ', texts);
    const {viewId} = this.props.match.params;
    let vid = viewId;
    if (this.props.startedToolAsLayer) {
      vid = this.props.vid;
    }
    const idsFromLocalStorage = JSON.parse(localStorage.getItem('openProject_' + vid));
    idsFromLocalStorage.textUpdate = '1';

    // Parsing all Texts to HTML --> For perfect view in WBT
    for (let property in texts) {
      if (texts.hasOwnProperty(property)) {
        let oldTexts = texts[property].headline_text;
        texts[property].headline_text = parseHTML(oldTexts);
      }
    }

    for (let property in texts) {
      if (texts.hasOwnProperty(property) && property !== '__proto__') {
        let innerProperty = texts[property];
        // console.log('property', property);
        for (let property2 in innerProperty) {
          if (innerProperty.hasOwnProperty(property2)) {
            let notHTMLtexts = innerProperty[property2];
            if (notHTMLtexts) {
              // console.log('notHTMLtexts', notHTMLtexts);
              texts[property][property2] = parseHTML(notHTMLtexts);
              // console.log('texts[property][property2]', texts[property][property2]);
            }
          }
        }
      }
    }


    idsFromLocalStorage.newTexts = texts;
    localStorage.setItem('openProject_' + vid, JSON.stringify(idsFromLocalStorage));
    if (this.props.startedToolAsLayer) {
      this.props.dispatchTextUpdate(texts);
    }
    // checkMemorySizeOf(idsFromLocalStorage);
  };

  handleScroll = (e) => {
    this.root = document.querySelector('#root');
    let scrollHeight = this.root.scrollHeight;
    let offset = 10;
    let maxScroll = scrollHeight - window.innerHeight;
    if (this.root.scrollTop + e.deltaY <= 0) {
      this.addToTop();
    } else if (this.root.scrollTop > (maxScroll - offset)) {
      this.addToBottom();
    }
    this.getScrollingPositionHeadline();
  };

  addToTop = () => {
    let ffsi = this.state.firstFieldSectionIndex;
    if (ffsi > 0) {
      ffsi--;
      this.update(ffsi);
    }
  };

  addToBottom = () => {
    const {firstFieldSectionIndex, sections, maxItems} = this.state;
    let ffsi = firstFieldSectionIndex;
    if (ffsi < sections.length - maxItems) {
      ffsi++;
      this.update(ffsi);
    }
  };

  toggleAreExpanded = () => {
    this.props.setAreExpanded(!this.props.areExpanded);
  };

  toggleAreTagsShown = () => {
    this.props.setAreTagsShown(!this.props.areTagsShown);
  };

  toggleAreAllTextsShown = () => {
    this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
  };

  toggleAreOnlyDifferencesShown = () => {
    this.props.setAreOnlyDifferencesShown(!this.props.areOnlyDifferencesShown);
  };

  toggleAreDifferencesHighlighted = () => {
    this.props.setAreDifferencesHighlighted(!this.props.areDifferencesHighlighted);
  };

  toggleAreOnlyEmptyShown = () => {
    this.props.setAreOnlyEmptyShown(!this.props.areOnlyEmptyShown);
  };

  handleSearchInput = value => {
    this.setState({
      searchTerm: value,
      firstFieldSectionIndex: 0,
    }, () => this.updateSections(this.props));
  };

  handleSearchReset = () => {
    this.setState({searchTerm: '', firstFieldSectionIndex: 0}, () => this.updateSections(this.props));
  };

  handleSourceSnapshotClick = () => {
    this.setState({isSourceSnapshotBarVisible: !this.state.isSourceSnapshotBarVisible});
  };

  currentSourceSnapshotName = () => {
    var name = '';

    if (this.props.sourceSnapshots && this.props.sourceSnapshots._tail) {
      for (var j = 0; j < this.props.sourceSnapshots._tail.array.length; j++) {
        var item = this.props.sourceSnapshots._tail.array[j];
        if (item.id === this.state.sourceSnapshotId) {
          name = item.version + ' | ' + item.id + ' - ' + item.name;
        }
      }
    }
    return name;
  };

  openHelp = () => {
    this.setState({helpOpened: true});
  };

  closeHelp = () => {
    this.setState({helpOpened: false});
  };

  createSections = (props) => {
    const {
      translations,
      translation,
      areAllTextsShown
    } = props;

    const {textsOnStage} = this.state;

    let translationType = 'translation';
    if (translation.get('type')) {
      translationType = translation.get('type');
    }

    let sections = [];
    let textbase = textsOnStage;

    translations.map(collection => {
      // let refName = 'ref_' + collection.id;

      if (!areAllTextsShown && textsOnStage) {
        textbase = textsOnStage.filter(text => text.collection === collection.id);
      }

      let fieldSections = this.renderFieldSections(
        props,
        collection,
        textbase,
        translationType
      );

      if (fieldSections.length > 0) {
        sections.push(this.addCollection(collection.id));
        sections.push(...fieldSections);
      }
    });
    return sections;
  };

  updateSections = (props) => {
    const {firstFieldSectionIndex, sections} = this.state;
    let percent = sections.length > 0 ? (firstFieldSectionIndex / sections.length) * 100 : 0;

    let newSections = this.createSections(props);

    this.setState({
      sections: newSections
    }, () => this.update(percent, true));
  };

  addCollection = id => {
    return <div
      className={'headlineCollection'}
      key={'ref_' + id}
      data-collection={'ref_' + id}>
      <span>{id}</span>
    </div>;
  };

  update = (value, inPercent = false) => {
    const {sections, maxItems} = this.state;
    let numOfSections = sections.length;

    let ffsi = 0;
    let percent = 0;

    if (numOfSections > 0) {
      if (inPercent) {
        percent = value;
        if (percent < 0) {
          percent = 0;
        }
        ffsi = numOfSections * percent / 100;
      } else {
        ffsi = value;
        percent = (ffsi / numOfSections) * 100;
      }
    }

    let ffsiPlus = ffsi + maxItems;
    let lfsi = ffsiPlus < numOfSections ? ffsiPlus : numOfSections;

    this.setState({
      firstFieldSectionIndex: ffsi,
      lastFieldSectionIndex: lfsi,
      sliderValue: percent,
      moveSBH: percent,
      movingPositionHeadline: this.getScrollbarMovingPositionHeadline(percent),
      scrollbarIsMoving: false
    });
  };

  handleScrollBarClick = e => {
    if (!e.target.classList.contains('customScrollbar')) {
      return false;
    }
    let scrollbarTop = e.target.getBoundingClientRect().top;
    let scrollbarHeight = e.target.getBoundingClientRect().height;
    let scrollbarHandleHeight = e.target.querySelector('.handle')
      .getBoundingClientRect().height;
    let handleHeightPercent = (scrollbarHandleHeight / scrollbarHeight) * 100;
    let y = e.clientY;
    let percent = ((y - scrollbarTop) / scrollbarHeight) * 100;
    if (percent > (100 - handleHeightPercent)) {
      percent = 100 - handleHeightPercent;
    }
    this.update(percent, true);
  };

  handleScrollBarHandleMouseDown = e => {
    e.preventDefault();
    let handleHeightPx = e.target.getBoundingClientRect().height;
    let handleTopPx = e.target.getBoundingClientRect().top;
    let scrollbarTopPx = e.target.parentNode.getBoundingClientRect().top;
    let scrollbarHeightPx = e.target.parentNode.getBoundingClientRect().height;

    this.setState({
      scrollBarMoveStartValues: {
        handleHeightPx: handleHeightPx,
        handleTopPx: handleTopPx,
        scrollbarTopPx: scrollbarTopPx,
        scrollbarHeightPx: scrollbarHeightPx,
        moveStartPx: e.clientY,
        handleHeight: (handleHeightPx / scrollbarHeightPx) * 100,
        handleTop: (handleTopPx / scrollbarHeightPx) * 100,
        moveStart: (e.clientY / scrollbarHeightPx) * 100,
        movePercent: 0
      },
      scrollBarHandleEl: e.target,
      scrollbarIsMoving: true,
      moveSBH: ((handleTopPx - scrollbarTopPx) / scrollbarHeightPx) * 100
    });

    window.addEventListener('mousemove', this.handleScrollBarMouseMove, false);
    window.addEventListener('mouseup', this.handleScrollBarMouseUp, false);
  };

  handleScrollBarMouseMove = e => {
    const {scrollBarMoveStartValues} = this.state;
    let diffPx = e.clientY - scrollBarMoveStartValues.moveStartPx - scrollBarMoveStartValues.scrollbarTopPx;
    let diff = (diffPx / scrollBarMoveStartValues.scrollbarHeightPx) * 100;
    let movePercentDiffY = diff + scrollBarMoveStartValues.handleTop;

    if (movePercentDiffY > (100 - scrollBarMoveStartValues.handleHeight)) {
      movePercentDiffY = 100 - scrollBarMoveStartValues.handleHeight;
    }
    if (movePercentDiffY < 0) {
      movePercentDiffY = 0;
    }

    this.setState({
      moveSBH: movePercentDiffY,
      movingPositionHeadline: this.getScrollbarMovingPositionHeadline(movePercentDiffY)
    });
  };

  handleScrollBarMouseUp = e => {
    const {
      scrollBarMoveStartValues,
      moveSBH
    } = this.state;
    if (e.clientY === scrollBarMoveStartValues.moveStartPx) {
      return false;
    }
    let percent = moveSBH;

    if (percent > (100 - scrollBarMoveStartValues.handleHeight)) {
      percent = 100 - scrollBarMoveStartValues.handleHeight;
    }
    this.update(percent, true);
    window.removeEventListener('mousemove', this.handleScrollBarMouseMove, false);
    window.removeEventListener('mouseup', this.handleScrollBarMouseUp, false);
  };

  getScrollbarMovingPositionHeadline = percent => {
    const {sections} = this.state;

    if (sections && sections.length > 0) {
      let index = parseInt(sections.length * (percent / 100));
      if (index > sections.length) {
        index = sections.length - 1;
      }
      return sections[index].type === 'div'
        ? sections[index].props.children.props.children
        : sections[index].props.collectionId;
    }
    return null;
  };

  getScrollingPositionHeadline = () => {
    const {sections, movingPositionHeadline} = this.state;
    if (sections.length < 1) {
      return '';
    }
    let allHeadlines = [];
    for (var k = 0; k < sections.length; k++) {
      let section = sections[k];
      if (section.type === 'div') {
        let collectionId = section.props.children.props.children;
        let headlineEl = document.querySelector('[data-collection="ref_' + collectionId + '"]');
        let isPassed = 0; // default ==> not rendered
        if (headlineEl) {
          isPassed = this.isElementPassedOnScrolling(headlineEl);
        }
        allHeadlines.push({
          headline: collectionId,
          passedView: isPassed
        });
      }
    }
    let lastVisibleheadline = null;
    let startIndex = allHeadlines.findIndex(el => el.headline === movingPositionHeadline);
    for (var o = startIndex > 0 ? startIndex : 0; o < allHeadlines.length; o++) {
      if (allHeadlines[o].passedView === 0) {
        continue;
      } else if (allHeadlines[o].passedView === 'passed') {
        lastVisibleheadline = allHeadlines[o];
      } else if (allHeadlines[o].passedView === 'not_passed') {
        lastVisibleheadline = allHeadlines[o - 1];
        break;
      }
    }
    if (lastVisibleheadline
      && movingPositionHeadline !== lastVisibleheadline.headline) {
      this.setState({movingPositionHeadline: lastVisibleheadline.headline});
    }
  };

  isElementPassedOnScrolling = el => {
    return el.getBoundingClientRect().top < 50 ? 'passed' : 'not_passed';
  };


  renderHeaderBar = () => {
    const {
      areTagsShown,
      t
    } = this.props;
    const {
      searchTerm,
      sourceLanguageCode,
      translationLanguageCode,
      sourceSnapshotId
    } = this.state;
    // const expandedText = areExpanded ? t('foldPages') : t('unfoldPages');

    return (
      <div>
        <div className={styles.translationToolHeader}>
          <div
            className={'wbt-name'}
            title={
              'MANGOOLU LANGUAGE EDITOR\n' +
              'translate:\n' +
              // project.name +
              sourceLanguageCode +
              ' -> ' +
              translationLanguageCode +
              '\n(source language)  (target language)'
            }
          >
            <img src={images.logoTransparent}/>
            <FontIcon>translate</FontIcon>
            <span>EDITOR</span>
          </div>
          {/* aclFilter(
            <div className={'switch tag-switcher'}>
              <div>HTML</div>
              <Switch
                key="html-tags"
                id="html-tags"
                name="html-tags"
                aria-label="html-tags"
                checked={areTagsShown}
                disabled={false}
                onChange={this.toggleAreTagsShown}
              />
              <div>Tags</div>
            </div>
          )([], [aclTranslationTool.html_tags], Scope.TRANSLATION)
          */}
          <div className="rightButtons">
            <UnsavedTextFlag/>
            <Button icon className={'translationToolHelpButton'} onClick={this.openHelp}>
              <FontIcon>help</FontIcon>
            </Button>
            <Search
              searchTerm={searchTerm}
              handleSearchInput={this.handleSearchInput}
              handleSearchReset={this.handleSearchReset}
            />
            {this.translationToolWithCollection ?
              <ControlMenu
                hasSourceSnapshot={(sourceSnapshotId >= 0)}
                hideListItemTextOnStage={true}
              />
              :
              <ControlMenu
                hasSourceSnapshot={(sourceSnapshotId >= 0)}
                hideListItemTextOnStage={false}
              />}
          </div>
        </div>
      </div>
    );
  };

  /* eslint-disable-next-line-max-params */
  renderFieldSections = (props, collection, textsOnStageFromCollection, translationType) => {
    const {
      sourceLanguageId,
      translationId,
      searchTerm,
      currentPageId
    } = this.state;

    const {
      areAllTextsShown,
      areOnlyDifferencesShown,
      areOnlyEmptyShown,
      scrollTo,
      match: {
        params: {viewId}
      }
    } = props;

    const data = collection.data;
    const collectionId = collection.id;

    return data
      .filter(section => {
        const {compareWith, from, to} = section;

        if (compareWith) {
          if (areOnlyDifferencesShown && compareWith.content == from.content) {
            return false;
          }
        }

        if (textsOnStageFromCollection) {
          textsOnStageFromCollection.find(text => {
            return text.textfieldId === section.from.id;
          });
        }

        if (areAllTextsShown
          || currentPageId === collectionId
          || (textsOnStageFromCollection && textsOnStageFromCollection.find(text =>
            text.textfieldId === section.from.id
          ))
        ) {
          if (areOnlyEmptyShown && to.content !== '') {
            return false;
          }

          if (searchTerm === '') {
            return true;
          }

          const searchStr = searchTerm.toLowerCase();
          const fromStr = String(section.from.content + '').toLowerCase();
          if (fromStr.includes(searchStr)) {
            return true;
          }
          const toStr = String(section.to.content + '').toLowerCase();
          return toStr.includes(searchStr);
        }
      })
      .map(section => {
        let refName = 'ref_' + collectionId + '_' + section.from.id;

        let isExternalSelected = false;
        if (scrollTo
          && scrollTo.hasOwnProperty('collection')
          && scrollTo.hasOwnProperty('textfield_id')
        ) {
          isExternalSelected =
            scrollTo.collection === collectionId &&
            scrollTo.textfield_id === section.from.id;
        }

        return (
          <FieldSection
            refName={refName}
            key={refName}
            translationType={translationType}
            collectionId={collectionId}
            translationId={translationId}
            isExternalSelected={isExternalSelected}
            sourceLanguageId={sourceLanguageId}
            compareWith={section.compareWith}
            from={section.from}
            to={section.to}
            searchTerm={searchTerm}
            updateTextInIframe={this.localDispatchTextUpdate}
            viewId={viewId}
          />
        );
      });
  };

  renderSourceSnapshotBar = () => {
    const {
      areAllTextsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      areOnlyEmptyShown
    } = this.props;

    const {
      sourceSnapshotId
    } = this.state;

    return (
      <div className={styles.sourceSnapshotWrapper}>
        <div className={'legend'}>
          <div className={'rectangle removed'}/>
          <span>removed words</span>
        </div>
        <div className={'legend'}>
          <div className={'rectangle added'}/>
          <span>added words</span>
        </div>
        {/*
        <div className={'checkboxWrapper'}>
          <div className={'switch'}>
            <div className={areAllTextsShown
              ? 'md-text md-selection-control-label'
              : 'md-text md-selection-control-label bold'}>Texts on stage
            </div>
            <Switch
              key="show-all-texts"
              id="show-all-texts"
              name="show-all-texts"
              aria-label="Show all texts"
              checked={areAllTextsShown}
              disabled={false}
              onChange={this.toggleAreAllTextsShown}
            />
            <div className={areAllTextsShown
              ? 'md-text md-selection-control-label bold'
              : 'md-text md-selection-control-label '}>All texts
            </div>
          </div>
          {sourceSnapshotId !== -1 && [
            <Switch
              key="show-differences"
              id="show-differences"
              name="show-differences"
              label="Show differences"
              checked={areOnlyDifferencesShown}
              disabled={false}
              onChange={this.toggleAreOnlyDifferencesShown}
            />,
            <Switch
              key="highlight-differences"
              id="highlight-differences"
              name="highlight-differences"
              label="Highlight different texts"
              checked={areDifferencesHighlighted}
              disabled={false}
              onChange={this.toggleAreDifferencesHighlighted}
            />,
            <Switch
              key="show-empty"
              id="show-empty"
              name="show-empty"
              label="Show Empty"
              checked={areOnlyEmptyShown}
              disabled={false}
              // className={'checkbox'}
              onChange={this.toggleAreOnlyEmptyShown}
            />
          ]}
        </div>
        */}
      </div>
    );
  };

  renderFooterBar = () => {
    const {t} = this.props;
    const {
      sourceSnapshotId,
      sourceLanguageId,
      projectId,
      translationLanguageCode,
      sourceLanguageCode,
      sourceType,
      translationType
    } = this.state;

    let baseClassName = 'headerHeadline';

    let openCloseTooltip = e => {
      let parent = e.target.parentNode.classList;
      if (parent.contains('open')) {
        parent.remove('open');
      } else {
        parent.add('open');
      }
    };

    return (
      <div className={styles.translationToolFooter}>
        <div>
          {sourceSnapshotId > 0 && (
            <div
              className={' headerHeadline snapshot'}
              data-open={sourceSnapshotId >= -1}
              title={t('source_snapshot')}
            >
              <SourceSnapshots
                projectId={projectId}
                sourceLanguageId={sourceLanguageId}
                sourceSnapshotId={sourceSnapshotId}/>
              <span className="tooltip-wrapper">
                <FontIcon onClick={openCloseTooltip}>info</FontIcon>
                <span className="tooltip">{`${t('source_language')}`}</span>
              </span>
            </div>
          )}
          <div
            className={baseClassName + ' source'}
            title={`${t('source_language')} - Type: ${t(sourceType)}`}>
            <b>{sourceLanguageCode}</b>
            <span className="tooltip-wrapper">
              <FontIcon onClick={openCloseTooltip}>info</FontIcon>
              <span className="tooltip">{`${t('source_language')} - Type: ${t(sourceType)}`}</span>
            </span>
          </div>
          <div
            className={baseClassName + ' target'}
            title={`${t('target_language')}  - Type: ${t(translationType)}`}
          >
            <b>{translationLanguageCode}</b>
            <span className="tooltip-wrapper">
              <FontIcon onClick={openCloseTooltip}>info</FontIcon>
              <span className="tooltip">{`${t('target_language')} - Type: ${t(translationType)}`}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  getVisibleSections = () => {
    const {
      sections,
      firstFieldSectionIndex,
      lastFieldSectionIndex
    } = this.state;

    let array = [];
    for (let j = 0; j < sections.length; j++) {
      let s = sections[j];
      let pushed = false;
      if (s.type !== 'div') {
        let sEl = document.querySelector('[field-section-refname="' + s.props.refName + '"]');
        if (sEl !== null) {
          let rteOpen = sEl.getAttribute('data-isopen') === 'true';
          if (rteOpen) {
            array.push(s);
            pushed = true;
          }
        }
      }
      if (!pushed) {
        if (j <= lastFieldSectionIndex && j >= firstFieldSectionIndex) {
          array.push(s);
        }
      }
    }
    return array;
  };

  render () {
    const {translations, fetchingTranslations, t} = this.props;
    const {
      isSourceSnapshotBarVisible,
      helpOpened,
      sections,
      sliderValue,
      firstFieldSectionIndex,
      lastFieldSectionIndex,
      scrollbarIsMoving,
      moveSBH,
      movingPositionHeadline
    } = this.state;

    const pdfDe = '/documents/help/translation-tool/de/mangoolu_v2_Anleitung_de.pdf';
    const pdfEn = '/documents/help/translation-tool/en/mangoolu_v2_user_guide_en.pdf';

    if (fetchingTranslations === ProcessStatus.INITIAL ||
      fetchingTranslations === ProcessStatus.STARTED) {
      return (
        <LoadingBar
          show="true"
          message={t('loading_all_texts')}
        />
      );
    }

    if (!translations.toArray().length) {
      return <div>No translations found.</div>;
    }

    // let visibleSections = sections.slice(firstFieldSectionIndex, lastFieldSectionIndex);
    let visibleSections = this.getVisibleSections();

    let handleHeight = ((lastFieldSectionIndex - firstFieldSectionIndex) / sections.length) * 100;
    let handleTop = scrollbarIsMoving ? moveSBH + '%' : sliderValue + '%';

    return (
      <div
        className={this.props.startedToolAsLayer ? styles.translationTool + ' view' : styles.translationTool}
      >
        <div className={'translationToolWrapper'}>
          <div className={'fixedWrapper'}>{this.renderHeaderBar()}</div>
          <div className={'scrollWrapper'}>{visibleSections}</div>
          <div className={'legendWrapper'}>{this.renderFooterBar()}</div>
          <div className={'translationToolHelp'} visible={helpOpened.toString()}>
            <button onClick={this.closeHelp}><FontIcon>close</FontIcon></button>
            <h2>{t('help_headline')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('help_content')}}></p>
            <a href={pdfDe} download>{t('help_button_german')} <FontIcon>picture_as_pdf</FontIcon></a>
            <a href={pdfEn} download>{t('help_button_english')} <FontIcon>picture_as_pdf</FontIcon></a>
          </div>
        </div>
        <div className={scrollbarIsMoving ? 'scrollDragOverLay moving' : 'scrollDragOverLay'}>
        </div>
        <div
          className={scrollbarIsMoving ? 'customScrollbar moving' : 'customScrollbar'}
          onClick={this.handleScrollBarClick}>
          <div
            className={'handle'}
            style={{top: handleTop, height: handleHeight + '%'}}
            onMouseDown={this.handleScrollBarHandleMouseDown}>
          </div>
        </div>
        <div className={scrollbarIsMoving ? 'whereAmI moving' : 'whereAmI'}>
          <span>{movingPositionHeadline}</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslations: state.getIn(['translationTool', 'fetchingTranslations']),
    translation: state.getIn(['projects', 'translation']),
    translations: state.getIn(['translationTool', 'translations']),
    textsForLocalStorage: state.getIn(['translationTool', 'textsForLocalStorage']),
    sourceSnapshots: state.getIn(['sourceSnapshots', 'sourceSnapshots']),
    sourceSnapshotProjectId: state.getIn(['sourceSnapshots', 'projectId']),
    areExpanded: state.getIn(['translationTool', 'areExpanded']),
    areTagsShown: state.getIn(['translationTool', 'areTagsShown']),
    areAllTextsShown: state.getIn(['translationTool', 'areAllTextsShown']),
    areOnlyDifferencesShown: state.getIn(['translationTool', 'areOnlyDifferencesShown']),
    areDifferencesHighlighted: state.getIn(['translationTool', 'areDifferencesHighlighted']),
    areOnlyEmptyShown: state.getIn(['translationTool', 'areOnlyEmptyShown']),
    scrollTo: state.getIn(['fieldLevel', 'scrollTo'])
    // allCourseTexts: state.getIn(['wbtViewer', 'texts'])
  };
}


export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchProject,
      fetchTranslation,
      fetchTranslations,
      fetchAdditionalContentTranslations,
      showNotification,
      fetchSourceSnapshots,
      // fetchAllTexts,
      setScrollTo,
      setAreExpanded,
      setAreTagsShown,
      setAreAllTextsShown,
      setAreOnlyDifferencesShown,
      setAreDifferencesHighlighted,
      setAreOnlyEmptyShown
    }
  )(TranslationTool)
);
