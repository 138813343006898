import {AuthAction} from '../utils/ActionTypes';
import {LOCATION_CHANGE} from 'react-router-redux';
import {map} from '../utils/Map';

export default function (store) {
  return next => action => {
    let url = '';
    let newAction = null;
    switch (action.type) {
      case LOCATION_CHANGE:
        url = action.payload.pathname;
        newAction = {
          type: AuthAction.PAGE_NOT_FOUND,
          payload: checkIfHasParam(url) ? parser(url) : false
        };
        store.dispatch(newAction);
        break;
      default:
        break;
    }
    next(action);
  };
}

/* eslint-disable */
function checkIfHasParam (url) {
  const urlParts = url.split('/');
  let mapPart = map;
  for (let i = 0; i < urlParts.length; i++) {
    if (urlParts[i] == '') continue;
    mapPart = mapPart[urlParts[i]];
    if (typeof mapPart === 'undefined') return true;
    // if empty object
    if (Object.keys(mapPart).length === 0 && mapPart.constructor === Object) {
      return false;
    } else {
      if (mapPart['__param']) {
        if (i == urlParts.length - 2) {
          return true;
        }
      }
    }
  }
  return false;
}

/* eslint-enable */

function parser (url) {
  let arr = url.split('/');
  if (arr.length > 0) {
    let value = arr[arr.length - 1];
    if (value === 'add' || value === 'edit') {
      return false;
    }
    let number = Math.floor(Number(value));
    return !(String(number) === value && number >= 0);
  }
  return false;
}
