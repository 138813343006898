import {ajax} from 'rxjs/observable/dom/ajax';

import {ImageAction} from '../utils/ActionTypes';

import {
  fetchImageFulfilled,
  fetchImageRejected,
  fetchFullImage,
  fetchFullImageFulfilled,
  fetchFullImageRejected,
  fetchCroppedImage,
  fetchCroppedImageFulfilled,
  fetchCroppedImageRejected,
  editImageFulfilled,
  editImageRejected,
  deleteImageFulfilled,
  deleteImageRejected,
  addImageFulfilled,
  addImageRejected,
} from '../actions/image';

import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';


const addImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.SAVE_IMAGE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload.projectId}/image`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.payload})
        .map(res => res.response)
        .map(addImageFulfilled)
        .catch(addImageRejected);
    });
};

const fetchImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.FETCH_IMAGE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload}/image`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchImageFulfilled)
        .catch(fetchImageRejected);
    });
};


const fetchFullImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.FETCH_FULL_IMAGE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload}/image/full`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchFullImageFulfilled)
        .catch(fetchFullImageRejected);
    });
};

const fetchCroppedImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.FETCH_CROPPED_IMAGE)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload}/image/cropped`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchCroppedImageFulfilled)
        .catch(fetchCroppedImageRejected);
    });
};

const editImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.EDIT_IMAGE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload.projectId}/image`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers: headers, body: action.payload})
        .map(res => res.response)
        .map(editImageFulfilled)
        .catch(editImageRejected);
    });
};

const deleteImageEpic = (action$, store) => {
  return action$.ofType(ImageAction.DELETE_IMAGE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = `${baseApiUrl}projects/${action.payload}/image`;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'DELETE', headers: headers})
        .map(res => res.response)
        .map(deleteImageFulfilled)
        .catch(deleteImageRejected);
    });
};


export {
  addImageEpic,
  fetchImageEpic,
  fetchFullImageEpic,
  fetchCroppedImageEpic,
  editImageEpic,
  deleteImageEpic
};
