import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import Nouislider from 'nouislider-react';
import styles from '../../styles/storyboards/slider.scss';
import {useDispatch} from 'react-redux';
import {SET_HISTORY} from '../../reducers/historySelect';

export default function HistoryCompareSlider (props) {
  const dispatch = useDispatch();
  const [handle, setHandle] = useState({left: 0, right: 1});
  const [slideHandle, setSlideHandle] = useState({left: 0, right: 1});
  const [sliderRange, setSliderRange] = useState(null);
  const [newStyle, setNewStyle] = useState(true);
  const [step, setStep] = useState(1);
  const [dragging, setDragging] = useState(0); // 1 == first Handle, 2 = second Handle

  const refSlider = useRef(null);
  let refHandles = null;
  let refDraggableConnects = null;

  let sliderValues = props.history.map((item, index) => {
    return {
      value: item.id,
      label: (index + 1)
             + '._'
             + item.created_at.substr(0, 16)
             + ':_'
             + item.author_name
    };
  });

  useEffect(() => {
    initSliderRange();
    update();

    setTimeout(() => {
      refHandles = refSlider.current.querySelectorAll('.noUi-touch-area');
      refDraggableConnects = refSlider.current.querySelector('.noUi-connect.noUi-draggable');
      refHandles[0].addEventListener('mousedown', () => {
        setDragging(1);
        // eslint-disable-next-line max-nested-callbacks
        document.addEventListener('mouseup', () => {setDragging(0);});
      });
      refHandles[1].addEventListener('mousedown', () => {
        setDragging(2);
        // eslint-disable-next-line max-nested-callbacks
        document.addEventListener('mouseup', () => {setDragging(0);});
      });
      refDraggableConnects.addEventListener('mousedown', () => {
        setDragging(3);
        // eslint-disable-next-line max-nested-callbacks
        document.addEventListener('mouseup', () => {setDragging(0);});
      });
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      update();
    }, 500);
  }, [handle]);

  const initSliderRange = () => {
    let sr = {}; // sliderRange
    let lengthForRange = sliderValues.length - 1;
    for (var i = 0; i < sliderValues.length; i++) {
      let key = i;
      let val = [];
      if (key === 0) {
        key = 'min';
        val.push(0);
        val.push(1);
      } else if (key === lengthForRange) {
        key = 'max';
        val.push(lengthForRange);
      } else {
        continue;
      }
      sr[key] = val;
    }
    setSliderRange(sr);
    setHandle({left: lengthForRange - 1, right: lengthForRange});
    setSlideHandle({left: lengthForRange - 1, right: lengthForRange});
  };

  const update = () => {
    const {siteId, textBlockId} = props;
    let itemForAction0 = getItemForAction(handle.left);
    let itemForAction1 = getItemForAction(handle.right);

    if (itemForAction0 && itemForAction1) {
      dispatch({
        type: SET_HISTORY, payload: {
          siteId: siteId,
          textBlockId: textBlockId,
          historySelectId: 'historySelectId_0',
          value: itemForAction0.value
        }
      });

      dispatch({
        type: SET_HISTORY, payload: {
          siteId: siteId,
          textBlockId: textBlockId,
          historySelectId: 'historySelectId_1',
          value: itemForAction1.value
        }
      });
    }
  };


  const handleSliderChange = sliderVal => {
    setHandle({
      left: sliderVal[0],
      right: sliderVal[1]
    });
  };

  const handleSliderSlide = sliderVal => {
    setSlideHandle({
      left: sliderVal[0],
      right: sliderVal[1]
    });
  };

  const getItemForAction = sliderHandleValue => {
    return sliderValues[parseInt(sliderHandleValue)];
  };

  const getHtmlLabel = item => {
    let array = item.label.split(' | ');
    let firstStringAsArray = array[0].split('.');
    let firstStringModified = '<span class="number">' + firstStringAsArray[0] + '</span>' + firstStringAsArray[1];
    array[0] = firstStringModified;
    return array.join('<br />');
  };

  let left = getItemForAction(slideHandle.left);
  let right = getItemForAction(slideHandle.right);

  const getTableString = (label) => {
    let a = label.split('_');
    let tableString = '';
    let tableStart = '<table class="labletable"><tr>';
    let tableEnd = '</tr></table>';
    let colStart = '<td>';
    let colEnd = '</td>';
    for (var i = 0; i < a.length; i++) {
      if (i === 0) {
        tableString += tableStart + colStart + a[i] + colEnd;
      } else if (i === 1) {
        let timeArray = a[i].split(' ');
        tableString += colStart + timeArray[0] + colEnd;
        tableString += colStart + timeArray[1].slice(0, -1) + colEnd;
      } else if (i === a.length - 1) {
        tableString += colStart + a[i] + colEnd + tableEnd;
      } else {
        tableString += colStart + a[i] + colEnd;
      }
    }
    return tableString;
  };

  // console.log('getTableString', getTableString('9._2019-12-17 15:16:_Juan Henao'));

  if (!sliderRange || !sliderRange.hasOwnProperty('min') || !sliderRange.hasOwnProperty('max')) {
    return null;
  }

  let sliderClassName = styles.slider + ' oneHandleUpOneDown';
  if (newStyle) {
    sliderClassName = sliderClassName + ' newSliderStyle';
  }

  return (
    <>
      <div className={sliderClassName} ref={refSlider}>
        <div className={dragging === 1 || dragging === 3 ? 'newLabel top dragging' : 'newLabel top'}>
          <span dangerouslySetInnerHTML={{__html: getTableString(left.label)}}/>
        </div>
        <Nouislider
          onChange={handleSliderChange}
          onSlide={handleSliderSlide} // Für Label live change
          // onUpdate={handleSliderChange} // Update on drag, aber leider inperformant
          start={[handle.left, handle.right]}
          tooltips={
            [
              wNumb({
                encoder: function (value) {
                  return parseInt(value + 1);
                }
              }),
              wNumb({
                encoder: function (value) {
                  return parseInt(value + 1);
                }
              })
            ]
          } // aus, wegen rotation der Handles
          behaviour={'drag'}
          connect={true}
          range={sliderRange}
          step={step}
          pips={{
            mode: 'steps',
            density: step
          }}
        />
        <div className={dragging === 2 || dragging === 3 ? 'newLabel bottom dragging' : 'newLabel bottom'}>
          <span dangerouslySetInnerHTML={{__html: getTableString(right.label)}}/>
        </div>
      </div>
      <button
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          fontSize: '8px',
          opacity: '0'
        }}
        onClick={() => {
          setNewStyle(!newStyle);
        }}
      >{newStyle ? 'old' : 'new'}</button>
      {
        !props.isSplitView && !newStyle &&
        <table className={styles.sliderTableSmall}>
          <tr>
            <td><span style={{fontWeight: 600}}>old:</span></td>
            <td>
              {left &&
               <span dangerouslySetInnerHTML={{__html: left.label}}/>
              }
            </td>
          </tr>
          <tr>
            <td><span style={{fontWeight: 600}}>new</span></td>
            <td>
              {right &&
               <span dangerouslySetInnerHTML={{__html: right.label}}/>
              }
            </td>
          </tr>
        </table>
      }
      {
        props.isSplitView && !newStyle &&
        <table className={styles.sliderTable}>
          <tr>
            <th><span>old</span></th>
            <th><span>new</span></th>
          </tr>
          <tr>
            <td>
              {left &&
               <span dangerouslySetInnerHTML={{__html: getHtmlLabel(left)}}/>
              }
            </td>
            <td>
              {right &&
               <span dangerouslySetInnerHTML={{__html: getHtmlLabel(right)}}/>
              }
            </td>
          </tr>
        </table>
      }

    </>
  );
}
