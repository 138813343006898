import React, { Component } from 'react';
import { Field } from 'react-final-form';
import {withTranslation} from 'react-i18next';

import { renderUploader } from '../../general/BasicFormComponents';
import { required } from '../../../utils/ValidationRules';
import ValidateUpload from './ValidateUpload';

const UPLOADED_FILE = 'uploadedFile';
const renderValidateUpload = ({input, meta: {touched, error}, ...custom}) => {
  return (
    <ValidateUpload
      id={input.name}
      value={input.value}
      error={touched && error}
      {...custom}
      onChange={input.onChange}
    />
  );
};

@withTranslation('project', {wait: true})
class UploadTranslation extends Component {
  constructor (props) {
    super(props);
  }

  deleteUpload = () => {
    this.props.form.change(UPLOADED_FILE, null);
  }

  render () {
    const {values, t} = this.props;

    return (
      <div>
        <Field
          validate={required}
          errorText={ t('hello_again') }
          id={UPLOADED_FILE}
          name={UPLOADED_FILE}
          type="text"
          className="md-cell md-cell--12"
          component={renderUploader}
        />
        {values[UPLOADED_FILE] && (
          <Field
            uploadedFile={values[UPLOADED_FILE]}
            validate={required}
            id="validate_upload"
            name="validate_upload"
            type="text"
            className="md-cell md-cell--12"
            onDiscard={this.deleteUpload}
            component={renderValidateUpload}
          />
        )}
      </div>
    );
  }
}

export default UploadTranslation;
