import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';
import RichTextEditorDraft from './editor/RichTextEditorDraft';
import {FontIcon, TextField} from 'react-md';
import LoadingBar from '../general/DumbComponent';
import {saveTranslation, setEditingInitialStatus} from '../../actions/translationTool';
import {showNotification} from '../../actions/notifications';
import {ProcessStatus} from '../../utils/Constants';
import Paper from '@material-ui/core/Paper';
import styles from '../../styles/storyboards/contentEditor.scss';
import stylesSite from '../../styles/storyboards/site.scss';
import stylesWrapper from '../../styles/storyboards/contentEditorWrapperWrapper.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import SyncIcon from '@material-ui/icons/Sync';
// import CheckIcon from '@material-ui/icons/Check';
import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore';
import HistoryBar from './editor/HistoryBar';
import HistoryIcon from '@material-ui/icons/History';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import {convertDraftRawToHtmlString} from './editor/helper/convertingForEditorWorks';
import RenderHtmlOrEditor from './RenderHtmlOrEditor';
import {
  deleteSite,
  setCurrentEditorId,
  unlockTextBlock,
  openStoryboardQuestionDialog
} from '../../actions/storyboard';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {customShallowEqual} from './Site';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


@withTranslation(['translation', 'general'], {wait: true})
class ContentEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      contentForDraft: this.props.content,
      saved: false,
      savePending: false,
      isWindowClosePrevented: false,
      editorHasChanges: false,
      active: true,
      saveAnimation: false,
      copyContent: null,
      canceled: false,
      showHistory: false,
      changesAreGoingToLooseOnDiscard: false,
      btnMenuActive: false,
      tempContent: null,
      selectedTextBlockIdForHistory: -1,
      moreMenuOpen: false
    };
    this.contentFast = null;
    this.discard = false;
    this.moreMenuBtn = React.createRef();
  }

  componentDidMount () {
    // TODO: M.Orf -> hier weiter
    // console.log('ContentEditor componentDidMount, ======> textBlock', this.props.textBlock);
  }

  componentDidUpdate (prevProps) {
    if (this.props.textBlock !== null
      && this.state.copyContent === null
    ) {
      console.log('setze copyContent', this.props.textBlock.latest_text.content);
      this.setState({
        copyContent: this.props.textBlock.latest_text.content
      });
    }

    // if (this.props.textBlock !== null) {
    //   console.log('componentDidUpdate this.props.textBlock', this.props.textBlock);
    //   // this.setState({contentForDraft: this.props.textBlock.content}); // TODO: weiter
    // }

    // console.log('prevProps.currentContentEditorId', prevProps.currentContentEditorId);
    // if (this.props.id === this.props.currentContentEditorId) { // beim erneuten öffnen!
    //
    // }
    if (prevProps.editingTranslation !== this.props.editingTranslation) {
      switch (this.props.editingTranslation) {
        case ProcessStatus.FINISHED:
          break;
        case ProcessStatus.FAILED:
          this.editableField = '';
          // this.props.showNotification(NotificationType.ERROR, 'EditTranslationFailure');
          break;
        default:
          break;
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    let shouldUpdate = (
      // nextState.saveAnimation !== this.state.saveAnimation           // save-Animation
      nextState.showHistory !== this.state.showHistory            // History anzeigen
      // || nextState.active !== this.state.active                      // Active??
      // || nextState.editorHasChanges !== this.state.editorHasChanges  // Changes Editor???
      // || nextState.contentForDraft !== this.state.contentForDraft    // neuer Content ??
      || nextState.moreMenuOpen !== this.state.moreMenuOpen             // wenn MORE_MENU
      || nextState.changesAreGoingToLooseOnDiscard !== this.state.changesAreGoingToLooseOnDiscard             // DIALOG
      // || nextProps.textItem !== this.props.textItem                  // neues TextItem ?
      ||
      (
        nextProps.currentContentEditorId === this.props.id      // TEXTFELD ÖFFNEN
        || this.props.currentContentEditorId === this.props.id  // TEXTFELD SCHLIESSEN
      )

      || nextProps.textBlock.lock !== this.props.textBlock.lock

      || (nextProps.updatingTextBlock !== ProcessStatus.STARTED   // Änderung UPDATE anzeigen
          && this.props.updatingTextBlock === ProcessStatus.STARTED
          && this.props.currentContentEditorId === this.props.id)
      || (nextProps.updatingTextBlock !== ProcessStatus.FINISHED   // ENDE UPDATE anzeigen
          && this.props.updatingTextBlock === ProcessStatus.FINISHED
          && this.props.currentContentEditorId === this.props.id)
      || (nextProps.deletingTextBlock !== ProcessStatus.STARTED   // Änderung DELETE anzeigen
          && this.props.deletingTextBlock === ProcessStatus.STARTED
          && this.props.currentContentEditorId === this.props.id)
      || (nextProps.deletingTextBlock !== ProcessStatus.FINISHED   // ENDE DELETE anzeigen
          && this.props.deletingTextBlock === ProcessStatus.FINISHED
          && this.props.currentContentEditorId === this.props.id)
      || (    // wenn TEXTBLOCK-UPDATE & OFFEN
        nextProps.updatingTextBlock === ProcessStatus.FINISHED
        && nextProps.currentContentEditorId === this.props.id
      )
      && (    // wenn Content nicht NULL
        nextProps.content !== null
      )
    );

    // console.log('shouldComponentUpdate shouldUpdate:', shouldUpdate, nextProps, nextState);
    return shouldUpdate;
  }

  setTempContent = c => {
    this.setState({
      tempContent: c
    });
  };

  openDiscardDialog = () => {
    console.log('openDiscardDialog');
    this.setState({
      changesAreGoingToLooseOnDiscard: true
    }, () => {
      console.log('danach changesAreGoingToLooseOnDiscard: ', this.state.changesAreGoingToLooseOnDiscard);

      console.log('OPEN');
      setTimeout(() => {
        this.props.openStoryboardQuestionDialog({
          title: 'Discard editing?',
          text: null,
          yesBtn: {
            text: 'discard',
            actionFunction: () => {
              console.log('this.cancelEditing()');
              this.cancelEditing();
            }
          },
          noBtn: {
            text: 'continue writing'
          }
        });
      }, 10);
    });
  };

  hasContentChanged = () => {
    let processedContent = convertDraftRawToHtmlString(this.contentFast);
    let preparsed = convertDraftRawToHtmlString(this.state.copyContent);
    return processedContent !== preparsed;
  };

  handleDiscardBtn = e => {
    console.log('handleDiscardBtn');
    e.preventDefault();
    this.discard = true;

    setTimeout(() => {
      let contentChanged = this.hasContentChanged();
      if (contentChanged) { // WENN ÄNDERUNGEN ==> NACHFRAGEN
        console.log('ÄNDERUNGEN VORHANDEN: DIALOG!');
        this.openDiscardDialog();
      } else {
        console.log('KEINE ÄNDERUNGEN: CANCEL');
        this.cancelEditing();
      }
    }, 10);
  };

  activateEditor = () => {
    this.setState({saveAnimation: false});
  };

  onEditorBlur = () => {
    if (this.discard) {
      return;
    }
    this.saveWithAnimation(true);
  };

  saveWithAnimation = closeAfterSafe => {
    console.log('saveWithAnimation', this.contentFast, closeAfterSafe);
    this.setTempContent(this.contentFast);
    this.setState({
      saveAnimation: true
    }, () => {
      this.setState({
        isWindowClosePrevented: false
      }, () => {
        // eslint-disable-next-line max-nested-callbacks
        setTimeout(() => {
          this.saveTextDependOnSituation(this.contentFast, closeAfterSafe);
          this.setState({
            saveAnimation: false,
            active: false
          });
        }, 500);
      });
    });

    setTimeout(() => {
      if (closeAfterSafe) {
        this.props.unlockTextBlock(this.props.currentContentEditorId);

        setTimeout(() => {
          this.props.setCurrentEditorId(-1);
        }, 50);
      }
    }, 510);
  };

  cancelEditing = () => {
    console.log('cancelEditing');
    // this.setState({
    //   saveAnimation: false,
    //   canceled: false,
    //   changesAreGoingToLooseOnDiscard: false
    // }, () => {
    //   this.discard = false;
    //   this.props.setCurrentEditorId(-1);
    //   // this.props.unlockTextBlock(this.props.currentContentEditorId);
    // });

    this.discard = false;
    this.props.unlockTextBlock(this.props.currentContentEditorId);
    setTimeout(() => {
      this.props.setCurrentEditorId(-1);
    }, 100);
  };

  saveContent = e => {
    e.preventDefault();
    this.saveWithAnimation(false);
  };

  saveContentAndClose = e => {
    e.preventDefault();
    this.saveWithAnimation(true);
  };

  save = c => { // nur neuen Wert Speichern, wird vom Editor eigenständig ausgeführt
    if (c) {
      console.log(c);
    }
    this.contentFast = c;
  };

  // HELPER-METHOD
  saveTextDependOnSituation = (c, unlock) => {
    this.props.saveText(this.props.id, c, unlock); // Site.js
  };

  openHistory = id => {
    this.setState({
      showHistory: true,
      selectedTextBlockIdForHistory: id
    });
  };

  closeHistory = () => {
    this.setState({showHistory: false}, () => {
      // this.forceUpdate();
    });
  };

  renderHistoryBar = () => {
    const {selectedTextBlockIdForHistory} = this.state;
    const {setTextFromHistory, id, deleteHistoryEntry} = this.props;
    console.log('renderHistoryBar');

    return <HistoryBar
      contentId={id}
      textBlockId={selectedTextBlockIdForHistory}
      setTextFromHistory={setTextFromHistory}
      deletetHistoryEntry={deleteHistoryEntry}
      closeHistory={this.closeHistory}
    />;
  };

  openDialogRemoveTextItem = () => {
    console.log('openDialogRemoveTextItem');

    // TODO: @m.orf: löschen nur ermöglichen, wenn editor NICHT geöffnet und NICHT geblockt!

    this.props.openStoryboardQuestionDialog({
      title: 'Really remove Text-Field?',
      text: <>
        <table>
          <tr>
            <td>ID: </td>
            <td>{this.props.id}</td>
          </tr>
        </table>
      </>,
      yesBtn: {
        text: 'remove',
        actionFunction: () => {
          this.props.removeTextItem(this.props.id);
        }
      },
      noBtn: {
        text: 'abort'
      }
    });
  };

  renderMoreMenu = () => {
    const {textBlock} = this.props;
    const {moreMenuOpen} = this.state;

    const handleMoreBtnClick = () => {
      console.log('this.moreMenuBtn', this.moreMenuBtn);
      this.setState({
        moreMenuOpen: true
      });
    };

    const handleMoreMenuClose = () => {
      this.setState({
        moreMenuOpen: false
      });
    };


    return (
      <>
        <IconButton
          className="bottom"
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMoreBtnClick}
          ref={this.moreMenuBtn}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.moreMenuBtn.current}
          keepMounted
          open={moreMenuOpen}
          onClose={handleMoreMenuClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: 'auto'
            },
            square: true,
            elevation: 4
          }}
          classes={{
            paper: stylesSite.siteMoreMenu,
            list: 'siteMoreMenuList'
          }}
        >
          <div className={'inner'}>
            {/*
            <Button
              variant=""
              color="default"
              startIcon={<CloseIcon />}
              onClick={handleMoreMenuClose}
            >
              Close
            </Button>
            */}

            { textBlock.history_count > 1 &&
              <Button
                variant=""
                color="default"
                title="History of textitem"
                startIcon={<HistoryIcon />}
                onClick={() => {
                  this.openHistory(textBlock.id);
                  handleMoreMenuClose();
                }}
              >
                History
              </Button>
            }

            {
              !this.props.hasOwnProperty('storyboardOnly')
              && this.props.contentLength > 1
              &&
              <Button
                variant=""
                color="default"
                title="Delete textitem"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  this.openDialogRemoveTextItem();
                  handleMoreMenuClose();
                }}
              >Delete</Button>
            }
          </div>
        </Menu>
      </>
    );
  };

  render () {
    const {
      editingTranslation,
      t,
      id,
      textBlock,
      currentContentEditorId,
      type,
      siteId,
      textBlockIndex,
      storyboardOnly,
      updatingTextBlock,
      deletingTextBlock,
      user
    } = this.props;
    const {
      savePending,
      active,
      saveAnimation,
      canceled,
      showHistory,
      tempContent,
      contentForDraft,
      changesAreGoingToLooseOnDiscard
    } = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (editingTranslation === ProcessStatus.STARTED && id === this.editableField) {
      return <LoadingBar show="true"/>;
    }

    let contentEditorClasses = 'contentEditor';
    contentEditorClasses += ' relativeToolbar';

    let contentEditorWrapperWrapperClasses = stylesWrapper.contentEditorWrapperWrapper;
    let contentEditorWrapperClasses = styles.contentEditorWrapper
      + ' rightContent rteEditorStyles';

    if (!textBlock) {
      return null;
    }

    if (textBlock.lock  && textBlock.lock.creator !== user.id) {
      contentEditorWrapperWrapperClasses = contentEditorWrapperWrapperClasses + ' locked';
    } else if (id === currentContentEditorId) {
      contentEditorClasses += ' active';
      contentEditorWrapperClasses += ' active';
      contentEditorWrapperWrapperClasses += ' active';
    } else if (updatingTextBlock !== ProcessStatus.FINISHED
        && deletingTextBlock !== ProcessStatus.INITIAL) {
      contentEditorWrapperWrapperClasses = contentEditorWrapperWrapperClasses + ' update';
    } else if (changesAreGoingToLooseOnDiscard) {
      contentEditorWrapperWrapperClasses = contentEditorWrapperWrapperClasses + ' shouldDelete';
    } else if (showHistory) {
      contentEditorWrapperWrapperClasses = contentEditorWrapperWrapperClasses + ' marked';
    }

    let isLocked = textBlock.lock && textBlock.lock.creator !== user.id;

    let textBlockLatestContent = textBlock.latest_text.content;

    return (
      <div className={contentEditorWrapperWrapperClasses}>
        <RenderHtmlOrEditor
          id={id} //
          textBlockType={type}
          textBlockIndex={textBlockIndex}
          siteId={siteId}
          currentContentEditorId={currentContentEditorId}
          content={textBlockLatestContent}
          tempContent={tempContent}
        >
          <Paper
            square={true}
            elevation={active ? 0 : 0}
            style={{padding: '0'}}
          >
            <div className={contentEditorWrapperClasses}>
              <div className={contentEditorClasses + ' saving'}>
                <FontIcon
                  className={'unsavedFlag'}
                  title={t(`${generalLNS}:information`) + ': ' + t('unsaved_content')}>turned_in</FontIcon>
                {(textBlockLatestContent || contentForDraft) &&
                <RichTextEditorDraft
                  initialText={
                    textBlockLatestContent
                      ? textBlockLatestContent
                      : contentForDraft
                  }
                  initialContextForDraft={textBlockLatestContent}
                  rawCode={textBlockLatestContent}
                  save={this.save}
                  handleContentChange={() => {
                    return null;
                  }}
                  savePending={savePending}
                  editorHasChanges={false}
                  active={true}
                  onEditorFocus={this.activateEditor}
                  onEditorBlur={this.onEditorBlur}
                  storyboardOnly={storyboardOnly}
                  toolbarLocation={'relative'}
                  stateSaveInterval={1000}
                />
                }
              </div>
              <div className={'rteButtonBar'}>
                <button
                  title={t('cancel_edit_mode') + '\n\n ' + t('or_press_esc')}
                  onMouseDown={this.handleDiscardBtn}
                >
                  <span className="innerText">discard</span>
                </button>
                <button onMouseDown={this.saveContent}>
                  <span className="innerText">save</span>
                </button>
                <button
                  className={'saveAndCloseBtn'}
                  disabled={savePending}
                  title={t(`${generalLNS}:save_all_edits`)}
                  onMouseDown={this.saveContentAndClose}
                >
                  <span className="innerText">save & close</span>
                </button>
              </div>

              <div className={saveAnimation ? 'saveLayer active' : 'saveLayer'}>
                {!canceled && <SaveIcon/>}
                {canceled && <SettingsBackupRestore/>}
              </div>
            </div>
          </Paper>
        </RenderHtmlOrEditor>
        <div className="textItemButtonWrapper">
          <div className="buttons">
            {this.renderMoreMenu()}
          </div>
        </div>
        {showHistory && this.renderHistoryBar()}
        <div className={isLocked ? 'blockOverlay visible' : 'blockOverlay'}>
          <div className="inner">
            <div
              className="blockIcon"
              onClick={() => {
                unlockTextBlock(this.props.id);
              }}>
              <LockIcon />
            </div>
            <div className={'owner'}>Editing by {textBlock.lock ? textBlock.lock.username : ''}</div>
          </div>
        </div>
        <span
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            opacity: '0.3',
            fontSize: '10px'
          }}
        >{this.props.id}</span>
      </div>
    );
  }
}

ContentEditor.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textId: PropTypes.string,
  collectionId: PropTypes.string,
  source: PropTypes.object
};

function getTextBlockTitle (textBlocks) {
  return textBlocks.find(textBlock => textBlock.type_id === 1);
}

function getTextBlockDescription (textBlocks) {
  return textBlocks.find(textBlock => textBlock.type_id === 3);
}

function getTextBlockContents (textBlocks) {
  return textBlocks.filter(textBlock => {
    if (textBlock.type_id === 4) {
      return textBlock.latest_text;
    }
  });
}

function mapStateToProps (state, ownProps) {
  let textBlock = null;

  let site = state.getIn(['storyboard', 'chapter']).find(site => {
    return (
      site.id === ownProps.siteId
    );
  });

  if (ownProps.type === 'title') {
    textBlock = getTextBlockTitle(site.text_blocks);
  } else if (ownProps.type === 'description') {
    textBlock = getTextBlockDescription(site.text_blocks);
  } else if (ownProps.type === 'contents') {
    textBlock = getTextBlockContents(site.text_blocks)[ownProps.textBlockIndex];
  }

  return {
    editingTranslation: state.getIn(['translationTool', 'editingTranslation']),
    savedTranslation: state.getIn(['translationTool', 'savedTranslation']),
    currentContentEditorId: state.getIn(['storyboard', 'currentContentEditorId']),
    updatingTextBlock: state.getIn(['storyboard', 'updatingTextBlock']),
    deletingTextBlock: state.getIn(['storyboard', 'deletingTextBlock']),
    user: state.getIn(['auth', 'user']),
    textBlock: textBlock
  };
}

export default connect(
  mapStateToProps,
  {
    saveTranslation,
    setEditingInitialStatus,
    showNotification,
    setCurrentEditorId,
    unlockTextBlock,
    openStoryboardQuestionDialog
  }
)(ContentEditor);
