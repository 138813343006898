import { ajax } from 'rxjs/observable/dom/ajax';
import {WbtViewerAction} from '../utils/ActionTypes';
import {
  fetchAllTextsFulfilled,
  fetchAllTextsRejected,
  fetchAllTextsGroupedByCollectionFulfilled,
  fetchAllTextsGroupedByCollectionRejected,
  fetchSourceFulfilled,
  fetchSourceRejected
} from '../actions/wbtViewer';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchAllTextsEpic = (action$, store) => {
  return action$
    .ofType(WbtViewerAction.FETCH_ALL_TEXTS)
    .debounceTime(500)
    .mergeMap(action => {
      let url =
        baseApiUrl +
        'texts/external/' +
        action.sourceTranslationId +
        '/' +
        action.targetTranslationId +
        '/all/false';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .get(url, headers)
        .map(ajaxResponse => ajaxResponse.response)
        .map(fetchAllTextsFulfilled)
        .catch(fetchAllTextsRejected);
    });
};

const fetchAllTextsGroupedByCollectionEpic = (action$, store) => {
  return action$
    .ofType(WbtViewerAction.FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION)
    .debounceTime(500)
    .mergeMap(action => {
      let url =
        baseApiUrl +
        'texts/external/' +
        action.sourceTranslationId +
        '/' +
        action.targetTranslationId +
        '/all/true';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .get(url, headers)
        .map(ajaxResponse => ajaxResponse.response)
        .map(fetchAllTextsGroupedByCollectionFulfilled)
        .catch(fetchAllTextsGroupedByCollectionRejected);
    });
};

const fetchSourceEpic = (action$, store) => {
  return action$.ofType(WbtViewerAction.FETCH_SOURCE)
    .debounceTime(600)  // TODO: 500 debounceTime wird rejected, mit Carsten schauen
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/translations/' + action.sourceId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchSourceFulfilled)
        .catch(fetchSourceRejected);
    });
};

export { fetchAllTextsEpic, fetchAllTextsGroupedByCollectionEpic, fetchSourceEpic };
