import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import styles from '../../styles/mangoolu_loading.scss';

@withTranslation('general', {wait: true})
class MangooluLoading extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loadingInterval: null,
      currentCount: '...'
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.show) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  }

  componentDidMount () {
    this.startTimer();
  }

  componentWillUnmount () {
    this.stopTimer();
  }

  startTimer = () => {
    if (this.timerID == undefined) {
      this.timerID = setInterval(() => this.timer(), 1000);
    }
  };

  stopTimer = () => {
    clearInterval(this.timerID);
  };

  timer = () => {
    this.setState({
      currentCount: this.state.currentCount.length > 9 ? ` ${this.state.currentCount}.` : '...'
    });
  };

  render () {
    const {show, hidetext, t} = this.props;
    const {currentCount} = this.state;
    const gifImage = require('../../images/mangoolu-loop.gif');
    const gifImageDark = require('../../images/mangoolu-loop-dark.gif');

    return (
      <div
        className={
          show ? styles.loadingContainer : styles.displayNone + ' ' + styles.loadingContainer
        }>
        <div className="center" draggable={false}>
          <img className={'loop-light'} alt={ t('loading') } src={gifImage} draggable={false}/>
          <img className={'loop-dark'} alt={ t('loading') } src={gifImageDark} draggable={false}/>
          <span
            className={styles.loadingText}
            style={{display: hidetext ? 'none' : ''}}
            draggable={false}>
            {t('loading') + currentCount}
          </span>
        </div>
      </div>
    );
  }
}

export default MangooluLoading;
