import React from 'react';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';
import {Scope} from '../../utils/Constants';
import {aclFilter} from '../../utils/ACL';
import {aclTranslationTool} from '../../utils/Permissions';
import styles from '../../styles/translationTool/translationTool.scss';
import stylesEditor from './draftEditorAdditional/style/mainEditorStyle.scss';
// import { parseCode, parseHTML, stripAllTags } from '../../utils/RTEParser';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import {convertToRaw, convertFromRaw, Modifier, EditorState} from 'draft-js';
import {convertToHTML} from 'draft-convert';
import {convertFromHTML} from './draftEditorAdditional/convert/convert';
import {DraftailEditor} from 'draftail';
import {importerConfig, exporterConfig} from './draftEditorAdditional/ImporterExporterConfigs';
import {entityTypes, blockTypes, inlineStyles} from './draftEditorAdditional/EditorTypes';
import RawEditor from './RawEditor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';
// import createLinkPlugin from 'draft-js-anchor-plugin';
import {OrderedSet as orderedSet} from 'immutable';
import ShyButton from './draftEditorAdditional/extensions/ShyButton';
import ZwspButton from './draftEditorAdditional/extensions/ZwspButton';
import SoftBreakButton from './draftEditorAdditional/extensions/SoftBreakButton';
import IndentListButton from './draftEditorAdditional/extensions/IndentListButton';
import OudentListButton from './draftEditorAdditional/extensions/OudentListButton';
import NoBreakButton from './draftEditorAdditional/extensions/NoBreakButton';
import SubButton from './draftEditorAdditional/extensions/SubButton';
import SuperButton from './draftEditorAdditional/extensions/SuperButton';
import BoldButton from './draftEditorAdditional/extensions/BoldButton';
import ListButton from './draftEditorAdditional/extensions/ListButton';
import QuoteButton from './draftEditorAdditional/extensions/QuoteButton';
import UndoRedoComponent from './draftEditorAdditional/extensions/UndoRedoComponent';
import LinkButton from './draftEditorAdditional/extensions/LinkButton';


@withTranslation(['translation_navigation', 'general'], {wait: true})
class RichTextEditorDraft extends React.Component {
  constructor (props) {
    super(props);
    const {t} = this.props;
    this.state = {
      isIE11: true,
      initialText: this.props.initialText,
      rawEditorOpen: false,
      contentChanged: false,
      editorState: null,
      descriptions: {
        bold: t('bold_text'),
        superscript: t('superscript_text'),
        subscript: t('subscript_text'),
        softBreak: t('soft_break'),
        nobreak: t('nobreak_text'),
        quote: t('quote_text'),
        list: t('list_text'),
        indent: t('indent_text'),
        decrease: t('decrease_text'),
        link: t('link_text'),
        mail: t('mail_text'),
        shy: t('shy_text'),
        zwsp: t('zwsp_text'),
        undo: t('undo'),
        redo: t('redo')
      },
      staticToolBarHidden: true
    };

    // Editor-Plugins:
    this.toolbarPlugin = createToolbarPlugin();
    this.inlineToolbarPlugin = createInlineToolbarPlugin();
    this.undoPlugin = createUndoPlugin({
      undoContent: <span className="material-icons">undo</span>,
      redoContent: <span className="material-icons">redo</span>
    });
    // this.linkPlugin = createLinkPlugin();
    this.editorPlugins = [
      this.toolbarPlugin,            // Toolbar über Editor
      this.inlineToolbarPlugin,      // HOVER-TOOLBAR
      this.undoPlugin               // Undo-Plugin
    ];
    this.editorId = Math.random().toString(16).slice(2);
    this.editorWrapper = React.createRef();
  }

  fromHTML = (html) => convertToRaw(convertFromHTML(importerConfig)(html));
  toHTML = (raw) => raw ? convertToHTML(exporterConfig)(convertFromRaw(raw)) : ''; // funzt!

  componentDidMount () {
    this.updateMenu();
    window.addEventListener('wheel', this.updateStaticMenuPosition, false);

    this.parseInitialText();
  }

  componentWillUnmount () {
    window.removeEventListener('wheel', this.updateStaticMenuPosition, false);
  }

  updateStaticMenuPosition = () => {
    this.setState({
      staticToolBarHidden: !this.elementInViewport(this.editorWrapper.current)
    });
  };

  elementInViewport = (el) => {
    let rect = el.getBoundingClientRect();
    let root = document.querySelector('#root').getBoundingClientRect();

    return (
      rect.bottom > root.top + (root.bottom * 0.9) &&
      rect.top < root.top
    );
  };

  parseInitialText = () => {
    /* TODO: M.Orf / J.Hosenfeld: UNBEDINGT VERBESSERN: Parsing nicht bei jedem Rendern erledigen! */
    const {initialText} = this.props;

    // Line-Breaks-Vorparsen:
    let newText = initialText
      .replace(/<br>|<br\/>|<br \/>/g, '\n')
      .replace(/(\r\n?|\n)/g, '<br>')
    ;

    // LISTEN-umparsen:
    // <li>((?!<\/li)[\s\S])*<ul> // regex Ausschluss
    let listRegex = /<li>(((?!<ul>|<\/li>)[\s\S])*)(((?!<\/li>)[\s\S])*(<ul>[\s\S]*<\/ul>)([\s\S]*))+(<\/li>)/g;
    let newText2 = this.replaceRecursive(newText, listRegex, '<li>$1</li>$3');

    return this.fromHTML(newText2);
  };


  componentDidUpdate = () => {
    this.updateMenu();
  };

  updateMenu = () => {
    const menu = this.hoverMenu;
    if (!menu) return;

    const {value} = this.state;
    const {fragment, selection} = value;

    if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
      if (menu.hasAttribute('style')) {
        menu.removeAttribute('style');
      }
      return;
    }

    const native = window.getSelection();
    const range = native.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    menu.style.opacity = 1;
    menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;
    menu.style.left = `${rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2}px`;
  };

  clickNoBreak = (event) => {
    event.preventDefault();
    this.importSpecialTagEntity('nb', 'NOBREAK', 'MUTABLE');
  };

  clickSup = (event) => {
    event.preventDefault();
    this.importSpecialTagEntity('sup', 'SUP', 'MUTABLE');
  };

  clickSub = (event) => {
    event.preventDefault();
    this.importSpecialTagEntity('sub', 'SUB', 'MUTABLE');
  };

  getSelectedText = () => {
    const {editorState} = this.refs.draftEditor;
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
  };

  importSpecialTagEntity = (tagname, entityName, mutability) => {
    const {editorState} = this.refs.draftEditor;
    const text = '[' + tagname + ']' + this.getSelectedText() + '[/' + tagname + ']';
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(entityName, mutability, {time: new Date().getTime()});
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const modifiedContent = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      orderedSet(['INSERT']),
      entityKey
    );
    const nextEditorState = EditorState.push(
      editorState,
      modifiedContent,
      editorState.getLastChangeType()
    );
    editorState.change(nextEditorState);
  };

  focusEditor = () => {
    // console.log('focusEditor');
    // this.editorDraft.getEditorRef().refs.editor.focus();
    setTimeout(this.editorDraft.focus, 50);
  };

  renderInlineToolbar = () => {
    const InlineToolbar = this.inlineToolbarPlugin.InlineToolbar;

    return (
      <div className="inline-toolbar">
        <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            externalProps => {
              return (
                <div>
                  {aclFilter(
                    <BoldButton description={this.state.descriptions.bold} {...externalProps} />
                  )([], [aclTranslationTool.bold], Scope.TRANSLATION)}

                  {aclFilter(
                    <SubButton description={this.state.descriptions.subscript} {...externalProps} />
                  )([], [aclTranslationTool.subscript], Scope.TRANSLATION)}

                  {aclFilter(
                    <SuperButton description={this.state.descriptions.superscript} {...externalProps} />
                  )([], [aclTranslationTool.superscript], Scope.TRANSLATION)}

                  {aclFilter(
                    <NoBreakButton description={this.state.descriptions.nobreak} {...externalProps} />
                  )([], [aclTranslationTool.nobreak], Scope.TRANSLATION)}

                  {/*
                    BESCHREIBUNG:
                    ALter Button hier war:
                    <LinkButton
                      description={this.state.descriptions.link}
                      focusEditor={this.focusEditor}
                      {...externalProps} />

                      Fehler mit Inline-Toolbar: Bei Klick auf Link-Btn wird eine neue Entity erzeugt,
                      statt wie bei allen anderen Inline-Buttons ein Style hinzugefügt.
                      Deshalb ergibt sich ein Focus-Fehler im Editor und die Inline-Toolbar
                      wird nicht korrekt positioniert, bis der Editor wieder erneut einen Focus erhält.
                      LÖSUNG: Nachfolgendes Span löst per MouseDown-Event (Firefox akzeptierte hier kein Click)
                      einen Klick auf Button, welcher in der Static-Toolbar (unsichtbar) [Zeile 276]
                      gerendert wurde, aus.
                  */}
                  {aclFilter(
                    <span
                      title={this.state.descriptions.link}
                      className="toolbar-button"
                      onMouseDown={() => {
                        document.querySelector('.addLinkBtn_' + this.editorId).click();
                      }}>
                      <FontIcon>insert_link</FontIcon>
                    </span>
                  )([], [aclTranslationTool.link], Scope.TRANSLATION)}

                </div>
              );
            }
          }
        </InlineToolbar>
      </div>
    );
  };

  renderToolbar = () => {
    // const {Toolbar} = this.toolbarPlugin;
    const Toolbar = this.inlineToolbarPlugin.InlineToolbar;
    // const {UndoButton, RedoButton} = this.undoPlugin;

    return (
      <div className={'static-toolbar'}>
        <Toolbar>
          {
            (externalProps) => (
              <div>
                <QuoteButton description={this.state.descriptions.quote} {...externalProps} />
                {aclFilter(
                  <QuoteButton description={this.state.descriptions.quote} {...externalProps} />
                )([], [aclTranslationTool.quote], Scope.TRANSLATION || Scope.STORYBOARD)}
                <span className={'separator'}> </span>

                {aclFilter(
                  <ListButton description={this.state.descriptions.list} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.STORYBOARD)}

                {aclFilter(
                  <IndentListButton description={this.state.descriptions.indent} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}
                {aclFilter(
                  <OudentListButton description={this.state.descriptions.decrease} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}
                {aclFilter(
                  <span className={'separator'}> </span>
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}

                {aclFilter(
                  <ShyButton description={this.state.descriptions.shy} {...externalProps}/>
                )([], [aclTranslationTool.shy], Scope.TRANSLATION)}
                {aclFilter(
                  <ZwspButton description={this.state.descriptions.zwsp} {...externalProps} />
                )([], [aclTranslationTool.zero_width_space], Scope.TRANSLATION)}

                <SoftBreakButton description={this.state.descriptions.softBreak} {...externalProps} />

                <span className={'separator'}> </span>

                {aclFilter(
                  <LinkButton
                    editorId={this.editorId}
                    focusEditor={this.focusEditor}
                    {...externalProps} />
                )([], [aclTranslationTool.link], Scope.TRANSLATION)}

                <UndoRedoComponent
                  descriptionUndo={this.state.descriptions.undo}
                  descriptionRedo={this.state.descriptions.redo}
                  {...externalProps} />
              </div>
            )
          }
        </Toolbar>
        {/*
        <span className={'separator'}> </span>
        <div className="btn-group">
          <UndoButton className={'button undoRedo'}/>
          <RedoButton className={'button undoRedo'}/>
        </div>
        */}
      </div>
    );
  };

  renderSideToolbar = () => {
    const {staticToolBarHidden} = this.state;
    const {t} = this.props;
    // const {Toolbar} = this.toolbarPlugin;
    const Toolbar = this.inlineToolbarPlugin.InlineToolbar;
    // const {UndoButton, RedoButton} = this.undoPlugin;

    if (!this.editorWrapper
      || !this.editorWrapper.current
      || window.innerHeight < 650
      || this.editorWrapper.current.getBoundingClientRect().height < window.innerHeight) {
      return null;
    }
    return (
      <div className={'static-toolbar right-static'}>
        <Toolbar>
          {
            (externalProps) => (
              <div className={staticToolBarHidden ? 'disabled' : ''}>
                {aclFilter(
                  <QuoteButton description={this.state.descriptions.quote} {...externalProps} />
                )([], [aclTranslationTool.quote], Scope.TRANSLATION)}
                <span className={'separator'}> </span>

                {aclFilter(
                  <ListButton description={this.state.descriptions.list} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}

                {aclFilter(
                  <IndentListButton description={this.state.descriptions.indent} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}
                {aclFilter(
                  <OudentListButton description={this.state.descriptions.decrease} {...externalProps} />
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}
                {aclFilter(
                  <span className={'separator'}> </span>
                )([], [aclTranslationTool.list], Scope.TRANSLATION)}

                {aclFilter(
                  <ShyButton description={this.state.descriptions.shy} {...externalProps}/>
                )([], [aclTranslationTool.shy], Scope.TRANSLATION)}
                {aclFilter(
                  <ZwspButton description={this.state.descriptions.zwsp} {...externalProps} />
                )([], [aclTranslationTool.zero_width_space], Scope.TRANSLATION)}

                <SoftBreakButton description={this.state.descriptions.softBreak} {...externalProps} />

                <span className={'separator'}> </span>


                {aclFilter(
                  <LinkButton
                    editorId={this.editorId}
                    focusEditor={this.focusEditor}
                    {...externalProps} />
                )([], [aclTranslationTool.link], Scope.TRANSLATION)}

                <UndoRedoComponent
                  descriptionUndo={this.state.descriptions.undo}
                  descriptionRedo={this.state.descriptions.redo}
                  {...externalProps} />


                <span className={'separator'}> </span>
                <span className={'separator'}> </span>
                <span className={'separator'}> </span>

                <span
                  className={'button'}
                  icon
                  title={t('cancel_edit_mode') + '\n\n ' + t('or_press_esc')}
                  onClick={this.props.cancelContentChange}
                ><FontIcon>close</FontIcon></span>
                <span
                  className={'button'}
                  disabled={this.props.savePending}
                  title={t('general:save_all_edits')}
                  onClick={this.props.saveTranslation}
                ><FontIcon>{this.props.savePending ? 'sync' : 'check'}</FontIcon></span>
              </div>
            )
          }
        </Toolbar>
      </div>
    );
  };

  call (change) {
    this.setState({
      value: this.state.value.change().call(change).value
    });
  }

  showRawEditor = e => {
    if (e.shiftKey) {
      this.setState({rawEditorOpen: true});
    }
  };
  hideRawEditor = () => {
    this.setState({rawEditorOpen: false});
  };

  // onDraftChange = editorState => {
  //   this.setState({editorState});
  // };

  onSave = content => {
    // console.log('onSave');
    // DEBUG:
    // sessionStorage.setItem('draftail:content', JSON.stringify(content));
    // console.log('onSave content:\n', content);

    // EDITORSTATE --> HTML

    // console.log('content\n', content);
    let html = this.toHTML(content);

    // console.log('html\n', html);
    // console.log('initialText stripped:\n', '<p>' + this.props.initialText + '</p>');
    // this.setState({contentChanged: html !== '<p>' + this.props.initialText + '</p>'});
    // console.log('this.contentChanged', this.state.contentChanged);


    // Parsing nested-lists to valid HTML:
    html = html
      .replace(/<\/li><ul>/g, '<ul>')
      .replace(/l>((?!<)[\s\S])*<\/u/g, 'l></li></u')
    ;
    this.props.handleContentChange(html);
  };

  replaceRecursive = (string, pattern, replacement) => {
    let returnString = string;
    let depthCount = 0;
    while (pattern.test(returnString)) {
      depthCount++;
      returnString = returnString.replace(pattern, replacement);
    }
    console.warn('Parsed LISTs, depth: ', depthCount);
    return returnString;
  };

  renderEditor = () => {
    const {contentChanged} = this.state;
    const {initialContextForDraft} = this.props;
    // const textObject = this.parseInitialText();

    const setContentChanged = ({getEditorState}) => {
      const editorState = getEditorState();
      if (contentChanged !== editorState.getUndoStack().isEmpty()) {
        this.setState({
          editorState: editorState
        });
      }
      return null;
    };

    return (
      <div
        className={'editor ' + stylesEditor.drafttailEditor}
        contentchanged={this.props.editorHasChanges.toString()}
        ref={this.editorWrapper}
      >
        <DraftailEditor
          rawContentState={initialContextForDraft}
          ref={el => (this.editorDraft = el)}
          onSave={this.onSave}
          onFocus={null}
          onBlur={null}
          placeholder={null}
          stripPastedStyles={false}
          textAlignment={null}
          textDirectionality={null}
          // enableHorizontalRule={{description: 'Horizontal rule'}}
          enableLineBreak={{
            description: 'Soft line break',
            icon: <FontIcon>keyboard_return</FontIcon>
          }}
          showUndoControl={{description: 'Undo last change'}}
          showRedoControl={{description: 'Redo last change'}}
          maxListNesting={10}
          inlineStyles={inlineStyles}
          blockTypes={blockTypes}
          entityTypes={entityTypes}
          stateSaveInterval={250}
          controls={[setContentChanged]}
          plugins={this.editorPlugins}
          topToolbar={null}
        />
      </div>
    );
  };

  renderRawEditor = () => {
    const {rawCode} = this.props;
    return (
      <RawEditor
        rawCode={rawCode}
        hideRawEditor={this.hideRawEditor}
        saveTranslation={this.props.saveTranslation}
        handleContentChange={this.props.handleContentChange}
      />
    );
  };

  render () {
    const {
      showLinkDialog,
      showMailLinkDialog,
      // isIE11,
      rawEditorOpen
    } = this.state;
    return (
      <div>
        <div
          className={styles.rteWrapper}
          data-invisible={showLinkDialog || showMailLinkDialog}
        >
          {aclFilter(
            <span
              className="button-start-rawEditor"
              onMouseDown={this.showRawEditor}
            ><span className="material-icons">code</span></span>
          )(['admin'])}
          {this.renderEditor()}
          {this.renderToolbar()}
          {this.renderSideToolbar()}
          {this.renderInlineToolbar()}
          {rawEditorOpen && this.renderRawEditor()}

          {/*
          <div  style={{display: 'flex'}}>
            <Button
              className={'cancelButton'}
              icon
              title={t('cancel_edit_mode') + '\n\n ' + t('or_press_esc')}
              onClick={this.cancelContentChange}
            >close</Button>
            {contentChanged === true &&
            <Button
              className={'checkButton'}
              icon
              disabled={this.props.savePending}
              title={t('general:save_all_edits')}
              onClick={this.saveTranslation}
            >{this.props.savePending ? 'sync' : 'check'}</Button>
            }
          </div>
          */}
        </div>
      </div>
    );
  }
}

export default RichTextEditorDraft;
