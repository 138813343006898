import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ExpansionList, Card, Button, FontIcon} from 'react-md';
import {Row, Col} from 'react-grid-system';
import {withTranslation} from 'react-i18next';

import {Scope} from '../../../utils/Constants';
import {TranslationModel} from '../../../tableModels';
import DeleteProjectWarning from '../dialog/DeleteProjectWarning';

import TranslationPanel from './TranslationPanel';

import {deleteProject} from '../../../actions/projects';
import {showWarning} from '../../../actions/globalDialog';
import ImageInput from '../../general/image/ImageInput';

import styles from '../../../styles/projects/project.scss';
import {aclProject} from '../../../utils/Permissions';
import {aclFilter} from '../../../utils/ACL';

import {baseApiUrl} from '../../../utils/AuthHelper';

@withTranslation(['project', 'general'], {wait: true})
class ProjectOverview extends Component {
  constructor (props) {
    super(props);

    this.state = {
      baseUrl: ''
    };
  }

  componentWillMount () {
    this.setBaseUrl(this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setBaseUrl(nextProps);
  }

  setBaseUrl = props => {
    let projectsPart = '/projects/' + props.match.params.projectId;
    let translationsPart = '/translations/' + props.match.params.translationId;
    let sourceLanguagePart = props.match.params.sourceLanguageId
      ? '/' + props.match.params.sourceLanguageId
      : '';

    this.setState({baseUrl: projectsPart + translationsPart + sourceLanguagePart});
  };

  handleEdit = () => {
    this.props.history.push(this.state.baseUrl + '/edit-project');
  };

  handleUpdate = () => {
    this.props.history.push(this.state.baseUrl + '/update-project');
  };

  showDeleteWarning = () => {
    const {showWarning, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text = t('warning_delete_project');

    let component = <DeleteProjectWarning projectId={this.props.project.id}/>;

    showWarning({
      title: title,
      text: text,
      actions: [],
      component: component,
      isCancellable: false
    });
  };


  render () {
    const {project, translations, translation, updateToken, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let phone = null;
    let email = null;

    if (!project) {
      return (<div>{t(`${generalLNS}:loading`)}</div>);
    }

    if (project && project.contact && project.contact.pl_phone_number) {
      phone = 'tel:' + project.contact.pl_phone_number;
    }

    if (project && project.contact && project.contact.pl_email) {
      email = 'mailto:' + project.contact.pl_email;
    }

    return (
      <Card className={styles.projectOverview}>
        <div className={'projectOverviewRowWrapper'}>
          <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%'
          }}>
            <div style={{
              flexGrow: '0',
              flexShrink: '0',
              flexBasis: '350px'
            }}>
              <ImageInput alt="Project Image"/>
            </div>
            <div style={{
              flexGrow: '1',
              flexShrink: '1',
              flexBasis: 'auto',
              padding: '0 1rem',
              overflow: 'hidden'
            }}>
              <div className={'titleWrapper'}>
                <div className={'projOverTitle'}>
                  <div className={'titleAndButtonRow'}>
                    <h3
                      title={t('project_name') + ': ' + project.name} // WICHTIG, weil text-overflow
                    >
                      {project.name}
                    </h3>
                    <div className={'titleButtonRow buttonRow'}>
                      {aclFilter(
                        <Button
                          title={t('delete_project')}
                          className={'hover-round-button red-skin'}
                          onClick={this.showDeleteWarning} icon>
                          delete
                        </Button>
                      )([], [aclProject.delete], Scope.PROJECT)}
                      {aclFilter(
                        <Button
                          title={t('update_project_package')}
                          className={'hover-round-button'}
                          onClick={this.handleUpdate} icon>
                          sync
                        </Button>
                      )([], [aclProject.update_package], Scope.PROJECT)}
                      {aclFilter(
                        <Button
                          title={t('edit_project')}
                          className={'hover-round-button'}
                          onClick={this.handleEdit} icon>
                          mode_edit
                        </Button>
                      )([], [aclProject.read])}
                    </div>

                  </div>

                  <div title="Project-ID" className={'projOverId'}>
                    {t(`${generalLNS}:project_id`)}: {project.id}
                  </div>
                </div>
              </div>
              {project.contact
                ? (
                  <div className={'contact'}>
                    <div className={'row'}>
                      <FontIcon>person_outline</FontIcon>
                      <span>{project.contact.project_leader_name}</span>
                    </div>
                    <div className={'row'}>
                      <FontIcon>phone</FontIcon>
                      <span><a href={phone}>{project.contact.pl_phone_number}</a></span>
                    </div>
                    <div className={'row'}>
                      <FontIcon>alternate_email</FontIcon>
                      <span><a href={email}>{project.contact.pl_email}</a></span>
                    </div>
                  </div>
                )
                :
                <div className={'noContactsWarning'}>
                  <FontIcon>warning</FontIcon><span>{t('no_project_contact_message')}</span>
                </div>
              }
            </div>
          </div>
        </div>

        {aclFilter(
          <ExpansionList className={'md-cell md-cell--12 expansionList'}>
            <TranslationPanel
              label={t('translations_overview')}
              model={TranslationModel}
              translation={translation}
              translations={translations}
              updateToken={updateToken}
            />
          </ExpansionList>
        )([], [aclProject.read_translations], Scope.PROJECT)}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    updateToken: state.getIn(['projects', 'updateToken']),
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {deleteProject, showWarning}
  )(ProjectOverview)
);
