import {ajax} from 'rxjs/observable/dom/ajax';
import {ClientsAction} from '../utils/ActionTypes';

import {
  fetchClientsFulfilled,
  fetchClientsRejected,
  fetchClientFulfilled,
  fetchClientRejected,
  addClientFulfilled,
  addClientRejected,
  editClientFulfilled,
  editClientRejected,
  deleteClientFulfilled,
  deleteClientRejected,
  linkClientToUserFulfilled,
  linkClientToUserRejected,
  linkClientToExportTypeFulfilled,
  linkClientToExportTypeRejected
} from '../actions/clients';

import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';


const fetchClientsEpic = (action$, store) => {
  return action$.ofType(ClientsAction.FETCH_CLIENTS)
    .debounceTime(900)
    .mergeMap(() => {
      let url = baseApiUrl + 'clients';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'GET', headers: headers})
        .map(ajaxResponse => ajaxResponse.response.data)
        .map(fetchClientsFulfilled)
        .catch(fetchClientsRejected);
    });
};

const fetchClientEpic = (action$, store) => {
  return action$.ofType(ClientsAction.FETCH_CLIENT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients/' + action.clientId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchClientFulfilled)
        .catch(fetchClientRejected);
    });
};

const addClientEpic = (action$, store) => {
  return action$.ofType(ClientsAction.ADD_CLIENT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.client})
        .map(res => res.response.data)
        .map(addClientFulfilled)
        .catch(addClientRejected);
    });
};

const editClientEpic = (action$, store) => {
  return action$.ofType(ClientsAction.EDIT_CLIENT)
  // .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients/' + action.client.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers: headers, body: action.client})
        .map(res => res.response.data)
        .map(editClientFulfilled)
        .catch(editClientRejected);
    });
};

const deleteClientEpic = (action$, store) => {
  return action$.ofType(ClientsAction.DELETE_CLIENT)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients/' + action.clientId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'DELETE', headers: headers})
        .map(res => res.response)
        .map(() => deleteClientFulfilled(action.clientId))
        .catch(deleteClientRejected);
    });
};

const linkClientToUserEpic = (action$, store) => {
  return action$.ofType(ClientsAction.LINK_CLIENT_TO_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients/' + action.data.client_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers: headers, body: action.data})
        .map(res => res.response.users)
        .map(linkClientToUserFulfilled)
        .catch(linkClientToUserRejected);
    });
};

const linkClientToExportTypeEpic = (action$, store) => {
  return action$.ofType(ClientsAction.LINK_CLIENT_TO_EXPORT_TYPE)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'clients/' + action.data.client_id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers: headers, body: action.data})
        .map(res => res.response.exportTypes)
        .map(linkClientToExportTypeFulfilled)
        .catch(linkClientToExportTypeRejected);
    });
};

export {
  fetchClientsEpic,
  fetchClientEpic,
  addClientEpic,
  editClientEpic,
  deleteClientEpic,
  linkClientToUserEpic,
  linkClientToExportTypeEpic
};
