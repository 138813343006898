import React, { Component } from 'react';
import {EditorState, Modifier} from 'draft-js';
import {withTranslation} from 'react-i18next';
// import stylesEditor from '../style/mainEditorStyle.scss';
import {OrderedSet as orderedSet} from 'immutable';
import {getSelectionInlineStyle} from '../helper/helperFunctions';

@withTranslation(['translation_navigation'], {wait: true})
class ShyButton extends Component {
  constructor (props) {
    super(props);
    // const { entity } = this.props;
    // const state = {
    //   url: ''
    // };
  }

  onClick = e => {
    e.preventDefault();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    // const content = editorState.getCurrentContent();
    const currentInlineStyle = getSelectionInlineStyle(editorState);

    if (selectionState.isCollapsed() && !currentInlineStyle.NOBREAK) {
      const text = '|';
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'ZWSP', 'IMMUTABLE', {}
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const modifiedContent = Modifier.replaceText(
        contentState,
        selectionState,
        text,
        null,
        entityKey
      );
      const nextState = EditorState.push(
        editorState,
        modifiedContent,
        editorState.getLastChangeType()
      );
      const selectedEditorState = EditorState.forceSelection(
        nextState,
        modifiedContent.getSelectionAfter()
      );
      setEditorState(selectedEditorState);

      /* TOGGLE STYLE: */
      // _this.props.setEditorState(_draftJs.RichUtils.toggleInlineStyle(_this.props.getEditorState(), style));
    }
  };

  cannotAct = () => {
    const { getEditorState } = this.props;
    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    const currentInlineStyle = getSelectionInlineStyle(editorState);
    return !selectionState.isCollapsed() || currentInlineStyle.NOBREAK;
  };

  render () {
    let disabled = this.cannotAct();
    return (
      <span
        className="button shy-button"
        onMouseDown={this.onClick}
        title={this.props.description}
        data-disabled={disabled.toString()}
        data-active={''}
      >|</span>
    );
  }
}

export default ShyButton;
