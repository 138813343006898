import React from 'react';
import {withRouter} from 'react-router';

import {withReactRouterLink} from '../../utils/LinkWrapper';
import {ListItem, Button} from 'react-md';

import styles from '../../styles/basic_components.scss';

// wird nicht verwendet
const ActionableListItem = props => {
  /*
    const Item = withRouter(withReactRouterLink(ListItem));
    return (
        <Item {...props}  className={props.className ? props.className + ' ' + styles.list_item : styles.list_item}>
            <button>123</button>
        </Item>
    );
    */
  return (
    <ListItem
      {...props}
      className={props.className ? props.className + ' ' + styles.list_item : styles.list_item}
    >
      <button>123</button>
    </ListItem>
  );
};

// wird nicht verwendet
const ListItemLink = function (data) {
  const Item = withRouter(withReactRouterLink(ListItem));
  return (
    <Item
      {...data}
      className={data.className ? data.className + ' ' + styles.list_item : styles.list_item}
    />
  );
};

const NewButton = function (data) {
  const ButtonLink = withRouter(withReactRouterLink(Button));
  return <ButtonLink {...data} floating iconChildren="add"/>;
};

const UploadButton = (data) => {
  return (
    <Button
      className={styles.hoverRoundButton}
      name='uploadButton'
      onClick={data.onClick}
      title={data.title}
      icon
      iconChildren='file_upload'/>
  );
};

const DownloadButton = (data) => {
  return (
    <Button
      className={styles.hoverRoundButton}
      name='downloadButton'
      onClick={data.onClick}
      title={data.title}
      icon
      iconChildren='save_alt'/>
  );
};

const EditButton = (data) => {
  return (
    <Button
      className={styles.hoverRoundButton}
      name='editButton'
      onClick={data.onClick}
      title={data.title}
      icon
      iconChildren='mode_edit'/>
  );
};

const DeleteButton = (data) => {
  return (
    <Button
      className={[styles.hoverRoundButton, 'red-skin'].join(' ')}
      name='deleteButton'
      onClick={data.onClick}
      title={data.title}
      icon
      iconChildren='delete'/>
  );
};

const LoginAsButton = (data) => {
  return (
    <Button
      className={styles.hoverRoundButton}
      name='loginAsButton'
      disabled={data.disabled}
      onClick={data.onClick}
      title={data.title}
      icon
      iconChildren='supervised_user_circle'/>
  );
};

/*

lastInvitationInfoText: t('last_invitation_info'),
inviteNewUserText: t('invite_new_user'),
inviteExistingUserText: t('invite_existing_user'),
renewInvitationText: t('renew_invitation')

 */

const InviteButton = (data) => {
  let title = null;
  let iconName = null;

  if (data.invitedAt) {
    let lastInvitationInfo = ' (' + data.textsObject.lastInvitationInfoText + ': ' + data.invitedAt.substr(0, 10) + ')';

    if (data.lastApiAction) {
      title = data.textsObject.inviteExistingUserText + ' ' + lastInvitationInfo;
      iconName = 'mail_outline';
    } else {
      title = data.textsObject.renewInvitationText + ' ' + lastInvitationInfo;
      iconName = 'email';
    }
  } else {
    if (data.lastApiAction) {
      title = data.textsObject.inviteExistingUserText;
      iconName = 'mail_outline';
    } else {
      title = data.textsObject.inviteNewUserText;
      iconName = 'drafts';
    }
  }

  return (
    <Button
      className={styles.hoverRoundButton}
      name='editButton'
      onClick={data.onClick}
      icon
      title={title}
      iconChildren={iconName}/>
  );
};

const BackButton = () => {
  const LinkedButton = withRouter(withReactRouterLink(Button));
  return <LinkedButton iconChildren="keyboard_arrow_left" children="Back" raised back/>;
};

export {
  ActionableListItem,
  ListItemLink,
  NewButton,
  UploadButton,
  DownloadButton,
  DeleteButton,
  BackButton,
  EditButton,
  LoginAsButton,
  InviteButton
};
