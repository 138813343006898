


function getSelectedBlocksMap (editorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

function getSelectedBlocksList (editorState) {
  return getSelectedBlocksMap(editorState).toList();
}
/*
 * Function returns an object of inline styles currently applicable.
 * Following rules are applicable:
 * - styles are all false if editor is not focused
 * - if focus is at beginning of the block and selection is collapsed
 *     styles of first character in block is returned.
 * - if focus id anywhere inside the block and selection is collapsed
 *     style of a character before focus is returned.
 */

const inlineStylesArray = [
  'BOLD',
  'SUPERSCRIPT',
  'SUBSCRIPT',
  'NOBREAK'
];
export function getSelectionInlineStyle (editorState) {
  const currentSelection = editorState.getSelection();
  if (currentSelection.isCollapsed()) {
    const inlineStyles = {};
    const styleList = editorState.getCurrentInlineStyle().toList().toJS();
    if (styleList) {
      inlineStylesArray.forEach((style) => {
        inlineStyles[style] = styleList.indexOf(style) >= 0;
      });
      return inlineStyles;
    }
  }
  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();
  const selectedBlocks = getSelectedBlocksList(editorState);
  if (selectedBlocks.size > 0) {
    const inlineStyles = {
      BOLD: true,
      SUPERSCRIPT: true,
      SUBSCRIPT: true,
      NOBREAK: true
    };
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      let blockStart = i === 0 ? start : 0;
      let blockEnd =
        i === (selectedBlocks.size - 1) ? end : selectedBlocks.get(i).getText().length;
      if (blockStart === blockEnd && blockStart === 0) {
        blockStart = 1;
        blockEnd = 2;
      } else if (blockStart === blockEnd) {
        blockStart -= 1;
      }
      for (let j = blockStart; j < blockEnd; j += 1) {
        const inlineStylesAtOffset = selectedBlocks.get(i).getInlineStyleAt(j);
        inlineStylesArray.forEach((style) => {
          inlineStyles[style] = inlineStyles[style] && inlineStylesAtOffset.get(style) === style;
        });
      }
    }
    return inlineStyles;
  }
  return {};
}

