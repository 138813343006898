import { List, fromJS } from 'immutable';

import {SnapshotsAction} from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';
import {generateGuid} from '../utils/HelperFunctions';

const initialState = fromJS({
  snapshots: List(),
  fetchingSnapshots: ProcessStatus.INITIAL,
  addingSnapshot: ProcessStatus.INITIAL,
  editingSnapshot: ProcessStatus.INITIAL,
  deletingSnapshot: ProcessStatus.INITIAL
});
let index = 0;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SnapshotsAction.FETCH_SNAPSHOTS:
      return state
        .set('fetchingSnapshots', ProcessStatus.STARTED);
    case SnapshotsAction.FETCH_SNAPSHOTS_FULFILLED:
      return state
        .set('snapshots', List(action.snapshots))
        .set('fetchingSnapshots', ProcessStatus.FINISHED);
    case SnapshotsAction.FETCH_SNAPSHOTS_REJECTED:
      return state
        .set('fetchingSnapshots', ProcessStatus.FAILED)
        .set('snapshots', List());
    case SnapshotsAction.ADD_SNAPSHOT:
      return state.set('addingSnapshot', ProcessStatus.STARTED);
    case SnapshotsAction.ADD_SNAPSHOT_FULFILLED:
      return state
        .set('addingSnapshot', ProcessStatus.FINISHED)
        .set('snapshots', state.get('snapshots').push(action.snapshot));
    case SnapshotsAction.ADD_SNAPSHOT_REJECTED:
      return state.set('addingSnapshot', ProcessStatus.FAILED);
    case SnapshotsAction.EDIT_SNAPSHOT:
      return state.set('editingSnapshot', ProcessStatus.STARTED);
    case SnapshotsAction.EDIT_SNAPSHOT_FULFILLED:
      index = state.get('snapshots').findIndex(function (snapshot) {
        return snapshot.id === action.snapshot.id;
      });
      if (index > -1) {
        return state
          .set('editingSnapshot', ProcessStatus.FINISHED)
          .set('snapshots', state.get('snapshots').set(index, action.snapshot))
          .set('updateToken', generateGuid());
      }
      return state.set('editingSnapshot', ProcessStatus.FINISHED);
    case SnapshotsAction.EDIT_SNAPSHOT_REJECTED:
      return state.set('editingSnapshot', ProcessStatus.FAILED);
    case SnapshotsAction.DELETE_SNAPSHOT:
      return state.set('deletingSnapshot', ProcessStatus.STARTED);
    case SnapshotsAction.DELETE_SNAPSHOT_FULFILLED:
      state = state.set('deletingSnapshot', ProcessStatus.FINISHED);
      index = state.get('snapshots').findIndex(function (obj) {
        return obj.id === action.snapshotId;
      });
      if (index > -1) {
        state = state.set('snapshots', state.get('snapshots').delete(index));
      }
      return state;
    case SnapshotsAction.DELETE_SNAPSHOT_REJECTED:
      return state.set('deletingSnapshot', ProcessStatus.FAILED);

    default:
      return state;
  }
};

export { initialState, reducer as snapshots };
