import ReactDOM from 'react-dom';
// eslint-disable-next-line no-unused-vars
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const  PortalWindowHooks = props => {
  const {t} = useTranslation('general');

  // STEP 1: create a container <div>
  const containerEl = document.createElement('div');
  let externalWindow = null;
  let checkIfOpenInterval = true;
  let isPopupBlockerActive = null;
  let child = null;

  const left = (screen.width - 950) / 2;
  const top = (screen.height - 600) / 2;
  let strWindowFeatures = null;
  if (props.collectionId) {
    strWindowFeatures =
      'menubar=no,titlebar=no,toolbar=no,directories=no,location=no,personalbar=no,resizable,top=' +
      top +
      ',left=' +
      left +
      ',width=950,height=600,collection=' +
      props.collectionId;
  } else {
    strWindowFeatures =
      'menubar=no,titlebar=no,toolbar=no,directories=no,location=no,personalbar=no,resizable,top=' +
      top +
      ',left=' +
      left +
      ',width=950,height=600';
  }

  const checkForPopupBlocker = () => {
    return (!externalWindow ||
      externalWindow.closed ||
      typeof externalWindow.closed === 'undefined');
  };

  const checkIfOpen = () => {
    if (externalWindow.closed) {
      props.onWindowClosed();
      clearInterval(checkIfOpenInterval);
    }
  };

  useEffect(() => {
    // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
    externalWindow = window.open(
      '/projects/translationtool/' + props.translationViewId,
      '',
      strWindowFeatures
    );
    isPopupBlockerActive = checkForPopupBlocker();

    // STEP 3: open a new browser window and store a reference to it
    if (isPopupBlockerActive) {
      // TODO: SHOW WARNING "POPUP BLOCKER"
      window.alert(t('popup_blocker_warning'));
    } else {
      checkIfOpenInterval = setInterval(checkIfOpen, 500);
    }

    props.resetSafeClosingInitiation();
    child = React.cloneElement(props.children, {
      parentwindow: externalWindow.document.body
    });

    return () => {
      if (externalWindow) {
        externalWindow.close();
      }
    };
  }, []);

  return isPopupBlockerActive ? null : ReactDOM.createPortal(child, containerEl);
};

export default PortalWindowHooks;
