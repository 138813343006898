import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {SelectField, FontIcon} from 'react-md';
import Radiobar from '../../../utils/RadioBar';
import {
  fetchSourceSnapshots,
  setSourceSnapshotId
} from '../../../actions/sourceSnapshots';
import {editUserSettings} from '../../../actions/auth';
import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import styles from '../../../styles/projects/translationoverview/general.scss';
import {withTranslation} from 'react-i18next';
import {aclTranslation} from '../../../utils/Permissions';
import {aclFilter} from '../../../utils/ACL';

@withTranslation(['project', 'general'], {wait: true})
class SourceLanguage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sourceSnapshots: [],
      sourceSnapshotDescription: null,
      sourceSnapshotInfoOpen: false,
      sourceLanguageId: 0
    };
  }

  componentWillMount () {
    const {sourceLanguageId} = this.props;
    this.setNewSourceId(
      sourceLanguageId
        ? sourceLanguageId
        : this.props.match.params.translationId
    );
    this.initUserSettings(this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {sourceSnapshots, fetchingSourceSnapshots, sourceLanguageId, sourceSnapshotId, snapshots} = nextProps;

    if (sourceLanguageId) { // Wird manchmal 'undefined'
      if (sourceLanguageId !== this.state.sourceLanguageId) {
        this.setNewSourceId(nextProps.sourceLanguageId);
      }
    } else {
      if (snapshots != this.props.snapshots) {
        let snapshot = snapshots.find(snapshot => snapshot.id == sourceSnapshotId);
        let snapshotId = snapshot ? sourceSnapshotId : -1;

        this.setNewSourceId(nextProps.match.params.translationId, snapshotId);
      }
    }

    if (fetchingSourceSnapshots == ProcessStatus.FINISHED
      && sourceSnapshots != this.props.sourceSnapshots) {
      this.setState({sourceSnapshots: sourceSnapshots.toArray()});
      this.initUserSettings(nextProps);
    }

    if (sourceSnapshotId != this.props.sourceSnapshotId) {
      let sourceSnapshot = this.state.sourceSnapshots
        .find(sourceSnapshot => sourceSnapshot.id == sourceSnapshotId);
      let description = sourceSnapshot ? sourceSnapshot.description : null;
      this.setState({sourceSnapshotDescription: description});
    }
  }

  formatSourceSnapshotOptions = sourceSnapshots => {
    return sourceSnapshots.map(sourceSnapshot => {
      return {
        label: sourceSnapshot.created_at.substr(0, 10)
          + ' | ' + sourceSnapshot.id + ' - ' + sourceSnapshot.name,
        value: sourceSnapshot.id
      };
    });
  };

  handleSourceSnapshotChange = value => {
    this.editUserSettings(value);
    this.props.setSourceSnapshotId(value);
  };

  initUserSettings = (props) => {
    const {
      match: {
        params: {
          projectId,
          translationId
        }
      },
      sourceLanguageId,
      sourceSnapshots,
      user,
      impersonatedUser
    } = props;

    let currentUser = impersonatedUser ? impersonatedUser : user;
    let sourceId = sourceLanguageId ? sourceLanguageId : translationId;

    let sourceSnapshotIdFromSettings = _.get(currentUser,
      `settings.projects.project_${projectId}.translation_${translationId}.source_${sourceId}.snapshot`);

    if (sourceSnapshotIdFromSettings) {
      this.props.setSourceSnapshotId(sourceSnapshotIdFromSettings);
    } else {
      if (sourceSnapshots.size > 0) {
        let latestSourceSnapshot = sourceSnapshots.get(-1);
        this.props.setSourceSnapshotId(latestSourceSnapshot.id);
      }
    }
  };

  editUserSettings = snapshotId => {
    const {
      match: {
        params: {
          projectId,
          sourceLanguageId,
          translationId
        }
      },
      user,
      impersonatedUser
    } = this.props;

    let userToEdit = impersonatedUser ? impersonatedUser : user;
    let sourceId = sourceLanguageId ? sourceLanguageId : translationId;

    let projects = {
      projects: {
        [`project_${projectId}`]: {
          last_visited: Date.now(),
          [`translation_${translationId}`]: {
            last_visited: Date.now(),
            [`source_${sourceId}`]: {
              last_visited: Date.now(),
              snapshot: snapshotId
            }
          }
        }
      }
    };

    let settings = _.merge(userToEdit.settings, projects);
    this.props.editUserSettings(userToEdit.id, settings);
  };

  sourceSnapshotInfoClick = () => {
    this.setState({sourceSnapshotInfoOpen: !this.state.sourceSnapshotInfoOpen});
  };

  sortDescending = (a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  };

  setNewSourceId = (sourceId, sourceSnapshotId = -1) => {
    this.setState({
      sourceLanguageId: sourceId
    });
    const projectId = this.props.translation.get('project_id');

    this.props.fetchSourceSnapshots(projectId, sourceId, sourceSnapshotId);
  };

  radioBarChange = index => {
    const {sources} = this.props;
    this.setNewSourceId(sources[index].value);
    this.props.radioBarChange(sources[index].value);
  };

  renderSourceLanguage () {
    const {
      sources,
      radioName,
      parentId,
      sourceLanguageId,
      t
    } = this.props;

    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let activeElement = sources.findIndex(child => child.value == sourceLanguageId);
    activeElement = activeElement === -1 ? 0 : activeElement;

    if (sources !== null) {
      return (
        <div style={{width: '100%'}}>
          <h4
            key="3"
            className={'sourceLanguageHeadlineNoMargin'}
          >{ t('source_language') }{sources.length > 0 && <span>s</span>}</h4>
          {parentId === null &&
          <p>{ t(`${generalLNS}:information`) }: { t('this_is_master_language') }</p>
          }
          {parentId !== null && sources.length === 0 &&
          <p>{ t(`${generalLNS}:information`) }: { t('please_add_source_language') }<br/></p>
          }
          {sources.length === 1 &&
          <p>{ t(`${generalLNS}:information`) }: { t('translation_has_only_one_source_language') }<br/></p>
          }
          {sources.length > 1 &&
          <p>&nbsp;({ t('please_select_source_language') })</p>
          }
          {sources.length > 0 &&
          <Radiobar
            key="4"
            onChange={this.radioBarChange}
            children={sources}
            sourceLanguageId={sourceLanguageId}
            radioName={radioName}
            activeElement={activeElement}
          />
          }
        </div>
      );
    }
  }

  renderSourceSnapshots () {
    const {
      fetchingSourceSnapshots,
      sources,
      sourceLanguageId,
      sourceSnapshotId,
      t
    } = this.props;

    let activeElement = sources.findIndex(child => child.value == sourceLanguageId);
    activeElement = activeElement === -1 ? 0 : activeElement;

    const {
      sourceSnapshots,
      sourceSnapshotDescription,
      sourceSnapshotInfoOpen
    } = this.state;

    if (fetchingSourceSnapshots === 1) {
      return (
        <LoadingBar show={fetchingSourceSnapshots === 1}/>
      );
    }

    let head = <h4 key="3" className={'sourceLanguageHeadlineNoMargin'}>
      <span>{ t('source_snapshot') }</span>
    </h4>;

    let formattedSourceSnapshots = sourceSnapshots;

    if (sourceSnapshots.length > 0) {
      formattedSourceSnapshots = this.formatSourceSnapshotOptions(sourceSnapshots)
        .sort((a, b) => this.sortDescending(a, b));
      formattedSourceSnapshots.unshift({label: 'None', value: -1});

      if (fetchingSourceSnapshots !== ProcessStatus.STARTED) {
        return (
          <div>
            {head}
            <div className={'sourceSnapshotSelectWrapper'}>
              <SelectField
                className={'sourceSnapshotSelect'}
                id="sourceSnapshots"
                menuItems={formattedSourceSnapshots}
                value={sourceSnapshotId}
                dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
                onClick={() => {
                  if (sourceSnapshotInfoOpen) {
                    this.sourceSnapshotInfoClick();
                  }
                }}
                helpText={t('source_snapshot_help_text') }
                onChange={this.handleSourceSnapshotChange}
              />
              {sourceSnapshotDescription && (
                <button
                  className={'sourceSnapshotInfoButton'}
                  data-open={sourceSnapshotInfoOpen}
                  onClick={this.sourceSnapshotInfoClick}
                >
                  <FontIcon>info</FontIcon>
                  <span className={'sourceSnapshotDescription'}>{sourceSnapshotDescription}</span>
                </button>
              )}
            </div>
          </div>
        );
      }
    } else {
      if (sources.length > 0 && sources[activeElement]) {
        return (
          <div>
            {head}
            <p>{ t('no_source_snapshots_found_for') } {sources[activeElement].text}.</p>
          </div>
        );
      }
      return null;
    }
  }

  render () {
    return (
      <div className={styles.sourceFlexRow + ' borderTop'}>
        <div className={'innerBoxHeadline'} help-tool="3">
          {this.renderSourceLanguage()}
        </div>
        {aclFilter(
          <div className={'sourceSnapshotWrapper innerBoxHeadline'} help-tool="4">
            {this.renderSourceSnapshots()}
          </div>
        )([], [aclTranslation.read_source_snapshots])}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslationStatistics: state.getIn(['projects', 'fetchingTranslationStatistics']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation']),
    sourceSnapshots: state.getIn(['sourceSnapshots', 'sourceSnapshots']),
    snapshots: state.getIn(['snapshots', 'snapshots']),
    fetchingSourceSnapshots: state.getIn(['sourceSnapshots', 'fetchingSourceSnapshots']),
    sourceSnapshotId: state.getIn(['sourceSnapshots', 'sourceSnapshotId']),
    user: state.getIn(['auth', 'user']),
    impersonatedUser: state.getIn(['auth', 'impersonatedUser'])
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    fetchSourceSnapshots,
    setSourceSnapshotId,
    editUserSettings
  }
)(SourceLanguage));
