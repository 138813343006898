import React from 'react';
import { LinearProgress } from 'react-md';

import styles from '../../styles/app.scss';
export default props => (
  <div
    className={
      props.show
        ? props.wrapperClassname + ' ' + styles.progressWrapperGeneral
        : props.wrapperClassname + ' ' + styles.progressWrapperGeneral + ' ' + styles.displayNone
    }>
    <LinearProgress
      id={props.id ? props.id : 'linear-progress'}
      className={
        props.progressClassName
          ? props.progressClassName + ' ' + styles.progressGeneral
          : styles.progressGeneral
      }
      query={props.direction === 'rtl'}
    />
    <p style={{ display: props.message ? 'block' : 'none' }} className={styles.progressMessage}>
      {props.message}
    </p>
  </div>
);
