import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';
import styles from '../../styles/action_response_button.scss';

@withTranslation(['translation_navigation'], {wait: true})
class ActionResponseButton extends Component {
  constructor (props) {
    super(props);
    this.clicked = false;
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.finished !== nextProps.finished && nextProps.finished) {
      setTimeout(() => {
        this.clicked = false;
        this.forceUpdate();
      }, 1000);
    }
  }

  onClick = e => {
    const { type } = this.props;
    this.clicked = true;
    if (type === 'submit') {
      return;
    }
    e.preventDefault();
    this.props.onClick();
  };

  renderIcon () {
    const {
      clicked
    } = this;
    const {
      defaulticon,
      loading,
      finished
    } = this.props;

    if (clicked) {
      if (finished) {
        return (<FontIcon className="">check</FontIcon>);
      } else if (loading) {
        return (<span className='loading'><span /></span>);
      }
    } else {
      if (defaulticon) {
        return (<FontIcon>{defaulticon}</FontIcon>);
      }
    }
  }

  render () {
    const {
      text,
      defaulticon,
      title,
      deactivated,
      type,
      loading
    } = this.props;

    let classNameAdditional = '';
    if (defaulticon) {
      classNameAdditional += ' has-icon';
    }
    if (loading) {
      classNameAdditional += ' loading';
    }
    if (this.clicked) {
      classNameAdditional += ' clicked';
    }

    let deactive = deactivated;

    return (
      <button
        className={styles.actionResponseButton + classNameAdditional}
        onClick={this.onClick}
        type={type ? type : 'button'}
        title={title}
        data-disabled={deactive.toString()}
      >
        <span className="text">{text}</span>
        { this.renderIcon() }
      </button>
    );
  }
}

export default ActionResponseButton;
