import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {ProcessStatus} from '../../../utils/Constants';

import {fetchTranslationStatistics} from '../../../actions/projects';
import styles from '../../../styles/projects/translationoverview/general.scss';

@withTranslation(['project', 'general'], {wait: true})
class TranslationStatistics extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    const {projectId, translationId} = this.props;
    this.props.fetchTranslationStatistics(projectId, translationId);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {projectId, translationId, settingForceApproval} = nextProps;

    if (translationId != this.props.translationId ||
      settingForceApproval == ProcessStatus.FINISHED &&
      settingForceApproval != this.props.settingForceApproval) {
      this.props.fetchTranslationStatistics(projectId, translationId);
    }
  }


  render () {
    const {translationStatistics, t} = this.props;

    return (
      <div>
        <h4>{ t('screen_text') }</h4>
        <div className={styles.progressWithText}>
          <span>
            <span>{ t('translated') }: </span>
            <span>{translationStatistics.translated_percentage}%</span>
          </span>
          {/* <div>*/}
          {/* <LinearProgress id="progressTranslated" value={translationStatistics.translated_percentage}/>*/}
          {/* </div>*/}
        </div>
        <div className={styles.progressWithText}>
          <span>
            <span>{ t('approved') }: </span>
            <span>{translationStatistics.approved_percentage}%</span>
          </span>
          {/* <div>*/}
          {/* <LinearProgress id="progressApproved" value={translationStatistics.approved_percentage}/>*/}
          {/* </div>*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslationStatistics: state.getIn(['projects', 'fetchingTranslationStatistics']),
    translationStatistics: state.getIn(['projects', 'translationStatistics']),
    settingForceApproval: state.getIn(['projects', 'settingForceApproval'])
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    fetchTranslationStatistics
  }
)(TranslationStatistics));
