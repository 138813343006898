import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';
import RichTextEditor from './RichTextEditor';
import RichTextEditorDraft from './RichTextEditorDraft';
import { parseCode, parseHTML, stripAllTags } from '../../utils/RTEParser';
import {Button, FontIcon, Switch} from 'react-md';
import LoadingBar from '../general/DumbComponent';
import { saveTranslation, setEditingInitialStatus } from '../../actions/translationTool';
import { showNotification } from '../../actions/notifications';
import { ProcessStatus } from '../../utils/Constants';
import styles from '../../styles/translationTool/translationTool.scss';
import {aclFilter} from '../../utils/ACL';
import {convertToRaw} from 'draft-js';
import {convertFromHTML} from './draftEditorAdditional/convert/convert';
import {importerConfig} from './draftEditorAdditional/ImporterExporterConfigs';
// import rules from './rules';

function mapStateToProps (state) {
  return {
    editingTranslation: state.getIn(['translationTool', 'editingTranslation']),
    savedTranslation: state.getIn(['translationTool', 'savedTranslation'])
  };
}

@connect(
  mapStateToProps,
  {saveTranslation, setEditingInitialStatus, showNotification}
)

@withTranslation(['translation', 'general'], {wait: true})
class ContentTools extends Component {
  constructor (props) {
    super(props);
    this.state = {
      initContent: parseCode(this.props.content),
      content: parseCode(this.props.content),
      contentForDraft: this.parseInitialTextForDraft(parseCode(this.props.content)),
      saved: false,
      savePending: false,
      rawContent: null,
      isWindowClosePrevented: false,
      editorDraft: true, // Switch zwischen beiden Editoren
      editorHasChanges: false
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {savedTranslation, collectionId, textId} = nextProps;


    if (savedTranslation && savedTranslation.section === collectionId && savedTranslation.id === textId) {
      this.setState({content: nextProps.content, saved: true, savePending: false},
        this.props.setEditingInitialStatus()
      );
      this.props.hideRTE();
    }


    switch (nextProps.editingTranslation) {
      case ProcessStatus.FINISHED:
        break;
      case ProcessStatus.FAILED:
        this.editableField = '';
        // this.props.showNotification(NotificationType.ERROR, 'EditTranslationFailure');
        break;
      default:
        break;
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // return nextState.rawContent == this.state.rawContent;
    return false;
  }

  handleContentChange = content => {
    // console.log('handleContentChange:: content = ', content);

    if (!this.state.isWindowClosePrevented) {
      // TODO: FÜR WAS BRAUCHE ICH isWindowClosePrevented
    }

    let processedContent = this.processRawContent(content);
    let preparsed = this.preParseTextForDraft(this.state.initContent);
    if (processedContent === preparsed) {
      this.editorSetHasNoChanges();
    } else {
      this.editorSetHasChanges();
    }
    this.setState({rawContent: content, isWindowClosePrevented: true});
  };

  cancelContentChange = () => {
    this.setState({isWindowClosePrevented: false}, this.props.hideRTE);
  };

  processRawContent = (content) => {
    // var searchRegExp = /(<\/p>)((.|\n)*?)(<p>)/g;
    // var replacementString = '$2<br />';
    // var replacedString = content.replace(searchRegExp, replacementString);

    var regexWithUl = /<\/p><p>/g;
    var replacedString = content.replace(regexWithUl, '<br/>');

    var regexP = /<p>/g;
    var regexPend = /<\/p>/g;
    replacedString = replacedString.replace(regexP, '');
    replacedString = replacedString.replace(regexPend, '');

    var regexP2 = /<br\/>/g;
    replacedString = replacedString.replace(regexP2, '<br>');

    return replacedString;
  };

  saveTranslation = () => {
    const rawContent = this.state.rawContent;
    // console.log('0. saveTranslation, this.state.rawContent', rawContent);

    if (rawContent === null) {
      this.props.hideRTE();
    } else {
      const {collectionId, textId, translationId, sourceLanguageId} = this.props;
      // console.log('00 rawContent', rawContent);
      const strippedContent = stripAllTags(rawContent);
      const content = this.processRawContent(strippedContent);
      // const strippedContent = stripAllTags(content);
      const parsedHTML = parseHTML(content);
      const textsForLocalStorage = {[collectionId]: {[textId]: content}};

      // console.log('1. strippedContent', strippedContent);
      // console.log('2. content', content);
      // console.log('3. parsedHTML', parsedHTML);
      // console.log('4. textsForLocalStorage', textsForLocalStorage);


      this.props.saveTranslation(
        {
          collection: collectionId,
          target_translation_id: translationId,
          source_translation_id: sourceLanguageId,
          text: parsedHTML,
          textsForLocalStorage: textsForLocalStorage,
          textfield_id: textId
        }
      );
      this.setState({rawContent: rawContent, isWindowClosePrevented: false});
    }
  };

  changeEditor = () => {
    this.setState({editorDraft: !this.state.editorDraft});
    // this.forceUpdate();
  };

  fromHTML = (html) => convertToRaw(convertFromHTML(importerConfig)(html));

  replaceRecursive = (string, pattern, replacement) => {
    let returnString = string;
    let depthCount = 0;
    while (pattern.test(returnString)) {
      depthCount++;
      returnString = returnString.replace(pattern, replacement);
    }
    console.warn('Parsed LISTs, depth: ', depthCount);
    return returnString;
  };

  preParseTextForDraft = initialText => {
    // Line-Breaks-Vorparsen:
    let newText = initialText
      .replace(/<br>|<br\/>|<br \/>/g, '\n')
      .replace(/(\r\n?|\n)/g, '<br>')
      .replace(/<br><ul>|<br\/><ul>|<br \/><ul>/g, '<br><br><ul>')
    ;

    // LISTEN-umparsen:
    // <li>((?!<\/li)[\s\S])*<ul> // regex Ausschluss
    let listRegex = /<li>(((?!<ul>|<\/li>)[\s\S])*)(((?!<\/li>)[\s\S])*(<ul>[\s\S]*<\/ul>)([\s\S]*))+(<\/li>)/g;
    let newText2 = this.replaceRecursive(newText, listRegex, '<li>$1</li>$3');

    return newText2;
  };

  parseInitialTextForDraft = initialText => {
    return this.fromHTML(this.preParseTextForDraft(initialText));
  };

  editorSetHasChanges = () => {
    console.log('editorSetHasChanges');
    this.setState({
      editorHasChanges: true
    }, () => {
      console.log('this.state.editorHasChanges', this.state.editorHasChanges);
      // this.forceUpdate();
    });
  };

  editorSetHasNoChanges = () => {
    console.log('editorSetHasChanges NOOOOOO');
    this.setState({
      editorHasChanges: false
    }, () => {
      console.log('this.state.editorHasChanges', this.state.editorHasChanges);
      // this.forceUpdate();
    });
  };

  render () {
    const {editingTranslation, t} = this.props;
    const {savePending, editorDraft} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (editingTranslation === ProcessStatus.STARTED && this.props.id === this.editableField) {
      return <LoadingBar show="true"/>;
    }

    console.log('RENDER CONTENTTOOLS this.state.editorHasChanges', this.state.editorHasChanges);

    return (
      <div
        className={styles.contentTools +  ' rightContent orangeBorder rteEditorStyles'}
        style={{
          padding: editorDraft ? '0px' : '5px'
        }}
      >
        <FontIcon
          className={'unsavedFlag'}
          title={ t(`${generalLNS}:information`) + ': ' + t('unsaved_content')}>turned_in</FontIcon>
        {!editorDraft &&
          <RichTextEditor
            initialText={this.state.content}
            rawCode={this.props.content}
            saveTranslation={this.saveTranslation}
            cancelContentChange={this.cancelContentChange}
            handleContentChange={this.handleContentChange}
          />
        }
        {editorDraft &&
          <RichTextEditorDraft
            initialText={this.state.content}
            initialContextForDraft={this.state.contentForDraft}
            rawCode={this.props.content}
            saveTranslation={this.saveTranslation}
            cancelContentChange={this.cancelContentChange}
            handleContentChange={this.handleContentChange}
            savePending={savePending}
            editorSetHasChanges={this.editorSetHasChanges}
            editorSetHasNoChanges={this.editorSetHasNoChanges}
            editorHasChanges={this.state.editorHasChanges}
          />
        }
        <div className={'rteButtonBar'}>
          <div style={{display: 'flex', flexGrow: '0'}}>
            {/*
            {aclFilter(
              <div style={{display: 'flex'}}>
                <Button
                  className={'switch editors'}
                  style={{
                    minWidth: '40px',
                    width: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                  title={'change editor version 1.0 <-> 2.0'}
                  icon
                  onClick={this.changeEditor}
                >swap_horizontal</Button>
                <span
                  style={{
                    height: '30px',
                    lineHeight: '30px',
                    fontSize: '11px'
                  }}>{editorDraft ? 'editor: 2.0 (draft)' : 'editor: 1.0 (slate)'}</span>
              </div>
            )(['admin'])}
            */}
          </div>
          <div style={{display: 'flex'}}>
            <Button
              className={'cancelButton'}
              icon
              title={ t('cancel_edit_mode') + '\n\n ' + t('or_press_esc')}
              onClick={this.cancelContentChange}
            >close</Button>
            {this.state.editorHasChanges &&
              <Button
                className={'checkButton'}
                icon
                disabled={savePending}
                title={t(`${generalLNS}:save_all_edits`)}
                onClick={this.saveTranslation}
              >{savePending ? 'sync' : 'check'}</Button>
            }
          </div>
        </div>
      </div>
    );
  }
}

ContentTools.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textId: PropTypes.string,
  collectionId: PropTypes.string,
  source: PropTypes.object
};

export default ContentTools;
