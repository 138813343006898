import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import {
  closeStoryboardQuestionDialog
} from '../../actions/storyboard';
import styles from '../../styles/storyboards/questionDialog.scss';
import {convertDraftRawToHtmlString} from "./editor/helper/convertingForEditorWorks";

export default function StoryboardQuestionDialog () {
  const questionDialogObject = useSelector(state => state.getIn(['storyboard', 'questionDialogObject']));
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeStoryboardQuestionDialog(false));
  };

  const handleYes = () => {
    if (questionDialogObject.yesBtn.hasOwnProperty('actionFunction')) {
      questionDialogObject.yesBtn.actionFunction();
    }
    handleClose();
  };

  const handleNo = () => {
    if (questionDialogObject.noBtn.hasOwnProperty('actionFunction')) {
      questionDialogObject.noBtn.actionFunction();
    }
    handleClose();
  };

  return (
    <>
      <Dialog
        open={questionDialogObject !== null}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.questionDialog}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 'auto'
          },
          square: true,
          elevation: 4
        }}
      >
        {
          questionDialogObject !== null &&
          <>
            <DialogTitle id="alert-dialog-title">{questionDialogObject.title}</DialogTitle>
            { questionDialogObject.hasOwnProperty('text') &&
              <DialogContent>
                <p
                  className="MuiTypography-root
                   MuiDialogContentText-root MuiTypography-body1 MuiTypography-colorTextSecondary"
                  id="alert-dialog-description"
                >
                  {questionDialogObject.text}
                </p>
              </DialogContent>
            }
            <DialogActions>
              <Button onClick={handleNo} color="primary">{questionDialogObject.noBtn.text}</Button>
              <Button onClick={handleYes} color="primary" autoFocus>{questionDialogObject.yesBtn.text}</Button>
            </DialogActions>
          </>
        }
      </Dialog>
    </>
  );
}
