import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import {renderTextField} from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
import {formatRoleOptions, sortAlphabetically} from '../../../utils/ChipHelperFunctions';
import {fetchRoles} from '../../../actions/roles';

@withTranslation(['clients', 'general'], {wait: true})
class ClientPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      max: 400,
      roleOptions: null
    };
  }

  componentWillMount () {
    const {roles} = this.props;
    this.safeUpdate(this.props);

    if (roles.size == 0) {
      this.props.fetchRoles();
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.safeUpdate(nextProps);
  }

  safeUpdate = props => {
    const {roles} = props;
    if (roles.size > 0) {
      let roleOptions = formatRoleOptions(roles)
        .toArray()
        .sort((a, b) => sortAlphabetically(a, b));

      this.setState({
        roleOptions: roleOptions
      });
    }
  };

  render () {
    const {editMode, t} = this.props;
    const {roleOptions} = this.state;
    const generalLNS = 'general'; // clientsLanguageNamespaceSource

    if (roleOptions == null) {
      return <LoadingBar show="true" message={ t(`${generalLNS}:loading_bar_information`)}/>;
    }

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 className={'headName'}>{ t(`${generalLNS}:information`)}</h2>
        </div>
        <div className="editableElements">
          <Field
            id="name"
            name="name"
            label={ t(`${generalLNS}:name`)}
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id="parent_id"
            name="parent_id"
            label={ t(`${generalLNS}:parent_id`)}
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            component={renderTextField}
          />
          <Field
            id="description"
            name="description"
            label={ t(`${generalLNS}:description`)}
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id="logo"
            name="logo"
            label={ t('logo') }
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            component={renderTextField}
          />
        </div>

        <div className="notEditableElements">
          <Field
            id="id"
            name="id"
            label={ t(`${generalLNS}:id`)}
            type="text"
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    roles: state.getIn(['roles', 'roles'])
  };
}

export default connect(
  mapStateToProps,
  {fetchRoles, formatRoleOptions}
)(ClientPrime);
