import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import {withTranslation} from 'react-i18next';
import { renderTextField, renderSelectField } from '../../general/BasicFormComponents';
import LoadingBar from '../../general/DumbComponent';
import { fetchProjectEnumDefaults } from '../../../actions/enumDefaults';
import { fetchClients } from '../../../actions/clients';
import ObjectAutocomplete from '../../general/ObjectAutocomplete';
import { truequired } from '../../../utils/ValidationRules';
import { sortAlphabetically } from '../../../utils/ChipHelperFunctions';


@withTranslation(['project', 'general'], {wait: true})
class CollectProjectInformation extends Component {
  constructor (props) {
    super(props);

    this.state = {
      clientOptions: null
    };
  }

  componentWillMount () {
    this.props.fetchProjectEnumDefaults();
    this.props.fetchClients(true);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {clients} = nextProps;

    console.log('componentWillReceiveProps:: nextProps = ', nextProps);

    if (clients) {
      this.update(clients);
    }
  }

  update = clients => {
    let clientOptions = this.formatClientOptions(clients)
      .toArray()
      .sort((a, b) => sortAlphabetically(a, b));
    this.setState({clientOptions: clientOptions});
  };

  formatClientOptions = clients => {
    return clients.map(client => {
      // return {value: client.id, label: client.name, disabled: false}
      return {value: client.id, label: client.name, disabled: false};
    });
  };

  render () {
    const {enumDefaults, clients, t} = this.props;
    const {clientOptions} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource


    if (enumDefaults.size == 0 || clients.size == 0 || clientOptions == null) {
      return <LoadingBar show="true"/>;
    }

    let typeLabels = enumDefaults.get('type');
    let engineTypeLabels = enumDefaults.get('engine_type');
    let textScopeLabels = enumDefaults.get('text_scope');

    // let clientsLabelsList = clients.map(client => {return ({id: client.id, name: client.name});});
    // let clientsLabels = clientsLabelsList.toArray();

    return (
      <div className={'md-grid'}>
        <Field
          id="name"
          name="name"
          label={ t(`${generalLNS}:name`) }
          type="text"
          className="md-cell  md-cell--12"
          required
          errorText={ t('enter_name_error') }
          component={renderTextField}
        />
        <Field
          id="description"
          name="description"
          label={ t(`${generalLNS}:description`) }
          type="text"
          className="md-cell md-cell--12"
          errorText={ t('enter_description_error') }
          component={renderTextField}
        />

        <Field
          id="client_id"
          name="client_name"
          label={ t(`${generalLNS}:parent_company`) }
          helpText={ t('select_parent_company_help') }
          // errorText="You have to select a parent company"
          // errorTextForNotFound="This is not a valid parent company"
          // errorTextForDisabled="This code is already used"
          // value={values && values.client ? values.client_id : null}
          // value={values ? values.client_id : null}
          changeValues={this.props.form.mutators.changeValues}
          // options={clientsLabels}
          options={clientOptions}
          match={'label'}
          validate={truequired}
          required
          component={ObjectAutocomplete}
        />

        <Field
          id="type"
          name="type"
          label={ t(`${generalLNS}:type`) }
          type="text"
          className="md-cell md-cell--12"
          menuItems={typeLabels}
          component={renderSelectField}
        />
        <Field
          id="engine_type"
          name="engine_type"
          label={ t(`${generalLNS}:engine_type`) }
          type="text"
          className="md-cell md-cell--12"
          menuItems={engineTypeLabels}
          component={renderSelectField}
        />
        <Field
          id="text_scope"
          name="text_scope"
          label={ t(`${generalLNS}:text_scope`) }
          type="text"
          className="md-cell md-cell--12"
          menuItems={textScopeLabels}
          component={renderSelectField}
        />
      </div>
    );
  }
}

function mapStateToProps (state, props) {
  console.log('mapStateToProps, props = ', props);

  return {
    enumDefaults: state.getIn(['enumDefaults', 'projectEnumDefaults']),
    clients: state.getIn(['clients', 'clients'])
  };
}

export default connect(
  mapStateToProps,
  {fetchProjectEnumDefaults, fetchClients}
)(CollectProjectInformation);
