import { fromJS } from 'immutable';

import { ProfileAction } from '../utils/ActionTypes';
import { ProcessStatus } from '../utils/Constants';

const initialState = fromJS({
  pristine: true,
  profile: null,
  editingProfile: ProcessStatus.INITIAL
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ProfileAction.EDIT_PROFILE_INITIATED:
      return state.set('editingProfile', ProcessStatus.INITIAL).set('pristine', false);
    case ProfileAction.EDIT_PROFILE:
      return state.set('editingProfile', ProcessStatus.STARTED).set('pristine', false);
    case ProfileAction.EDIT_PROFILE_FULFILLED:
      return state.set('editingProfile', ProcessStatus.FINISHED).set('profile', action.profile);
    case ProfileAction.EDIT_PROFILE_REJECTED:
      return state.set('editingProfile', ProcessStatus.FAILED).set('error', action.payload);
    default:
      return state;
  }
};

export { initialState, reducer as profile };
