import { ErrorDialogAction } from '../utils/ActionTypes';

const removeDialog = identifier => {
  return {
    type: ErrorDialogAction.REMOVE_ERROR_DIALOG,
    identifier: identifier
  };
};

export { removeDialog };
