import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ExpansionList } from 'react-md';
import UserPanel from '../translationoverview/UserPanel';
import styles from '../../../styles/projects/translationoverview/general.scss';

class TranslationUsers extends Component {
  render () {
    const {key, onChange, users} = this.props;

    return (
      <div>
        <ExpansionList className={styles.expansionList}>
          <UserPanel key={key} onChange={onChange} users={users}/>
        </ExpansionList>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslationStatistics: state.getIn(['projects', 'fetchingTranslationStatistics']),
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(TranslationUsers)
);
