import React, {Component} from 'react';
import {connect} from 'react-redux';
// import {Menu, MenuItem} from 'react-toolbox/lib/menu';

import styles from '../../styles/button_menu.scss';

class ButtonMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      active: false,
      status: 0
    };
  }

  componentWillMount () {
    if (this.props.item.status) {
      if (this.props.item.status.approved == false) {
        this.setState({status: 1});
      } else if (this.props.item.status.translated == false) {
        this.setState({status: 2});
      } else {
        this.setState({status: 3});
      }
    }
  }

  handleButtonClick = event => {
    // console.log('handleButtonMenuClick', event);
    event.stopPropagation();
    this.setState({active: !this.state.active});

    if (this.props.onMenuClick) {
      this.props.onMenuClick(event);
    }
  };

  /*
  handleMenuClick = (event, data) => {
    console.log('handleMenuClick', event, data);
    event.stopPropagation();

    console.log(data && data.props && data.props.value);

    if (data && data.props && data.props.value) {
      let value = data.props.value;

      console.log(this.props.onMenuClick);

      this.props.onMenuClick(value);
    }
    this.setState({active: false});
  };
  */

  handleMenuHide = () => {
    this.setState({active: false});
  };

  render () {
    var icons = [];
    if (Math.random() > 0.5) {
      icons.push(<div key={'pdf'} className={styles.buttonPoint + ' ' + styles.pdfIcon}/>);
    } else {
      icons.push(<div key={'pdf'} className={styles.buttonPoint + ' ' + styles.invPdfIcon}/>);
    }
    if (Math.random() > 0.5) {
      icons.push(
        <div key={'newstext'} className={styles.buttonPoint + ' ' + styles.newstextIcon}/>
      );
    } else {
      icons.push(
        <div key={'newstext'} className={styles.buttonPoint + ' ' + styles.invNewstextIcon}/>
      );
    }

    var icons2 = [];
    for (var i = 0; i < this.state.status; i++) {
      icons2.push(<div key={i} className={styles.buttonPoint + ' ' + styles.icon}/>);
    }

    return (
      <div
        style={{display: 'inline-block', position: 'relative'}}
        className={styles.buttonMenuWrapper}>

        <div onClick={this.handleButtonClick} className={styles.buttonPointWrapper}>
          <div className={styles.buttonRow}>{icons}</div>
          <div className={styles.buttonRow}>{icons2}</div>
        </div>
        {/*
                <Menu position="topLeft"
                      active={this.state.active}
                      onHide={this.handleMenuHide}
                      className={styles.menuWrapper}>
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='creation'
                              icon='favorite'
                              caption='Creation phase' />
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='translation'
                              icon='favorite'
                              caption='Translation phase' />
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='review'
                              icon='favorite'
                              caption='Review phase' />
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='qc'
                              icon='favorite'
                              caption='Quality control' />
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='publication'
                              icon='favorite'
                              caption='Publication phase' />
                    <MenuItem onClick={(event, data) => this.handleMenuClick(event, data)}
                              value='final'
                              icon='favorite'
                              caption='Finalized' />
                </Menu>
                */}
      </div>
    );
  }
}

export default connect()(ButtonMenu);
