import React, {useEffect, useState, useRef} from 'react';
import Portal from './Portal';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from '../../../styles/storyboards/historybar.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import RawDraftToHtml from './RawDraftToHtml.js';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Tooltip from '@material-ui/core/Tooltip';
import {useSelector, useDispatch} from 'react-redux';
import {fetchHistory} from '../../../actions/storyboard';
import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';


// import {withTranslation} from 'react-i18next';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActionArea from '@material-ui/core/CardActionArea';

export default function HistoryBar (props) {
  // const {history} = props;
  const history = useSelector(state => state.getIn(['storyboard', 'history']));
  const fetchingHistory = useSelector(state => state.getIn(['storyboard', 'fetchingHistory']));
  const [open, setOpen] = useState(false);
  const cardScrollWrapper = useRef(null);
  const dispatch = useDispatch();

  console.log('fetchHistory props.id', props.textBlockId);

  useEffect(() => {
    dispatch(fetchHistory(props.textBlockId));
    setTimeout(() => {
      console.log('hallo');
      setOpen(true);
      cardScrollWrapper.current.scrollTop = cardScrollWrapper.current.scrollHeight;

      setTimeout(() => {
        console.log('HistoryBar history', history);
      }, 5000);
    }, 100);
  }, []);

  useEffect(() => {
    console.log('fetchingHistory', fetchingHistory);
  }, [fetchingHistory]);

  console.log('HistoryBar props', props, open, history);

  return (
    <Portal className={open ? styles.historyToolbar + ' open' : styles.historyToolbar}>
      <div className="layover" onClick={props.closeHistory}/>
      <Paper
        square={true}
        elevation={4}
        className={open ? 'historyToolbarInner open' : 'historyToolbarInner'}
      >
        <Typography
          gutterBottom
          variant="h4"
          component="h4"
          style={{margin: '7px 10px 0 10px'}}
        >History</Typography>

        {
          fetchingHistory !== ProcessStatus.FINISHED &&
          <LoadingBar show='true' />
        }

        {history && fetchingHistory === ProcessStatus.FINISHED &&
         <div className="cardScrollWrapper" ref={cardScrollWrapper}>
           <table>
             <tbody>
               {
                 history.history.map((item, index) => {
                   let header = item.author_username;

                   return (
                     <tr
                       key={`item-${item.id}`}
                       style={{margin: '15px'}}
                     >
                       <td>
                         <div style={{
                           marginBottom: '0',
                           fontSize: '12px',
                           display: 'flex',
                           justifyContent: 'space-between'
                         }}>
                           <span>{header}</span>
                           {item.created_at && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                           {item.created_at && <span>{item.created_at}</span>}
                         </div>
                         <Card
                           square={true}
                           elevation={3}
                           style={{
                             padding: '8px',
                             backgroundColor: index !== 0 ? '#eeeeee' : '#ffffff',
                             marginBottom: '15px',
                             maxWidth: '700px'
                           }}
                         >
                           <RawDraftToHtml raw={item.content}/>
                         </Card>
                       </td>
                       <td>
                         <div style={{
                           display: 'flex',
                           flexDirection: 'column'
                         }}>
                           <Tooltip title="restore" placement="left">
                             <IconButton
                               disabled
                               size="small"
                               aria-label="add"
                               onClick={() => {
                                 // props.setTextFromHistory(props.contentId, item.id);
                               }}
                             ><SettingsBackupRestoreIcon/></IconButton>
                           </Tooltip>
                           <Tooltip title="delete" placement="left">
                             <IconButton
                               disabled
                               size="small"
                               aria-label="add"
                               onClick={() => {
                                 // props.deteletHistoryEntry(props.contentId, item.id);
                               }}
                             ><CloseIcon/></IconButton>
                           </Tooltip>
                           {/*
                           {
                             index < history.length - 1 &&

                           }
                           {
                             index !== 0 &&
                             index < history.length - 1 &&

                           }
                           */}
                         </div>
                       </td>
                     </tr>
                   );
                 })}
             </tbody>
           </table>
         </div>
        }


        <IconButton
          className="closeHistory"
          size="small"
          aria-label="close"
          onClick={props.closeHistory}
        ><CloseIcon/></IconButton>
      </Paper>
    </Portal>
  );
}
