import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import stylesDrawer from '../../styles/drawer_inner.scss';
import {withTranslation} from 'react-i18next';
import Impersonation from './Impersonation';
import EditProfile from './EditProfile';
import ChangeProfilePassword from './ChangeProfilePassword';
import Theme from './Theme';
import stylesTabs from '../../styles/tabs.scss';
import helpStyles from '../../styles/help.scss';
import {FontIcon} from 'react-md';

@withTranslation(['profile', 'general'], {wait: true})
class EditProfileContainer extends Component {
  constructor (props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      tabIndex: 0,
      tabCount: 3
    };
  }

  setTab = tabIndex => {
    this.setState({tabIndex: tabIndex});
  };

  componentDidMount () {
    const {impersonatedUser} = this.props;
    document.addEventListener('keydown', this.escFunction, false);
    if (impersonatedUser) {
      this.setState({tabCount: 2, tabIndex: 0});
    } else {
      this.setState({tabCount: 3, tabIndex: 0});
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {impersonatedUser} = nextProps;

    if (impersonatedUser !== this.props.impersonatedUser) {
      if (impersonatedUser) {
        this.setState({tabCount: 2, tabIndex: 0});
      } else {
        this.setState({tabCount: 3, tabIndex: 0});
      }
    }
  }

  escFunction (event) {
    if (event.keyCode === 27) {
      this.props.closeDrawer();
    }
  }

  logout = () => {
    this.props.history.push('/logout');
  };

  render () {
    const {t, impersonatedUser, toggleTheme, isDark} = this.props;
    const {tabIndex, tabCount} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let transformValue = (100 * (tabIndex / tabCount)) * -1;
    let tabContainerTransformValue = 'translateX(' + transformValue + '%) translateZ(0)';
    let tabContainerWidth = 'calc(100% * ' + tabCount + ')';

    if (impersonatedUser) {
      return (
        <div
          className={stylesDrawer.drawer}
          style={{minWidth: '30vw', maxWidth: '100vw'}}>
          <div className={'head'}>
            <div className={'formHeader headlines'}>
              <h2>{t('profile')}</h2>
            </div>
          </div>

          <div className={stylesTabs.tabBtnRow}>
            <button
              className={'tabBtn'}
              is-active={(tabIndex === 0).toString()}
              onClick={() => {
                this.setTab(0);
              }}>{t('impersonation')}</button>
            <button
              className={'tabBtn'}
              is-active={(tabIndex === 1).toString()}
              onClick={() => {
                this.setTab(1);
              }}>{t('theme')}</button>
          </div>

          <div className={stylesTabs.tabContainerRow}>
            <div
              className="tabContainerWrapper"
              style={{
                transform: tabContainerTransformValue,
                width: tabContainerWidth
              }}>
              <div className={'tabContainer'}>
                <Impersonation closeDrawer={this.props.closeDrawer} impersonatedUser={impersonatedUser}/>
              </div>
              <div className={'tabContainer'}>
                <Theme isDark={isDark} toggleTheme={toggleTheme}/>
              </div>
            </div>
          </div>

        </div>
      );
    }

    return ([
      <button
        key={'logoutbtn'}
        title={t('general:logout')}
        className={stylesTabs.logoutButton}
        onClick={this.logout}
      >
        <FontIcon>power_settings_new</FontIcon>
      </button>,
      <div
        key={'drawer'}
        className={stylesDrawer.drawer}
        style={{minWidth: '30vw', maxWidth: '100vw', position: 'relative'}}>

        <div className={'head'}>
          <div className={'formHeader headlines'}>
            <h2>{t('profile')}</h2>
          </div>
        </div>

        <div className={stylesTabs.tabBtnRow}>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 0).toString()}
            onClick={() => {
              this.setTab(0);
            }}>{t(`${generalLNS}:properties`)}</button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 1).toString()}
            onClick={() => {
              this.setTab(1);
            }}>{t('change_password')}</button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 2).toString()}
            onClick={() => {
              this.setTab(2);
            }}>{t('theme')}</button>
        </div>

        <div className={stylesTabs.tabContainerRow}>
          <div
            className="tabContainerWrapper"
            style={{
              transform: tabContainerTransformValue,
              width: tabContainerWidth
            }}>
            <div className={'tabContainer'}>
              <EditProfile closeDrawer={this.props.closeDrawer} />
            </div>
            <div className={'tabContainer'}>
              <ChangeProfilePassword closeDrawer={this.props.closeDrawer} />
            </div>
            <div className={'tabContainer'}>
              <Theme isDark={this.props.isDark} toggleTheme={this.props.toggleTheme}/>
            </div>
          </div>
        </div>
      </div>
    ]
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['auth', 'user']),
    impersonatedUser: state.getIn(['auth', 'impersonatedUser'])
  };
}

export default withRouter(
  connect(
    mapStateToProps
  )(EditProfileContainer)
);
