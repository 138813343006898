import {useEffect, useState} from 'react';

export const useLocalStorage = (key, initialValue) => {
  // const [value, setValue] = useState(
  //   localStorage.getItem(key) || JSON.stringify(initialValue)
  // );
  // useEffect(() => {
  //   localStorage.setItem(key, value);
  // }, [value]);
  // return [value, setValue];


  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn('useLocalStorage hook useState error: ', error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      console.log('INITIAL VALUEEEE!!!!', valueToStore);
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.warn('useLocalStorage hook setValue error: ', error);
    }
  };

  return [storedValue, setValue];
};
