import React, {Component} from 'react';
import {Autocomplete} from 'react-md';
import styles from '../../styles/object_autocomplete.scss';

class ObjectAutocomplete extends Component {
  constructor (props) {
    super(props);

    this.state = {
      error: false,
      isMenuOpen: false,
      match: 'label'
    };
  }

  componentWillMount () {
    const {match} = this.props;

    if (this.props.match) {
      this.setState({match: match});
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let option = nextProps.options.find(option => option.label == nextProps.input.value);

    if (option && !option.disabled && this.state.error) {
      this.setState({error: false});
    }
  }

  handleChange = text => {
    const {
      input: {name}
    } = this.props;

    if (!this.state.isMenuOpen) {
      this.setState({error: true});
    }

    this.props.changeValues({name: name, value: text});
  };

  handleAutocomplete = (suggestion, suggestionIndex, matches) => {
    const {
      id,
      input: {name}
    } = this.props;
    const option = matches[suggestionIndex];

    this.props.changeValues(
      {name: name, value: suggestion},
      {name: id, value: option.value},
      {name: 'disabled', value: option.disabled}
    );
  };

  onClose = () => {
  };

  handleMenuOpen = () => {
    this.setState({error: false, isMenuOpen: true});
  };

  handleMenuClose = () => {
    const {
      options,
      input: {value},
      errorTextForNotFound,
      errorTextForDisabled
    } = this.props;

    let error = false;
    let errorText = null;

    if (value) {
      let option = options.find(option => option[this.state.match] == value);

      // if the name of the option is typed instead of selected and does not match a option
      if (option == undefined) {
        error = true;
        errorText = errorTextForNotFound;
        this.changeValues(null, null);
      } else {
        if (option.disabled) {
          error = true;
          errorText = errorTextForDisabled;
        }
        this.changeValues(option.value, option.disabled);
      }
    } else {
      this.changeValues(null, null);
    }
    this.setState({error: error, errorText: errorText, isMenuOpen: false});
  };

  changeValues = () => {
    // const { id } = this.props;
    // setTimeout(() => {
    //    this.props.changeValues({name:id, value:value}, {name:"disabled", value:disabled});
    // }, 250);
  };

  render () {
    const {
      options,
      input,
      meta: {touched, error},
      label,
      placeholder,
      helpText,
      disabled,
      required,
      errorText
      // ,
      // values
    } = this.props;

    let customErrorText = this.state.errorText ? this.state.errorText : errorText;

    return (
      <Autocomplete
        className={styles.objectAutocomplete}
        id={input.name}
        label={label}
        placeholder={placeholder}
        required={required}
        // error={!this.state.isMenuOpen && (touched && error || this.state.error)}
        error={(touched && error) || this.state.error}
        errorText={customErrorText}
        helpText={helpText}
        data={options}
        dataLabel="label"
        autocompleteWithLabel={true}
        value={input.value}
        dataValue={input.value}
        disabled={disabled}
        onChange={this.handleChange}
        onAutocomplete={this.handleAutocomplete}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        onBlur={this.handleBlur}
        onClose={this.onClose}
      />
    );
  }
}

export default ObjectAutocomplete;
