import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { renderTextField } from '../../general/BasicFormComponents';
import { fetchRoles } from '../../../actions/roles';
import styles from '../../../styles/translation_detail.scss';
import { withTranslation } from 'react-i18next';

@withTranslation(['roles', 'general' /* , 'types' */], {wait: true})
class RolePrime extends Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  componentWillMount () {
  }

  // UNSAFE_componentWillReceiveProps (nextProps) {
  // }

  render () {
    const { t } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'md-grid ' + styles.translationPrime}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{ t('add_role') }</h2>
          </div>
        </div>

        <div className={'formContentWrapper'}>
          <Field
            id="name"
            name="name"
            label={ t(`${generalLNS}:name`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="display_name"
            name="display_name"
            label={ t(`${generalLNS}:display_name`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    roles: state.getIn(['roles', 'roles'])
  };
}

export default connect(
  mapStateToProps,
  {fetchRoles}
)(RolePrime);
