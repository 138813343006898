import React, {Component} from 'react';
import textStyles from '../../../styles/text.scss';
import {withTranslation} from 'react-i18next';
import licenses from '../../../../licenses';
import {ExpansionPanel, ExpansionList} from 'react-md';
import styles from '../../../styles/projects/expansionpanel/general.scss';

@withTranslation('privacy', {wait: true})
class PrivacyText extends Component {
  constructor (props) {
    super(props);

    this.state = {
      allLicenseItems: null
    };
  }

  componentWillMount () {
    let licensesString = JSON.stringify(licenses);
    let licensesObj = JSON.parse(licensesString);
    let formattedLicenses = Object.entries(licensesObj);

    this.setState({
      allLicenseItems: formattedLicenses
    });
  }

  renderExpansionTables = (tableData) => {
    return (
      <ExpansionList>
        {tableData.map((item, i) => {
          return (
            <ExpansionPanel
              key={`license${i}`}
              label={item[0]}
              footer={null}
              className={styles.expansionPanel}
              headerClassName={styles.expansionHeader}
            >
              <p><b>License: </b> {item[1].licenses}</p>
              <p>
                <b>Repository: </b>
                <a rel="noopener noreferrer" target="_blank" href={item[1].repository}>{item[1].repository}</a>
              </p>
              <p>
                <b>License-URL: </b>
                <a rel="noopener noreferrer" target="_blank" href={item[1].licenseUrl}>{item[1].licenseUrl}</a>
              </p>
              <p><b>Parents: </b>{item[1].parents}</p>
            </ExpansionPanel>
          );
        })}
      </ExpansionList>
    );
  };

  render () {
    const { t } = this.props;
    const { allLicenseItems } = this.state;

    return (
      <div>
        <h3 className={textStyles.h3} style={{marginTop: '0', paddingTop: '0'}}>
          { t('headline') }
        </h3>
        <h5>
          { t('subheadline') }
        </h5>
        <div className={textStyles.ImprintText} style={{
          paddingBottom: '20px'
        }}>
          <div dangerouslySetInnerHTML={{__html: t('content')}}></div>
        </div>

        <h3 className={textStyles.h3}>NODE-MODULES</h3>
        { this.renderExpansionTables(allLicenseItems) }
      </div>
    );
  }
}

export default PrivacyText;
