// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".themeButton {\n  background-color: transparent;\n  opacity: 0.5;\n  transition: opacity 0.3s ease;\n  display: inline-flex;\n}\n\n.themeButton * {\n  pointer-events: none;\n}\n\n.themeButton > span {\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  background-color: black;\n  display: inline-block;\n  border-radius: 50%;\n  height: 27px;\n  width: 27px;\n  margin-bottom: 2px;\n  background-size: 68% auto;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.themeButton:hover {\n  opacity: 1;\n}\n\n.themeButton.dark > span {\n  background-color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"themeButton": "themeButton",
	"dark": "dark"
};
module.exports = exports;
