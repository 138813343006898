// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.expansionPanel {\n  transition: all 0.3s ease;\n}\n\n.expansionPanel:hover [class*=\"md-panel-header\"] {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.expansionPanel[class*=\"--expanded\"] {\n  margin-top: 0 !important;\n}\n\n.expansionPanel[class*=\"--expanded\"] [class*=\"md-panel-header\"] {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.expansionPanel[class*=\"--expanded\"] [class*=\"md-panel-content\"] {\n  padding: 0 !important;\n}\n\n.expansionPanel[class*=\"--expanded\"] > div:last-child {\n  padding: 0 24px 24px 24px;\n}\n\n.expansionPanel .actionRow {\n  padding-top: 20px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.expansionPanel .actionRow.disabled {\n  opacity: 0.5;\n  pointer-events: none;\n}\n\n.expansionSubHeader {\n  display: flex;\n  flex-direction: row-reverse;\n  margin: 1rem 0;\n}\n\n.expansionSubHeader .addButton {\n  position: relative;\n  background-color: #ff5900 !important;\n  margin: 0;\n}\n\n.expansionHeader {\n  position: relative;\n}\n\n.expansionHeader > div:nth-of-type(2) {\n  display: inline-block;\n  position: absolute;\n  top: 50%;\n  right: 80px;\n  transform: translateY(-50%);\n}\n\n.expansionHeader[class*=\"--expanded\"] {\n  margin-top: 0;\n  margin-bottom: 0;\n  height: 48px !important;\n}\n\n.expansionHeader[class*=\"--expanded\"] div, .expansionHeader[class*=\"--expanded\"] i {\n  font-weight: bolder;\n  color: black !important;\n  padding: 0 !important;\n}\n\n.expansionHeader[class*=\"--expanded\"] li {\n  padding: 0 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"expansionPanel": "expansionPanel",
	"actionRow": "actionRow",
	"disabled": "disabled",
	"expansionSubHeader": "expansionSubHeader",
	"addButton": "addButton",
	"expansionHeader": "expansionHeader"
};
module.exports = exports;
