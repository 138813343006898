import React, { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from '../../styles/storyboards/site.scss';

export default function MoreMenu (props) {
  const refMoreMenuBtn = useRef(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  if (props.hasOwnProperty('useParentState')) {
    return (
      <>
        <IconButton
          className="moreMenuSiteBtn"
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={props.onMoreBtnClick}
          ref={refMoreMenuBtn}
          style={{
            position: 'absolute',
            top: '0',
            right: '0'
          }}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={refMoreMenuBtn.current}
          keepMounted
          open={props.open}
          onClose={props.onClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: 'auto'
            },
            square: true,
            elevation: 4
          }}
          classes={{
            paper: styles.siteMoreMenu,
            list: 'siteMoreMenuList'
          }}
        >
          <div className={'inner'}>
            {/*
          <Button
            variant=""
            color="default"
            startIcon={<CloseIcon />}
            onClick={handleMoreMenuClose}
          >
            Close
          </Button>
          */}
            {props.children}
          </div>
        </Menu>
      </>
    );
  }

  const handleMoreBtnClick = () => {
    setMoreMenuOpen(true);
  };

  const handleMoreMenuClose = () => {
    setMoreMenuOpen(false);
  };

  return (
    <>
      <IconButton
        className="moreMenuSiteBtn"
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMoreBtnClick}
        ref={refMoreMenuBtn}
        style={{
          position: 'absolute',
          top: '0',
          right: '0'
        }}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={refMoreMenuBtn.current}
        keepMounted
        open={moreMenuOpen}
        onClose={handleMoreMenuClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 'auto'
          },
          square: true,
          elevation: 4
        }}
        classes={{
          paper: styles.siteMoreMenu,
          list: 'siteMoreMenuList'
        }}
      >
        <div className={'inner'}>
          {/*
        <Button
          variant=""
          color="default"
          startIcon={<CloseIcon />}
          onClick={handleMoreMenuClose}
        >
          Close
        </Button>
        */}
          {props.children}
        </div>
      </Menu>
    </>
  );
}
