import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {fetchChapter, fetchStoryboard, setCurrentChapter, setViewMode, setCompareMode} from '../../actions/storyboard';
import Chapter from './Chapter';
import Site from './Site.js';
import GlobalSnackbar from '../general/GlobalSnackbar';
import GlobalDialog from '../general/dialog/GlobalDialog';
import ErrorDialog from '../general/dialog/ErrorDialog';
import StoryboardQuestionDialog from './StoryboardQuestionDialog';
import Structure from './Structure';
import FoldingContainer from './FoldingContainer';
import StoryboardHeader from './StoryboardHeader';
import StoryboardExitDialog from './StoryboardExitDialog';
import styles from '../../styles/storyboards/storyboard.scss';
import structureStyles from '../../styles/storyboards/structure.scss';
import {project, structure} from './exampleContent';
import StoryboardStatusDialog from './ProcessStatusDialog';
import {ProcessStatus} from '../../utils/Constants';
import {showWarning, removeDialog} from '../../actions/globalDialog';

import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'react-md';
import useForceUpdate from "use-force-update";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default function Storyboard (props) {
  const classesFromMaterialUI = useStyles();

  const [showStructureViewDrawer, setShowStructureViewDrawer] = useState(false);
  // const [currentChapterIndex, setCurrentChapterIndex] = useState(null);
  const [currentCourseSiteId, setCurrentCourseSiteId] = useState(null);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const fetchingStoryboard = useSelector(state => state.getIn(['storyboard', 'fetchingStoryboard']));
  const storyboard = useSelector(state => state.getIn(['storyboard', 'storyboard']));
  const currentChapterId = useSelector(state => state.getIn(['storyboard', 'currentChapterId']));
  const fetchingChapter = useSelector(state => state.getIn(['storyboard', 'fetchingChapter']));
  const viewMode = useSelector(state => state.getIn(['storyboard', 'viewMode']));
  const compareMode = useSelector(state => state.getIn(['storyboard', 'compareMode']));
  // const addingSite = useSelector(state => state.getIn(['storyboard', 'addingSite']));
  const {t, i18n} = useTranslation('translation_navigation');
  const dispatch = useDispatch();
  const history = useHistory();
  // const forceUpdate = useForceUpdate();

  const [showExitDialog, setShowExitDialog] = useState(false);

  // TODO: M.Orf: Wieder entfernen, wenn Dark-Mode implementiert
  let html = document.querySelector('html');
  let htmlHasClassDark = html.classList.contains('dark');
  if (htmlHasClassDark) {
    document.querySelector('html').classList.remove('dark');
  }


  // EFFECT initial: fetch the storyboard structure
  useEffect(() => {
    const {storyboardId} = props.match.params;
    dispatch(fetchStoryboard(storyboardId));
  }, []);

  // Fetch the initial chapter
  useEffect(() => {
    const {chapterId} = props.match.params;
    console.log('This  is the chapter from the route', chapterId);
    if (storyboard && storyboard.chapters) {
      const chapterInStoryboard = storyboard.chapters.find(elem => elem.id === parseInt(chapterId, 10));
      if (chapterId && chapterInStoryboard) {
        // setCurrentChapterIndex(chapterId);
        dispatch(setCurrentChapter(chapterId));
      } else {
        // setValidChapter(false);
        let firstSelectedChapter = storyboard.chapters[0].chapters[0].id;
        history.push(`/storyboards/${storyboard.id}/${firstSelectedChapter}`);
        // setCurrentChapterIndex(0);
        dispatch(setCurrentChapter(firstSelectedChapter));
      }
    }
  }, [storyboard]);

  useEffect(() => {
    setShowLoadingDialog(fetchingChapter !== ProcessStatus.FINISHED);
  }, [fetchingChapter]);


  // useEffect(() => {
  //   if (addingSite === ProcessStatus.FINISHED) {
  //     console.log('addingSite', addingSite, '====> FORCE!');
  //     // const {storyboardId} = props.match.params;
  //     // dispatch(fetchStoryboard(storyboardId));
  //   }
  // }, [addingSite]);


  // const loadMore = () => {
  //   const {
  //     loadUsers,
  //     state: {
  //       error,
  //       isLoading,
  //       hasMore
  //     }
  //   } = this;
  //
  //   // Bails early if:
  //   // * there's an error
  //   // * it's already loading
  //   // * there's nothing left to load
  //   if (error || isLoading || !hasMore) return;
  //
  //   // Checks that the site has scrolled to the bottom
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop
  //     === document.documentElement.offsetHeight
  //   ) {
  //     setCurrentChapterIndex(currentChapterIndex + 1);
  //     fetchChapter(storyboard.chapters[currentChapterIndex].id);
  //   }
  // };

  const toggleStructureView = () => {
    setShowStructureViewDrawer(!showStructureViewDrawer);
  };

  const scrollToLocalStorage = (e) => {
    localStorage.setItem('scrollTop', e.target.scrollTop);
  };

  const handleNodeSelect = node => { // for STRUCTURE NEEDED
    console.warn('handleNodeSelect node: ', node);
    // goToSite(node.external_id);
  };

  // const goToSite = id => { // for STRUCTURE NEEDED
  //   // setCurrentSiteId(id);
  //   // setCurrentRequestedId(id);
  //   // setIsCourseBehind(true);
  // };


  const handleViewModeChange = (event, newValue) => {
    console.log('setViewMode', newValue);
    dispatch(setViewMode(newValue));
  };

  const handleCompareModeChange = (event, newValue) => {
    console.log('setCompareMode', newValue);
    dispatch(setCompareMode(newValue));
  };

  if (currentChapterId === null || !storyboard) {
    return null;
  }

  return (
    <div className={styles.storyboard}>
      <div className="global-scroll-wrapper" onScroll={scrollToLocalStorage}>
        <FoldingContainer title={t('structure')} paper={true}>
          <Structure
            toggleStructureView={toggleStructureView}
            storyboard={storyboard}
            project={project}
            translationId={project.translation_id}
            structure={structure}
            searchTerm={''}
            updateToken={'testbla'}
            onNodeSelect={handleNodeSelect}
            currentChapterId={currentChapterId}
            currentCourseSiteId={currentCourseSiteId}
            invisible={!showStructureViewDrawer}
          />
        </FoldingContainer>
        <Chapter id={currentChapterId}/>
        {/*
        <StructureReplacement
          toggleStructureView={toggleStructureView}
          showStructureViewDrawer={showStructureViewDrawer}
        />
        */}
      </div>
      <StoryboardHeader
        navBackToProject={() => {setShowExitDialog(true);}}
        viewMode={viewMode}
        compareMode={compareMode}
        handleViewModeChange={handleViewModeChange}
        handleCompareModeChange={handleCompareModeChange}
        name={storyboard.name}
      />
      <StoryboardStatusDialog
        inHide={() => {}}
        storyboard={true}
        title={t('loading_status')}
        active={showLoadingDialog}
        processStatus={{
          // fetchingProject: fetchingStoryboard !== ProcessStatus.FINISHED,
          // fetchingStructure: fetchingStoryboard !== ProcessStatus.FINISHED,
          fetchingTexts: fetchingChapter !== ProcessStatus.FINISHED
          // packageConnected: fetchingChapter === ProcessStatus.FINISHED
        }}
      />
      <GlobalSnackbar/>
      <StoryboardExitDialog
        show={showExitDialog}
        deactivate={() => {setShowExitDialog(false);}}
      />
      <StoryboardQuestionDialog />
      <ErrorDialog/>
    </div>
  );
}
