import React, {Component} from 'react';

import {Button, TextField} from 'react-md';

import {withTranslation} from 'react-i18next';

@withTranslation('general', {wait: true})
class SearchField extends Component {
  tweenTimeout = null;

  constructor (props) {
    super(props);
  }

  componentDidMount () {
    // this.nameInput.focus();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isVisible && !this.props.isVisible) {
      this.nameInput.focus();
    }
  }

  handleInputChange = value => {
    clearTimeout(this.tweenTimeout);
    this.props.handleSearchInput(value);
  };

  handleResetPress = () => {
    this.props.handleSearchReset();
    clearTimeout(this.tweenTimeout);
    this.tweenTimeout = setTimeout(() => {
      this.setState({
        isSearchFieldVisible: false
      });
    }, 200);
  };

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      e.target.blur();
      this.props.hitEnterToFocus();
    }
  };

  render () {
    const {searchTerm, isVisible} = this.props;
    const placeholder = this.props.placeholder || '';

    return (
      <div className={`searchBoxWrapper ${isVisible ? '' : ' hidden'}`}>
        <TextField
          id="searchbox"
          placeholder={placeholder}
          value={searchTerm}
          ref={(input) => { this.nameInput = input; }}
          onChange={this.handleInputChange}
          onKeyUp={this.handleKeyUp}
        />
        <Button
          icon
          onClick={this.handleResetPress}
        >
          clear
        </Button>
      </div>
    );
  }
}

export default SearchField;
