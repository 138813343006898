import React  from 'react';

export const withReactRouterLink = Component =>
  class Decorated extends React.Component {
    resolveToLocation = to => {
      const {location} = this.props;
      return typeof to === 'function' ? to(location) : to;
    };

    handleClick = event => {
      const {to, back} = this.props;
      const {history} = this.props;
      event.preventDefault();
      if (back) {
        history.goBack();
      } else {
        history.push(this.resolveToLocation(to));
      }
    };

    render () {
      const {activeClassName, className, to, ...rest} = this.props;
      let _className = className;

      // Remove objects added by withRouter
      ['match', 'location', 'history', 'staticContext'].forEach(function (elem) {
        delete rest[elem];
      });
      if (this.props.to) {
        const toLocation = this.resolveToLocation(to);
        const isActive = this.props.location.pathname === toLocation;
        _className = isActive ? `${className} ${activeClassName}` : className;
      }
      return <Component {...rest} className={_className} onClick={this.handleClick}/>;
    }
  };
