import { Roles, Scope, Permissions } from './constants/ACL';
import { LogOps } from './constants/LogicalOperations';

const RoleAttributes = [
  {
    id: 1,
    name: 'Admin',
    className: 'admin',
    short: 'A'
  },
  {
    id: 2,
    name: 'Guest',
    className: 'guest',
    short: 'G'
  },
  {
    id: 3,
    name: 'Reviewer',
    className: 'reviewer',
    short: 'R'
  },
  {
    id: 4,
    name: 'Editor',
    className: 'editor',
    short: 'E'
  },
  {
    id: 5,
    name: 'Manager',
    className: 'manager',
    short: 'M'
  },
  {
    id: 6,
    name: 'Translator',
    cassName: 'translator',
    short: 'T'
  },
  {
    id: 7,
    name: 'Viewer',
    className: 'viewer',
    short: 'V'
  },
  {
    id: 8,
    name: 'Superadmin',
    className: 'superadmin',
    short: 'Sa'
  },
  {
    id: 9,
    name: 'Supermanager',
    className: 'supermanager',
    short: 'Sm'
  }
];

const FilterType = {
  ALL: 'ALL'
};

const ProjectSortProp = {
  NONE: 'NONE',
  CREATED_BY: 'CREATED_BY',
  NAME: 'NAME'
};

// Notification
const NotificationType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  NEUTRAL: 'neutral'
};

const NotificationAnimation = {
  ENTER: 1000,
  APPEAR: 3000,
  LEAVE: 3000,
  DURATION: 4000
};

const ProcessStatus = {
  INITIAL: 0,
  STARTED: 1,
  FAILED: 2,
  FINISHED: 3
};

const NodeId = {
  MAIN_CONTAINER: 'main-container'
};

const Routes = {
  LOGIN: '/login',
  HOME: '/projects',
  CLIENTS: {
    ADD: '/clients/add',
    EDIT: '/clients/:clientId/edit'
  },
  PROJECTS: {
    TRANSLATION_VIEW: '/projects/translate/:translationId/:sourceLanguageId',
    IMPORT: '/projects/import-project',
    IMPORT_WARNING: '/projects/import-project/warning',
    UPDATE: '/projects/:projectId/translations/:translationId/:sourceLanguageId?/update-project',
    EDIT: '/projects/:projectId/translations/:translationId/:sourceLanguageId?/edit-project',
    TRANSLATIONS: {
      ADD: '/projects/:projectId/translations/:translationId/:sourceLanguageId?/add-translation',
      EDIT: '/projects/:projectId/translations/:translationId/:sourceLanguageId?/edit-translation',
      EDIT_WARNING:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/edit-translation/warning',
      IMPORT_PDF:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/import-pdf-translation/:collectionId?',
      IMPORT_PDF_ERROR:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/import-pdf-translation/error',
      IMPORT:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/import-translation',
      IMPORT_ERROR:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/import-translation/error',
      EXPORT:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/export-translation',
      EXPORT_PDF:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/export-pdf-translation/:collectionId?',
      ADD_SNAPSHOT:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/add-snapshot',
      EDIT_SNAPSHOT:
        '/projects/:projectId/translations/:translationId/:sourceLanguageId?/snapshots/:snapshotId/edit',

      /*
      ADD_SNAPSHOTATROOT:
        '/projects/:projectId/translations/:translationId/add-snapshot',
      EDIT_SNAPSHOTATROOT:
        '/projects/:projectId/translations/:translationId/edit-snapshot',
*/
      VIEW: '/projects/:projectId/translations/:translationId?/:sourceLanguageId?'
    }
  },
  ROLES: {
    ADD: '/roles/add',
    EDIT: '/roles/:roleId/edit'
    // EDITBYID:'/roles/edit/:roleId'
  },
  USERS: {
    ADD: '/users/add',
    EDIT: '/users/:userId/edit'
  },
  CONTACTS: {
    ADD: '/contacts/add',
    EDIT: '/contacts/:contactId/edit'
  },
  PERMISSIONS: {
    ADD: '/permissions/add',
    EDIT: '/permissions/:permissionId/edit'
  }
};

const Image = {
  DUMMY_IMAGE: '/src/images/project-dummy.jpg'
};

const IframeSizes = {
  LAPTOP: 'laptop',
  TABLET: 'tablet',
  TABLET_LANDSCAPE: 'tablet_landscape',
  SMARTPHONE: 'smartphone',
  SMARTPHONE_LANDSCAPE: 'smartphone_landscape'
};

const HeaderTitles = {
  PROJECTS: 'projects',
  CLIENTS: 'clients',
  USERS: 'users',
  PERMISSIONS: 'permissions',
  ROLES: 'roles',
  IMPRINT: 'imprint',
  PRIVACY: 'privacy',
  LOGIN: 'login',
  HOME: 'home',
  STORYBOARDS: 'storyboards'
};

const ViewMode = {
  COMPOSE: 0,
  COMPARE: 1
};

const CompareMode = {
  UNIFIED: 0,
  SPLIT: 1
};

export {
  RoleAttributes,
  FilterType,
  ProjectSortProp,
  NotificationType,
  NotificationAnimation,
  ProcessStatus,
  Roles,
  Scope,
  Permissions,
  LogOps,
  NodeId,
  Routes,
  IframeSizes,
  HeaderTitles,
  Image,
  ViewMode,
  CompareMode
};
