import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, FontIcon} from 'react-md';
import {aclFilter} from '../../utils/ACL';
import {aclHoverPanel} from '../../utils/Permissions';
import {ProcessStatus, Scope} from '../../utils/Constants';
import {setItemStatus} from '../../actions/structure';
import styles from '../../styles/wbtViewer/hoverPanel.scss';
import {withTranslation} from 'react-i18next';

@withTranslation('translation_navigation', {wait: true})
class HoverPanel extends Component {
  static propTypes = {
    goToPage: PropTypes.func.isRequired,
    prevPageId: PropTypes.string,
    nextPageId: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.state = {
      translationsForSelect: null,
      radioBarChildren: null,
      isTranslated: false,
      isApproved: false,
      isVisible: true,
      isPopup: false
    };
  }

  componentWillMount () {
  }

  componentDidMount () {
    console.log('componentDidMount this.props.getItemStatus', this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {selectedItem} = nextProps;

    if (nextProps.currentPageId) {
      this.setState({isPopup: nextProps.currentPageId.split('_').length > 3});
    }

    if (selectedItem && selectedItem != this.props.selectedItem) {
      if (selectedItem.get('status')) {
        this.setState({
          isTranslated: selectedItem.get('status').translated,
          isApproved: selectedItem.get('status').approved,
          isVisible: selectedItem.get('status').visible
        });
      }
    }
  }

  toggleTranslationSwitch = () => {
    const {projectId, translationId} = this.props.match.params;
    const {selectedItem} = this.props;

    let newState = !this.state.isTranslated;
    let status = newState ? 'translated' : 'not_translated';

    this.setState({isTranslated: newState});
    this.props.setItemStatus({
      projectId,
      translationId,
      itemId: selectedItem.get('id'),
      status: {translated: status === 'translated' ? 1 : 0}
    });
  };

  toggleVerificationSwitch = () => {
    const {projectId, translationId} = this.props.match.params;
    const {selectedItem} = this.props;

    let newState = !this.state.isApproved;
    let status = newState ? 'approved' : 'not_approved';

    this.setState({isApproved: newState});
    this.props.setItemStatus({
      projectId,
      translationId,
      itemId: selectedItem.get('id'),
      status: {approved: status === 'approved' ? 1 : 0}
    });
  };

  toggleVisibilitySwitch = () => {
    const {projectId, translationId} = this.props.match.params;
    const {selectedItem} = this.props;

    let newState = !this.state.isVisible;
    this.setState({isVisible: newState});
    this.props.setItemStatus({
      projectId,
      translationId,
      itemId: selectedItem.get('id'),
      status: {visible: newState ? 1 : 0}
    });
  };

  onDownMover = e => {
    e.preventDefault();
    this.movePane.style.display = 'block';
    this.movePane.addEventListener('mousemove', this.onMoveMover);
    this.movePane.addEventListener('mouseup', this.onUpMover);
    this.movePane.addEventListener('mouseleave', this.onUpMover); // falls der User mit der Maus aus dem Fester kommt
  };

  /* eslint-disable complexity */
  onMoveMover = e => {
    let bodyRect = document.body.getBoundingClientRect();
    let bodyWidth = bodyRect.width;
    let bodyHeight = bodyRect.height;
    let halfButtonWidth = this.handleElement.getBoundingClientRect().width / 2;
    let halfButtonHeight = this.handleElement.getBoundingClientRect().height / 2;
    let halfPanelWidth = this.dragElement.getBoundingClientRect().width / 2;
    let paddingToBottom = (this.dragElement.getBoundingClientRect().height / 4) * 3;
    let mousePosButtonX = e.clientX - halfButtonWidth;
    let mousePosButtonY = e.clientY - halfButtonHeight;
    let percentLeft = (mousePosButtonX / bodyWidth) * 100;
    let percentTop = (mousePosButtonY / bodyHeight) * 100;

    let boundingTolerance = 10;

    let boundXMin = e.clientX >= halfButtonWidth + boundingTolerance;
    let boundXMax =
      e.clientX <=
      bodyWidth - (halfPanelWidth + halfPanelWidth - halfButtonWidth) - boundingTolerance;
    let boundX = boundXMin && boundXMax;

    let boundYMin = e.clientY >= paddingToBottom + boundingTolerance;
    let boundYMax = e.clientY <= bodyHeight - paddingToBottom - boundingTolerance;
    let boundY = boundYMin && boundYMax;

    let boundXIsInMin = e.clientX < halfButtonWidth + boundingTolerance;
    let boundXIsInMax =
      e.clientX >
      bodyWidth - (halfPanelWidth + halfPanelWidth - halfButtonWidth) - boundingTolerance;
    let boundYIsInMin = e.clientY < paddingToBottom + boundingTolerance;
    let boundYIsInMax = e.clientY > bodyHeight - paddingToBottom - boundingTolerance;

    let isInTopLeftCorner = boundXIsInMin && boundYIsInMin;
    let isInTopRightCorner = boundXIsInMax && boundYIsInMin;
    let isInBottomLeftCorner = boundXIsInMin && boundYIsInMax;
    let isInBottomRightCorner = boundXIsInMax && boundYIsInMax;

    let isInCorner =
      isInTopLeftCorner || isInTopRightCorner || isInBottomLeftCorner || isInBottomRightCorner;

    let isInTop = boundYIsInMin && !boundXIsInMin && !boundXIsInMax;
    let isInBottom = boundYIsInMax && !boundXIsInMin && !boundXIsInMax;
    let isInLeft = boundXIsInMin && !boundYIsInMin && !boundYIsInMax;
    let isInRight = boundXIsInMax && !boundYIsInMin && !boundYIsInMax;

    if (boundX && boundY) {
      this.dragElement.style.top = percentTop + '%';
      this.dragElement.style.left = percentLeft + '%';
    } else {
      // out of bounds
      if (isInCorner) {
        if (isInTopLeftCorner) {
          this.dragElement.style.top = '0%';
          this.dragElement.style.left = '0%';
        } else if (isInTopRightCorner) {
          this.dragElement.style.top = '0%';
          this.dragElement.style.left =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().width + 'px)';
        } else if (isInBottomLeftCorner) {
          this.dragElement.style.top =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().height + 'px)';
          this.dragElement.style.left = '0%';
        } else if (isInBottomRightCorner) {
          this.dragElement.style.top =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().height + 'px)';
          this.dragElement.style.left =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().width + 'px)';
        }
      } else {
        if (isInTop) {
          this.dragElement.style.top = '0%';
          this.dragElement.style.left = percentLeft + '%';
        } else if (isInBottom) {
          this.dragElement.style.top =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().height + 'px)';
          this.dragElement.style.left = percentLeft + '%';
        } else if (isInLeft) {
          this.dragElement.style.top = percentTop + '%';
          this.dragElement.style.left = '0%';
        } else if (isInRight) {
          this.dragElement.style.top = percentTop + '%';
          this.dragElement.style.left =
            'calc(100% - ' + this.dragElement.getBoundingClientRect().width + 'px)';
        }
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };
  /* eslint-enable complexity */

  onUpMover = e => {
    this.movePane.removeEventListener('mousemove', this.onMoveMover);
    this.movePane.removeEventListener('mouseup', this.onUpMover);
    this.movePane.removeEventListener('mouseleave', this.onUpMover);
    this.movePane.style.display = 'none';
    e.preventDefault();
  };

  render () {
    const {
      currentPageId,
      makeExternalLink,
      isTranslationWindowVisible,
      isReadyForEditing,
      onTranslationButton,
      toggleLiveEdit,
      isLiveEditEnabled,
      selectingItem,
      goToPage,
      prevPageId,
      nextPageId,
      t
    } = this.props;
    const {isVisible, isTranslated, isApproved, isPopup} = this.state;
    const translationSwitchText = t('translated');
    const verificationSwitchText = t('verified');
    const visibilitySwitchText = t('pageVisible');
    const unVisibilitySwitchText = 'status: not visible, click to set visible';

    let areTranslationAndApprovalDisabled =
      !isReadyForEditing || selectingItem != ProcessStatus.FINISHED;
    /*
        if(true) {
            return null;
        }
        */
    return [
      <div key={0} className={styles.hoverPanelMovePane} ref={div => (this.movePane = div)}/>,
      <div
        help-tool="11"
        key={1}
        className={styles.panelWrapper}
        ref={div => (this.dragElement = div)}>
        <div className={'rowBar'}>
          <div className={'rowBarGroup'}>
            <button
              help-tool="12"
              onClick={null}
              onMouseDown={this.onDownMover}
              ref={button => (this.handleElement = button)}
              title={ t('click_hold_drag') }
              className={'handle handleButton'}
            >
              <FontIcon>control_camera</FontIcon>
            </button>
          </div>
          <div className={'rowBarGroup' + ' grow'}>
            <h3 className={'panelPageId'} title={ t('site_id') }>
              {currentPageId}
            </h3>
            <Button
              icon
              help-tool="13"
              title={ t('click_to_generate_link') }
              className={'pageIdLink'}
              onClick={() => makeExternalLink(currentPageId)}
            >
              insert_link
            </Button>
          </div>


          <div className={'rowBarGroup no-border'}>
            {aclFilter(
              <Button
                icon
                help-tool="14"
                className={'visibility'}
                disabled={areTranslationAndApprovalDisabled || isPopup}
                active={'false'}
                title={
                  isVisible
                    ? visibilitySwitchText
                    : unVisibilitySwitchText
                }
                onClick={this.toggleVisibilitySwitch}
              >
                {
                  isVisible
                    ? 'visibility'
                    : 'visibility_off'
                }
              </Button>
            )([], [aclHoverPanel.change_visibility], Scope.TRANSLATION)}
          </div>
        </div>


        <div className={'rowBar'}>
          <div className={'rowBarGroup'}>
            <Button
              icon
              title={ t('go_to_previous_site') }
              disabled={prevPageId === null}
              onClick={() => {
                goToPage(prevPageId);
              }}
            >
              navigate_before
            </Button>
          </div>


          {aclFilter(
            <div className={'rowBarGroup grow left'}>
              <button
                help-tool="18"
                className={'liveTranslateButton'}
                active={isTranslationWindowVisible.toString()}
                disabled={!isReadyForEditing}
                title={t('translate')}
                onClick={onTranslationButton}
              >
                <FontIcon>translate</FontIcon>
                <span className={'live'}>editor</span>
              </button>
            </div>
          )([], [aclHoverPanel.translate], Scope.TRANSLATION)}


          {aclFilter(
            <div className={'rowBarGroup grow left'}>
              <button
                help-tool="17"
                className={'liveTranslateButton'}
                active={isLiveEditEnabled.toString()}
                disabled={!isReadyForEditing}
                title={t('live_edit')}
                onClick={toggleLiveEdit}
              >
                <FontIcon>edit</FontIcon>
                <span className={'live'}>select</span>
              </button>
            </div>
          )([], [aclHoverPanel.live_translate], Scope.TRANSLATION)}
          {aclFilter(
            <div className={'rowBarGroup' + ' right'}>
              <Button
                icon
                help-tool="16"
                active={(isReadyForEditing && isTranslated).toString()}
                disabled={areTranslationAndApprovalDisabled}
                title={translationSwitchText}
                onClick={this.toggleTranslationSwitch}
              >
                translate
              </Button>
            </div>
          )([], [aclHoverPanel.approve_translation], Scope.TRANSLATION)}
          {aclFilter(
            <div className={'rowBarGroup' + ' right'}>
              <Button
                icon
                help-tool="15"
                active={(isReadyForEditing && isApproved).toString()}
                disabled={areTranslationAndApprovalDisabled}
                title={verificationSwitchText}
                onClick={this.toggleVerificationSwitch}
              >
                check
              </Button>
            </div>
          )([], [aclHoverPanel.approve_content], Scope.TRANSLATION)}
          <div className={'rowBarGroup' + ' no-border'}>
            <Button
              icon
              disabled={nextPageId === null}
              title={ t('go_to_next_site') }
              className={'navigationButton'}
              onClick={() => {
                goToPage(nextPageId);
              }}
            >
              navigate_next
            </Button>
          </div>
        </div>
      </div>
    ];
  }
}

function mapStateToProps (state) {
  return {
    selectedItem: state.getIn(['structure', 'selectedItem']),
    selectingItem: state.getIn(['structure', 'selectingItem']),
    isLiveEditEnabled: state.getIn(['wbtViewer', 'isLiveEditEnabled'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {setItemStatus}
  )(HoverPanel)
);
