import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';
import {renderPasswordTextField, renderTextField} from '../general/BasicFormComponents';
import passwordStyle from '../../styles/password_change.scss';

@withTranslation(['profile', 'change_password'], {wait: true})
class PasswordPrime extends Component {
  constructor (props) {
    super(props);
    this.state = {
      nPWFocus: false,
      nPWTouched: false,
      nPWStrength: 0,
      nPWLowercase: false,
      nPWUppercase: false,
      nPWNumbers: false,
      nPWSpecial: false,
      nPWLength: false,
      nPWLengthNumber: 0,

      nConfirmPWFocus: false,
      nConfirmPWTouched: false
    };
  }

  checkOldPasswordEmpty = (oPw) => {
    const oldPasswordFilled = !oPw ? false : true;
    this.props.setFormFields({oldPasswordFilled});
  };

  checkPasswordStrength = (nPw) => {
    let nPWStrength = 0;
    let {
      nPWLowercase,
      nPWUppercase,
      nPWNumbers,
      nPWSpecial,
      nPWLength,
      newPasswordFilled
    } = false;
    if (nPw) {
      if (nPw.length > 9) {
        nPWLength = true;
        nPWStrength += 2;
      }
      if (nPw.match(/[A-Z]|[Ä-ß]/g)) {
        nPWUppercase = true;
        nPWStrength++;
      }
      if (nPw.match(/[a-z]|[ä-ü]/g)) {
        nPWLowercase = true;
        nPWStrength++;
      }
      if (nPw.match(/\d/g)) {
        nPWNumbers = true;
        nPWStrength++;
      }
      if (nPw.match(/!+|@+|#+|\$+|%+|\^+|&+|\*+|\(+|\)+/g)) {
        nPWSpecial = true;
        nPWStrength++;
      }
      if (nPWStrength >= 5) {
        newPasswordFilled = true;
      }

      this.setState({
        nPWStrength,
        nPWLowercase,
        nPWUppercase,
        nPWNumbers,
        nPWSpecial,
        nPWLength,
        nPWLengthNumber: nPw.length
      });

      this.props.values.new_password = nPw;
      if (this.props.values.confirm_new_password) {
        if (this.props.values.new_password) {
          const newPasswordConfirmed = (nPw === this.props.values.confirm_new_password);
          this.props.setFormFields({newPasswordConfirmed});
        } else {
          this.props.setFormFields({newPasswordConfirmed: false});
        }
      }
      this.props.setFormFields({newPasswordFilled});
    } else {
      this.setState({
        nPWStrength: 0,
        nPWLowercase: false,
        nPWUppercase: false,
        nPWNumbers: false,
        nPWSpecial: false,
        nPWLength: false,
        nPWLengthNumber: 0
      });
      this.props.setFormFields({newPasswordFilled: false});
    }
  };

  checkPassIdentical = (cPw) => {
    if (this.props.values.new_password) {
      const newPasswordConfirmed = (this.props.values.new_password === cPw);
      this.props.setFormFields({newPasswordConfirmed});
    } else {
      this.props.setFormFields({newPasswordConfirmed: false});
    }
  };

  onFocusNewPassword = () => {
    this.setState({
      nPWFocus: true,
      nPWTouched: true
    });
  };

  onBlurNewPassword = () => {
    this.setState({
      nPWFocus: false
    });
  };

  onFocusConfirmNewPassword = () => {
    this.setState({
      nConfirmPWFocus: true,
      nConfirmPWTouched: true
    });
  };

  onBlurConfirmNewPassword = () => {
    this.setState({
      nConfirmPWFocus: false
    });
  };

  validationInformation = () => {
    const { t } = this.props;
    const {
      nPWLowercase,
      nPWUppercase,
      nPWNumbers,
      nPWSpecial,
      nPWLength,
      nPWLengthNumber
    } = this.state;
    let lNS = 'change_password'; // languageNamespaceSource

    return (
      <div className={'validationInformation ' + this.state.nPWFocus ? 'show' : ''}>
        <div className={'validationInformationItem'}>
          <FontIcon>{nPWLowercase ? 'done' : 'clear'}</FontIcon>
          <div className={'validateText'}>
            { t(`${lNS}:validate_nPWLowercase`) }
          </div>
        </div>
        <div className={'validationInformationItem'}>
          <FontIcon>{nPWUppercase ? 'done' : 'clear'}</FontIcon>
          <div className={'validateText'}>
            { t(`${lNS}:validate_nPWUppercase`) }
          </div>
        </div>
        <div className={'validationInformationItem'}>
          <FontIcon>{nPWNumbers ? 'done' : 'clear'}</FontIcon>
          <div className={'validateText'}>
            { t(`${lNS}:validate_nPWNumbers`) }
          </div>
        </div>
        <div className={'validationInformationItem'}>
          <FontIcon>{nPWSpecial ? 'done' : 'clear'}</FontIcon>
          <div className={'validateText'}>
            { t(`${lNS}:validate_nPWSpecial`) }
          </div>
        </div>
        <div className={'validationInformationItem'}>
          <FontIcon>{nPWLength ? 'done' : 'clear'}</FontIcon>
          <div className={'validateText'}>
            { nPWLengthNumber } /{ t(`${lNS}:validate_nPWLength`) }
          </div>
        </div>
      </div>
    );
  };

  render () {
    const { t } = this.props;
    const siteLNS = 'change_password'; // siteLanguageNamespaceSource
    const {oldPasswordFilled, newPasswordFilled, newPasswordConfirmed} = this.props.formFields;
    const { nPWFocus, nPWStrength } = this.state;
    const showNewPassword = oldPasswordFilled ? 'show' : '';
    const showConfirmPassword = (oldPasswordFilled && newPasswordFilled) ? 'show' : '';
    const strengthNPw = 'strength' + (nPWStrength * 20);
    const isConfirmPasswordIdentical = newPasswordConfirmed ? 'strength100' : 'strength0';
    const showValidationInformation = (nPWFocus && !newPasswordFilled) ? 'show' : '';

    return (
      <div className={'formContentWrapper body paddingLeftRight changePasswordWrapper'}>
        <div className={'inputFieldContainer'}>
          <Field
            id="old_password"
            name="old_password"
            label={ t(`${siteLNS}:type_actual_password`) }
            type="password"
            autoComplete="current-password"
            required
            component={renderPasswordTextField}
            validate={this.checkOldPasswordEmpty}
          />
          {oldPasswordFilled && <FontIcon>done</FontIcon>}
        </div>
        <div className={`inputFieldContainer newPassword ${strengthNPw}`}>
          <Field
            id="new_password"
            name="new_password"
            label={ t(`${siteLNS}:type_new_password`) }
            type="password"
            className={`passwordContainer ${showNewPassword}`}
            autoComplete="new-password"
            required
            component={renderPasswordTextField}
            validate={this.checkPasswordStrength}
            onFocus={this.onFocusNewPassword}
            onBlur={this.onBlurNewPassword}
          />
          {oldPasswordFilled && newPasswordFilled && <FontIcon>done</FontIcon>}
        </div>

        <div className={`validationInformationContainer ${showValidationInformation}`}>
          { this.validationInformation() }
        </div>

        <div className={`inputFieldContainer newPassword ${isConfirmPasswordIdentical}`}>
          <Field
            id="confirm_new_password"
            name="confirm_new_password"
            label={ t(`${siteLNS}:confirm_new_password`) }
            type="password"
            className={`passwordContainer ${showConfirmPassword}`}
            autoComplete="new-password"
            required
            component={renderPasswordTextField}
            validate={this.checkPassIdentical}
            onFocus={this.onFocusConfirmNewPassword}
            onBlur={this.onBlurConfirmNewPassword}
          />
          {oldPasswordFilled && newPasswordFilled && newPasswordConfirmed && <FontIcon>done</FontIcon>}
        </div>
      </div>
    );
  }
}


export default connect(
)(PasswordPrime);
