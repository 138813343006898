const performScroll = scrollToProps => {
  if (!scrollToProps) {
    return;
  }

  // JH this.setState({isScrolling: true});

  let fs = getFieldSection(scrollToProps.refName);
  let eyeCandy = -32; // visual correction to show right position of fieldSection;

  if (fs) {
    // ALTERNATIVE ZU SCROLLTO
    // fs.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
    // fs.scrollIntoView(); // IE11
    // this.setState({
    //   hasNewScrollTo: false,
    //   scrollTo: scrollToProps,
    //   isScrolling: false
    // });

    let scrollContainer = window.document.querySelector('.root');
    let offset = fs.parentNode.offsetTop + fs.offsetTop + eyeCandy;
    scrollTo(scrollContainer, offset, 250, () => {});
  } else {
    /*
    this.setState({
      isScrolling: false
    });*/
  }
};

const performScrollToUnsavedText = scrollToProps => {
  if (!scrollToProps) {
    return;
  }
  // this.unfoldAll(); // ALLE KAPITEL AUSKLAPPEN
  // JH this.setState({isScrolling: true});

  let fs = getFieldSection(scrollToProps);
  let eyeCandy = -62; // visual correction to show right position of fieldSection;

  if (fs) {
    let scrollContainer = window.document.querySelector('.root');
    let offset = fs.parentNode.offsetTop + fs.offsetTop + eyeCandy;
    scrollTo(scrollContainer, offset, 250, () => {});
  } else {
    // this.setState({isScrolling: false});
  }
};

const performScrollToCollection = collectionId => {
  if (!collectionId) {
    return;
  }

  console.log('performScrollToCollection', collectionId);

  let fs = getDataSection(collectionId);
  if (!fs) {
    console.warn('KEIN ELEMENT GEFUNDEN');
    return;
  }

  let eyeCandy = 0; // visual correction to show right position of fieldSection;

  if (fs) {
    // eslint-disable-next-line
    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
      fs.scrollIntoView();
    } else {
      let scrollContainer = window.document.querySelector('.root');
      let offset = fs.parentNode.offsetTop + fs.offsetTop + eyeCandy;
      scrollTo(scrollContainer, offset, 250,  () => {});
    }
  }
};

const getDataSection = collectionId => {
  return document.querySelector('[section-collection-id="' + collectionId + '"]');
};

const getFieldSection = refName => {
  return document.querySelector('[field-section-refname="' + refName + '"]');
};


//------------------------------------------
function scrollTo (element, to, duration, callback) {
  console.log('duration, callback', duration, callback);
  element.scrollTop = to + 20;
  // let start = element.scrollTop;
  // let change = to - start;
  // let currentTime = 0;
  // let increment = 20;
  //
  // let animateScroll = function () {
  //   currentTime += increment;
  //   let val = Math.easeInOutQuad(currentTime, start, change, duration);
  //   element.scrollTop = val;
  //   if (currentTime < duration) {
  //     setTimeout(animateScroll, increment);
  //   } else {
  //     callback();
  //   }
  // };
  // animateScroll();
}

/* eslint-disable */
/* HELFER-FUNCTION FÜR SCROLLTO
  t = current time
  b = start value
  c = change in value
  d = duration
 */
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
/* eslint-enable */


export {
  performScroll,
  performScrollToUnsavedText,
  performScrollToCollection,
  getFieldSection
};
