import React from 'react';
import store from './store';
import {Scope} from './constants/ACL';
import {impersonatedUserFromStore, userFromStore} from '../actions/auth';
import sha512 from 'crypto-js/sha512';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import {Route, Redirect} from 'react-router-dom';
import {cryptedStore} from './CryptedStore';
import {connect} from 'react-redux';
import i18n from './i18n';

const combineUrl = (url, ...params) => {
  const sanitized = [url, ...params]
    .join('/')
    .replace(/(https?:\/\/)|(\/)+/g, '$1$2');

  return `${sanitized}/`;
};

const getBaseUrl = () => {
  return combineUrl(ENV.API_URL);
};

const baseUrl = getBaseUrl();
const baseApiUrl = combineUrl(ENV.API_URL, 'v1');
const basePackagesUrl = combineUrl(ENV.API_URL, 'packages');
const packageVersion = ENV.PACKAGE_VERSION;

// const unsecureURIs = [
//   '/login',
//   '/translations/',
//   '/media',
//   '/file/',
//   '/crnjbs/',
//   '/cleanup',
//   '/api',
//   '/docs'
// ];

const AuthStatus = {
  AUTHENTICATED: 'AUTHENTICATED',
  GUEST: 'GUEST'
};

const isAuthenticated = store => {
  return store
    .getState()
    .get('auth')
    .get('authenticated');
};

const hasStatus = (store, status) => {
  const isAuthenticated = store.value.get('auth').get('authenticated');
  switch (status) {
    case AuthStatus.AUTHENTICATED:
      return isAuthenticated;
    case AuthStatus.GUEST:
      return !isAuthenticated;
    default:
      return false;
  }
};

const getAuthHeadersWithToken = (token, user, url) => {
  const microTime = new Date().getTime();

  // Kein User - keine Auth-Headers
  if (!user) {
    return {};
  }

  const hmacSecret = sha512(
    sha512(user.username + ':' + user.username) + ':' + user.auth_token
  ).toString();
  /** Higher security, currently incompatible with api */
  /* const hash = hmacSHA512(
    url + ':' + (_data.length ? _data : JSON.stringify(_data)) + ':' + microTime,
    hmacSecret
  ); */

  const hash = hmacSHA512(
    url + ':' + JSON.stringify([]) + ':' + microTime,
    hmacSecret
  );

  return {
    'X-MICRO-TIME': microTime,
    'X-SESSION-TOKEN': token,
    'X-HMAC-HASH': hash,
    'Accept': 'application/json'
    // 'content-type': application/json
    // 'X-Requested-With': 'XMLHttpRequest'
  };
};


const getHttpAuthHeaders = (store, url) => {
  const microTime = new Date().getTime();

  let token = null;
  let user = null;

  if (store && store.value) {
    token = store.value.get('auth').get('token');
    user = store.value.get('auth').get('user');
  } else if (store && store.getState()) {
    token = store
      .getState()
      .get('auth')
      .get('token');
    user = store
      .getState()
      .get('auth')
      .get('user');
  }

  // Unsecured URIs beachten? Bislang wird diese Funktion einfach nicht aufgerufen

  // Kein User - keine Auth-Headers
  if (!user) {
    return {};
  }

  const hmacSecret = sha512(
    sha512(user.username + ':' + user.username) + ':' + user.auth_token
  ).toString();
  /** Higher security, currently incompatible with api */
  /* const hash = hmacSHA512(
    url + ':' + (_data.length ? _data : JSON.stringify(_data)) + ':' + microTime,
    hmacSecret
  ); */

  const hash = hmacSHA512(
    url + ':' + JSON.stringify([]) + ':' + microTime,
    hmacSecret
  );

  return {
    'X-MICRO-TIME': microTime,
    'X-SESSION-TOKEN': token,
    'X-HMAC-HASH': hash,
    'Accept': 'application/json'
    // 'content-type': application/json
    // 'X-Requested-With': 'XMLHttpRequest'
  };
};

const hasRole = userRole => {
  if (
    store
      .getState()
      .get('auth')
      .get('user') === null
  ) {
    return false;
  }
  let roles = store
    .getState()
    .get('auth')
    .get('user')
    .roles.map(role => role.name);
  if (roles.length > 0) {
    if (roles.includes(userRole)) {
      return true;
    }
  }
  return false;
};

const getCurrentUser = () => {
  let impersonatedUser = store
    .getState()
    .get('auth')
    .get('impersonatedUser');

  if (impersonatedUser) {
    return impersonatedUser;
  }

  let authenticatedUser = store
    .getState()
    .get('auth')
    .get('user');

  if (authenticatedUser) {
    return authenticatedUser;
  }

  return null;
};

const hasPermission = (userPermission, scope = Scope.GLOBAL) => {
  let projects = null;
  let project = null;
  let translation = null;
  let projectPermissions = null;
  let translationPermissions = null;
  let user = getCurrentUser();


  if (user) {
    if (scope === Scope.PROJECT || scope === Scope.TRANSLATION) {
      projects = store
        .getState()
        .get('projects');

      if (projects == null) {
        // console.log("1. AuthHelper:: null");
        return false;
      }

      switch (scope) {
        case Scope.TRANSLATION:
          translation = projects.get('translation');

          if (translation && translation.size > 0) {
            translationPermissions = translation.get('permissions');

            if (translationPermissions && translationPermissions.length > 0) {
              // console.log("2. AuthHelper:: translationPermissions", translationPermissions);
              return translationPermissions.includes(userPermission);
            }
          } else {
            // console.log("3. AuthHelper:: false");
            return false;
          }
        // falls through
        case Scope.PROJECT:
          project = projects.get('selectedProject');

          if (project) {
            projectPermissions = project.permissions;
            if (projectPermissions && projectPermissions.length > 0) {
              // console.log("4. AuthHelper:: projectPermissions", projectPermissions);
              return projectPermissions.includes(userPermission);
            }
          } else {
            // console.log("5. AuthHelper:: false");
            return false;
          }
        // falls through
        default:
          // console.log("6. AuthHelper:: user.permissions", user.permissions);
          return user.permissions.includes(userPermission);
      }
    } else {
      // console.log("7. AuthHelper:: user.permissions", user.permissions);
      return user.permissions.includes(userPermission);
    }
  } else {
    if (user) {
      return user.permissions;
    }
    return false;
  }
};


const hasPermissions = permissions => {
  let hp = true;

  if (permissions && permissions.length > 0) {
    permissions.map(permission => {
      hp = hp && hasPermission(permission);
    });
  }
  return hp;
};

const hasProjectPermission = project => {
  const userId = store.value.get('auth').get('user').id;
  let index = project.users.findIndex(user => {
    return user.id === userId;
  });
  return index > -1; // if-statement gespart :)
};

const hasTranslationPermission = translation => {
  const userId = store.value.get('auth').get('user').id;
  let index = translation.users.findIndex(user => {
    return user.id === userId;
  });
  return index > -1; // if-statement gespart :)
};

class PrivateRouteIntern extends React.Component {
  componentDidMount () {
    if (!this.props.authenticated && cryptedStore.get('user')) {
      let user = cryptedStore.get('user');
      store.dispatch(userFromStore(user));
      i18n.changeLanguage(user.languagecode, (err) => {
        if (err) return console.log('something went wrong loading', err);
      });
      if (cryptedStore.get('impersonatedUser')) {
        let impersonatedUser = cryptedStore.get('impersonatedUser');
        store.dispatch(impersonatedUserFromStore(impersonatedUser));
      }
    }
  }

  render () {
    const {component: Component, authenticated, ...rest} = this.props;
    const hasPermission = hasPermissions(rest.withPermissions);
    return (
      <Route
        {...rest}
        render = {
          props => {
            if (authenticated && hasPermission) {
              return <Component {...props} {...rest} />;
            } else if (authenticated && !hasPermission) {
              return <Redirect to='/'/>;
            }
            return <RedirectToLogin authenticated={authenticated} {...props} />;
          }
        }
      />
    );
  }
}

const PrivateRoute = connect(state => ({
  authenticated: state.getIn(['auth', 'authenticated'])
}))(PrivateRouteIntern);

const RedirectToLogin = props => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {from: props.location}
      }}
    />
  );
};

export {
  baseUrl,
  baseApiUrl,
  basePackagesUrl,
  packageVersion,
  AuthStatus,
  hasStatus,
  getAuthHeadersWithToken,
  isAuthenticated,
  getHttpAuthHeaders,
  hasRole,
  hasPermission,
  hasProjectPermission,
  RedirectToLogin,
  hasTranslationPermission,
  PrivateRoute
};
