import {changeDatetimeToDate} from '../utils/HelperFunctions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction
} from './defaultFunctions';
import {aclSnapshot} from '../utils/Permissions';

/**
 * User Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'version',
      translation_id: 'version'
      // name: 'Version'
    },
    {
      id: 'name',
      translation_id: 'name'
      // name: 'Name'
    },
    {
      id: 'description',
      translation_id: 'description',
      // name: 'Description',
      sortable: 'false'
    },
    {
      id: 'created_at',
      translation_id: 'created_at',
      // name: 'Created at',
      format: changeDatetimeToDate,
      time: true
    },
    {
      id: 'actions',
      translation_id: 'actions',
      // name: 'Actions',
      textAlign: 'right',
      permissions: [aclSnapshot.delete, aclSnapshot.update],
      sortable: 'false'
    }
  ],

  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name']
};

export default model;
