import { EnumDefaultsAction } from '../utils/ActionTypes';

const fetchProjectEnumDefaults = () => {
  return {
    type: EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS,
    data: {}
  };
};

const receiveProjectEnumDefaults = data => {
  return {
    type: EnumDefaultsAction.FETCH_PROJECT_ENUM_DEFAULTS_FULFILLED,
    enumDefaults: data,
    receivedAt: Date.now()
  };
};

const fetchTranslationEnumDefaults = () => {
  return {
    type: EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS,
    data: {}
  };
};

const receiveTranslationEnumDefaults = data => {
  return {
    type: EnumDefaultsAction.FETCH_TRANSLATION_ENUM_DEFAULTS_FULFILLED,
    enumDefaults: data,
    receivedAt: Date.now()
  };
};

export {
  fetchProjectEnumDefaults,
  receiveProjectEnumDefaults,
  fetchTranslationEnumDefaults,
  receiveTranslationEnumDefaults
};
