import React from 'react';
import { Scope } from './constants/ACL';
import { hasRole, hasPermission } from './AuthHelper';

const matchesRoles = roles => {
  if (roles.length === 0) {
    return true;
  }
  for (let role of roles) {
    if (hasRole(role)) {
      return true;
    }
  }
  return false;
};

const matchesPermissions = (permissions, scope) => {
  if (permissions.length === 0) {
    return true;
  }
  for (let permission of permissions) {
    if (hasPermission(permission, scope)) {
      return true;
    }
  }
  return false;
};

const aclFilter = (...renderables) => {
  return (userRoles = [], userPermissions = [], scope = Scope.GLOBAL) => {

    // Duplikat
    if (userRoles.length === 0 && !matchesPermissions(userPermissions, scope)) {
      return false;
    }

    if (!matchesRoles(userRoles) && userPermissions.length === 0) {
      return false;
    }

    if (renderables.length === 0) {
      return false;
    }

    if (renderables.length === 1) {
      return renderables[0];
    }

    let elementList = [];
    for (let [index, element] of renderables.entries()) {
      elementList.push(React.cloneElement(element, {key: index}));
    }

    return <React.Fragment>{elementList}</React.Fragment>;
  };
};

// ggf hier gucken: https://github.com/lucasconstantino/react-policies
const aclWrap = (userRoles = [], userPermissions = []) => {
  return UnwrappedComponent => {
    return class extends React.Component {
      render () {
        // DUPLIKAT
        if (userRoles.length === 0 && !matchesPermissions(userPermissions)) {
          return null;
        }

        if (!matchesRoles(userRoles) && userPermissions.length === 0) {
          return null;
        }

        return <UnwrappedComponent {...this.props} />;
      }
    };
  };
};

export { matchesRoles, matchesPermissions, aclFilter, aclWrap };
