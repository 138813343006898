import * as Rx from 'rxjs';

import {FieldLevelAction} from '../utils/ActionTypes';


const setScrollTo = scrollTo => {
  return {
    type: FieldLevelAction.SET_SCROLL_TO,
    scrollTo: scrollTo
  };
};

const addUnsavedText = refName => {
  return {
    type: FieldLevelAction.ADD_UNSAVED_TEXT,
    refName: refName
  };
};

const removeUnsavedText = refName => {
  return {
    type: FieldLevelAction.REMOVE_UNSAVED_TEXT,
    refName: refName
  };
};


export {
  setScrollTo,
  addUnsavedText,
  removeUnsavedText
};
