import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import StructureNode from './StructureNode';
import {showWarning, removeDialog} from '../../actions/globalDialog';
import {setCurrentChapter, fetchChapter} from '../../actions/storyboard';
// import GlobalHelp from '../general/help/GlobalHelp';
import styles from '../../styles/storyboards/structure.scss';
import {connect} from 'react-redux';
import {ProcessStatus} from '../../utils/Constants';

@withTranslation(['translation_navigation', 'general'], {wait: true})
class Structure extends Component {
  constructor (props) {
    super(props);
    this.state = {
      mode: 'view',
      unfoldAll: false,
      width: 350,
      labeledViewports: []
    };
    this.showHeadlines = true;
  }

  componentDidUpdate (prevProps) {
    const {currentChapterId, fetchingChapter, storyboard} = prevProps;
    if (
      fetchingChapter === ProcessStatus.FINISHED
      && parseInt(currentChapterId) !== parseInt(this.props.currentChapterId)
    ) {
      this.props.history.push(
        '/storyboards/'
        + this.props.match.params.storyboardId + '/'
        + this.props.currentChapterId
      );
      this.forceUpdate();
    }

    console.log('storyboard.chapters', storyboard.chapters);

    if (
      (this.props.addingSite === ProcessStatus.FINISHED
        ||  this.props.deletingSite === ProcessStatus.FINISHED)
      && this.props.storyboard.chapters.length !== storyboard.chapters.length
    ) {
      setTimeout(() => {
        this.forceUpdate();
      }, 1000);
    }
  }

  changeMode = newMode => {
    this.setState({mode: newMode});
  };

  toggleUnfoldAll = () => {
    this.setState({unfoldAll: !this.state.unfoldAll});
  };

  onDownResizer = e => {
    e.preventDefault();
    document.body.addEventListener('mousemove', this.onMoveResizer);
    e.target.setAttribute('active', 'true');
  };

  onMoveResizer = e => {
    let transformValue = e.clientX - e.target.parentNode.getBoundingClientRect().width;
    e.target.style.transform = 'translateX(' + transformValue + 'px)';
  };

  onUpResizer = e => {
    document.body.removeEventListener('mousemove', this.onMoveResizer);
    e.target.style.transform = 'translateX(0px)';
    e.target.removeAttribute('active');
    this.setState({width: e.clientX});
  };

  renderChildren = (children, collapsed = false, level = 0, expandParent = null) => {
    const {
      currentSiteId,
      currentCourseSiteId,
      makeExternalLink,
      searchTerm,
      matchingIds
    } = this.props;

    const {unfoldAll} = this.state;

    if (typeof children !== 'undefined' && children.length > 0) {
      let className = 'level' + level;
      if (collapsed) {
        className += ' childrenCollapsed';
      }

      // TODO: Parent expanden, wenn direktzugriff mit Hash erfolgt
      return (
        <ul
          className={className}
          showheadlines={this.showHeadlines.toString()}>
          {children.map((child, key) => {
            console.log('child', child);
            child.name = child.name ? child.name : child.collection;
            child.external_id = child.collection;
            child.childs = child.sites;

            if (child.sites) {
              child.childs = child.sites;
            } else if (child.chapters) {
              console.log('chapters: ', child.chapters);
              child.childs = child.chapters;
            }

            if (searchTerm.length > 0 && !matchingIds.includes(child.external_id)) {
              return false;
            }

            const isSelected = currentSiteId === child.external_id;
            const isInCourseSelected = currentCourseSiteId === child.external_id;
            const showingHeadlines = this.showHeadlines;
            let refName = 'ref_structurenode_' + child.id;  // wird zum scrollen in translationView genutzt

            return (
              <StructureNode
                refName={refName}
                key={key}
                node={child}
                level={level}
                chapter={key + 1}
                expandParent={expandParent}
                onNodeSelect={() => {
                  // this.props.setCurrentChapter(child.chapter_id);
                  this.props.fetchChapter(child.chapter_id);
                }}
                childHandler={this.renderChildren}
                makeExternalLink={makeExternalLink}
                isSelected={isSelected}
                isInCourseSelected={isInCourseSelected}
                showingHeadlines={showingHeadlines}
                foldAll={!unfoldAll}
              />
            );
          })}
        </ul>
      );
    }
  };

  render () {
    const {
      storyboard
    } = this.props;
    const {
      labeledViewports
    } = this.state;

    if (labeledViewports == null) {
      return null;
    }

    // SEARCH SITE-ID AND MARK SELECTED CHAPTER:
    let newStructure = storyboard.chapters;
    console.log('storyboard.chapters', storyboard.chapters);

    if (newStructure.length > 0) {
      for (var r = 0; r < newStructure.length; r++) {
        let item = newStructure[r];
        newStructure[r].isActiveChapter = parseInt(this.props.currentChapterId) === item.id;

        if (item.childs) {
          for (var rr = 0; rr < item.childs.length; rr++) {
            let item2 = item.childs[rr];
            item.childs[rr].isActiveChapter = parseInt(this.props.currentChapterId) === item2.id;
          }
        }
      }
    }

    return (
      <div
        className={styles.structureViewStoryboard}>
        <div className={'structureViewOverflowWrapper'} help-tool="1">
          {/*
          <div className={'logoAndBtnRow'}>
            <button
              className={'backBtn'}
              onClick={this.navBackToProject}
              theme={buttonWhiteTheme}
              title={'back to storyboard overview'}
            >
              <FontIcon>keyboard_arrow_left</FontIcon>
            </button>

            <span className={'logoImgWrapper'} title={t('click_to_get_back_to_slash_projects')}>
              <span>
                <img className={'logoLight'} src={require('../../images/logo-structureview_light.png')}/>
                <img className={'logoDark'} src={require('../../images/logo-structureview_dark.png')}/>
                <span className={'storyboardTitle'}>storyboard</span>
              </span>
            </span>


            <FontIcon>invert_colors</FontIcon>
            <FontIcon>invert_colors_off</FontIcon>
            <FontIcon>brightness_medium</FontIcon>


            {aclFilter(
              <button
                className={'liveTranslateInlineButton'}
                // active={this.props.isTranslationToolInline.toString()}
                title={'set translationtool inline'}
                onClick={this.props.toggleTranslationInline}
              >
                <FontIcon>layers</FontIcon>
                <FontIcon>layers_clear</FontIcon>
              </button>
            )(['admin'])}

            <button
              help-tool="2"
              title={this.props.isDark ? t(`${generalLNS}:theme_btn_off`) : t(`${generalLNS}:theme_btn_on`)}
              onClick={this.props.toggleTheme}
              className={this.props.isDark ? 'themeButton dark' : 'themeButton'}
            >
              <span style={{backgroundImage: themeUrl}}></span>
            </button>


            <button
              key={'help'}
              help-tool="3"
              title={t(`${generalLNS}:open_help`)}
              className={helpStyles.helpButton + ' helpBtn'}
              onClick={openHelp}
            >
              <FontIcon>help</FontIcon>
            </button>

            <GlobalHelp show={helpOpened} close={closeHelp}/>


            <button
              help-tool="4"
              className={'toggleStructureView'}
              onClick={toggleStructureView}
              title={'hide sidebar'}
              theme={buttonWhiteTheme}
            >
              <FontIcon>vertical_align_bottom</FontIcon>
            </button>
          </div>

          <div className={'wbtNameWrapper'} help-tool="5">
            <div className={'translationNameWrapper'}>
              <span className={'translationName'} title={''}>
                {storyboard !== null && storyboard.name}
              </span>
            </div>
          </div>
          <div className={'searchWrapper'} help-tool="7">
            <SearchBox
              placeholder={t('search')}
              customClass={'searchBox'}
              searchTerm={searchTerm}
              handleSearchInput={handleSearchInput}
              handleSearchReset={handleSearchReset}
            />
          </div>

          <div className={'structureTools'}>

            <Button
              help-tool="8"
              children={unfoldText}
              className={'unfoldButton'}
              onClick={this.toggleUnfoldAll}
              flat
              primary
              swapTheming
              iconChildren={unfoldIcon}
              iconBefore={true}
            />
            <div className={'structureToolsButtonsRight'}>
              <button
                help-tool="9"
                className={'headlineButton'}
                onClick={() => {
                  this.showHeadlines = !this.showHeadlines;
                  // this.forceUpdate();
                  this.headlineText1.style.opacity = this.showHeadlines ? '1' : '0.3';
                  this.headlineText2.style.opacity = this.showHeadlines ? '1' : '0.3';
                }}
              >
                <span>ID</span>
                <span ref={span => (this.headlineText1 = span)}>&nbsp;/&nbsp;</span>
                <span ref={span => (this.headlineText2 = span)}>{t('headline')}</span>
              </button>
            </div>

          </div>
          */}

          <div
            help-tool="6"
            ref-name="structure-wrapper" // wird zum scrollen in translationView genutzt
            className={'scrollerWrapper'}
            style={{height: '100%'}}>
            <div className={'scroller'}>
              <div>{this.renderChildren(newStructure)}</div>
            </div>
          </div>
          {/*
          <div
            className={'structureViewResizer'}
            onMouseDown={this.onDownResizer}
            onMouseUp={this.onUpResizer}
          >
            {' '}
          </div>
          */}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    dialogs: state.getIn(['globalDialog', 'dialogs']),
    currentChapterId: state.getIn(['storyboard', 'currentChapterId']),
    currentChapterCollection: state.getIn(['storyboard', 'currentChapterCollection']),
    fetchingChapter: state.getIn(['storyboard', 'fetchingChapter']),
    addingSite: state.getIn(['storyboard', 'addingSite']),
    deletingSite: state.getIn(['storyboard', 'deletingSite'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {showWarning, removeDialog, setCurrentChapter, fetchChapter}
  )(Structure)
);
