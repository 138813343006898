import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Button } from 'react-md';
import {withTranslation} from 'react-i18next';
import { ProcessStatus } from '../../../utils/Constants';
import RolePrime from './RolePrime';
import { addRole, fetchRoles } from '../../../actions/roles';
import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import ActionResponseButton from '../../general/ActionResponseButton';


@withTranslation(['roles', 'general'], {wait: true})
class AddRole extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    const {fetchingRoles, roles} = this.props;
    if (fetchingRoles != ProcessStatus.STARTED && roles.size == 0) this.props.fetchRoles();
  }

  handleSubmit = data => {
    console.log('handleSubmit, data = ', data);

    this.props.addRole(data);
    this.props.closeDrawer();
  };

  render () {
    const { t, fetchingRoles } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'}>
        <Form
          onSubmit={this.handleSubmit}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <div className={'body paddingLeftRight marginTop'}>
                <FormSpy subscription={{values: true}} no component={RolePrime}/>
                <div className={styles.buttonsRightWrapper}>
                  <ActionResponseButton
                    title={''}
                    defaulticon=""
                    type="submit"
                    loading={fetchingRoles === ProcessStatus.STARTED}
                    finished={fetchingRoles === ProcessStatus.FINISHED}
                    text={t(`${generalLNS}:submit`)}
                    deactivated={false}
                    onClick={null}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    roles: state.getIn(['roles', 'roles']),
    fetchingRoles: state.getIn(['roles', 'fetchingRoles'])
  };
}

export default connect(
  mapStateToProps,
  {addRole, fetchRoles}
)(AddRole);
