import * as Rx from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';
import { EngineTypesAction } from '../utils/ActionTypes';
import { receiveEngineTypes } from '../actions/engineTypes';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchEngineTypesEpic = (action$, store) => {
  return action$
    .ofType(EngineTypesAction.FETCH_ENGINE_TYPES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'enumdefaults/projects/engine_type';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map(res => res.data)
        .map(receiveEngineTypes)
        .catch(error =>
          Rx.Observable.of({
            type: EngineTypesAction.FETCH_ENGINE_TYPES_REJECTED,
            payload: error.xhr.response,
            error: true
          })
        );
    });
};

export { fetchEngineTypesEpic };
