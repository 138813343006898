import React, {
  useEffect,
  // useEffect,
  useState
} from 'react';
import styles from '../../styles/storyboards/site.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default function FoldingContainer (props) {
  const [folded, setFolded] = useState(false);

  let className = styles.foldingContainer;

  useEffect(() => {
    if (folded) {
      if (props.hasOwnProperty('onSetSmall')) {
        props.onSetSmall();
      }
    } else {
      if (props.hasOwnProperty('onSetWide')) {
        props.onSetWide();
      }
    }
  }, [folded]);

  if (props.hasOwnProperty('paper') && props.paper) {
    className = className + ' paper';
  }

  if (folded) {
    className = className + ' folded';
  }

  return (
    <div className={className}>
      <div
        className="foldingContainerInner"
        onClick={() => {
          if (!folded) {
            return;
          }
          setFolded(!folded);
        }}
      >
        <div
          className="foldHeader"
          onClick={() => {
            if (folded) {
              return;
            }
            setFolded(!folded);
          }}>
          <button
            className={'foldingBtn'}>
            <ExpandMoreIcon/>
          </button>
          <div className="headline">
            <h1>{props.title}</h1>
          </div>
        </div>
        <div className={'inner'}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
