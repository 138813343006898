import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {List} from 'immutable';
import {NavigationDrawer, FontIcon} from 'react-md';
import {withRouter} from 'react-router-dom';
import {matchPath} from 'react-router';
import {withTranslation} from 'react-i18next';
import {NodeId, Routes} from '../../utils/Constants';
import ContentDrawer from '../../utils/ContentDrawer';
import {hasPermission} from '../../utils/AuthHelper';
import {aclResources} from '../../utils/Permissions';
import AvatarWithInitials from './AvatarWithInitials';
import NavItemLink from './NavItemLink';
import HeaderTitle from './HeaderTitle';
import EditProfileContainer from '../profile/EditProfileContainer';
import ErrorDialog from '../general/dialog/ErrorDialog';
import GlobalDialog from '../general/dialog/GlobalDialog';
import GlobalHelp from '../general/help/GlobalHelp';
import GlobalSnackbar from '../general/GlobalSnackbar';
import {ProcessStatus} from '../../utils/Constants';
import {logoutAsUser, editUserSettings} from '../../actions/auth';
import {setHasHelp} from '../../actions/help';
import helpTexts from '../general/help/helpTexts';

// STYLES
import styles from '../../styles/navigation_content_wrapper.scss';
import helpStyles from '../../styles/help.scss';
import ThemeSwitchBtn from '../general/ThemeSwitchBtn';


@withTranslation('general', {wait: true})
class NavigationContentWrapper extends PureComponent {
  t = this.props.t;

  constructor (props) {
    super(props);
    this.state = {
      isEditProfileVisible: false,
      toolbarActions: null,
      navGroupedItems: null,
      groupNameList: List([
        {label: 'contacts', class: 'top', helpToolId: 20},
        {label: 'logout', class: 'bottom', helpToolId: 21}
      ]),
      darkMode: true,
      helpOpened: false,
      isStoryboardOrHome: false
    };
  }

  componentWillMount () {
    // TODO: @m.orf:
    //  HIER IST DER DARKMODE FÜR /HOME UND /STORYBOARD AUSGESCHALTET,
    //  wenn darkmode für Material-UI Elemente implementiert ist, kann das hier wieder raus!
    if (this.props.history.location.pathname === '/home'
        || this.props.history.location.pathname.startsWith('/storyboards')) {
      this.setState({isStoryboardOrHome: true});
      this.setTheme(false, true);
    }

    if (this.state.helpOpened) {
      setTimeout(() => {
        this.openHelp();
      }, 5000);
    }
  }

  componentDidMount () {
    this.handleSettings(this.props);
    this.checkOnHelpTexts(this.props.location.pathname);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // this.props.editUserSettings(nextProps.user.id, {wasShown: null});

    const {loggingInAsUser, loggingOutAsUser, location, user, history} = nextProps;

    if ((loggingInAsUser === ProcessStatus.FINISHED && loggingInAsUser !== this.props.loggingInAsUser) ||
      (loggingOutAsUser === ProcessStatus.FINISHED && loggingOutAsUser !== this.props.loggingOutAsUser)
    ) {
      this.props.history.push(Routes.HOME);
    }


    if (user && this.props.user === null || user && user.id !== this.props.user.id) {
      // console.log('NavigationContentWrapper:: this.props.user = ', this.props.user);
      this.handleSettings(nextProps);
    }

    if (location.pathname !== this.props.location.pathname) {
      this.checkOnHelpTexts(location.pathname);
    }

    // TODO: @m.orf:
    //  HIER IST DER DARKMODE FÜR /HOME UND /STORYBOARD AUSGESCHALTET,
    //  wenn darkmode für Material-UI Elemente implementiert ist, kann das hier wieder raus!
    if (nextProps.history.location.pathname === '/home'
        || nextProps.history.location.pathname.startsWith('/storyboards')) {
      this.setState({isStoryboardOrHome: true});
      this.setTheme(true, true);
    } else {
      this.setState({isStoryboardOrHome: false});
    }
  }

  handleSettings = (props) => {
    const {user, impersonatedUser} = props;

    let lightMode = false;
    let currentUser = impersonatedUser ? impersonatedUser : user;
    let themeFromSettings = _.get(currentUser, 'settings.theme.adminView');

    if (themeFromSettings) {
      lightMode = themeFromSettings === 'light';
    }
    this.setTheme(lightMode, false);
  };


  checkOnHelpTexts = route => {
    let hasHelp = false;
    for (var k = 0; k < helpTexts.length; k++) {
      if (matchPath(route, {path: helpTexts[k].route})) {
        hasHelp = true;
        break;
      }
    }
    this.props.setHasHelp(hasHelp);
  };

  navItems = [
    {
      label: 'home',
      to: '/home',
      // permission: aclProject.read,
      icon: 'home'
      // icon: 'dashboard'
    }, {
      label: 'projects',
      to: '/projects',
      // permission: aclProject.read,
      icon: 'event_note'
    }, {
      label: 'storyboards',
      to: '/storyboards',
      icon: 'post_add'
    }, {
      label: 'clients',
      to: '/clients',
      permission: aclResources.clients,
      icon: 'domain'
    }, {
      label: 'users',
      to: '/users',
      permission: aclResources.users,
      icon: 'supervisor_account'
    }, {
      label: 'permissions',
      to: '/permissions',
      permission: aclResources.permissions,
      icon: 'gavel'
    }, {
      label: 'roles',
      to: '/roles',
      permission: aclResources.roles,
      icon: 'style'
    }, {
      label: 'contacts',
      to: '/contacts',
      permission: aclResources.contacts,
      icon: 'perm_contact_calendar'
    }, {
      label: 'imprint',
      to: '/imprint',
      icon: 'info_outline'
    }, {
      label: 'data_security',
      to: '/privacy',
      icon: 'verified_user'
    }, {
      label: 'logout',
      to: '/logout',
      icon: 'power_settings_new'
    }
  ];
  /*
  checkOnDarkLightMode = () => {
    // WENN SCHON VORHANDEN
    let mangooluMode = JSON.parse(localStorage.getItem('mangoolu_darkmode'));
    if (mangooluMode) { // WENN LocalStorage
      this.setTheme(mangooluMode.darkMode);
    } else {
      if (this.state.darkMode) { // INITIAL
        this.setTheme(this.state.darkMode);
      }
    }
  };
  */

  setTheme = (lightMode, shouldUpdateUserSettings = true) => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');

    if (lightMode) {
      if (htmlHasClassDark) {
        document.querySelector('html').classList.remove('dark');
      }
    } else {
      if (!htmlHasClassDark) {
        document.querySelector('html').classList.add('dark');
      }
    }

    if (shouldUpdateUserSettings) {
      this.editUserSettings(lightMode);
    }

    this.setState({darkMode: !lightMode});
  };

  /*
  setTheme = (dark) => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (dark) {
      if (!htmlHasClassDark) {
        document.querySelector('html').classList.add('dark');
      }
    } else {
      if (htmlHasClassDark) {
        document.querySelector('html').classList.remove('dark');
      }
    }
    this.setState({darkMode: dark});
  };

  toggleTheme = () => {
    let html = document.querySelector('html');
    let htmlHasClassDark = html.classList.contains('dark');
    if (htmlHasClassDark) {
      html.classList.remove('dark');
    } else {
      html.classList.add('dark');
    }

    this.editUserSettings(htmlHasClassDark);

    this.updateDarkModeLocalStorage();
  };

  updateDarkModeLocalStorage = () => {
    let isDark = document.querySelector('html').classList.contains('dark');
    if (isDark !== this.state.darkmode) {
      this.setState({darkMode: isDark});
    }
    let localStorageObject = {darkMode: isDark};
    localStorage.setItem('mangoolu_darkmode', JSON.stringify(localStorageObject));
  };
  */

  editUserSettings = lightMode => {
    const {user, impersonatedUser} = this.props;

    let userToEdit = impersonatedUser ? impersonatedUser : user;
    let themeInAdminView = lightMode ? 'light' : 'dark';

    let theme = {
      theme: {
        adminView: themeInAdminView
      }
    };

    let settings = _.merge(userToEdit.settings, theme);
    this.props.editUserSettings(userToEdit.id, settings);
  };

  openDrawer = () => {
    this.setState({isEditProfileVisible: true});
  };

  closeDrawer = () => {
    this.setState({isEditProfileVisible: false});
  };

  createListGroups (list) {
    const {groupNameList} = this.state;
    let endList = [];
    let temp = [];
    let chapterHeadline = null;

    list.map(el => {
      chapterHeadline = groupNameList.find(group => group.label === el.label);
      temp.push(el);
      if (chapterHeadline != null) {
        endList.push({
          class: chapterHeadline.class,
          items: temp,
          helpToolId: chapterHeadline.helpToolId
        });
        temp = [];
      }
    });
    return endList;
  }

  openHelp = () => {
    this.setState({
      helpOpened: true
    });
  };

  closeHelp = () => {
    this.setState({
      helpOpened: false
    });
  };

  render () {
    const {helpOpened, darkMode, isStoryboardOrHome} = this.state;
    const {t, hasHelp, user} = this.props;
    let toolbarActions;

    const themeUrl = darkMode
      ? 'url(' + require('../../images/theme_on.svg') + ')'
      : 'url(' + require('../../images/theme_off_dark.svg') + ')';

    console.log('NavigationContentWrapper this.props', this.props);

    if (!user) {
      toolbarActions = null;
    } else {
      toolbarActions = (
        <div className={styles.ncwButtonWrapper}>
          {
            !isStoryboardOrHome
            &&
            <ThemeSwitchBtn
              newIcon={true}
              hasHelp="2"
              translation="general"
              toggleTheme={() => {this.setTheme(darkMode, true);}}
              isDark={darkMode}
            />
          }

          {hasHelp &&
          <button
            help-project-tool="23"
            key={'help'}
            title={t('open_help')}
            className={helpStyles.helpButton}
            onClick={this.openHelp}
          >
            <FontIcon>help</FontIcon>
          </button>
          }
          <GlobalHelp key="4" show={helpOpened} close={this.closeHelp}/>
          <div help-project-tool="22" className={styles.ncwButtonWrapper}>
            <AvatarWithInitials onClick={this.openDrawer}/>
            {user.loggedin_as != null &&
            <FontIcon className={'logginAs'}>supervised_user_circle</FontIcon>
            }
          </div>
        </div>
      );
    }

    // Menü-Items NICHT gruppiert:
    // const newItems = this.navItems.map(props =>
    //     <NavItemLink {...props} key={props.to}/>
    // );

    // Menü-Items gruppiert:
    const navGroupedItems = this.createListGroups(this.navItems).map(group =>
      <div key={group.class} className={styles[group.class]}>
        <div help-project-tool={group.helpToolId.toString()}>
          {
            group.items.map(props => {
              if (props['permission']) {
                const {permission, ...rest} = props;

                if (hasPermission(permission)) {
                  return (<NavItemLink {...rest} key={props.to}/>);
                }
              } else {
                return (<NavItemLink {...props} key={props.to}/>);
              }
            })
          }
        </div>
      </div>
    );


    const editProfile = <EditProfileContainer
      isDark={this.state.darkMode}
      toggleTheme={this.setTheme}
      // logoutAsUser={this.logoutAsUser}
      closeDrawer={this.closeDrawer}/>;

    const children = [
      <ContentDrawer
        key="1"
        children={editProfile}
        visible={this.state.isEditProfileVisible}
        closeDrawer={this.closeDrawer}
      />,
      <ErrorDialog key="2"/>,
      <GlobalDialog key="3"/>,
      <GlobalSnackbar key="4"/>,
      this.props.children
    ];

    return (
      <NavigationDrawer
        className={styles.ncwWrapper}
        navClassName={styles.ncwNav}
        miniNavClassName={styles.ncwMiniNav}
        toolbarTitle={<HeaderTitle/>}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        navItems={navGroupedItems}
        contentId={NodeId.MAIN_CONTAINER}
        contentClassName={'md-grid ' + styles.mainContainer}
        transitionName={'hallo123'}
        children={children}
        toolbarActions={toolbarActions}
        toolbarTitleClassName={styles.ncwWrapperTitle}
        toolbarClassName={styles.ncwWrapperToolbar}
        miniDrawerClassName={styles.ncwMiniDrawer}
        drawerClassName={styles.ncwDrawer}
        darkMode={this.state.darkMode}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.getIn(['auth', 'user']),
    loggingInAsUser: state.getIn(['auth', 'loggingInAsUser']),
    loggingOutAsUser: state.getIn(['auth', 'loggingOutAsUser']),
    hasHelp: state.getIn(['help', 'hasHelp'])
  };
}

export default withRouter(
  connect(mapStateToProps, {logoutAsUser, setHasHelp, editUserSettings})(NavigationContentWrapper)
);
