import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { cryptedStore } from '../../utils/CryptedStore';
import {logout} from '../../actions/auth';
import i18n from '../../utils/i18n';

class LogoutScreen extends Component {
  constructor () {
    super();
    this.displayName = 'LogoutScreen';
  }

  componentWillMount () {
    document.querySelector('html').removeAttribute('class'); // remove DarkTheme
    cryptedStore.removeAll();
    this.props.dispatch(logout());
    i18n.changeLanguage('en', (err) => {
      if (err) return console.log('something went wrong loading', err);
    });
    this.props.history.replace('/');
  }

  render () {
    return null;
  }
}

LogoutScreen.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(withRouter(LogoutScreen));
