import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HeaderTitles } from '../../utils/Constants';
import styles from '../../styles/navigation_header.scss';
import {withTranslation} from 'react-i18next';

@withTranslation('general', {wait: true})
class HeaderTitle extends Component {
  constructor (props) {
    super(props);
  }

  handleClickOnHeaderLink = e => {
    e.preventDefault();
    let dataTarget = e.target.getAttribute('data-target');
    let possibleBacks = ['projects', 'storyboards'];
    if (possibleBacks.indexOf(dataTarget) !== -1) {
      this.props.history.push('/' + dataTarget);
      this.forceUpdate();
    }
  };

  render () {
    const {project, t} = this.props;
    let location = this.props.location.pathname;
    location = location.slice(1, location.length);
    let locationArray = location.split('/');
    let isProjects = locationArray[0] === 'projects'
      || locationArray[0] === 'storyboards';
    let isProjectLink = isProjects && locationArray.length > 1;
    let uppercaseTitle = locationArray[0].toUpperCase();
    let isInHeadTitles = HeaderTitles[uppercaseTitle] !== undefined;

    return (
      <div className={styles.ncwHeader}>
        <img className={'image-light'} src={require('../../images/logo.png')}/>
        <img className={'image-dark'} src={require('../../images/logo-dark.png')}/>

        <span
          level={0}
          link={isProjectLink ? 1 : 0}
          data-target={locationArray[0]}
          onClick={this.handleClickOnHeaderLink}
          className={'header-item'}
        >
          {isInHeadTitles ? t(HeaderTitles[uppercaseTitle]).toUpperCase() : locationArray[0]}
        </span>

        {isProjectLink
        && project
        && locationArray[0] !== 'storyboards' && [
          <span key={'0'} className={'separator'}>
            {' '}
          </span>,
          <span key={'1'} className={'header-item'}>
            {project.name.toUpperCase()}
          </span>
        ]}
        {isProjectLink && locationArray[0] === 'storyboards' && [
          <span key={'0'} className={'separator'}>
            {' '}
          </span>,
          <span key={'1'} className={'header-item'}>test-Storyboard</span>
        ]}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default withRouter(connect(mapStateToProps)(HeaderTitle));
