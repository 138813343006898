import { changeDatetimeToDate } from '../utils/HelperFunctions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction
} from './defaultFunctions';
import {aclTranslation} from '../utils/Permissions';

/**
 * User Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'name',
      translation_id: 'name',
      component: 'additionalName'
    },
    {
      id: 'preview',
      translation_id: 'preview',
      sortable: 'false',
      component: 'pdfPreview'
    },
    {
      id: 'pdfActions',
      translation_id: 'actions',
      sortable: 'false',
      textAlign: 'right',
      component: 'pdfActions'
    }
    // ,
    // {
    //   id: 'active',
    //   translation_id: 'active',
    //   sortable: 'false',
    //   textAlign: 'right',
    //   permissions: [aclTranslation.activate]
    // }
  ],

  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name']
};

export default model;
