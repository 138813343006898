import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';

import { Card, CardActions, CardText, CardTitle, Button } from 'react-md';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { validateTranslation } from '../../../actions/projects';
import styles from '../../../styles/project_detail.scss';
import {discardUpload} from '../../../actions/uploader';

@withTranslation(['project', 'general'], {wait: true})
class ValidateUpload extends Component {
  constructor (props) {
    super(props);

    this.state = {
      translationData: null
    };
  }

  componentWillMount () {
    console.log('componentWillMount, this.props = ', this.props);
    this.validateTranslation();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    console.log('componentWillReceiveProps, nextProps = ', nextProps);

    if (
      nextProps.validatingTranslation == ProcessStatus.FINISHED &&
      nextProps.validatingTranslation != this.props.validatingTranslation
    ) {
      this.props.onChange(this.state.translationData);
    }
  }

  validateTranslation = () => {
    const translationData = this.setTranslationData();
    this.setState({translationData: translationData});
    this.props.validateTranslation(translationData);
  };

  setTranslationData = () => {
    const {project, translation, uploadedFile} = this.props;

    let parentTranslationId = translation.get('parent_id');
    let parentTranslation = project.translations.find(
      translation => translation.id == parentTranslationId
    );

    console.log("translation.get('parent_id')", translation.get('parent_id'));
    console.log("translation.get('languagecode')", translation.get('languagecode'));
    console.log('parentTranslation', parentTranslation);

    return {
      project_id: project.id,
      project_name: project.name,
      parent_translation_id: parentTranslationId,
      parent_translation_lc: parentTranslation ? parentTranslation.languagecode : null,
      possible_translation_id: translation.get('id'),
      suggested_import_lc: translation.get('languagecode'),
      uuid: uploadedFile.uuid,
      filename: uploadedFile.name
    };
  };

  discardTranslation = () => {
    const {uploadedFile} = this.props;

    if (this.props.onDiscard) {
      this.props.onDiscard();
    }

    this.props.discardUpload(uploadedFile.uuid);
  };

  render () {
    const {uploadedFile, validatingTranslation, error, t, errorObject} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let errorMessage = t(`${generalLNS}:error`);

    // console.log('errorObject: ', errorObject);
    // console.log('this.props', this.props);
    console.log('error', error);

    if (errorObject && errorObject.notify && errorObject.notify.error) {
      errorMessage = errorObject.notify.error[0];
    }

    let subtitle = t('question_subtitle');

    return (
      <Card className="md-block-centered">
        <CardTitle title={uploadedFile.name} subtitle={subtitle}/>
        <CardText>
          {validatingTranslation == ProcessStatus.STARTED && (
            <LoadingBar
              show="true"
              wrapperClassname={styles.loadingBarWrapper}
              message={ t('validating_translation') }
            />
          )}
          {validatingTranslation == ProcessStatus.FINISHED && (
            <div>
              <p>{ t('translation_validated') }</p>
            </div>
          )}
          {validatingTranslation == ProcessStatus.FAILED && (
            <div>
              <p>{ t('translation_failed') }: {errorMessage}</p>
            </div>
          )}
        </CardText>
        {validatingTranslation == ProcessStatus.FAILED && (
          <CardActions>
            <Button key="discard" flat onClick={this.discardTranslation}>
              { t(`${generalLNS}:discard`) }
            </Button>
            <Button key="try_again" flat onClick={this.validateTranslation}>
              { t(`${generalLNS}:try_again`) }
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    translation: state.getIn(['projects', 'translation']),
    validatingTranslation: state.getIn(['projects', 'validatingTranslation']),
    errorObject: state.getIn(['projects', 'error'])
  };
}

export default connect(
  mapStateToProps,
  {
    validateTranslation,
    discardUpload
  }
)(ValidateUpload);
