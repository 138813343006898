import React, {Component} from 'react';
import {connect} from 'react-redux';

import {validateUpdate, importUpdate} from '../../../actions/projects';
import LoadingBar from '../../general/DumbComponent';
import {required} from '../../../utils/ValidationRules';
import ValidateUpload from './ValidateUpload';
import UploadComponent from '../../general/UploadComponent';
import {Button, SelectionControlGroup} from 'react-md';
import {ProcessStatus} from '../../../utils/Constants';
import styles from '../../../styles/project_detail.scss';
import {withTranslation} from 'react-i18next';

@withTranslation(['project', 'general', 'export'], {wait: true})
class UpdateProject extends Component {
  constructor (props) {
    super(props);

    this.state = {
      uploadData: null,
      uploadedFile: null,
      mode: null,
      extractPath: '',
      isValidated: false,
      isImported: false,
      importMessage: null,
      showUpload: false
    };
  }

  t = this.props.t;
  radioControls = [
    {
      label: this.t(`${'general'}:please_choose`),
      value: ''
    },
    {
      label: this.t(`${'general'}:package`),
      value: 'package_update'
    },
    {
      label: this.t(`${'general'}:structure`),
      value: 'master_structure_update'
    }
  ];

  componentWillMount () {
    this.setState({mode: this.radioControls[0].value});
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {importingUpdate} = nextProps;
    const {t} = this.props;

    if (importingUpdate === ProcessStatus.FINISHED &&
      importingUpdate !== this.props.importingUpdate) {
      this.setState({isImported: true, importMessage: t('import_completed')});
    } else if (importingUpdate === ProcessStatus.FAILED &&
      importingUpdate !== this.props.importingUpdate) {
      this.setState({isImported: true, importMessage: t('import_error')});
    }
  }

  handleModeChange = mode => {
    console.log('handleModeChange', mode);
    this.setState({mode: mode});
  };

  handleDelete = uuid => {
    console.log('ist deleted', uuid);
    this.setState({uploadedFile: null});
  };

  handleChange = uploadData => {
    console.log('handleChange', uploadData);
    this.setState({uploadData: uploadData});
  };

  handleValidateChange = extractPath => {
    console.log('handleValidateChange', extractPath);
    this.setState({isValidated: true, extractPath: extractPath});
  };

  handleValidateDiscard = () => {
    console.log('handleValidateDiscard');
    // this.setState({extractPath: extractPath});

    this.handleDelete();
  };

  validateUpdate = () => {
    const {projectId} = this.props;
    const {mode, uploadData} = this.state;

    this.props.validateUpdate({
      project_id: projectId,
      package_type: 'jsbeam_1_0',
      uuid: uploadData.uuid,
      filename: uploadData.name,
      mode: mode
    });
  };

  importUpdate = () => {
    const {projectId} = this.props;
    const {mode, uploadData, extractPath} = this.state;

    this.props.importUpdate({
      project_id: projectId,
      package_type: 'jsbeam_1_0',
      uuid: uploadData.uuid,
      filename: uploadData.name,
      extractPath: extractPath,
      mode: mode
    });
  };

  showUploadComponent = () => {
    this.setState({showUpload: true});
  };


  render () {
    const {importingUpdate, t} = this.props;
    const {mode, uploadData, isValidated, isImported, importMessage, showUpload} = this.state;

    console.log('mode', mode);

    return (
      <div>
        <div className={'head'} style={{marginTop: '20px'}}>
          <div className={'formHeader headlines no-margin'}>
            <h2>{t('update_project')}</h2>
          </div>
        </div>
        <SelectionControlGroup
          id="mode"
          name="mode"
          label={t('question_project_update_type')}
          type="radio"
          className="md-cell md-cell--12"
          value={mode}
          // defaultValue={pristine ? initial : input.value}
          // defaultValue={initial}
          onChange={this.handleModeChange}
          onClick={this.showUploadComponent}
          controls={this.radioControls}
        />
        { mode === 'package_update' &&
          <div style={{maxWidth: '316px', padding: '20px'}}>{t('update_info')}</div>
        }
        {
          showUpload && mode !== '' &&
          <div style={{padding: '20px'}}>
            <UploadComponent
              id="uploadedFile"
              name="uploadedFile"
              type="text"
              className="md-cell md-cell--12"
              label={t('uploaded_file')}
              // value={input.value}
              // error={touched && error}
              onChange={this.handleChange}
              validate={required}
              onDelete={this.handleDelete}
              errorText={t('hello_again')}
            />
            {uploadData &&
            <ValidateUpload
              id="extract_path"
              uploadedFile={uploadData}
              validate={required}
              name="extractPath"
              type="text"
              className="md-cell md-cell--12"
              // value={input.value}
              // error={touched && error}
              // {...custom}
              onChange={this.handleValidateChange}
              onDiscard={this.handleValidateDiscard}
              validateUpdate={this.validateUpdate}
            />
            }
            {isValidated && !isImported &&
            <Button
              key="import"
              flat
              disabled={importingUpdate === ProcessStatus.STARTED}
              onClick={this.importUpdate}>
              Import
            </Button>
            }
            {importingUpdate == ProcessStatus.STARTED && (
              <LoadingBar
                show="true"
                wrapperClassname={styles.loadingBarWrapper}
                message={t('importing_update')}
              />
            )}
            {isImported &&
            <div>
              <span>{importMessage}</span>
            </div>
            }
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    importingUpdate: state.getIn(['projects', 'importingUpdate'])
  };
}

export default connect(
  mapStateToProps,
  {validateUpdate, importUpdate}
)(UpdateProject);
