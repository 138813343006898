import React, { Component } from 'react';
import { RichUtils, EditorState } from 'draft-js';
import {DraftUtils} from 'draftail';
import EditorUtils from 'draft-js-plugins-utils';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';
import LinkSource from '../helper/LinkSource';

@withTranslation(['translation_navigation'], {wait: true})
class LinkButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sourceOptions: null
    };
    this.toolbarLinkButton = null;
  }

  onCompleteSource = (nextState) => {
    const {setEditorState} = this.props;
    console.log('this.props', this.props);
    this.setState({sourceOptions: null}, () => {
      if (nextState) {
        console.log('nextState', nextState);
        setEditorState(nextState);
      }
    });
  };

  onCloseSource = () => {
    this.setState({sourceOptions: null});
  };

  onClick = () => {
    console.log('----------------------------> LINK onClick');
    // e.preventDefault();
    // e.stopPropagation();
    const { getEditorState } = this.props;
    const editorState = getEditorState();
    var contentState = editorState.getCurrentContent();
    var entityKey = DraftUtils.getSelectionEntity(editorState);

    setTimeout(() => {
      this.setState({
        sourceOptions: {
          entityType: 'LINK',
          entityKey: entityKey,
          entity: entityKey ? contentState.getEntity(entityKey) : null
        }
      });
    }, 100);
  };

  // createLinkAtSelection = (editorState, url) => {
  //   var contentState = editorState.getCurrentContent().createEntity('LINK', 'MUTABLE', { url: url });
  //   var entityKey = contentState.getLastCreatedEntityKey();
  //   var withLink = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey);
  //   return EditorState.forceSelection(withLink, editorState.getSelection());
  // };
  // removeLinkAtSelection = editorState => {
  //   var selection = editorState.getSelection();
  //   return RichUtils.toggleLink(editorState, selection, null);
  // };

  getCurrentEntityKey = editorState => {
    var selection = editorState.getSelection();
    var anchorKey = selection.getAnchorKey();
    var contentState = editorState.getCurrentContent();
    var anchorBlock = contentState.getBlockForKey(anchorKey);
    var offset = selection.anchorOffset;
    var index = selection.isBackward ? offset - 1 : offset;
    return anchorBlock.getEntityAt(index);
  };

  getCurrentEntity = editorState => {
    var contentState = editorState.getCurrentContent();
    var entityKey = this.getCurrentEntityKey(editorState);
    return entityKey ? contentState.getEntity(entityKey) : null;
  };

  hasEntity = (editorState, entityType) => {
    var entity = this.getCurrentEntity(editorState);
    return entity && entity.getType() === entityType;
  };

  onRemoveLinkAtSelection = () => {
    const {setEditorState, getEditorState} = this.props;
    setEditorState(
      EditorUtils.removeLinkAtSelection(getEditorState())
    );
  };

  render () {
    const { sourceOptions} = this.state;
    const { getEditorState} = this.props;
    const editorState = getEditorState();
    const hasLinkSelected = this.hasEntity(editorState, 'LINK') !== null;

    // let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const clickAction = hasLinkSelected ? this.onRemoveLinkAtSelection : this.onClick;
    return (
      <>
        <span
          className={'button toolbar-button unvisible addLinkBtn_' + this.props.editorId}
          ref={span => (this.toolbarLinkButton = span)}
          onMouseDown={e => e.preventDefault()}  // FIREFOX-PROBLEM: HATTE PROBLEM MIT onClick
          onClick={clickAction}
          data-active={hasLinkSelected.toString()}
          title={this.props.description}
        ><FontIcon>insert_link</FontIcon></span>
        {sourceOptions !== null &&
          <LinkSource
            focusEditor={this.props.focusEditor}
            editorState={editorState}
            onComplete={this.onCompleteSource}
            onClose={this.onCloseSource}
            entity={sourceOptions.entity}
            entityKey={sourceOptions.entityKey}
            entityType={sourceOptions.entityType}
          />
        }
      </>

    );
  }
}

export default LinkButton;
