import {SourceSnapshotsAction} from '../utils/ActionTypes';
import * as Rx from 'rxjs';

const fetchSourceSnapshots = (projectId, translationId, sourceSnapshotId = -1) => {
  return {
    type: SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS,
    projectId: projectId,
    translationId: translationId,
    sourceSnapshotId: sourceSnapshotId
  };
};

const fetchSourceSnapshotsFulfilled = data => {
  return {
    type: SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS_FULFILLED,
    sourceSnapshots: data
  };
};

const fetchSourceSnapshotsRejected = error => {
  return Rx.Observable.of({
    type: SourceSnapshotsAction.FETCH_SOURCE_SNAPSHOTS_REJECTED,
    payload: error.xhr ? error.xhr.response : error,
    error: true
  });
};

const setSourceSnapshotId = id => {
  return {
    type: SourceSnapshotsAction.SET_SOURCE_SNAPSHOT_ID,
    id: id
  };
};

export {
  fetchSourceSnapshots,
  fetchSourceSnapshotsFulfilled,
  fetchSourceSnapshotsRejected,
  setSourceSnapshotId
};
