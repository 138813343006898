import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import { deleteTranslation } from '../../../actions/projects';
import { removeDialog } from '../../../actions/globalDialog';


@withTranslation(['project', 'general'], {wait: true})
class DeleteTranslationWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let deletingTranslation = nextProps.deletingTranslation;
    if (
      deletingTranslation == ProcessStatus.FINISHED &&
      deletingTranslation != this.props.deletingTranslation
    ) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  delete = () => {
    let projectId = this.props.projectId;
    let translationId = this.props.translationId;

    this.props.deleteTranslation({projectId: projectId, translationId: translationId});
  };

  render () {
    const {deletingTranslation, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (deletingTranslation == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t(`${generalLNS}:cancel`) }
        </Button>
        <Button flat onClick={this.delete}>
          { t(`${generalLNS}:delete`) }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    deletingTranslation: state.getIn(['projects', 'deletingTranslation']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {deleteTranslation, removeDialog}
)(DeleteTranslationWarning);
