import { ajax } from 'rxjs/observable/dom/ajax';
import { TranslationToolAction } from '../utils/ActionTypes';

import {
  fetchTranslationsFulfilled,
  fetchTranslationsRejected,
  fetchAdditionalContentTranslationsFulfilled,
  fetchAdditionalContentTranslationsRejected,
  saveTranslationSuccess,
  saveTranslationError
} from '../actions/translationTool';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';


const fetchTranslationsEpic = (action$, store) => {
  return action$
    .ofType(TranslationToolAction.FETCH_TRANSLATIONS)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'texts/external/processtextsonstage';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.data})
        .map(res => res.response)
        .map(fetchTranslationsFulfilled)
        .catch(fetchTranslationsRejected);
    });
};

const fetchAdditionalContentTranslationsEpic = (action$, store) => {
  return action$
    .ofType(TranslationToolAction.FETCH_ADDITIONAL_CONTENT_TRANSLATIONS)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'texts/external/processtextsonstagebycollection';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.data})
        .map(res => res.response)
        .map(fetchAdditionalContentTranslationsFulfilled)
        .catch(fetchAdditionalContentTranslationsRejected);
    });
};

// https://redux-observable.js.org/docs/basics/Epics.html
const saveTranslationEpic = (action$, store) => {
  return action$
    .ofType(TranslationToolAction.SAVE_TRANSLATION)
    // action$.filter(action => action.type === 'TranslationToolAction.SAVE_TRANSLATION')
    .throttleTime(300) // TODO: eher "throttleTime" verwenden
    .mergeMap(action => {
      let url = baseApiUrl + 'texts/external';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.data})
        .map(() => action.data)
        .map(saveTranslationSuccess) // mappt data auf saveTranslations und returnt diese action
        // .do(() => action.onSuccessCallback()) // Nebeneffekt
        .catch(saveTranslationError);
    });

  // Once you're inside your Epic, use any Observable patterns you desire
  // as long as anything output from the final, returned stream, is an action

  // The actions you emit will be immediately dispatched through the normal store.dispatch(),
  // so under the hood redux-observable effectively does epic(action$, store).subscribe(store.dispatch)
};

export {
  fetchTranslationsEpic,
  fetchAdditionalContentTranslationsEpic,
  saveTranslationEpic };
