import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';
import EditProject from './EditProject';
import ProjectConfig from './ProjectConfig';
import ProjectUsers from './ProjectUsers';
import ProjectExportTypes from './ProjectExportTypes';
import styles from '../../../styles/project_detail.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';
import stylesTabs from '../../../styles/tabs.scss';
import {aclFilter, matchesPermissions} from '../../../utils/ACL';
import {aclProject} from '../../../utils/Permissions';
import {Scope} from '../../../utils/constants/ACL';

@withTranslation(['project', 'general'], {wait: true})
class EditProjectContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tabIndex: 0,
      tabCount: matchesPermissions([aclProject.config], Scope.PROJECT) ? 3 : 2
    };
  }

  setTab = tabIndex => {
    this.setState({tabIndex: tabIndex});
  };

  render () {
    const {project, t} = this.props;
    const {tabIndex, tabCount} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let transformValue = (100 * (tabIndex / tabCount)) * -1;
    let tabContainerTransformValue = 'translateX(' + transformValue + '%) translateZ(0)';
    let tabContainerWidth = 'calc(100% * ' + tabCount + ')';

    return (
      <div className={stylesDrawer.drawer + ' editDrawer'} style={{minWidth: '80vw'}}>
        <div className={'head'}>
          <div className={'headlines'}>
            <h2>{project.name}</h2>
            <h4>
              {project.type} | {project.version}
            </h4>
          </div>
        </div>

        <div className={stylesTabs.tabBtnRow}>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 0).toString()}
            onClick={() => {
              this.setTab(0);
            }}>{t(`${generalLNS}:properties`)}</button>
          <button
            className={'tabBtn'}
            is-active={(tabIndex === 1).toString()}
            onClick={() => {
              this.setTab(1);
            }}>{t(`${generalLNS}:relations`)}</button>
          {aclFilter(
            <button
              className={'tabBtn'}
              is-active={(tabIndex === 2).toString()}
              onClick={() => {
                this.setTab(2);
              }}>{t(`${generalLNS}:config`)}</button>
          )([], [aclProject.config], Scope.PROJECT)}
        </div>

        <div className={stylesTabs.tabContainerRow}>
          <div
            className="tabContainerWrapper"
            style={{
              transform: tabContainerTransformValue,
              width: tabContainerWidth
            }}>
            <div className={'tabContainer'}>
              <EditProject/>
            </div>
            <div className={'tabContainer'}>
              <div className={styles.formContentWrapper + ' ' + styles.flexHorizontalWrapper}>
                <div className={'column'}>
                  <ProjectUsers/>
                </div>
                <div className={'column'}>
                  <ProjectExportTypes/>
                </div>
              </div>
            </div>
            {aclFilter(
              <div className={'tabContainer'}>
                <ProjectConfig projectId={project.id}/>
              </div>
            )([], [aclProject.config], Scope.PROJECT)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    project: state.getIn(['projects', 'selectedProject'])
  };
}

export default connect(
  mapStateToProps,
  {}
)(EditProjectContainer);
