import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Snackbar} from 'react-md';

import {addToast, removeToast} from '../../actions/globalSnackbar';

class GlobalSnackbar extends PureComponent {
  static propTypes = {
    mobile: PropTypes.bool
  };

  constructor (props) {
    super(props);
  }

  dismissToast = () => {
    this.props.removeToast();
  };

  render () {
    const {toasts} = this.props;

    let autohide = toasts.size > 0 ? toasts.get(0).autohide : true;

    return (
      <Snackbar
        id="global-snackbar"
        toasts={toasts.toArray()}
        autohide={autohide}
        onDismiss={this.dismissToast}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    toasts: state.getIn(['globalSnackbar', 'toasts'])
  };
}

export default connect(
  mapStateToProps,
  {addToast, removeToast}
)(GlobalSnackbar);
