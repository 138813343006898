import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';

import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';

import PermissionPrime from './PermissionPrime';

import {editPermission} from '../../../actions/permissions';

import styles from '../../../styles/project_detail.scss';


@withTranslation(['permissions', 'general'], {wait: true})
class EditPermission extends Component {
  constructor (props) {
    super(props);

    this.state = {
      editMode: false,
      permission: null
    };
  }

  componentWillMount () {
    const {permissions, permissionId} = this.props;

    if (permissions.size >= 0) {
      this.setState({'permission': permissions.find(permission => permission.id == permissionId)});
    }
  }

  handleSubmit = (permission) => {
    this.props.editPermission(permission);
    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({editMode: !this.state.editMode});
  };

  render () {
    const {editingPermission, t} = this.props;
    const {editMode, permission} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    if (false) {
      return (
        <LoadingBar show='true'/>
      );
    }

    let initialValues = permission;

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
            <div className={styles.formContentWrapper}>
              {false && editingPermission == ProcessStatus.STARTED &&
              <LoadingBar
                show="true"
                wrapperClassname={styles.loadingBarWrapper}
                message={ t(`${generalLNS}:updating_permission`) }/>
              }
              <div className={styles.buttonsRightWrapper + ' ' + styles.buttonsRightWrapperInTab}>
                <Button
                  title={ t(`${generalLNS}:edit_mode`) }
                  className={styles.editButton + ' ' + styles.editButtonFilled}
                  style={{display: !editMode ? 'block' : 'none'}}
                  onClick={this.switchEditMode}
                  icon primary swapTheming>mode_edit</Button>

                <Button
                  children={ t(`${generalLNS}:save_all_edits`) }
                  type="submit"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formSubmitButton}
                  disabled={editingPermission == ProcessStatus.STARTED}
                  flat primary swapTheming/>

                <Button
                  children={ t(`${generalLNS}:cancel`) }
                  type="button"
                  style={{display: editMode ? 'block' : 'none'}}
                  className={styles.formCancelButton}
                  onClick={this.switchEditMode}
                  disabled={editingPermission == ProcessStatus.STARTED}
                  flat primary/>
              </div>
              <div className={styles.flexHorizontalWrapper}>
                <FormSpy
                  subscription={{values: true}}
                  editMode={editMode}
                  component={PermissionPrime}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}


function mapStateToProps (state) {
  return {
    permissions: state.getIn(['permissions', 'permissions']),
    editingPermission: state.getIn(['permissions', 'editingPermission'])
  };
}

export default connect(mapStateToProps, {editPermission})(EditPermission);
