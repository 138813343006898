import {Observable} from 'rxjs';
import {ajax} from 'rxjs/observable/dom/ajax';

import {AuthAction} from '../utils/ActionTypes';
import {
  authenticate,
  loginRejected,
  loginAsUserFulfilled,
  loginAsUserRejected,
  logoutAsUserFulfilled,
  logoutAsUserRejected,
  logoutFulfilled,
  logoutRejected,
  resetPasswordFulfilled,
  resetPasswordRejected,
  requestResetPasswordFulfilled,
  requestResetPasswordRejected,
  editUserSettingsFulfilled,
  editUserSettingsRejected,
  updateUserDataFulfilled,
  updateUserDataRejected,
  getUsernameByTokenFulfilled,
  getUsernameByTokenRejected
} from '../actions/auth';
import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';

const loginEpic = (action$, store) => {
  return action$
    .ofType(AuthAction.LOGIN)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'login';
      let body = {
        username: action.formData.username,
        password: action.formData.password
      };
      return ajax({url, method: 'POST', body: body})
        .map(res => res.response)
        .map(authenticate)
        .catch(loginRejected);
    });
};

const loginAsUserEpic = (action$, store) => {
  return action$.ofType(AuthAction.LOGIN_AS_USER)
    .debounceTime(250)
    .mergeMap(action => {
      let url = baseApiUrl + 'admin/loginas';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: {user_id: action.userId}})
        .map(res => res.response)
        .map(loginAsUserFulfilled)
        .catch(loginAsUserRejected);
    });
};

const logoutAsUserEpic = (action$, store) => {
  return action$
    .ofType(AuthAction.LOGOUT_AS_USER)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'admin/logoutas';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: {user_id: action.userId}})
        .map(res => res.response)
        .map(logoutAsUserFulfilled)
        .catch(logoutAsUserRejected);
    });
};

const logoutEpic = (action$, store) => {
  return action$
    .ofType(AuthAction.LOGOUT)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'logout';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'GET', headers: headers})
        .map(res => res.response)
        .map(logoutFulfilled)
        .catch(logoutRejected);
    });
};

/*
* Route::post('/password/requestreset', array('as' => 'v1.user.password.requestreset', 'uses' => 'UserController@requestResetPassword'));
            Route::post('/password/reset', array('as' => 'v1.user.password.reset', 'uses' => 'UserController@resetPassword'));
* */

const requestResetPasswordEpic = action$ => {
  return action$
    .ofType(AuthAction.REQUEST_RESET_PASSWORD)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'user/password/requestreset';
      let body = {
        email: action.formData.email
      };
      return ajax({url, method: 'POST', body: body})
        .map(res => res.response)
        .map(requestResetPasswordFulfilled)
        .catch(requestResetPasswordRejected);
    });
};

const resetPasswordEpic = action$ => {
  return action$
    .ofType(AuthAction.RESET_PASSWORD)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'user/password/reset';
      let body = {
        token: action.formData.token,
        password: action.formData.new_password,
        password_confirm: action.formData.confirm_new_password
      };
      return ajax({url, method: 'POST', body: body})
        .map(res => res.response)
        .map(resetPasswordFulfilled)
        .catch(resetPasswordRejected);
    });
};

const editUserSettingsEpic = (action$, store) => {
  return action$.ofType(AuthAction.EDIT_USER_SETTINGS)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'user/settings';
      let headers = getHttpAuthHeaders(store, url);
      console.log('action.settings', action.settings);
      return ajax({url, method: 'PUT', headers: headers, body: {'settings': JSON.stringify(action.settings)}})
        .map(res => res.response.data)
        .map(() => editUserSettingsFulfilled(action))
        .catch(editUserSettingsRejected);
    });
};

const updateStoreUserEpic = (action$, store) => {
  return action$.ofType(AuthAction.UPDATE_STORE_USER)
    .debounceTime(500)
    .mergeMap((action) => {
      let url = baseApiUrl + 'users/' + action.user.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'PUT', headers: headers, body: action.user})
        .map(res => res.response.data)
        .map(() => updateUserDataFulfilled(action))
        .catch(updateUserDataRejected);
    });
};

const getUsernameByTokenEpic = (action$, store) => {
  return action$.ofType(AuthAction.GET_USERNAME_BY_TOKEN)
    .debounceTime(250)
    .mergeMap(action => {
      let url = baseApiUrl + 'login/' + action.token;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers})
        .map(res => res.response.data)
        .map(getUsernameByTokenFulfilled)
        .catch(getUsernameByTokenRejected);
    });
};

export {
  loginEpic,
  loginAsUserEpic,
  logoutAsUserEpic,
  logoutEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  editUserSettingsEpic,
  updateStoreUserEpic,
  getUsernameByTokenEpic
};
