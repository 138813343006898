import {changeDatetimeToDate, getTimeOutOfDate} from '../utils/HelperFunctions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction, defaultPrepareFiltersFunction
} from './defaultFunctions';
import {aclProject} from '../utils/Permissions';

/**
 * Project Model
 * @type {*[]}
 */
const model = {

  columns: [{
    id: 'type_label',
    // name: 'Type'
    translation_id: 'type'
  }, {
    id: 'client_id',
    // name: 'Client',
    translation_id: 'client',
    proxy: 'clients',
    proxyProp: 'name'
  }, {
    id: 'name',
    // name: 'Name'
    translation_id: 'name'
  }, {
    id: 'created_at',
    // name: 'Created',
    translation_id: 'created',
    format: changeDatetimeToDate,
    time: true
    // numeric: true
  }, {
    id: 'updated_at',
    // name: 'Updated',
    translation_id: 'updated',
    format: changeDatetimeToDate,
    time: true
    // numeric: true
  },
  //   {
  //   id: 'pinAction',
  //   translation_id: 'favorite',
  //   sortable: 'false',
  //   textAlign: 'right',
  //   component: 'pinAction'
  // },
  /*{
    id: 'active',
    // name: 'Active',
    translation_id: 'active',
    permissions: [aclProject.activate]
  }*/],

  filters: [{
    // label: 'Client',
    translation_id: 'client',
    id: 'client_id',
    placeholder: 'Choose a client',
    proxy: 'clients',
    proxyProp: 'name'
  }, {
    // label: 'Type',
    translation_id: 'type',
    placeholder: 'Choose a type',
    id: 'type_label'
  }],


  prepareFilters: defaultPrepareFiltersFunction,
  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name'],

  initSortType: 'updated_at',
  initSortOrder: 'desc',
  initRowsPerPage: 7
};

export default model;
