import { EngineTypesAction } from '../utils/ActionTypes';

const fetchEngineTypes = () => {
  return {
    type: EngineTypesAction.FETCH_ENGINE_TYPES,
    data: {}
  };
};

const receiveEngineTypes = data => {
  return {
    type: EngineTypesAction.FETCH_ENGINE_TYPES_FULFILLED,
    engineTypes: data,
    receivedAt: Date.now()
  };
};

export { fetchEngineTypes, receiveEngineTypes };
