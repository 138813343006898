import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction,
  defaultPrepareFiltersFunction
} from './defaultFunctions';
import {aclContact} from '../utils/Permissions';

/**
 * Contacts Model
 * @type {*[]}
 */
const model = {
  columns: [
    {
      id: 'id',
      translation_id: 'id'
      // name: 'Id'
    },
    {
      id: 'project_leader_name',
      translation_id: 'project_leader_name'
      // name: 'Project leader name'
    },
    {
      id: 'pl_phone_number',
      // name: 'Phone number',
      translation_id: 'phone_number',
      sortable: 'false'
    },
    {
      id: 'pl_email',
      // name: 'Email',
      translation_id: 'email',
      sortable: 'false'
    },
    {
      id: 'company_name',
      translation_id: 'company'
      // name: 'Company'
    },
    {
      id: 'actions',
      // name: 'Actions',
      translation_id: 'actions',
      textAlign: 'right',
      permissions: [aclContact.delete, aclContact.update],
      sortable: 'false'
    }
  ],

  filters: [
    {
      // label: 'Company',
      translation_id: 'company',
      primaryText: 'Company',
      id: 'company_name'
    },
    {
      // label: 'Email',
      translation_id: 'email',
      primaryText: 'Email',
      id: 'pl_email'
    }
  ],

  prepareFilters: defaultPrepareFiltersFunction,
  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['project_leader_name', 'company_name', 'pl_email'],

  initRowsPerPage: 12
};

export default model;
