import { ViewportsAction } from '../utils/ActionTypes';

const fetchViewports = () => {
  return {
    type: ViewportsAction.FETCH_VIEWPORTS,
    data: {}
  };
};

const receiveViewports = data => {
  return {
    type: ViewportsAction.FETCH_VIEWPORTS_FULFILLED,
    viewports: data,
    receivedAt: Date.now()
  };
};

const updateWidth = data => {
  return {
    type: ViewportsAction.UPDATE_WIDTH,
    width: data
  };
};

const updateHeight = data => {
  return {
    type: ViewportsAction.UPDATE_HEIGHT,
    height: data
  };
};

const updateViewport = data => {
  return {
    type: ViewportsAction.UPDATE_VIEWPORT,
    viewport: data
  };
};

const setCustomViewport = () => {
  return {
    type: ViewportsAction.SET_CUSTOM_VIEWPORT
  };
};

export {
  fetchViewports,
  receiveViewports,
  updateWidth,
  updateHeight,
  updateViewport,
  setCustomViewport
};
