import { ajax } from 'rxjs/observable/dom/ajax';
import { I18nAction } from '../utils/ActionTypes';

import {
  fetchAvailableTranslationsFulfilled,
  fetchAvailableTranslationsRejected
} from '../actions/i18n';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const availableTranslationsEpic = (action$, store) => {
  return action$
    .ofType(I18nAction.FETCH_AVAILABLE_TRANSLATIONS)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'translations/available';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'GET', headers: headers })
        .map(res => res.response.data)
        .map(fetchAvailableTranslationsFulfilled)
        .catch(fetchAvailableTranslationsRejected);
    });
};

export { availableTranslationsEpic };
