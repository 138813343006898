import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {diffWords} from 'diff';
import { markSearchTerm, parseCode, parseTags } from '../../utils/RTEParser';
import StandardWithoutContentTools from './StandardWithoutContentTools';
import ContentTools from './ContentTools';
import { renderToString } from 'react-dom/server';

import { addUnsavedText, removeUnsavedText } from '../../actions/fieldLevel';

import styles from '../../styles/translationTool/translationTool.scss';
import {connect} from 'react-redux';

const COMPARE = 'compare';
const FROM = 'from';
const TO = 'to';


@withTranslation('translation_navigation', {wait: true})
class FieldSection extends Component {
  tweenTimeout = null;

  constructor (props) {
    super(props);

    this.state = {
      isRTEVisible: false,
      areContentToolsVisible: false,
      indicator: false
    };
  }

  componentWillUnmount () {
    const {isRTEVisible} = this.state;
    const {scrollTo} = this.props;

    if (scrollTo.collection === ''
    || scrollTo.textfield_id === '') {

    }

    if (isRTEVisible) {
      this.removeUnsavedText();
    }
  }

  // checkForSelected = props => {
  //   const {scrollTo, collectionId, from} = props;
  //   const {isRTEVisible} = this.state;
  //
  //   if (!isRTEVisible && scrollTo.collection == collectionId &&
  //     scrollTo.textfield_id == from.id) {
  //     this.showRTE();
  //   }
  // };

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {refName} = this.props;
    const {scrollTo, unsavedTexts} = nextProps;

    const {isRTEVisible} = this.state;

    if (!isRTEVisible) {
      if (scrollTo.hasOwnProperty('collection')
        && scrollTo.hasOwnProperty('textfield_id')
        && scrollTo.collection === this.props.collectionId
        && scrollTo.textfield_id === this.props.from.id) {
        this.showRTE();
      }
    }

    if (isRTEVisible && !unsavedTexts.toArray().includes(refName)) {
      this.hideRTE();
    }
  }


  shouldComponentUpdate (nextProps, nextState) {
    const {areTagsShown, areOnlyDifferencesShown, areDifferencesHighlighted, searchTerm} = nextProps;
    const {isRTEVisible, areContentToolsVisible} = nextState;

    if (
      searchTerm !== this.props.searchTerm ||
      areTagsShown !== this.props.areTagsShown ||
      areOnlyDifferencesShown !== this.props.areOnlyDifferencesShown ||
      areDifferencesHighlighted !== this.props.areDifferencesHighlighted ||
      isRTEVisible !== this.state.isRTEVisible ||
      areContentToolsVisible !== this.state.areContentToolsVisible) {
      return true;
    }
    return false;
  }

  showRTE = () => {
    this.addUnsavedText();

    this.setState({isRTEVisible: true});
    clearTimeout(this.tweenTimeout);
    this.tweenTimeout = setTimeout(() => {
      this.setState({
        areContentToolsVisible: true,
        indicator: true
      });
    }, 200);
  };

  hideRTE = () => {
    const {scrollTo} = this.props;
    this.removeUnsavedText();

    this.setState({isRTEVisible: false});
    clearTimeout(this.tweenTimeout);
    this.tweenTimeout = setTimeout(() => {
      this.setState({
        areContentToolsVisible: false,
        indicator: false
      });
    }, 200);
  };

  addUnsavedText = () => {
    const {refName} = this.props;
    this.props.addUnsavedText(refName);
  };

  removeUnsavedText = () => {
    const {refName} = this.props;
    this.props.removeUnsavedText(refName);
  };

  createHighlight = (compareWith, from, version) => {
    let element = null;
    let className = '';

    let diff = diffWords(compareWith.toString(), from.toString());

    return (
      <div>
        {diff.map((part, index) => {
          element = null;

          className = part.added ?
            'added'
            :
            part.removed ? 'removed' : 'unchanged';

          if (className === 'removed' && version === COMPARE) {
            element = (<span key={index} className="removed">{part.value}</span>);
          } else if (className === 'added' && version === FROM) {
            element = (<span key={index} className="added">{part.value}</span>);
          } else if (className === 'unchanged') {
            element = (<span key={index} className="unchanged">{part.value}</span>);
          }
          return element;
        })}
      </div>
    );
  };

  createElements = (modus) => {
    const {
      compareWith,
      from,
      areTagsShown,
      areDifferencesHighlighted,
      searchTerm
    } = this.props;

    let highlighted = null;
    let renderedToString = null;
    let parsed = null;

    if (compareWith) {
      if (areDifferencesHighlighted) {
        highlighted = this.createHighlight(compareWith.content, from.content, modus);
        renderedToString = renderToString(highlighted);
      } else {
        renderedToString = modus === COMPARE ? compareWith.content : from.content;
      }
    } else {
      renderedToString = from.content;
    }

    if (!areTagsShown) {
      // breaks korrekt anzeigen lassen => Zero-Width-Space wird genutzt
      let regexBreak = /\[br\]/g;
      let newRenderedToString = renderedToString.toString().replace(regexBreak, '\n&#8203;');

      parsed = parseCode(newRenderedToString);
    } else {
      let regexBreak = /\[br\]/g;
      let newRenderedToString = renderedToString.toString().replace(regexBreak, '[br]\n'); // Einrückungen korrekt anzeigen
      parsed = parseTags(newRenderedToString);
    }

    let stringifiedFromContent = (parsed + '').replace(/<p>(.*)<\/p>/, '$1'); // Int crasht!
    stringifiedFromContent =
      searchTerm !== '' ? markSearchTerm(stringifiedFromContent, searchTerm) : stringifiedFromContent;

    return (
      <div className={modus} dangerouslySetInnerHTML={{__html: stringifiedFromContent}}/>
    );
  };


  render () {
    const {
      collectionId,
      compareWith,
      from,
      to,
      translationType,
      translationId,
      sourceLanguageId,
      areTagsShown,
      searchTerm,
      updateTextInIframe,
      t,
      refName
    } = this.props;

    const {areContentToolsVisible, isRTEVisible} = this.state;

    let compareWithContent = null;

    if (compareWith) {
      compareWithContent = this.createElements(COMPARE);
    }
    let fromContent = this.createElements(FROM);
    let leftStyles = styles.nonEditableContent;
    let toContentWithTools = to.content;
    if (!to.content && (translationType === 'adaption')) {
      toContentWithTools = from.content;
    }

    return (
      <div
        className={styles.fieldSection}
        field-section-refname={refName}
        data-isopen={isRTEVisible.toString()}
        ref={myRef => (this.myRef = myRef)}
      >
        <div className={'fieldSectionLabel'} title={t('textfield_identifier_label')}>
          {from.id}
        </div>
        <div className={'fieldSectionInner'}>
          <div
            className={
              areContentToolsVisible
                ? 'fieldSectionInnerBoxShadow innerField expanded'
                : 'fieldSectionInnerBoxShadow innerField'
            }
            data-indicator={this.state.indicator.toString()}
          >
            {compareWithContent && (
              <div
                className={'fieldCompareWith'}
                active={this.state.isRTEVisible ? 'true' : 'false'}
              >
                <div className={areTagsShown ? leftStyles + ' styled-tags' : leftStyles}>
                  {compareWithContent}
                </div>
              </div>
            )}
            <div className={'fieldFrom'} active={this.state.isRTEVisible ? 'true' : 'false'}>
              <div className={areTagsShown ? leftStyles + ' styled-tags' : leftStyles}>
                {fromContent}
              </div>
            </div>
            {}
            <div className={'fieldTo'} active={this.state.isRTEVisible ? 'true' : 'false'}>
              {areContentToolsVisible &&
                <ContentTools
                  translationId={translationId}
                  sourceLanguageId={sourceLanguageId}
                  id={to.id}
                  content={toContentWithTools}
                  collectionId={collectionId}
                  textId={to.id}
                  searchTerm={searchTerm}
                  updateTextInIframe={updateTextInIframe}
                  hideRTE={this.hideRTE}
                  editable
                  textfieldId={from.id}
                />
              }
              {!areContentToolsVisible &&
              <StandardWithoutContentTools
                translationType={translationType}
                content={to.content}
                searchTerm={searchTerm}
                showRTE={this.showRTE}
              />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FieldSection.propTypes = {
  // from: PropTypes.object,
  // to: PropTypes.object,
  // id: PropTypes.string
};

function mapStateToProps (state) {
  return {
    scrollTo: state.getIn(['fieldLevel', 'scrollTo']),
    unsavedTexts: state.getIn(['fieldLevel', 'unsavedTexts']),
    areTagsShown: state.getIn(['translationTool', 'areTagsShown']),
    areOnlyDifferencesShown: state.getIn(['translationTool', 'areOnlyDifferencesShown']),
    areDifferencesHighlighted: state.getIn(['translationTool', 'areDifferencesHighlighted'])
  };
}


export default connect(
  mapStateToProps,
  {addUnsavedText, removeUnsavedText}
)(FieldSection);
