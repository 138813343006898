import React from 'react';
import {Button} from 'react-md';
import {connect} from 'react-redux';
import {switchMode} from '../../../actions/image';
import {aclFilter} from '../../../utils/ACL';
import {aclProject} from '../../../utils/Permissions';
import {withTranslation} from 'react-i18next';

@withTranslation('project', {wait: true})
class ImageBar extends React.Component {
  renderEditBar = () => {
    const {editMode, t} = this.props;
    if (!editMode) {
      return (
        <div className={'controls controlsTop dynamicControls'}>
          <Button icon title={t('enter_edit_mode')} onClick={this.props.switchMode}>edit</Button>
        </div>
      );
    }
    return null;
  };

  render () {
    return aclFilter(
      this.renderEditBar()
    )([], [aclProject.upload_image]);
  }
}

function mapStateToProps (state) {
  return {
    editMode: state.getIn(['image', 'editMode'])
  };
}

export default connect(
  mapStateToProps,
  {switchMode}
)(ImageBar);
