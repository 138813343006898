import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FormSpy} from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import {withTranslation} from 'react-i18next';
import {Map} from 'immutable';
import {ProcessStatus} from '../../../utils/Constants';
import Wizard from '../../general/Wizard';
import WizardBase from '../../general/WizardBase';
import DecideProjectCreationMode from './DecideProjectCreationMode';
import CollectProjectInformation from './CollectProjectInformation';
import ImportProjectMetadata from './ImportProjectMetadata';
import ImportUpload from './ImportUpload';
import {importProject} from '../../../actions/projects';
import styles from '../../../styles/export-translation.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';


@withTranslation(['project', 'general'], {wait: true})
class ImportProjectWizard extends Component {
  constructor (props) {
    super(props);

    this.state = {
      data: {
        projectCreationMode: 'zip'
      }
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      nextProps.importingProject == ProcessStatus.FINISHED &&
      nextProps.importingProject != this.props.importingProject
    ) {
      this.props.history.push('/projects');
    }
  }

  importProject = data => {
    let additionalValues = Map({
      package_type: data.engine_type,
      status: 'translation',

      uuid: data.uploadedFile.uuid,
      filename: data.uploadedFile.name,
      extract_path: data.extractPath
    });

    let mergedData = Map(data).merge(additionalValues);

    console.log('importProject -> mergedData: ', mergedData.toJSON());
    this.props.importProject(mergedData);
  };

  render () {
    const {importingProject, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    let initialValues = {...this.state.data};

    return (
      <section>
        <div className={styles.form + ' ' + stylesDrawer.drawer}>
          <div className={'head'}>
            <div className={'formHeader headlines no-margin'}>
              <h2>{ t('import_project') }</h2>
            </div>
          </div>
          <div className={'formContentWrapper body paddingLeftRight'}>
            <Wizard
              mutators={{
                setFieldData,
                setCollectedInfos: (args, state) => {
                  state.formState.values = {...state.formState.values, ...args[0]};
                },
                changeValue: (args, state, utils) => {
                  utils.changeValue(state, args[0].name, () => args[0].value);
                },
                changeValues: (args, state, utils) => {
                  args.map(arg => utils.changeValue(state, arg.name, () => arg.value));
                }
              }}
              initialValues={initialValues}
              submitLabel={ t(`${generalLNS}:import`) }
              onSubmit={this.importProject}
              submitStatus={importingProject}
            >
              <WizardBase.Page>
                <FormSpy subscription={{values: true}} component={DecideProjectCreationMode}/>
              </WizardBase.Page>
              <WizardBase.Page>
                <FormSpy subscription={{values: true}} component={CollectProjectInformation}/>
              </WizardBase.Page>
              <WizardBase.Page>
                <FormSpy subscription={{values: true}} component={ImportProjectMetadata}/>
                <FormSpy subscription={{values: true}} component={ImportUpload}/>
              </WizardBase.Page>
            </Wizard>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps (state) {
  return {
    importingProject: state.getIn(['projects', 'importingProject'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {importProject}
  )(ImportProjectWizard)
);
