import { fromJS } from 'immutable';
import { HelpAction } from '../utils/ActionTypes';

const initialState = fromJS({
  hasHelp: false
});

/* eslint-disable */
function help (state = initialState, action) {
  switch (action.type) {
    case HelpAction.SET_HELP:
      return state.set('hasHelp', action.hasHelp);
    default:
      return state;
  }
}
/* eslint-enable */

export { initialState, help };
