import {
  // convertDraftRawToHtmlString
  convertHtmlStringToDraftRaw
} from './editor/helper/convertingForEditorWorks';
import {useSelector, useDispatch} from 'react-redux';



let contentT1 = '<h1>SiteTitle</h1>';
let contentD1 = 'Beschreibung der Seite';
let content1 = 'hallo <b>bold</b>';
// eslint-disable-next-line max-len
let content2 = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dolor sit amet, connsectetur adipiscing elit, sed do eiusmod tempor incididuntLorem ipsum dol';

const contentTitle = convertHtmlStringToDraftRaw(contentT1, 'header-one');
const contentDescription = convertHtmlStringToDraftRaw(contentD1);
const contentSite1 = convertHtmlStringToDraftRaw(content1);
const contentSite2 = convertHtmlStringToDraftRaw(content2);

export const initialSites = [
  {
    id: 1,
    text: {
      title: {
        content: contentTitle
      },
      description: {
        content: contentDescription
      },
      contents: [
        {
          id: 1,
          content: contentSite1
        }
      ]
    }
  },
  {
    id: 2,
    text: {
      title: {
        content: contentTitle
      },
      description: {
        content: contentDescription
      },
      contents: [
        {
          id: 1,
          content: contentSite2
        }
      ]
    }
  }
];

export const structure = [{
  id: 35838,
  project_id: 117,
  parent_id: null,
  sorting: 0,
  available_in: null,
  not_available_in: null,
  excluded: 0,
  languagecode: null,
  content_type: 'chapter',
  name: 'L00',
  external_id: 'L00',
  title_id: null,
  owner: null,
  properties: {
    info: {
      description: 'Hinweiskapitel',
      noRegisterCompleted: true,
      pageTurner: true,
      noIntroSite: true
    }
  },
  linkage_pre: null,
  pre_disabled: 0,
  linkage_post: null,
  post_disabled: 0,
  thumb: null,
  created_at: '2019-07-05 11:09:41',
  updated_at: '2019-07-05 11:09:41',
  deleted_at: null,
  status: {
    approved: false,
    translated: false,
    visible: true
  },
  level: 1,
  childs: [{
    id: 35839,
    project_id: 117,
    parent_id: 35838,
    sorting: 0,
    available_in: null,
    not_available_in: null,
    excluded: 0,
    languagecode: null,
    content_type: 'chapter',
    name: 'L00_00',
    external_id: 'L00_00',
    title_id: null,
    owner: null,
    properties: '{info:{description:"Hinweis"}}',
    linkage_pre: null,
    pre_disabled: 0,
    linkage_post: null,
    post_disabled: 0,
    thumb: null,
    created_at: '2019-07-05 11:09:41',
    updated_at: '2019-07-05 11:09:41',
    deleted_at: null,
    status: {
      approved: false,
      translated: false,
      visible: true
    },
    level: 2,
    childs: [{
      id: 35840,
      project_id: 117,
      parent_id: 35839,
      sorting: 0,
      available_in: null,
      not_available_in: null,
      excluded: 0,
      languagecode: null,
      content_type: 'site',
      name: 'Hinweis',
      external_id: '00_00_s01',
      title_id: null,
      owner: null,
      properties: {
        info: {
          description: 'Hinweis',
          controller: 'HintController',
          hideHeader: true,
          hideFooter: true,
          ignore_in_pagination: true
        }
      },
      linkage_pre: null,
      pre_disabled: 0,
      linkage_post: null,
      post_disabled: 0,
      thumb: null,
      created_at: '2019-07-05 11:09:41',
      updated_at: '2019-07-05 11:09:41',
      deleted_at: null,
      status: {
        approved: true,
        translated: true,
        visible: true
      },
      level: 3,
      childs: [{
        id: 35841,
        project_id: 117,
        parent_id: 35840,
        sorting: 0,
        available_in: null,
        not_available_in: null,
        excluded: 0,
        languagecode: null,
        content_type: 'popup',
        name: 'Video',
        external_id: '00_00_s01_sub01',
        title_id: null,
        owner: null,
        properties: {
          info: {
            controller: 'PopupVideo',
            description: 'Video'
          },
          config: 'assets\\/content\\/sites\\/L00\\/L00_00\\/{SITE_ID}\\/{AUTO_LANGUAGE}\\/config.json'
        },
        linkage_pre: null,
        pre_disabled: 0,
        linkage_post: null,
        post_disabled: 0,
        thumb: null,
        created_at: '2019-07-05 11:09:41',
        updated_at: '2019-07-05 11:09:41',
        deleted_at: null,
        status: {approved: true, translated: true, visible: true},
        level: 4,
        childs: [],
        is_objective: false,
        visible: true,
        collectedIds: ['00_00_s01_sub01', '00_00_s01', 'L00_00', 'L00']
      }],
      is_objective: false,
      visible: true,
      collectedIds: ['00_00_s01', 'L00_00', 'L00']
    }],
    is_objective: false,
    visible: true,
    collectedIds: ['L00_00', 'L00']
  }, {
    id: 35842,
    project_id: 117,
    parent_id: 35838,
    sorting: 1,
    available_in: null,
    not_available_in: null,
    excluded: 0,
    languagecode: null,
    content_type: 'chapter',
    name: 'L00_01',
    external_id: 'L00_01',
    title_id: null,
    owner: null,
    properties: '{info:{description:"Warm up"}}',
    linkage_pre: null,
    pre_disabled: 0,
    linkage_post: null,
    post_disabled: 0,
    thumb: null,
    created_at: '2019-07-05 11:09:41',
    updated_at: '2019-07-05 11:09:41',
    deleted_at: null,
    status: {
      approved: false,
      translated: false,
      visible: true
    },
    level: 2,
    childs: [{
      id: 35843,
      project_id: 117,
      parent_id: 35842,
      sorting: 0,
      available_in: null,
      not_available_in: null,
      excluded: 0,
      languagecode: null,
      content_type: 'site',
      name: 'Warm up',
      external_id: '00_01_s01',
      title_id: null,
      owner: null,
      properties: {
        info: {
          description: 'Warm up',
          controller: 'S00_01_S01',
          ignore_in_pagination: true
        }
      },
      linkage_pre: null,
      pre_disabled: 0,
      linkage_post: null,
      post_disabled: 0,
      thumb: null,
      created_at: '2019-07-05 11:09:41',
      updated_at: '2019-07-05 11:09:41',
      deleted_at: null,
      status: {approved: true, translated: true, visible: true},
      level: 3,
      childs: [],
      is_objective: false,
      visible: true,
      collectedIds: ['00_01_s01', 'L00_01', 'L00']
    }],
    is_objective: false,
    visible: true,
    collectedIds: ['L00_01', 'L00']
  }],
  is_objective: true,
  visible: true,
  obj_name: 'L00',
  obj_required: 1,
  obj_tracking: 1,
  collectedIds: ['L00']
}];
export const project = {
  id: 999,
  translation_id: 9999,
  client_id: 3,
  contact_id: 5,
  contact_id_external: null,
  image_id: null,
  job_number: 'null',
  identifier: 'storyboardExample1',
  name: 'Storyboard Example 1',
  description: 'Storyboard Example 1',
  version: '1.8.0',
  status: 'storyboard',
  master_translation: 948,
  text_scope: 'project',
  text_scope_id: 120,
  type: 'storyboard',
  engine_type: null,
  img_id: null,
  title_id: null,
  viewport: null,
  viewport_popup: null,
  safezone: null,
  safezone_popup: null,
  properties: null,
  owner: 4,
  is_user_restricted: null,
  project_end: null,
  go_live: null,
  active: 1,
  imported_at: '2019-09-04 12:35:27',
  exported_at: null,
  created_at: '2019-09-04 12:35:27',
  updated_at: '2019-09-04 12:35:27',
  img_url: null
};
