import {changeDatetimeToDate} from '../utils/HelperFunctions';
import {
  defaultCreateFilterFunction,
  defaultCreateSearchFunction,
  defaultCreateSortFunction
} from './defaultFunctions';
import {aclTranslation} from '../utils/Permissions';


/**
 * Translation Model
 * @type {*[]}
 */
const model = {

  columns: [{
    id: 'type_label',
    translation_id: 'type'
    // name: 'Type'
  }, {
    id: 'languagecode',
    // name: 'Language',
    translation_id: 'language',
    languageSpecial: ['languagecode', 'name']
  }, /* {
    id: 'progress',
    name: 'Progress',
    permissions: [aclTranslation.read_progress]
  }, */
  {
    id: 'active',
    translation_id: 'active',
    // name: 'Active',
    permissions: [aclTranslation.activate]
  }, {
    id: 'imported_at',
    translation_id: 'imported_at',
    // name: 'Imported at',
    format: changeDatetimeToDate
  }, {
    id: 'updated_at',
    translation_id: 'updated_at',
    // name: 'Updated at',
    format: changeDatetimeToDate
  }/*
  , {
    id: 'actions',
    name: 'Actions',
    textAlign: 'right',
    permissions: [aclTranslation.import, aclTranslation.update],
    sortable: 'false'
  }
  */
  ],

  createFilterFunction: defaultCreateFilterFunction,
  createSearchFunction: defaultCreateSearchFunction,
  createSortFunction: defaultCreateSortFunction,

  defaultSearchProps: ['name'],

  initSortType: 'languagecode',
  initSortOrder: 'asc'
};

export default model;
