import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FormSpy} from 'react-final-form';
import {Map} from 'immutable';
import {withTranslation} from 'react-i18next';
import {ProcessStatus} from '../../../utils/Constants';
import Wizard from '../../general/Wizard';
import WizardBase from '../../general/WizardBase';
import UploadTranslation from './UploadTranslation';
import ImportTranslationMetadata from './ImportTranslationMetadata';
import ImportUpload from './ImportUpload';
import {importTranslation} from '../../../actions/projects';
import {fetchProjectAdditionalContentCollections} from '../../../actions/projects';
import styles from '../../../styles/export-translation.scss';
import stylesDrawer from '../../../styles/drawer_inner.scss';


@withTranslation(['project', 'general'], {wait: true})
class ImportTranslationWizard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      existingAdditionalContentFetchStatus: false
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let projectId = nextProps.match.params.projectId;
    let translationId = nextProps.match.params.translationId;

    if (
      nextProps.importingTranslation == ProcessStatus.FINISHED &&
      nextProps.importingTranslation != this.props.importingTranslation
    ) {
      this.props.history.push('/projects/' + projectId + '/translations/' + translationId);
    }
  }

  importTranslation = data => {
    // console.log('UploadTranslation:: importTranslation: data', data);

    let additionalValues = Map({
      ...data.validate_upload
    });

    let mergedData = Map(data).merge(additionalValues);
    // for pdf translation import
    if (this.props.isPDFImporter) {
      let filteredAdditionalContent = this.props.additionalContents.filter(ac => {
        return ac.type === 'pdf';
      });
      // console.log('filteredAdditionalContent: ', filteredAdditionalContent);
      mergedData = Map(mergedData).merge({
        'additional_content_id': filteredAdditionalContent[0].id
      });

      if (this.props.collectionId) {
        mergedData = Map(mergedData).merge({
          'collection': this.props.collectionId
        });
      }
    }

    console.log('importProject -> mergedData: ', mergedData.toJSON());
    this.props.importTranslation(mergedData);
  };

  render () {
    const {importingTranslation, t} = this.props;
    if (
      importingTranslation === ProcessStatus.FINISHED &&
      this.state.existingAdditionalContentFetchStatus === false
    ) {
      this.props.fetchProjectAdditionalContentCollections({
        projectId: this.props.match.params.projectId,
        translationId: this.props.match.params.translationId
      });
      this.setState({existingAdditionalContentFetchStatus: true});
    }
    return (
      <div className={styles.form + ' ' + stylesDrawer.drawer + ' editDrawer'}>
        <div className={'head'}>
          <div className={'formHeader headlines no-margin'}>
            {
              this.props.isPDFImporter ?
                <h2>{t('import_pdf_translation')}</h2> :
                <h2>{t('import_translation')}</h2>
            }
            {
              this.props.collectionId ?
                <h3>{this.props.collectionId}</h3> :
                null
            }
          </div>
        </div>
        <div
          className={'formContentWrapper body paddingLeftRight'}
        >
          {/*
          <MakeAsyncFunction
              listener={promiseListener}
              start={ProjectsAction.IMPORT_TRANSLATION}           // the type of action to dispatch when this function is called
              resolve={ProjectsAction.IMPORT_TRANSLATION_FULFILLED} // the type of action that will resolve the promise
              reject={ProjectsAction.IMPORT_TRANSLATION_REJECTED}   // the type of action that will reject the promise
          >{asyncFunc => (
          */}
          <Wizard
            submitLabel="Import"
            // onSubmit={asyncFunc}
            onSubmit={this.importTranslation}
            submitStatus={importingTranslation}
          >
            <WizardBase.Page>
              <FormSpy subscription={{values: true}} component={UploadTranslation}/>
            </WizardBase.Page>
            <WizardBase.Page>
              <FormSpy subscription={{values: true}} component={ImportTranslationMetadata}/>
              <FormSpy subscription={{values: true}} component={ImportUpload}/>
            </WizardBase.Page>
          </Wizard>
          {/* )}</MakeAsyncFunction> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    importingTranslation: state.getIn(['projects', 'importingTranslation']),
    additionalContents: state.getIn(['projects', 'translation', 'additional_contents']),
    existingAdditionalContent: state.getIn(['projects', 'existingAdditionalContent'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      importTranslation,
      fetchProjectAdditionalContentCollections
    }
  )(ImportTranslationWizard)
);
