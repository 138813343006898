import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withTranslation} from 'react-i18next';

import { linkProjectToUser } from '../../../actions/projects';
import { fetchUsers } from '../../../actions/users';
import RelationBase from '../../general/RelationBase';
import RoleAvatarChip from '../../general/chip/RoleAvatarChip';
import {hasPermission} from '../../../utils/AuthHelper';
import {aclProject} from '../../../utils/Permissions';

@withTranslation(['project', 'general'], {wait: true})
class ProjectUsers extends Component {
  linkSourceToTarget = (mode, userId, roleId) => {
    this.props.linkProjectToUser({
      mode: mode,
      project_id: this.props.project.id,
      user_id: userId,
      role_id: roleId,
      property_type: 'projects_x_users'
    });
  };

  render () {
    const {project, users, fetchingUsers, linkingProjectToUser, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <RelationBase
        fetchTargets={this.props.fetchUsers}
        fetchingTargets={fetchingUsers}
        label={ t(`${generalLNS}:users`) }
        source={project}
        sourceTargets={project.users}
        targets={users}
        chip={RoleAvatarChip}
        editable={hasPermission(aclProject.update)}
        linkSourceToTarget={this.linkSourceToTarget}
        linkingSourceToTargets={linkingProjectToUser}
      />
    );
  }
}

function mapStateToProps (state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    users: state.getIn(['users', 'users']),
    fetchingUsers: state.getIn(['users', 'fetchingUsers']),
    linkingProjectToUser: state.getIn(['projects', 'linkingProjectToUser']),
    updateToken: state.getIn(['projects', 'updateToken'])
  };
}

export default connect(
  mapStateToProps,
  {linkProjectToUser, fetchUsers}
)(ProjectUsers);
