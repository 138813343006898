import React, {
  // useEffect,
  useState
} from 'react';
// import styles from '../../styles/storyboards/storyboard.scss';
// import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import styles from '../../styles/storyboards/site.scss';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FontIcon} from 'react-md';
import AvatarWithInitials from '../layout/AvatarWithInitials';
import {useTranslation} from 'react-i18next';
import styles from '../../styles/storyboards/storyboard.scss';
import {useSelector} from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


export default function StoryboardHeader (props) {
  const {t} = useTranslation('translation_navigation');
  let className = styles.storyboardHeader;
  const currentChapterCollection = useSelector(state => state.getIn(['storyboard', 'currentChapterCollection']));
  const currentParentChapter = useSelector(state => state.getIn(['storyboard', 'currentParentChapter']));

  console.log('props.viewMode', props.viewMode);

  console.log('StoryboardHeader currentChapterCollection', currentChapterCollection);

  if (!currentParentChapter || currentChapterCollection === '') {
    return null;
  }
  return (
    <div className={className}>
      <div className="leftSide">

        <button
          className={'backBtn'}
          onClick={props.navBackToProject}
          title={'back to storyboard overview'}
        >
          <FontIcon>keyboard_arrow_left</FontIcon>
        </button>
        <img className={'logoLight'} src={require('../../images/logo-structureview_light.png')}/>
        <img className={'logoDark'} src={require('../../images/logo-structureview_dark.png')}/>
        <span className={'storyboardTitle'}>stories</span>
        <span className={'storyboardName'}>
          {props.name}
          <ChevronRightIcon/>
          {currentParentChapter.collection}
          <ChevronRightIcon/>
          {currentChapterCollection}
        </span>
      </div>
      <div className="rightSide">

        {props.viewMode === 1 &&
         <Tabs
           value={props.compareMode}
           onChange={props.handleCompareModeChange}
           indicatorColor="primary"
           textColor="primary"
           centered
         >
           <Tab label="Unified" />
           <Tab label="Split" />
         </Tabs>
        }

        <Tabs
          value={props.viewMode}
          onChange={props.handleViewModeChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Compose" />
          <Tab label="Compare" />
        </Tabs>

        <AvatarWithInitials
          // onClick={this.openDrawer}
        />

      </div>
    </div>
  );
}
