import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';
import {FontIcon, ListItem} from 'react-md';

import styles from '../../styles/navigation_item_link.scss';
import {extend} from 'immutability-helper';
import {withTranslation} from 'react-i18next';

/*
 * Due to the fact that react-router uses context and most of the components
 * in react-md use PureComponent, the matching won't work as expected since
 * the PureComponent will block the context updates. This is a simple wrapper
 * with Route to make sure that the active state is correctly applied after
 * an item has been clicked.
 */
@withTranslation('general', {wait: true})
class NavItemLink extends Component {
  render () {
    const { label, to, icon, exact, t } = this.props;
    return (
      <Route path={to} exact={exact}>
        {({match}) => {
          let leftIcon;
          if (icon) {
            leftIcon = <FontIcon className={styles.icon}>{icon}</FontIcon>;
          }
          return (
            <ListItem
              className={styles.itemlink}
              activeBoxClassName={styles.itemlinkactive}
              title={t(label)}
              component={Link}
              active={!!match}
              to={to}
              primaryText={t(label)}
              leftIcon={leftIcon}
            />
          );
        }}
      </Route>
    );
  }
}

NavItemLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  exact: PropTypes.bool,
  icon: PropTypes.node
};
export default NavItemLink;
