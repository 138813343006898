export const aclClient = {
  // better name? client_permission ?? permission_client?? pclient?? p_client??
  create: 'client:create',
  read: 'client:read',
  update: 'client:update',
  delete: 'client:delete',
  activate: 'client:activate'
};

export const aclHoverPanel = {
  translate: 'hoverpanel:translate',
  live_translate: 'hoverpanel:live_translate',
  change_visibility: 'hoverpanel:change_visibility',
  approve_translation: 'hoverpanel:approve_translation',
  approve_content: 'hoverpanel:approve_content'
};

export const aclPermission = {
  create: 'permission:create',
  read: 'permission:read',
  update: 'permission:update',
  delete: 'permission:delete'
};

export const aclProfile = {
  show: 'profile:show',
  edit: 'profile:edit',
  updateAvatar: 'profile:updateAvatar',
  updatePassword: 'profile:updatePassword'
};

export const aclProject = {
  create: 'project:create',
  read: 'project:read',
  update: 'project:update',
  delete: 'project:delete',
  activate: 'project:activate',
  upload_image: 'project:upload_image',
  update_package: 'project:update_package',
  read_translations: 'project:read_translations',
  config: 'project:config'
};

export const aclResources = {
  projects: 'resources:projects',
  clients: 'resources:clients',
  users: 'resources:users',
  permissions: 'resources:permissions',
  roles: 'resources:roles',
  contacts: 'resources:contacts',
  profile: 'resources:profile'
};

export const aclRole = {
  create: 'role:create',
  read: 'role:read',
  update: 'role:update',
  delete: 'role:delete'
};

export const aclUser = {
  create: 'user:create',
  read: 'user:read',
  update: 'user:update',
  delete: 'user:delete',
  activate: 'user:activate',
  invite: 'user:invite',
  login_as: 'user:login_as',
  create_user_without_email: 'user:create_user_without_email',
  invite_to_me: 'user:invite_to_me'
};

export const aclContact = {
  create: 'contact:create',
  read: 'contact:read',
  update: 'contact:update',
  delete: 'contact:delete'
};

export const aclTranslation = {
  create: 'translation:create',
  read: 'translation:read',
  update: 'translation:update',
  delete: 'translation:delete',
  activate: 'translation:activate',
  import: 'translation:import',
  // import_pdf: 'translation:import_pdf',
  export: 'translation:export',
  // export_pdf: 'translation:export_pdf',
  read_progress: 'translation:read_progress',
  update_progress: 'translation:update_progress',
  approve_text: 'translation:approve_text',
  unapprove_text: 'translation:unapprove_text',
  read_snapshots: 'translation:read_snapshots',
  read_users: 'translation:read_users',
  read_source_snapshots: 'translation:read_source_snapshots',
  read_additional_content: 'translation:read_additional_content',
  read_additional_content_other: 'translation:read_additional_content_other'
};

export const aclSnapshot = {
  create: 'snapshot:create',
  read: 'snapshot:read',
  update: 'snapshot:update',
  delete: 'snapshot:delete'
};

export const aclTranslationTool = {
  quote: 'translationtool:quote',
  list: 'translationtool:list',
  indent_list: 'translationtool:indent_list',
  decrease_list: 'translationtool:decrease_list',
  shy: 'translationtool:shy',
  zero_width_space: 'translationtool:zero_width_space',
  bold: 'translationtool:bold',
  superscript: 'translationtool:superscript',
  subscript: 'translationtool:subscript',
  nobreak: 'translationtool:nobreak',
  link: 'translationtool:link',
  mail: 'translationtool:mail',
  html_tags: 'translationtool:html_tags'
};
