import { ajax } from 'rxjs/observable/dom/ajax';
import { LanguagesAction } from '../utils/ActionTypes';
import { fetchLanguagesFulfilled, fetchLanguagesRejected } from '../actions/languages';
import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const fetchLanguagesEpic = (action$, store) => {
  return action$
    .ofType(LanguagesAction.FETCH_LANGUAGES)
    .debounceTime(500)
    .mergeMap(() => {
      let url = baseApiUrl + 'languages/available';
      let headers = getHttpAuthHeaders(store, url);
      return ajax
        .getJSON(url, headers)
        .map(res => res.data)
        .map(fetchLanguagesFulfilled)
        .catch(fetchLanguagesRejected);
    });
};

export { fetchLanguagesEpic };
