import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import { ProcessStatus } from '../../../utils/Constants';

import {setForceApproval} from '../../../actions/projects';
import { removeDialog } from '../../../actions/globalDialog';


@withTranslation(['project', 'general'], {wait: true})
class ApproveLanguageVersionWarning extends Component {
  constructor (props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let settingForceApproval = nextProps.settingForceApproval;
    if (
      settingForceApproval == ProcessStatus.FINISHED &&
      settingForceApproval != this.props.settingForceApproval
    ) {
      this.closeDialog();
    }
  }

  closeDialog = () => {
    let dialog = this.props.dialogs.get(0);
    this.props.removeDialog(dialog.identifier);
  };

  setForceApproval = () => {
    const {projectId, translationId} = this.props;
    this.props.setForceApproval({projectId, translationId, status: true});
  };

  render () {
    const {settingForceApproval, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (settingForceApproval == ProcessStatus.STARTED) {
      return <LoadingBar show="true"/>;
    }

    return (
      <div>
        <Button flat onClick={this.closeDialog}>
          { t(`${generalLNS}:cancel`) }
        </Button>
        <Button flat onClick={this.setForceApproval}>
          { t(`${generalLNS}:approve`) }
        </Button>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    settingForceApproval: state.getIn(['projects', 'settingForceApproval']),
    dialogs: state.getIn(['globalDialog', 'dialogs'])
  };
}

export default connect(
  mapStateToProps,
  {removeDialog, setForceApproval}
)(ApproveLanguageVersionWarning);
