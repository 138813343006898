import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { List, Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import { renderTextField, renderSelectField } from '../../general/BasicFormComponents';
import { sortAlphabetically } from '../../../utils/ChipHelperFunctions';
import LoadingBar from '../../general/DumbComponent';
import ChipAllocation from '../../general/ChipAllocation';
import NamedChip from '../../general/chip/NamedChip';
import ObjectAutocomplete from '../../general/ObjectAutocomplete';
import { truequired } from '../../../utils/ValidationRules';
import { fetchTranslationEnumDefaults } from '../../../actions/enumDefaults';
import { fetchLanguages } from '../../../actions/languages';
import styles from '../../../styles/translation_detail.scss';

@withTranslation(['project', 'general', 'types'], {wait: true})
class TranslationPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      types: null,
      languageOptions: null,
      countryOptions: null,
      parentLanguageOptions: null,
      parentSourceOptions: null,
      isInitial: true
    };
  }

  componentWillMount () {
    this.props.fetchTranslationEnumDefaults();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {project, languages, countries, enumDefaults, values} = nextProps;

    if (
      project !== null &&
      project.translations !== null &&
      languages.size > 0 &&
      countries.size > 0 &&
      enumDefaults.size > 0
    ) {
      this.update(nextProps, project, languages, countries, enumDefaults, values);
    }
  }

  update = props => {
    const {project, languages, countries, enumDefaults, values} = props;
    let isInitial = this.state.isInitial;
    let types = enumDefaults.get('type');
    let translationsList = List(project.translations);
    let languageOptions = this.formatLanguageOptions(languages, values)
      .toArray()
      .sort((a, b) => sortAlphabetically(a, b));
    let countryOptions = this.formatCountryOptions(countries, values)
      .toArray()
      .sort((a, b) => sortAlphabetically(a, b));

    let parentLanguageOptions = this.formatParentLanguageOptions(
      this.filterParentLanguageOptions(translationsList)
    ).toArray();
    let parentSourceOptions = List(
      this.formatSourceLanguageOptions(translationsList)
        .toArray()
        .sort((a, b) => sortAlphabetically(a, b))
    );

    // this.checkIfExist2(project, languageOptions, countryOptions); //TODO:: WÄRE EINE ZWEITE MÖGLICHKEIT

    if (isInitial) {
      let initialSourceLanguages = List();

      let de = parentSourceOptions.find(option => option.get('name') == 'de - german (Intl\')');
      let en = parentSourceOptions.find(option => option.get('name') == 'en - english (Intl\')');

      if (de) {
        initialSourceLanguages = initialSourceLanguages.set(initialSourceLanguages.size, de);
      }
      if (en) {
        initialSourceLanguages = initialSourceLanguages.set(initialSourceLanguages.size, en);
      }

      this.props.form.mutators.changeValue({
        name: 'detailed_source_languages',
        value: initialSourceLanguages
      });
      isInitial = false;
    }

    this.setState({
      types: types,
      languageOptions: languageOptions,
      countryOptions: countryOptions,
      parentLanguageOptions: parentLanguageOptions,
      parentSourceOptions: parentSourceOptions,
      isInitial: isInitial
    });
  };

  formatLanguageOptions = (languages, values) => {
    const {countries} = this.props;
    let country;
    let languageCode;
    let label;
    let isDisabled = false;

    return languages.map(language => {
      if (values.country) {
        country = countries.find(country => country.id == values.country.value);

        if (country) {
          languageCode = country.cc ? language.lc + '-' + country.cc : language.lc;
          isDisabled = this.checkIfExist(languageCode);
        }
      }
      label = language.lc + ' - ' + language.name;
      return {value: language.id, label: label, disabled: isDisabled};
    });
  };

  formatCountryOptions = (countries, values) => {
    const {languages} = this.props;
    let language;
    let languageCode;
    let label;
    let isDisabled = false;

    return countries.map(country => {
      if (values.language) {
        language = languages.find(language => language.id === values.language.value);

        if (language && language.lc) {
          languageCode = country.cc ? language.lc + '-' + country.cc : language.lc;
          isDisabled = this.checkIfExist(languageCode);
        }
      }
      label = country.cc == null ? country.name : country.cc + ' - ' + country.name;
      return {value: country.id, label: label, disabled: isDisabled};
    });
  };

  filterParentLanguageOptions = languages => {
    return languages.filter(
      language => language.languagecode === 'de' || language.languagecode === 'en'
    );
  };

  formatParentLanguageOptions = languages => {
    return languages.map(language => {
      return {value: language.id, label: language.languagecode + ' - ' + language.name};
    });
  };

  formatSourceLanguageOptions = languages => {
    return languages.map(language => {
      return Map({id: language.id, name: language.languagecode + ' - ' + language.name});
    });
  };

  checkIfExist = languageCode => {
    let translations = this.props.project.translations;

    let languageCodes = translations.map(translation => {
      return translation.languagecode;
    });
    return languageCodes.indexOf(languageCode) >= 0;
  };

  /*
    checkIfExist2 = (project, languageOptions, countryOptions, values) => {

        if (project.translations) {
            const tlcs = project.translations.map(translation => translation.languagecode);
            const translation_languagecode = values.languagecode;

            let input_lc = "";
            let input_cc = "";
            let languagecode;


            if (values.language && values.language.label) {
                input_lc = values.language.label.split(" - ")[0];
            }

            if (values.country && values.country.label) {
                input_cc = values.country.label.split(" - ")[0];
            }


            if (typeof values.country !== 'undefined') {
                languageOptions.map(languageOption => {
                    let option_lc = languageOption.label.split(" - ")[0];

                    if (input_cc) {
                        languagecode = option_lc + "-" + input_cc;
                    } else {
                        languagecode = option_lc;
                    }
                    languageOption.disabled = tlcs.indexOf(languagecode) >= 0;
                });
            }

            if (typeof values.language !== 'undefined') {
                countryOptions.map(countryOption => {
                    let option_cc = countryOption.label.split(" - ")[0];

                    if (option_cc == "Intl'") {
                        languagecode = input_lc;
                    } else {
                        languagecode = input_lc + "-" + option_cc;
                    }

                    countryOption.disabled = tlcs.indexOf(languagecode) >= 0;
                });
            }
        }
    };
    */

  render () {
    const {project, languages, values, t} = this.props;
    // console.log('props: ', this.props);
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const typesLNS = 'types'; // typesLanguageNamespaceSource

    const {
      types,
      languageOptions,
      countryOptions,
      parentLanguageOptions,
      parentSourceOptions
    } = this.state;

    if (
      types == null ||
      languageOptions == null ||
      countryOptions == null ||
      parentLanguageOptions == null ||
      parentSourceOptions == null
    ) {
      return (
        <LoadingBar
          show="true"
          wrapperClassname={styles.translationPrime}
          message={ t(`${generalLNS}:submit`) }
        />
      );
    } else if (!languages) {
      return <div>{ t('no_languages_found') }</div>;
    } else if (project.translations == null) {
      return <div>{ t('no_translation_found_in_project') }</div>;
    }

    if (countryOptions !== null) {
      console.log('countryOptions: ', countryOptions);
    }

    let localeTypes = types.map(value => (
      {label: t(`${typesLNS}:${value}`), value: value}
    ));

    return (
      <div className={'md-grid ' + styles.translationPrime}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{ t('add_translation') }</h2>
          </div>
        </div>

        <div className={'formContentWrapper'}>
          <Field
            id="type"
            name="type"
            label={ t(`${generalLNS}:type`) }
            type="text"
            helpText={ t('select_type') }
            className="md-cell md-cell--12"
            menuItems={localeTypes}
            component={renderSelectField}
            validate={truequired}
            required
            errorText="No type selected"
          />
          <Field
            id="language.value"
            name="language.label"
            label={ t(`${generalLNS}:language`) }
            helpText={ t('select_language') }
            errorText={ t('select_language_error') }
            errorTextForNotFound={ t('select_language_error_not_found') }
            errorTextForDisabled={ t('select_language_error_disabled') }
            value={values && values.language ? values.language.label : null}
            changeValues={this.props.form.mutators.changeValues}
            options={languageOptions}
            validate={truequired}
            required
            component={ObjectAutocomplete}
          />
          <Field
            id="country.value"
            name="country.label"
            label={ t(`${generalLNS}:country`) }
            placeholder={ t('country_placeholder') }
            helpText={ t('country_help') }
            errorTextForNotFound={ t('country_error_not_found') }
            errorTextForDisabled={ t('country_error_disabled') }
            value={values && values.country ? values.country.label : null}
            changeValues={this.props.form.mutators.changeValues}
            options={countryOptions}
            validate={truequired}
            required
            component={ObjectAutocomplete}
          />
          {// values.type && values.type != 'adaption' && (
            <Field
              id="parent_id"
              name="parent_id"
              label={ t('translate_from') }
              type="text"
              helpText={ t('parent_id_help') }
              className="md-cell md-cell--12"
              menuItems={parentLanguageOptions}
              component={renderSelectField}
            />
          // )
          }
          <Field
            id="translator"
            name="translator"
            label={ t('translator') }
            type="text"
            helpText={ t('translator_help') }
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="detailed_source_languages"
            name="detailed_source_languages"
            label={ t('select_source_language') }
            type="text"
            helpText={ t('select_source_language_help') }
            className="md-cell md-cell--12"
            value={values}
            // required  DON'T PUT BACK IN!
            required={
              values.detailed_source_languages && values.detailed_source_languages.size == 0
            }
            items={parentSourceOptions}
            chip={NamedChip}
            component={ChipAllocation}
            asynchron={false}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  let project = state.getIn(['projects', 'selectedProject']);
  let translations = project ? project.translations : [];

  return {
    enumDefaults: state.getIn(['enumDefaults', 'translationEnumDefaults']),
    languages: state.getIn(['languages', 'languages']),
    countries: state.getIn(['languages', 'countries']),
    project: project,
    translations: translations
  };
}

export default connect(
  mapStateToProps,
  {fetchTranslationEnumDefaults, fetchLanguages}
)(TranslationPrime);
