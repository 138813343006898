import React, { Component } from 'react';
import { SelectField } from 'react-md';
import { fetchSourceSnapshots, setSourceSnapshotId } from '../../actions/sourceSnapshots';
import { connect } from 'react-redux';
import { ProcessStatus } from '../../utils/Constants';
import styles from '../../styles/translationTool/sourceSnapshots.scss';
import { FontIcon } from 'react-md';
import {withTranslation} from 'react-i18next';

@withTranslation('translation', {wait: true})
class SourceSnapshots extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sourceSnapshots: [],
      sourceSnapshotDescription: null
    };
  }

  componentWillMount () {
    const {
      sourceSnapshots,
      projectId,
      sourceLanguageId,
      sourceSnapshotId,
      sourceSnapshotProjectId,
      sourceSnapshotTranslationId,
      fetchingSourceSnapshots
    } = this.props;

    if (
      fetchingSourceSnapshots == ProcessStatus.FINISHED &&
      projectId == sourceSnapshotProjectId &&
      sourceLanguageId == sourceSnapshotTranslationId
    ) {
      this.setState({sourceSnapshots: sourceSnapshots.toArray()});
    } else {
      this.props.fetchSourceSnapshots(projectId, sourceLanguageId, sourceSnapshotId);
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {sourceSnapshots, fetchingSourceSnapshots, sourceSnapshotId} = nextProps;

    if (
      fetchingSourceSnapshots == ProcessStatus.FINISHED &&
      fetchingSourceSnapshots != this.props.fetchingSourceSnapshots
    ) {
      this.setState({sourceSnapshots: sourceSnapshots.toArray()});
    }

    if (sourceSnapshotId != this.props.sourceSnapshotId) {
      let sourceSnapshot = this.state.sourceSnapshots
        .find(sourceSnapshot => sourceSnapshot.id == sourceSnapshotId);
      let description = sourceSnapshot ? sourceSnapshot.description : null;
      this.setState({sourceSnapshotDescription: description});
    }
  }

  formatSourceSnapshotOptions = sourceSnapshots => {
    return sourceSnapshots.map(sourceSnapshot => {
      return {
        label: sourceSnapshot.created_at.substr(0, 10)
          + ' | ' + sourceSnapshot.id + ' - ' + sourceSnapshot.name,
        value: sourceSnapshot.id
      };
    });
  };

  handleSourceSnapshotChange = value => {
    this.props.setSourceSnapshotId(value);
  };

  sortDescending = (a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  };

  render () {
    const {sourceSnapshotId, t} = this.props;
    const {sourceSnapshots} = this.state;

    let formattedSourceSnapshots = [];

    if (sourceSnapshots.length > 0) {
      formattedSourceSnapshots = this.formatSourceSnapshotOptions(sourceSnapshots).sort((a, b) =>
        this.sortDescending(a, b)
      );
      formattedSourceSnapshots.unshift({label: t('source_snapshots_none'), value: -1});
    }

    let snapshot = formattedSourceSnapshots.find(sourceSnapshot => sourceSnapshot.value === sourceSnapshotId);

    if (snapshot == null) {
      return null;
    }

    return (
      <div className={styles.onlyOneSnapshot}>
        {snapshot.label}
        {/*
        <SelectField
          id="sourceSnapshots"
          //menuItems={formattedSourceSnapshots}
          menuItems={formattedSourceSnapshots}
          value={sourceSnapshotId}
          className={styles.sourceSnapshots}
          //dropdownIcon={<FontIcon>keyboard_arrow_down</FontIcon>}
          //onChange={this.handleSourceSnapshotChange}
        />
        */}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingSourceSnapshots: state.getIn(['sourceSnapshots', 'fetchingSourceSnapshots']),
    sourceSnapshots: state.getIn(['sourceSnapshots', 'sourceSnapshots']),
    sourceSnapshotProjectId: state.getIn(['sourceSnapshots', 'projectId']),
    sourceSnapshotTranslationId: state.getIn(['sourceSnapshots', 'translationId'])
  };
}

export default connect(
  mapStateToProps,
  {fetchSourceSnapshots, setSourceSnapshotId}
)(SourceSnapshots);
