import React, {Component} from 'react';
import {DialogContainer, FontIcon} from 'react-md';
import {withTranslation} from 'react-i18next';
import styles from '../../styles/wbtViewer/processStatusDialog.scss';


@withTranslation(['translation_navigation', 'general'], {wait: true})
class ProcessStatusDialog extends Component {
  render () {
    const {title, active, processStatus, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const actions = [];
    actions.push({
      children: t(`${generalLNS}:cancel`),
      onClick: () => this.props.toggle()
    });
    // actions.push({
    //   children: 'reload',
    //   onClick: () => {
    //     window.location.reload();
    //   }
    // });

    /*
    * ACHTUNG: Die FOOTER-BUTTON-BAR wurde via CSS (processStatusDialog.scss, Zeile 9) ausgeblendet.
    * */

    return (
      <DialogContainer
        id="simple-action-dialog-wbt"
        visible={active}
        onHide={this.props.toggle} // MUSS DAS REIN?
        actions={actions}
        title={title}
        className={styles.processStatusDialog}
      >
        <div className={'rowWrapper'}>
          {/*
          <div className={'textRow'}>
            {this.props.storyboard ? <div>{t('loading_text_storyboard')}</div> : <div>{t('loading_text_1')}</div>}
            <div>
              {processStatus.fetchingProject
                ? <FontIcon className={'animated'}>refresh</FontIcon>
                : <FontIcon>check</FontIcon>}
            </div>
          </div>

          <div className={'textRow'}>
            <div>{t('loading_text_2')}</div>
            <div>
              {processStatus.fetchingStructure
                ? <FontIcon className={'animated'}>refresh</FontIcon>
                : <FontIcon>check</FontIcon>}
            </div>
          </div>
          <div className={'textRow'}>
            <div>{t('loading_text_3')}</div>
            <div>
              {processStatus.fetchingTexts
                ? <FontIcon className={'animated'}>refresh</FontIcon>
                : <FontIcon>check</FontIcon>}
            </div>
          </div>
          <div className={'textRow'}>
            <div>{t('loading_text_4')}</div>
            <div>
              {processStatus.packageConnected
                ? <FontIcon>check</FontIcon>
                : <FontIcon className={'animated'}>refresh</FontIcon>}
            </div>
          </div>
          */}
          <div className={'textRow'}>
            <div>{t('loading_text_3')}</div>
            <div>
              {processStatus.fetchingTexts
                ? <FontIcon className={'animated'}>refresh</FontIcon>
                : <FontIcon>check</FontIcon>}
            </div>
          </div>
        </div>
      </DialogContainer>
    );
  }
}

export default ProcessStatusDialog;
