import React, {useState, useEffect} from 'react';
import styles from '../../styles/storyboards/site.scss';
import InlineDiffView from './InlineDiffView';
import DiffView from './DiffView';
import {useSelector} from 'react-redux';
import {CompareMode} from '../../utils/Constants';
import HistoryCompareSlider from './HistoryCompareSlider';

export default function CompareView (props) {
  const {siteId, textBlock} = props;

  const valueFromReducer = useSelector(state => state.getIn([
    'historySelect',
    'config',
    siteId,
    textBlock.id
  ]));

  const [oldHistoryId, setOldHistoryId] = useState(-1);
  const [newHistoryId, setNewHistoryId] = useState(-1);

  const [oldHistoryItem, setOldHistoryItem] = useState(0);
  const [newHistoryItem, setNewHistoryItem] = useState(0);


  React.useEffect(() => {
    const {textBlock} = props;
    if (textBlock.history && textBlock.history.length > 0) {
      update();
    }
  }, []);

  React.useEffect(() => {
    const {textBlock} = props;
    if (textBlock.history && textBlock.history.length > 0) {
      update();
    }
  }, [valueFromReducer]);

  React.useEffect(() => {
    const {textBlock} = props;
    if (textBlock.history && textBlock.history.length > 0) {
      let item = textBlock.history.find(historyItem => historyItem.id === oldHistoryId);
      setOldHistoryItem(item);
    }
  }, [oldHistoryId]);

  React.useEffect(() => {
    const {textBlock} = props;
    if (textBlock.history && textBlock.history.length > 0) {
      let item = textBlock.history.find(historyItem => historyItem.id === newHistoryId);
      setNewHistoryItem(item);
    }
  }, [newHistoryId]);

  const update = () => {
    const {history} = textBlock;

    let nhi = history[0].id;
    let ohi = nhi;

    if (history.length > 1) {
      ohi = history[1].id;
    }

    if (valueFromReducer) {
      if (valueFromReducer.get('historySelectId_0') != null) {
        ohi = valueFromReducer.get('historySelectId_0');
      }
      if (valueFromReducer.get('historySelectId_1') != null) {
        nhi = valueFromReducer.get('historySelectId_1');
      }
    }

    if (ohi !== oldHistoryId) {
      setOldHistoryId(ohi);
    }

    if (nhi !== newHistoryId) {
      setNewHistoryId(nhi);
    }
  };

  if (!textBlock.history || textBlock.history.length === 0 || !oldHistoryItem || !newHistoryItem) {
    return <div>No history yet</div>;
  }

  // INFO: Array.reverse() verändert das aktuelle Array, deshalb muss vorher eine Kopie erstellt werden
  const reverseHistory = JSON.parse(JSON.stringify(textBlock.history)).reverse();

  return (
    <div className={styles.compareViewWrapper}>
      <div className={styles.historySelectWrapper}>
        <HistoryCompareSlider
          history={reverseHistory}
          siteId={props.siteId}
          textBlockId={textBlock.id}
          historyItemOld={oldHistoryItem}
          historyItemNew={newHistoryItem}
          isSplitView={props.compareMode === CompareMode.SPLIT}
        />
      </div>
      {
        props.compareMode === CompareMode.SPLIT &&
        <>
          <DiffView
            old={oldHistoryItem.content}
            new={newHistoryItem.content}
          />
          <div className={styles.legendCompare}>
            <span style={{fontWeight: 600}}>Legend:</span>
            &nbsp;
            &nbsp;
            <span style={{backgroundColor: '#fee8e9'}}>old</span>
            ,
            &nbsp;
            <span style={{backgroundColor: '#ddffdd'}}>new</span>
            ,
            &nbsp;
            <span style={{backgroundColor: '#f39ea2'}}>removed</span>
            ,
            &nbsp;
            <span style={{backgroundColor: '#c0dc91'}}>added</span>
          </div>
        </>
      }
      { props.compareMode === CompareMode.UNIFIED &&
        <>
          <InlineDiffView
            old={oldHistoryItem.content}
            new={newHistoryItem.content}
          />
          <div className={styles.legendCompare}>
            <span style={{fontWeight: 600}}>Legend:</span>
            &nbsp;
            &nbsp;
            <span style={{backgroundColor: '#fee8e9'}}>removed</span>
            ,
            &nbsp;
            <span style={{backgroundColor: '#ddffdd'}}>added</span>
          </div>
        </>
      }
    </div>
  );
}
