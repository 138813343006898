export const APP = {
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND'
};

export const NotificationsAction = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS'
};

export const ErrorDialogAction = {
  REMOVE_ERROR_DIALOG: 'REMOVE_ERROR_DIALOG'
};

export const GlobalDialogAction = {
  SHOW_DIALOG: 'SHOW_DIALOG',
  REMOVE_DIALOG: 'REMOVE_DIALOG'
};

export const GlobalSnackbarAction = {
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST'
};

export const TranslationToolAction = {
  FETCH_TRANSLATIONS: 'FETCH_TRANSLATIONS',
  FETCH_TRANSLATIONS_FULFILLED: 'FETCH_TRANSLATIONS_FULFILLED',
  FETCH_TRANSLATIONS_REJECTED: 'FETCH_TRANSLATIONS_REJECTED',

  FETCH_ADDITIONAL_CONTENT_TRANSLATIONS: 'FETCH_ADDITIONAL_CONTENT_TRANSLATIONS',
  FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED: 'FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_FULFILLED',
  FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_REJECTED: 'FETCH_ADDITIONAL_CONTENT_TRANSLATIONS_REJECTED',

  SAVE_TRANSLATION: 'SAVE_TRANSLATION',
  SAVE_TRANSLATION_SUCCESS: 'SAVE_TRANSLATION_SUCCESS',
  SAVE_TRANSLATION_ERROR: 'SAVE_TRANSLATION_ERROR',

  UPDATE_STORAGE: 'UPDATE_STORAGE',
  SET_LOCAL_STORAGE: 'SET_LOCAL_STORAGE',
  SET_EDITING_INITIAL_STATUS: 'SET_EDITING_INITIAL_STATUS',

  SET_ARE_EXPANDED: 'SET_ARE_EXPANDED',
  SET_ARE_TAGS_SHOWN: 'SET_ARE_TAGS_SHOWN',
  SET_ARE_ALL_TEXTS_SHOWN: 'SET_ARE_ALL_TEXTS_SHOWN',
  SET_ARE_ONLY_DIFFERENCES_SHOWN: 'SET_ARE_ONLY_DIFFERENCES_SHOWN',
  SET_ARE_DIFFERENCES_HIGHLIGHTED: 'SET_ARE_DIFFERENCES_HIGHLIGHTED',
  SET_ARE_ONLY_EMPTY_SHOWN: 'SET_ARE_ONLY_EMPTY_SHOWN'
};

export const FieldLevelAction = {
  SET_SCROLL_TO: 'SET_SCROLL_TO',
  ADD_UNSAVED_TEXT: 'ADD_UNSAVED_TEXT',
  REMOVE_UNSAVED_TEXT: 'REMOVE_UNSAVED_TEXT'
};

export const AuthAction = {
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  USER_FROM_STORE: 'USER_FROM_STORE',
  IMPERSONATED_USER_FROM_STORE: 'IMPERSONATED_USER_FROM_STORE',
  UPDATE_FORM_VALUE: 'UPDATE_FORM_VALUE',
  RESET_FORM: 'RESET_FORM',
  SUBMIT_FORM: 'SUBMIT_FORM',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_FULFILLED: 'LOGOUT_FULFILLED',
  LOGOUT_REJECTED: 'LOGOUT_REJECTED',

  LOGIN_AS_USER: 'LOGIN_AS_USER',
  LOGIN_AS_USER_FULFILLED: 'LOGIN_AS_USER_FULFILLED',
  LOGIN_AS_USER_REJECTED: 'LOGIN_AS_USER_REJECTED',

  LOGOUT_AS_USER: 'LOGOUT_AS_USER',
  LOGOUT_AS_USER_FULFILLED: 'LOGOUT_AS_USER_FULFILLED',
  LOGOUT_AS_USER_REJECTED: 'LOGOUT_AS_USER_REJECTED',

  EDIT_USER_SETTINGS: 'EDIT_USER_SETTINGS',
  EDIT_USER_SETTINGS_FULFILLED: 'EDIT_USER_SETTINGS_FULFILLED',
  EDIT_USER_SETTINGS_REJECTED: 'EDIT_USER_SETTINGS_REJECTED',

  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  REQUEST_RESET_PASSWORD_FULFILLED: 'REQUEST_RESET_PASSWORD_FULFILLED',
  REQUEST_RESET_PASSWORD_REJECTED: 'REQUEST_RESET_PASSWORD_REJECTED',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_FULFILLED: 'RESET_PASSWORD_FULFILLED',
  RESET_PASSWORD_REJECTED: 'RESET_PASSWORD_REJECTED',

  TOGGLE_USER_SELECTOR: 'TOGGLE_USER_SELECTOR',
  SWITCH_USER: 'SWITCH_USER',
  SWITCH_BACK: 'SWITCH_BACK',

  UPDATE_STORE_USER: 'UPDATE_STORE_USER', // when we update a profile (may be better solution)
  UPDATE_STORE_USER_FULFILLED: 'UPDATE_STORE_USER_FULFILLED',
  UPDATE_STORE_USER_REJECTED: 'UPDATE_STORE_USER_REJECTED',

  GET_USERNAME_BY_TOKEN: 'GET_USERNAME_BY_TOKEN',
  GET_USERNAME_BY_TOKEN_FULFILLED: 'GET_USERNAME_BY_TOKEN_FULFILLED',
  GET_USERNAME_BY_TOKEN_REJECTED: 'GET_USERNAME_BY_TOKEN_REJECTED'
};

export const ClientsAction = {
  SELECT_CLIENT: 'SELECT_CLIENT',

  FETCH_CLIENTS: 'FETCH_CLIENTS',
  FETCH_CLIENTS_FULFILLED: 'FETCH_CLIENTS_FULFILLED',
  FETCH_CLIENTS_REJECTED: 'FETCH_CLIENTS_REJECTED',

  FETCH_CLIENT: 'FETCH_CLIENT',
  FETCH_CLIENT_FULFILLED: 'FETCH_CLIENT_FULFILLED',
  FETCH_CLIENT_REJECTED: 'FETCH_CLIENT_REJECTED',

  ADD_CLIENT: 'ADD_CLIENT',
  ADD_CLIENT_FULFILLED: 'ADD_CLIENT_FULFILLED',
  ADD_CLIENT_REJECTED: 'ADD_CLIENT_REJECTED',

  DELETE_CLIENT: 'DELETE_CLIENT',
  DELETE_CLIENT_FULFILLED: 'DELETE_CLIENT_FULFILLED',
  DELETE_CLIENT_REJECTED: 'DELETE_CLIENT_REJECTED',

  EDIT_CLIENT: 'EDIT_CLIENT',
  EDIT_CLIENT_FULFILLED: 'EDIT_CLIENT_FULFILLED',
  EDIT_CLIENT_REJECTED: 'EDIT_CLIENT_REJECTED',

  LINK_CLIENT_TO_USER: 'LINK_CLIENT_TO_USER',
  LINK_CLIENT_TO_USER_FULFILLED: 'LINK_CLIENT_TO_USER_FULFILLED',
  LINK_CLIENT_TO_USER_REJECTED: 'LINK_CLIENT_TO_USER_REJECTED',

  LINK_CLIENT_TO_EXPORT_TYPE: 'LINK_CLIENT_TO_EXPORT_TYPE',
  LINK_CLIENT_TO_EXPORT_TYPE_FULFILLED: 'LINK_CLIENT_TO_EXPORT_TYPE_FULFILLED',
  LINK_CLIENT_TO_EXPORT_TYPE_REJECTED: 'LINK_CLIENT_TO_EXPORT_TYPE_REJECTED'
};

export const UsersAction = {
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_FULFILLED: 'FETCH_USERS_FULFILLED',
  FETCH_USERS_REJECTED: 'FETCH_USERS_REJECTED',

  FETCH_USER: 'FETCH_USER',
  FETCH_USER_FULFILLED: 'FETCH_USER_FULFILLED',
  FETCH_USER_REJECTED: 'FETCH_USER_REJECTED',

  ADD_USER: 'ADD_USER',
  ADD_USER_FULFILLED: 'ADD_USER_FULFILLED',
  ADD_USER_REJECTED: 'ADD_USER_REJECTED',

  DELETE_USER: 'DELETE_USER',
  DELETE_USER_FULFILLED: 'DELETE_USER_FULFILLED',
  DELETE_USER_REJECTED: 'DELETE_USER_REJECTED',

  EDIT_USER: 'EDIT_USER',
  EDIT_USER_FULFILLED: 'EDIT_USER_FULFILLED',
  EDIT_USER_REJECTED: 'EDIT_USER_REJECTED',

  /* Connected Properties */
  ADD_USER_TO_PROJECT: 'ADD_USER_TO_PROJECT',
  REMOVE_USER_FROM_PROJECT: 'REMOVE_USER_FROM_PROJECT',
  ADD_USER_TO_TRANSLATION: 'ADD_USER_TO_TRANSLATION',
  REMOVE_USER_FROM_TRANSLATION: 'REMOVE_USER_FROM_TRANSLATION',
  UPDATE_CONNECTED_PROPERTY_SUCCESS: 'UPDATE_CONNECTED_PROPERTY_SUCCESS',
  UPDATE_CONNECTED_PROPERTY_ERROR: 'UPDATE_CONNECTED_PROPERTY_ERROR',

  CHANGE_USER_ROLE: 'CHANGE_USER_ROLE',
  CHANGE_USER_ROLE_FOR_TRANSLATION: 'CHANGE_USER_ROLE_FOR_TRANSLATION',

  LINK_USER_TO_PROJECT: 'LINK_USER_TO_PROJECT',
  LINK_USER_TO_PROJECT_FULFILLED: 'LINK_USER_TO_PROJECT_FULFILLED',
  LINK_USER_TO_PROJECT_REJECTED: 'LINK_USER_TO_PROJECT_REJECTED',

  LINK_USER_TO_TRANSLATION: 'LINK_USER_TO_TRANSLATION',
  LINK_USER_TO_TRANSLATION_FULFILLED: 'LINK_USER_TO_TRANSLATION_FULFILLED',
  LINK_USER_TO_TRANSLATION_REJECTED: 'LINK_USER_TO_TRANSLATION_REJECTED',

  LINK_USER_TO_CLIENT: 'LINK_USER_TO_CLIENT',
  LINK_USER_TO_CLIENT_FULFILLED: 'LINK_USER_TO_CLIENT_FULFILLED',
  LINK_USER_TO_CLIENT_REJECTED: 'LINK_USER_TO_CLIENT_REJECTED',

  INVITE_USER: 'INVITE_USER',
  INVITE_USER_FULFILLED: 'INVITE_USER_FULFILLED',
  INVITE_USER_REJECTED: 'INVITE_USER_REJECTED'
};

export const ProjectsAction = {
  SELECT_PROJECT: 'SELECT_PROJECT',

  FETCH_PROJECTS: 'FETCH_PROJECTS',
  FETCH_PROJECTS_FULFILLED: 'FETCH_PROJECTS_FULFILLED',
  FETCH_PROJECTS_REJECTED: 'FETCH_PROJECTS_REJECTED',

  FETCH_PROJECT: 'FETCH_PROJECT',
  FETCH_PROJECT_FULFILLED: 'FETCH_PROJECT_FULFILLED',
  FETCH_PROJECT_REJECTED: 'FETCH_PROJECT_REJECTED',

  FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS: 'FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS',
  FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_FULFILLED: 'FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_FULFILLED',
  FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_REJECTED: 'FETCH_PROJECT_ADDITIONAL_CONTENT_COLLECTIONS_REJECTED',

  IMPORT_PROJECT: 'IMPORT_PROJECT',
  IMPORT_PROJECT_FULFILLED: 'IMPORT_PROJECT_FULFILLED',
  IMPORT_PROJECT_REJECTED: 'IMPORT_PROJECT_REJECTED',

  EDIT_PROJECT: 'EDIT_PROJECT',
  EDIT_PROJECT_FULFILLED: 'EDIT_PROJECT_FULFILLED',
  EDIT_PROJECT_REJECTED: 'EDIT_PROJECT_REJECTED',

  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_FULFILLED: 'DELETE_PROJECT_FULFILLED',
  DELETE_PROJECT_REJECTED: 'DELETE_PROJECT_REJECTED',

  ON_UPLOAD_STARTED: 'ON_UPLOAD_STARTED',
  ON_UPLOAD_ENDED: 'ON_UPLOAD_ENDED',

  VALIDATE_PROJECT: 'VALIDATE_PROJECT',
  VALIDATE_PROJECT_FULFILLED: 'VALIDATE_PROJECT_FULFILLED',
  VALIDATE_PROJECT_REJECTED: 'VALIDATE_PROJECT_REJECTED',

  VALIDATE_UPDATE: 'VALIDATE_UPDATE',
  VALIDATE_UPDATE_FULFILLED: 'VALIDATE_UPDATE_FULFILLED',
  VALIDATE_UPDATE_REJECTED: 'VALIDATE_UPDATE_REJECTED',

  IMPORT_UPDATE: 'IMPORT_UPDATE',
  IMPORT_UPDATE_FULFILLED: 'IMPORT_UPDATE_FULFILLED',
  IMPORT_UPDATE_REJECTED: 'IMPORT_UPDATE_REJECTED',

  RESET_COLLECTED_INFOS: 'RESET_COLLECTED_INFOS',

  RESET_DOWNLOAD_PATH: 'RESET_DOWNLOAD_PATH',

  FETCH_AVAILABLE_EXPORTS: 'FETCH_AVAILABLE_EXPORTS',
  FETCH_AVAILABLE_EXPORTS_FULFILLED: 'FETCH_AVAILABLE_EXPORTS_FULFILLED',
  FETCH_AVAILABLE_EXPORTS_REJECTED: 'FETCH_AVAILABLE_EXPORTS_REJECTED',

  FETCH_TRANSLATION: 'FETCH_TRANSLATION',
  FETCH_TRANSLATION_FULFILLED: 'FETCH_TRANSLATION_FULFILLED',
  FETCH_TRANSLATION_REJECTED: 'FETCH_TRANSLATION_REJECTED',

  EDIT_TRANSLATION: 'EDIT_TRANSLATION',
  EDIT_TRANSLATION_FULFILLED: 'EDIT_TRANSLATION_FULFILLED',
  EDIT_TRANSLATION_REJECTED: 'EDIT_TRANSLATION_REJECTED',

  EXPORT_TRANSLATION: 'EXPORT_TRANSLATION',
  EXPORT_TRANSLATION_FULFILLED: 'EXPORT_TRANSLATION_FULFILLED',
  EXPORT_TRANSLATION_REJECTED: 'EXPORT_TRANSLATION_REJECTED',

  FETCH_TRANSLATION_STATISTICS: 'FETCH_TRANSLATION_STATISTICS',
  FETCH_TRANSLATION_STATISTICS_FULFILLED:
    'FETCH_TRANSLATION_STATISTICS_FULFILLED',
  FETCH_TRANSLATION_STATISTICS_REJECTED:
    'FETCH_TRANSLATION_STATISTICS_REJECTED',

  ADD_TRANSLATION: 'ADD_TRANSLATION',
  ADD_TRANSLATION_FULFILLED: 'ADD_TRANSLATION_FULFILLED',
  ADD_TRANSLATION_REJECTED: 'ADD_TRANSLATION_REJECTED',

  IMPORT_TRANSLATION: 'IMPORT_TRANSLATION',
  IMPORT_TRANSLATION_FULFILLED: 'IMPORT_TRANSLATION_FULFILLED',
  IMPORT_TRANSLATION_REJECTED: 'IMPORT_TRANSLATION_REJECTED',

  IMPORT_PDF_TRANSLATION: 'IMPORT_PDF_TRANSLATION',
  IMPORT_PDF_TRANSLATION_FULFILLED: 'IMPORT_PDF_TRANSLATION_FULFILLED',
  IMPORT_PDF_TRANSLATION_REJECTED: 'IMPORT_PDF_TRANSLATION_REJECTED',

  VALIDATE_TRANSLATION: 'VALIDATE_TRANSLATION',
  VALIDATE_TRANSLATION_FULFILLED: 'VALIDATE_TRANSLATION_FULFILLED',
  VALIDATE_TRANSLATION_REJECTED: 'VALIDATE_TRANSLATION_REJECTED',

  VALIDATE_PDF_TRANSLATION: 'VALIDATE_PDF_TRANSLATION',
  VALIDATE_PDF_TRANSLATION_FULFILLED: 'VALIDATE_PDF_TRANSLATION_FULFILLED',
  VALIDATE_PDF_TRANSLATION_REJECTED: 'VALIDATE_PDF_TRANSLATION_REJECTED',

  DELETE_TRANSLATION: 'DELETE_TRANSLATION',
  DELETE_TRANSLATION_FULFILLED: 'DELETE_TRANSLATION_FULFILLED',
  DELETE_TRANSLATION_REJECTED: 'DELETE_TRANSLATION_REJECTED',

  SET_FORCE_APPROVAL: 'SET_FORCE_APPROVAL',
  SET_FORCE_APPROVAL_FULFILLED: 'SET_FORCE_APPROVAL_FULFILLED',
  SET_FORCE_APPROVAL_REJECTED: 'SET_FORCE_APPROVAL_REJECTED',

  LINK_PROJECT_TO_USER: 'LINK_PROJECT_TO_USER',
  LINK_PROJECT_TO_USER_FULFILLED: 'LINK_PROJECT_TO_USER_FULFILLED',
  LINK_PROJECT_TO_USER_REJECTED: 'LINK_PROJECT_TO_USER_REJECTED',

  LINK_TRANSLATION_TO_USER: 'LINK_TRANSLATION_TO_USER',
  LINK_TRANSLATION_TO_USER_FULFILLED: 'LINK_TRANSLATION_TO_USER_FULFILLED',
  LINK_TRANSLATION_TO_USER_REJECTED: 'LINK_TRANSLATION_TO_USER_REJECTED',

  LINK_PROJECT_TO_EXPORT_TYPE: 'LINK_PROJECT_TO_EXPORT_TYPE',
  LINK_PROJECT_TO_EXPORT_TYPE_FULFILLED: 'LINK_PROJECT_TO_EXPORT_TYPE_FULFILLED',
  LINK_PROJECT_TO_EXPORT_TYPE_REJECTED: 'LINK_PROJECT_TO_EXPORT_TYPE_REJECTED',

  FETCH_CONFIG: 'FETCH_CONFIG',
  FETCH_CONFIG_FULFILLED: 'FETCH_CONFIG_FULFILLED',
  FETCH_CONFIG_REJECTED: 'FETCH_CONFIG_REJECTED'
};

export const AdditionalContentsAction = {
  FETCH_ADDITIONAL_CONTENTS: 'FETCH_ADDITIONAL_CONTENTS',
  FETCH_ADDITIONAL_CONTENTS_FULFILLED: 'FETCH_ADDITIONAL_CONTENTS_FULFILLED',
  FETCH_ADDITIONAL_CONTENTS_REJECTED: 'FETCH_ADDITIONAL_CONTENTS_REJECTED',

  FETCH_PDF_FILES: 'FETCH_PDF_FILES',
  FETCH_PDF_FILES_FULFILLED: 'FETCH_PDF_FILES_FULFILLED',
  FETCH_PDF_FILES_REJECTED: 'FETCH_PDF_FILES_REJECTED',

  FETCH_VO_FILES: 'FETCH_VO_FILES',
  FETCH_VO_FILES_FULFILLED: 'FETCH_VO_FILES_FULFILLED',
  FETCH_VO_FILES_REJECTED: 'FETCH_VO_FILES_REJECTED',

  UPDATE_ADDITIONAL_CONTENTS: 'UPDATE_ADDITIONAL_CONTENTS',
  UPDATE_ADDITIONAL_CONTENTS_FULFILLED: 'UPDATE_ADDITIONAL_CONTENTS_FULFILLED',
  UPDATE_ADDITIONAL_CONTENTS_REJECTED: 'UPDATE_ADDITIONAL_CONTENTS_REJECTED',

  ADD_ADDITIONAL_CONTENTS: 'ADD_ADDITIONAL_CONTENTS',
  ADD_ADDITIONAL_CONTENTS_FULFILLED: 'ADD_ADDITIONAL_CONTENTS_FULFILLED',
  ADD_ADDITIONAL_CONTENTS_REJECTED: 'ADD_ADDITIONAL_CONTENTS_REJECTED'
};

export const SnapshotsAction = {
  FETCH_SNAPSHOTS: 'FETCH_SNAPSHOTS',
  FETCH_SNAPSHOTS_FULFILLED: 'FETCH_SNAPSHOTS_FULFILLED',
  FETCH_SNAPSHOTS_REJECTED: 'FETCH_SNAPSHOTS_REJECTED',

  ADD_SNAPSHOT: 'ADD_SNAPSHOT',
  ADD_SNAPSHOT_FULFILLED: 'ADD_SNAPSHOT_FULFILLED',
  ADD_SNAPSHOT_REJECTED: 'ADD_SNAPSHOT_REJECTED',

  EDIT_SNAPSHOT: 'EDIT_SNAPSHOT',
  EDIT_SNAPSHOT_FULFILLED: 'EDIT_SNAPSHOT_FULFILLED',
  EDIT_SNAPSHOT_REJECTED: 'EDIT_SNAPSHOT_REJECTED',

  DELETE_SNAPSHOT: 'DELETE_SNAPSHOT',
  DELETE_SNAPSHOT_FULFILLED: 'DELETE_SNAPSHOT_FULFILLED',
  DELETE_SNAPSHOT_REJECTED: 'DELETE_SNAPSHOT_REJECTED'
};

export const SourceSnapshotsAction = {
  FETCH_SOURCE_SNAPSHOTS: 'FETCH_SOURCE_SNAPSHOTS',
  FETCH_SOURCE_SNAPSHOTS_FULFILLED: 'FETCH_SOURCE_SNAPSHOTS_FULFILLED',
  FETCH_SOURCE_SNAPSHOTS_REJECTED: 'FETCH_SOURCE_SNAPSHOTS_REJECTED',

  SET_SOURCE_SNAPSHOT_ID: 'SET_SOURCE_SNAPSHOT_ID'
};

export const ProjectsFilterAction = {
  FILTER_BY_TYPE: 'FILTER_BY_TYPE',
  RESET_FILTER: 'RESET_FILTER',
  SORT_BY_PROP: 'SORT_BY_PROP',
  RESET_SORT: 'RESET_SORT',
  SEARCH_BY_TERM: 'SEARCH_BY_TYPE',
  RESET_SEARCH: 'RESET_SEARCH'
};

export const LanguagesAction = {
  FETCH_LANGUAGES: 'FETCH_LANGUAGES',
  FETCH_LANGUAGES_FULFILLED: 'FETCH_LANGUAGES_FULFILLED',
  FETCH_LANGUAGES_REJECTED: 'FETCH_LANGUAGES_REJECTED'
};

export const ViewportsAction = {
  FETCH_VIEWPORTS: 'FETCH_VIEWPORTS',
  FETCH_VIEWPORTS_FULFILLED: 'FETCH_VIEWPORTS_FULFILLED',
  FETCH_VIEWPORTS_REJECTED: 'FETCH_VIEWPORTS_REJECTED',

  UPDATE_WIDTH: 'UPDATE_WIDTH',
  UPDATE_HEIGHT: 'UPDATE_HEIGHT',
  UPDATE_VIEWPORT: 'UPDATE_VIEWPORT',
  SET_CUSTOM_VIEWPORT: 'SET_CUSTOM_VIEWPORT'
};

export const TypesAction = {
  FETCH_TYPES: 'FETCH_TYPES',
  FETCH_TYPES_FULFILLED: 'FETCH_TYPES_FULFILLED',
  FETCH_TYPES_REJECTED: 'FETCH_TYPES_REJECTED'
};

export const EngineTypesAction = {
  FETCH_ENGINE_TYPES: 'FETCH_ENGINE_TYPES',
  FETCH_ENGINE_TYPES_FULFILLED: 'FETCH_ENGINE_TYPES_FULFILLED',
  FETCH_ENGINE_TYPES_REJECTED: 'FETCH_ENGINE_TYPES_REJECTED'
};

export const ExportTypesAction = {
  FETCH_EXPORT_TYPES: 'FETCH_EXPORT_TYPES',
  FETCH_EXPORT_TYPES_FULFILLED: 'FETCH_EXPORT_TYPES_FULFILLED',
  FETCH_EXPORT_TYPES_REJECTED: 'FETCH_EXPORT_TYPES_REJECTED'
};

export const TextScopesAction = {
  FETCH_TEXT_SCOPES: 'FETCH_TEXT_SCOPES',
  FETCH_TEXT_SCOPES_FULFILLED: 'FETCH_TEXT_SCOPES_FULFILLED',
  FETCH_TEXT_SCOPES_REJECTED: 'FETCH_TEXT_SCOPES_REJECTED'
};

export const StatusAction = {
  FETCH_STATUS: 'FETCH_STATUS',
  FETCH_STATUS_FULFILLED: 'FETCH_STATUS_FULFILLED',
  FETCH_STATUS_REJECTED: 'FETCH_STATUS_REJECTED'
};

export const EnumDefaultsAction = {
  FETCH_PROJECT_ENUM_DEFAULTS: 'FETCH_PROJECT_ENUM_DEFAULTS',
  FETCH_PROJECT_ENUM_DEFAULTS_FULFILLED:
    'FETCH_PROJECT_ENUM_DEFAULTS_FULFILLED',
  FETCH_PROJECT_ENUM_DEFAULTS_REJECTED: 'FETCH_PROJECT_ENUM_DEFAULTS_REJECTED',

  FETCH_TRANSLATION_ENUM_DEFAULTS: 'FETCH_TRANSLATION_ENUM_DEFAULTS',
  FETCH_TRANSLATION_ENUM_DEFAULTS_FULFILLED:
    'FETCH_TRANSLATION_ENUM_DEFAULTS_FULFILLED',
  FETCH_TRANSLATION_ENUM_DEFAULTS_REJECTED:
    'FETCH_TRANSLATION_ENUM_DEFAULTS_REJECTED'
};

export const TranslationsFilterAction = {
  SEARCH_BY_TERM: 'SEARCH_BY_TYPE',
  RESET_SEARCH: 'RESET_SEARCH'
};

export const ContactsAction = {
  FETCH_CONTACTS: 'FETCH_CONTACTS',
  FETCH_CONTACTS_FULFILLED: 'FETCH_CONTACTS_FULFILLED',
  FETCH_CONTACTS_REJECTED: 'FETCH_CONTACTS_REJECTED',

  ADD_CONTACT: 'ADD_CONTACT',
  ADD_CONTACT_FULFILLED: 'ADD_CONTACT_FULFILLED',
  ADD_CONTACT_REJECTED: 'ADD_CONTACT_REJECTED',

  EDIT_CONTACT: 'EDIT_CONTACT',
  EDIT_CONTACT_FULFILLED: 'EDIT_CONTACT_FULFILLED',
  EDIT_CONTACT_REJECTED: 'EDIT_CONTACT_REJECTED',

  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_CONTACT_FULFILLED: 'DELETE_CONTACT_FULFILLED',
  DELETE_CONTACT_REJECTED: 'DELETE_CONTACT_REJECTED'
};

export const RolesAction = {
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_FULFILLED: 'FETCH_ROLES_FULFILLED',
  FETCH_ROLES_REJECTED: 'FETCH_ROLES_REJECTED',

  ADD_ROLE: 'ADD_ROLE',
  ADD_ROLE_FULFILLED: 'ADD_ROLE_FULFILLED',
  ADD_ROLE_REJECTED: 'ADD_ROLE_REJECTED',

  EDIT_ROLE: 'EDIT_ROLE',
  EDIT_ROLE_FULFILLED: 'EDIT_ROLE_FULFILLED',
  EDIT_ROLE_REJECTED: 'EDIT_ROLE_REJECTED',

  DELETE_ROLE: 'DELETE_ROLE',
  DELETE_ROLE_FULFILLED: 'DELETE_ROLE_FULFILLED',
  DELETE_ROLE_REJECTED: 'DELETE_ROLE_REJECTED',

  FETCH_ROLE_PERMISSIONS: 'FETCH_ROLE_PERMISSIONS',

  FETCH_ROLE_PERMISSIONS_FULFILLED: 'FETCH_ROLE_PERMISSIONS_FULFILLED',
  FETCH_ROLE_PERMISSIONS_REJECTED: 'FETCH_ROLE_PERMISSIONS_REJECTED',
  RESET_ROLE_PERMISSIONS: 'RESET_ROLE_PERMISSIONS',

  CHANGE_ROLE_PERMISSIONS: 'CHANGE_ROLE_PERMISSIONS',
  CHANGE_ROLE_PERMISSIONS_FULFILLED: 'CHANGE_ROLE_PERMISSIONS_FULFILLED',
  CHANGE_ROLE_PERMISSIONS_REJECTED: 'CHANGE_ROLE_PERMISSIONS_REJECTED'
};

export const PermissionsAction = {
  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_FULFILLED: 'FETCH_PERMISSIONS_FULFILLED',
  FETCH_PERMISSIONS_REJECTED: 'FETCH_PERMISSIONS_REJECTED',

  ADD_PERMISSION: 'ADD_PERMISSION',
  ADD_PERMISSION_FULFILLED: 'ADD_PERMISSION_FULFILLED',
  ADD_PERMISSION_REJECTED: 'ADD_PERMISSION_REJECTED',

  EDIT_PERMISSION: 'EDIT_PERMISSION',
  EDIT_PERMISSION_FULFILLED: 'EDIT_PERMISSION_FULFILLED',
  EDIT_PERMISSION_REJECTED: 'EDIT_PERMISSION_REJECTED',

  DELETE_PERMISSION: 'DELETE_PERMISSION',
  DELETE_PERMISSION_FULFILLED: 'DELETE_PERMISSION_FULFILLED',
  DELETE_PERMISSION_REJECTED: 'DELETE_PERMISSION_REJECTED'
};

export const ProfileAction = {
  EDIT_PROFILE: 'EDIT_PROFILE',
  EDIT_PROFILE_INITIATED: 'EDIT_PROFILE_INITIATED',
  EDIT_PROFILE_FULFILLED: 'EDIT_PROFILE_FULFILLED',
  EDIT_PROFILE_REJECTED: 'EDIT_PROFILE_REJECTED'
};

export const I18nAction = {
  FETCH_AVAILABLE_TRANSLATIONS: 'FETCH_AVAILABLE_TRANSLATIONS',
  FETCH_AVAILABLE_TRANSLATIONS_FULFILLED:
    'FETCH_AVAILABLE_TRANSLATIONS_FULFILLED',
  FETCH_AVAILABLE_TRANSLATIONS_REJECTED: 'FETCH_AVAILABLE_TRANSLATIONS_REJECTED'
};

export const UploaderAction = {
  DISCARD_UPLOAD: 'DISCARD_UPLOAD',
  DISCARD_UPLOAD_FULFILLED: 'DISCARD_UPLOAD_FULFILLED',
  DISCARD_UPLOAD_REJECTED: 'DISCARD_UPLOAD_REJECTED'
};

export const WbtViewerAction = {
  FETCH_ALL_TEXTS: 'FETCH_ALL_TEXTS',
  FETCH_ALL_TEXTS_FULFILLED: 'FETCH_ALL_TEXTS_FULFILLED',
  FETCH_ALL_TEXTS_REJECTED: 'FETCH_ALL_TEXTS_REJECTED',

  FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION:
    'FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION',
  FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_FULFILLED:
    'FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_FULFILLED',
  FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_REJECTED:
    'FETCH_ALL_TEXTS_GROUPED_BY_COLLECTION_REJECTED',

  FETCH_SOURCE: 'FETCH_SOURCE',
  FETCH_SOURCE_FULFILLED: 'FETCH_SOURCE_FULFILLED',
  FETCH_SOURCE_REJECTED: 'FETCH_SOURCE_REJECTED',

  TOGGLE_LIVE_EDIT: 'TOGGLE_LIVE_EDIT'
};

export const StructureAction = {
  FETCH_STRUCTURE: 'FETCH_STRUCTURE',
  FETCH_STRUCTURE_FULFILLED: 'FETCH_STRUCTURE_FULFILLED',
  FETCH_STRUCTURE_REJECTED: 'FETCH_STRUCTURE_REJECTED',

  FILTER_STRUCTURE: 'FILTER_STRUCTURE',

  SELECT_ITEM: 'SELECT_ITEM',
  SELECT_ITEM_FULFILLED: 'SELECT_ITEM_FULFILLED',
  SELECT_ITEM_REJECTED: 'SELECT_ITEM_REJECTED',

  SET_ITEM_STATUS: 'SET_ITEM_STATUS',
  SET_ITEM_STATUS_FULFILLED: 'SET_ITEM_STATUS_FULFILLED',
  SET_ITEM_STATUS_REJECTED: 'SET_ITEM_STATUS_REJECTED',

  SET_ITEM_VISIBILITY: 'SET_ITEM_VISIBILITY',
  SET_ITEM_VISIBILITY_FULFILLED: 'SET_ITEM_VISIBILITY_FULFILLED',
  SET_ITEM_VISIBILITY_REJECTED: 'SET_ITEM_VISIBILITY_REJECTED',

  GET_ITEM_STATUS: 'GET_ITEM_STATUS'
};

export const ImageAction = {
  SWITCH_MODE: 'SWITCH_MODE',
  SWITCH_PERMANENTLY: 'SWITCH_PERMANENTLY',
  LOAD_IMAGE: 'LOAD_IMAGE',
  RESTORE_IMAGE: 'RESTORE_IMAGE',
  SET_CROPPED: 'SET_CROPPED',
  SAVE_IMAGE: 'SAVE_IMAGE',
  FETCH_IMAGE: 'FETCH_IMAGE',
  FETCH_IMAGE_FULFILLED: 'FETCH_IMAGE_FULFILLED',
  FETCH_IMAGE_REJECTED: 'FETCH_IMAGE_REJECTED',
  FETCH_FULL_IMAGE: 'FETCH_FULL_IMAGE',
  FETCH_FULL_IMAGE_FULFILLED: 'FETCH_FULL_IMAGE_FULFILLED',
  FETCH_FULL_IMAGE_REJECTED: 'FETCH_FULL_IMAGE_REJECTED',
  FETCH_CROPPED_IMAGE: 'FETCH_CROPPED_IMAGE',
  FETCH_CROPPED_IMAGE_FULFILLED: 'FETCH_CROPPED_IMAGE_FULFILLED',
  FETCH_CROPPED_IMAGE_REJECTED: 'FETCH_CROPPED_IMAGE_REJECTED',
  ADD_IMAGE: 'ADD_IMAGE',
  ADD_IMAGE_FULFILLED: 'ADD_IMAGE_FULFILLED',
  ADD_IMAGE_REJECTED: 'ADD_IMAGE_REJECTED',
  EDIT_IMAGE: 'EDIT_IMAGE',
  EDIT_IMAGE_FULFILLED: 'EDIT_IMAGE_FULFILLED',
  EDIT_IMAGE_REJECTED: 'EDIT_IMAGE_REJECTED',
  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_IMAGE_FULFILLED: 'DELETE_IMAGE_FULFILLED',
  DELETE_IMAGE_REJECTED: 'DELETE_IMAGE_REJECTED'
};

export const HelpAction = {
  SET_HELP: 'SET_HELP'
};

export const HistorySelectAction = {
  SET_HISTORY: 'SET_HISTORY'
};

export const StoryboardAction = {
  FETCH_CHAPTER: 'FETCH_CHAPTER',
  FETCH_CHAPTER_FULFILLED: 'FETCH_CHAPTER_FULFILLED',
  FETCH_CHAPTER_REJECTED: 'FETCH_CHAPTER_REJECTED',
  ADD_TEXT_BLOCK: 'ADD_TEXT_BLOCK',
  ADD_TEXT_BLOCK_FULFILLED: 'ADD_TEXT_BLOCK_FULFILLED',
  ADD_TEXT_BLOCK_REJECTED: 'ADD_TEXT_BLOCK_REJECTED',
  LOCK_TEXT_BLOCK: 'LOCK_TEXT_BLOCK',
  LOCK_TEXT_BLOCK_FULFILLED: 'LOCK_TEXT_BLOCK_FULFILLED',
  LOCK_TEXT_BLOCK_REJECTED: 'LOCK_TEXT_BLOCK_REJECTED',
  UNLOCK_TEXT_BLOCK: 'UNLOCK_TEXT_BLOCK',
  UNLOCK_TEXT_BLOCK_FULFILLED: 'UNLOCK_TEXT_BLOCK_FULFILLED',
  UNLOCK_TEXT_BLOCK_REJECTED: 'UNLOCK_TEXT_BLOCK_REJECTED',
  UPDATE_TEXT_BLOCK: 'UPDATE_TEXT_BLOCK',
  UPDATE_TEXT_BLOCK_FULFILLED: 'UPDATE_TEXT_BLOCK_FULFILLED',
  UPDATE_TEXT_BLOCK_REJECTED: 'UPDATE_TEXT_BLOCK_REJECTED',
  DELETE_TEXT_BLOCK: 'DELETE_TEXT_BLOCK',
  DELETE_TEXT_BLOCK_FULFILLED: 'DELETE_TEXT_BLOCK_FULFILLED',
  DELETE_TEXT_BLOCK_REJECTED: 'DELETE_TEXT_BLOCK_REJECTED',
  FETCH_STORYBOARD: 'FETCH_STORYBOARD',
  FETCH_STORYBOARD_FULFILLED: 'FETCH_STORYBOARD_FULFILLED',
  FETCH_STORYBOARD_REJECTED: 'FETCH_STORYBOARD_REJECTED',
  FETCH_STORYBOARDS: 'FETCH_STORYBOARDS',
  FETCH_STORYBOARDS_FULFILLED: 'FETCH_STORYBOARDS_FULFILLED',
  FETCH_STORYBOARDS_REJECTED: 'FETCH_STORYBOARDS_REJECTED',
  SET_CURRENT_CHAPTER: 'SET_CURRENT_CHAPTER',
  SET_VIEW_MODE: 'SET_VIEW_MODE',
  SET_COMPARE_MODE: 'SET_COMPARE_MODE',
  SET_CURRENT_EDITOR_ID: 'SET_CURRENT_EDITOR_ID',
  FETCH_HISTORY: 'FETCH_HISTORY',
  FETCH_HISTORY_FULFILLED: 'FETCH_HISTORY_FULFILLED',
  FETCH_HISTORY_REJECTED: 'FETCH_HISTORY_REJECTED',
  UPDATE_SITE: 'UPDATE_SITE',
  UPDATE_SITE_FULFILLED: 'UPDATE_SITE_FULFILLED',
  UPDATE_SITE_REJECTED: 'UPDATE_SITE_REJECTED',
  ADD_SITE: 'ADD_SITE',
  ADD_SITE_FULFILLED: 'ADD_SITE_FULFILLED',
  ADD_SITE_REJECTED: 'ADD_SITE_REJECTED',
  DELETE_SITE: 'DELETE_SITE',
  DELETE_SITE_FULFILLED: 'DELETE_SITE_FULFILLED',
  DELETE_SITE_REJECTED: 'DELETE_SITE_REJECTED',
  OPEN_STORYBOARD_QUESTION_DIALOG: 'OPEN_STORYBOARD_QUESTION_DIALOG',
  CLOSE_STORYBOARD_QUESTION_DIALOG: 'CLOSE_STORYBOARD_QUESTION_DIALOG'
};
