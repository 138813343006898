import React, {useState, useEffect, useMemo, useRef} from 'react';
import ContentEditor from './ContentEditor';
// import {ViewMode, CompareMode} from '../../utils/Constants';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {useLocalStorage} from './hooks/useLocalStorage';
import {useTranslation} from 'react-i18next';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import {
  addTextBlock,
  updateTextBlock,
  deleteTextBlock,
  updateSite,
  addSite,
  deleteSite,
  openStoryboardQuestionDialog
} from '../../actions/storyboard';
import arrayMove from 'array-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import styles from '../../styles/storyboards/site.scss';
import Tooltip from '@material-ui/core/Tooltip';
import {
  // convertDraftRawToHtmlString
  convertHtmlStringToDraftRaw
} from './editor/helper/convertingForEditorWorks';
import {is} from 'immutable';
import {ContentState,  convertToRaw, convertFromRaw } from 'draft-js';

import TextBlock from './TextBlock';
import FoldingContainer from './FoldingContainer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SortIcon from '@material-ui/icons/Sort';
import {ProcessStatus} from '../../utils/Constants';
import CachedIcon from '@material-ui/icons/Cached';
import useForceUpdate from 'use-force-update';
import CreateIcon from '@material-ui/icons/Create';
import MoreMenu from './MoreMenu';
import {useParams} from 'react-router-dom';

// USEFUL HOOKS:
// import useReactRouter from 'use-react-router';
// import useForceUpdate from 'use-force-update';

// /**
//  * @return {string}
//  */

export function customShallowEqual (objA, objB) {
  // console.log({objA, objB});
  // console.log('SITE:: is(objA, objB)');
  // console.log(is(objA, objB));
  // console.log('objA.text_blocks.length === objB.text_blocks.length',
  //   objA.text_blocks.length === objB.text_blocks.length);
  // console.log('SITE:: objA.id === objB.id =', objA.id === objB.id);
  // console.log(is(blobA, blobB));

  let hasHistoryUpdate = false;
  for (let i = 0; i < objA.text_blocks.length; i++) {
    // console.log('objA.text_blocks[i].history_count !== objB.text_blocks[i].history_count',
    //   objA.text_blocks[i].history_count !== objB.text_blocks[i].history_count);
    if (objA.text_blocks[i].history_count !== objB.text_blocks[i].history_count) {
      hasHistoryUpdate = true;
      break;
    }
  }

  //console.log('customShallowEqual');
  //console.log('objA', objA);
  //console.log('objB', objB);
  //console.log(objA.text_blocks, objB.text_blocks);
  //console.log(objA.text_blocks.length, objB.text_blocks.length);
  //console.log(objA.text_blocks.length === objB.text_blocks.length);

  if (objA.id === objB.id
   && objA.sorting === objB.sorting
   && objA.text_blocks.length === objB.text_blocks.length
   && !hasHistoryUpdate) {
    return true;
  }
  //console.log('customShallowEqual UPDATE!');
  return false;
}

export default function Site (props) {
  const site = useSelector((state) => {
    return state.getIn(['storyboard', 'chapter']).find(site => {
      return (
        site.id === props.siteId
      );
    });
  }, customShallowEqual);

  console.log('-----------site', site);

  const forceUpdate = useForceUpdate();

  const user = useSelector(state => state.getIn(['auth', 'user']));
  const addingTextBlock = useSelector(state => state.getIn(['storyboard', 'addingTextBlock']));
  const addingSite = useSelector(state => state.getIn(['storyboard', 'addingSite']));
  const currentChapterId = useSelector(state => state.getIn(['storyboard', 'currentChapterId']));
  const deletingSite = useSelector(state => state.getIn(['storyboard', 'deletingSite']));
  // const viewMode = useSelector(state => state.getIn(['storyboard', 'viewMode']));
  // const compareMode = useSelector(state => state.getIn(['storyboard', 'compareMode']));

  const getContentSortingArray = () => {
    let sortingArray = [];
    for (let u = 0; u < site.sorting.length; u++) {
      for (let uu = 0; uu < site.text_blocks.length; uu++) {
        if (site.text_blocks[uu].id === site.sorting[u]
            && site.text_blocks[uu].type_id === 4) {
          sortingArray.push(site.sorting[u]);
        }
      }
    }
    return sortingArray;
  };

  const [sorting, setSorting] = useState(getContentSortingArray(site.sorting));
  const [contentSize, setContentSize] = useState('inherit');
  const [showGhostRisize, setShowGhostRisize] = useState(false);
  const refPaper = useRef(null);
  const refGhostResize = useRef(null);
  const refMoreMenuBtn = useRef(null);
  const refSite = useRef(null);

  const [siteInMoveMode, setSiteInMoveMode] = useState(false);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const [contents, setContents] = useState(null);
  const [description, setDescription] = useState(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const [newSiteCollectionName, setNewSiteCollectionName] = useState('');
  const [siteLS, setSiteLS] = useLocalStorage('site_' + props.siteId, site);
  // const [siteLS, setSiteLS] = useState('site_' + props.siteId, site);
  const { t } = useTranslation('translation_navigation');

  let { storyboardId } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSiteBtnClicked, setDeleteSiteBtnClicked] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const addSiteFromDialog = () => {
    let sendObj = {
      insert_after_site_id: parseInt(id),
      storyboard_id: parseInt(storyboardId),
      chapter_id: currentChapterId,
      collection: newSiteCollectionName
    };
    dispatch(addSite(sendObj));
    setOpenDialog(false);
  };

  const newTextBlock = useSelector(state => state.getIn(['storyboard', 'textBlock']));
  const dispatch = useDispatch();

  const getCopyOfObject = (obj) => JSON.parse(JSON.stringify(obj));

  // get ONE TextBlock for Description
  const getTitle = (textBlocks) => {
    return textBlocks.find(textBLock => textBLock.type_id === 1);
  };

  // get ONE TextBlock for Description
  const getDescription = (textBlocks) => {
    return textBlocks.find(textBLock => textBLock.type_id === 3);
  };

  // get multiple TextBlocks for Contents-Array
  const getContents = (textBlocks) => {
    return textBlocks.filter(textBlock => {
      if (textBlock.type_id === 4) {
        return textBlock;
      }
    });
  };

  // EFFECT setze State
  useEffect(() => {
    if (site) {
      setId(site.id);
      setTitle(getTitle(site.text_blocks));
      setDescription(getDescription(site.text_blocks));
      setContents(getContents(site.text_blocks));
    }
  }, [site]);

  useEffect(() => {
    if (addingTextBlock === ProcessStatus.FINISHED) {
      setSorting(site.sorting); // Site mit neuem Textblock neu rendern
    }
  }, [addingTextBlock]);

  useEffect(() => {
    setTimeout(() => {
      if (siteInMoveMode) {
        refSite.current.classList.add('moveMode');
      } else {
        refSite.current.classList.remove('moveMode');
      }
    }, 50);
  }, [siteInMoveMode]);

  useEffect(() => {
    if (deletingSite !== ProcessStatus.INITIAL && deleteSiteBtnClicked) {
      refSite.current.classList.add('opacityTransition');
      setTimeout(() => {
        refSite.current.classList.add('deleteMarked');
      }, 10);
    }
  }, [deletingSite]);


  const removeTextItem = contentId => {
    let newContents = getCopyOfObject(contents);
    let textItemIndex = newContents.findIndex(elem => elem.id === contentId);
    if (textItemIndex >= 0) {
      let textItem = newContents[textItemIndex];
      newContents.splice(textItemIndex, 1);
      setContents(newContents);
      dispatch(deleteTextBlock(textItem.id, id));
    }
  };

  const addTextItem = () => {
    // let newSiteState = getCopyOfObject(site);
    // console.log('newSiteState', newSiteState);
    // let newContents = newSiteState.text_blocks;
    // let newEmptyTextItem = getCopyOfObject(
    //   newSiteState.text_blocks[newSiteState.text_blocks.length - 1]
    // );
    // newEmptyTextItem.history = [];
    // newEmptyTextItem.id = newEmptyTextItem.id + 1;
    // newEmptyTextItem.site_id = id;
    // newEmptyTextItem.content = convertHtmlStringToDraftRaw('...');
    // newEmptyTextItem.permanently = false;
    // newContents.push(newEmptyTextItem);
    // setContents(newContents);

    console.log('emtyContentJson', emtyContentJson);
    const textBlock = {
      'site_id': id,
      'type_id': 4,
      'content': emtyContentJson
    };
    dispatch(addTextBlock(textBlock));
  };

  const formatDate = (date) => {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var h = date.getHours();
    var min = date.getMinutes();
    var s = date.getSeconds();
    if (d < 10) {
      d = '0' + d;
    }
    if (m < 10) {
      m = '0' + m;
    }
    if (h < 10) {
      h = '0' + h;
    }
    if (min < 10) {
      min = '0' + min;
    }
    if (s < 10) {
      s = '0' + s;
    }
    return h + ':' + min + ':' + s + ' - ' + d + '.' + m + '.' + y;
  };

  const saveTitle = (textId, text, unlock) => {
    console.log('################# saveTitle', textId, text);
    const escapedJsonTex = transformJsonToString(text);
    const textBlock = {
      'site_id': id,
      'type_id': 1,
      'content': escapedJsonTex,
      'unlock': unlock
    };
    let newTitle = getCopyOfObject(title);
    console.log('newTitle', newTitle);
    const textBlockId = newTitle.id;
    dispatch(updateTextBlock(textBlockId, textBlock));
  };

  const saveDescription = (textId, text, unlock) => {
    console.log('################# saveDescription', textId, text);
    const escapedJsonTex = transformJsonToString(text);
    const textBlock = {
      'site_id': id,
      'type_id': 3,
      'content': escapedJsonTex,
      'unlock': unlock
    };
    let newDescription = getCopyOfObject(description);
    console.log('newDescription', newDescription);
    const textBlockId = newDescription.id;
    dispatch(updateTextBlock(textBlockId, textBlock));
  };

  const saveText = (textId, text, unlock) => {
    console.log('################# saveText', textId, text);
    let newContents = getCopyOfObject(contents);
    let index = newContents.findIndex(elem => elem.id === textId);
    if (index >= 0) {
      let element = newContents[index];

      const escapedJsonTex = transformJsonToString(text);
      const textBlock = {
        'site_id': id,
        'type_id': 4,
        'content': escapedJsonTex,
        'unlock': unlock
      };
      // if (!element.permanently) {
      //   dispatch(addTextBlock(textBlock));
      // } else {
      const textBlockId = element.id;
      dispatch(updateTextBlock(textBlockId, textBlock));
      // }
    }
  };

  const transformJsonToString = (value) => {
    return JSON.stringify(value)
      .replace(/\\n/g, '\\n')
      .replace(/\\'/g, '\\\'')
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');
  };

  const emtyContentJson = transformJsonToString(
    convertToRaw(ContentState.createFromText('...'))
  );

  const setTextFromHistory = (contentId, historyId) => {
    let newSiteState = getCopyOfObject(site);
    let newContents = newSiteState.text.contents;

    for (var i = 0; i < newContents.length; i++) {
      let d = new Date();
      let date = formatDate(d);
      if (newContents[i].id === contentId) {      // find content by id
        let historyItem = null;
        for (var j = 0; j < newContents[i].history.length; j++) { // find history by id
          let hi = newContents[i].history[j];
          if (hi.id === historyId) {
            historyItem = hi;
          }
        }
        newContents[i].content = historyItem.text;  // set text from History
        newContents[i].history.push({               // Add new history entry
          id: newContents[i].history.length + 1,
          author: user.id,
          time: date,
          text: historyItem.text,
          linksToComment: ''
        });
      }
    }
    setContents(newContents);
  };

  const deleteHistoryEntry = (contentId, historyId) => {
    let newSiteState = getCopyOfObject(site);
    let newContents = newSiteState.text.contents;

    for (var i = 0; i < newContents.length; i++) {
      if (newContents[i].id === contentId) {      // find content by id
        for (var j = 0; j < newContents[i].history.length; j++) { // find history by id
          let hi = newContents[i].history[j];
          if (hi.id === historyId) {
            newContents[i].history.splice(j, 1);
          }
        }
      }
    }
    setContents(newContents);
  };

  const onSortEnd = props => {
    const {oldIndex, newIndex} = props;
    let oldSortingArray = JSON.parse(JSON.stringify(sorting));
    let newSortingArray = arrayMove(oldSortingArray, oldIndex, newIndex);
    setSorting(newSortingArray);
  };

  const DragHandle = sortableHandle(() =>
    <Tooltip title="Move textitem" placement="bottom">
      <span
        className='handle'
        onMouseOver={e => {
          e.preventDefault();
          setSiteInMoveMode(true);
        }}
      >
        <DragHandleIcon/>
      </span>
    </Tooltip>
  );

  const SortableItemWithContentEditor = sortableElement(props => {
    return (
      <div className={styles.sortableElement}>
        {contents.length > 1 && <DragHandle/>}
        <TextBlock
          {...props}
          index={props.index}
          siteId={props.siteId}
          type={'contents'}
          editable
          removeTextItem={removeTextItem}
          contentLength={contents.length}
          saveText={saveText}
        />
      </div>
    );
  });

  const SortableContainerEl = sortableContainer(({children}) => {
    return <div className={'sortableContainer'}>{children}</div>;
  });

  // USEFFECT abwarten:
  if (!siteLS || !contents || !title || !description) {  // wenn null ==> nichts anzeigen!
    // console.log('// wenn null ==> nichts anzeigen!');
    // console.log('siteLS', siteLS);
    // console.log('contents', contents);
    // console.log('title', title);
    // console.log('description', description);
    return null;
  }

  const Title = () => {
    return (
      <TextBlock
        isTitle={true}
        id={title.id}
        siteId={props.siteId}
        siteIndex={props.siteIndex}
        type={'title'}
        editable
        storyboardOnly={true}
        toolbarLocation={'relative'}
        saveText={saveTitle}
      />
    );
    // eslint-disable-next-line no-lone-blocks,no-unreachable
    {/*
    return <ContentEditor
      isTitle={true}
      id={title.id}
      siteId={props.siteId}
      siteIndex={props.siteIndex}
      type={'title'}
      editable
      storyboardOnly={true}
      toolbarLocation={'relative'}
      saveText={saveTitle}
    />;
    */}
  };

  const Description = () => {
    return (
      <TextBlock
        id={description.id}
        siteId={props.siteId}
        siteIndex={props.siteIndex}
        type={'description'}
        editable
        storyboardOnly={true}
        toolbarLocation={'relative'}
        saveText={saveDescription}
      />
    );
    // eslint-disable-next-line no-lone-blocks,no-unreachable
    {/*
    return <ContentEditor
      id={description.id}
      siteId={props.siteId}
      siteIndex={props.siteIndex}
      type={'description'}
      editable
      storyboardOnly={true}
      toolbarLocation={'relative'}
      saveText={saveDescription}
    />;
    */}
  };

  // console.log('UPDATE SITE:: ', {props}, site);

  const onMouseDownOnHandle = e => {
    e.preventDefault();
    setShowGhostRisize(true);
    let paper = refPaper.current;
    let paperRect = paper.getBoundingClientRect();
    let handle = e.clientX;
    let percent = ((handle - paperRect.left) / paperRect.width) * 100;
    refGhostResize.current.setAttribute('style', 'left: ' + percent + '%');
    document.addEventListener('mousemove', onMouseMoveOnHandle);
    document.addEventListener('mouseup', onMouseUpnOnHandle);
  };

  const onMouseMoveOnHandle = e => {
    e.preventDefault();
    let paper = refPaper.current;
    let paperRect = paper.getBoundingClientRect();
    let handle = e.clientX;
    let percent = ((handle - paperRect.left) / paperRect.width) * 100;
    refGhostResize.current.setAttribute('style', 'left: ' + percent + '%');
  };

  const onMouseUpnOnHandle = e => {
    e.preventDefault();
    setShowGhostRisize(false);
    setContentSize(refGhostResize.current.style.left);
    document.removeEventListener('mousemove', onMouseMoveOnHandle);
    document.removeEventListener('mouseup', onMouseUpnOnHandle);
  };

  let siteClassName = styles.site;
  if (props.isFirstSite) {
    siteClassName = siteClassName + ' one';
  }

  const saveSort = () => {
    console.log('id, sorting', id, sorting);
    dispatch(updateSite(id, {sorting: sorting}));
    setSiteInMoveMode(false);
  };

  const renderMoreMenu = () => {
    const handleMoreBtnClick = () => {
      setMoreMenuOpen(true);
    };

    const handleMoreMenuClose = () => {
      setMoreMenuOpen(false);
    };

    const handleDeleteSite = () => {
      setDeleteSiteBtnClicked(true);
      dispatch(openStoryboardQuestionDialog({
        title: 'Seite löschen?',
        text: <>
          <table style={{width: '100%'}}>
            <tr>
              <td><b>collection:</b></td>
              <td>{site.collection}</td>
            </tr>
            <tr>
              <td><b>id:</b></td>
              <td>{id}</td>
            </tr>
          </table>
        </>,
        yesBtn: {
          text: 'löschen',
          actionFunction: () => {
            console.log('yeah');
            dispatch(deleteSite(id));
          }
        },
        noBtn: {
          text: 'abbrechen'
        }
      }));

      handleMoreMenuClose();
    };

    const handleSetSortMode = () => {
      setSiteInMoveMode(true);
      handleMoreMenuClose();
    };

    return (
      <>
        {siteInMoveMode &&
         <Tooltip title="close sortmode" placement="bottom">
           <Button
             className="closeSortMode"
             variant=""
             color="default"
             // startIcon={null}
             onClick={saveSort}
           >
             leave and save sort-mode
           </Button>
         </Tooltip>
        }
        <MoreMenu
          useParentState
          onMoreBtnClick={handleMoreBtnClick}
          onClose={handleMoreMenuClose}
          open={moreMenuOpen}
        >
          <h3
            style={{
              padding: '6px 16px',
              margin: '0'
            }}
          >
            <span>{site.collection}</span>
            <span
              style={{
                marginRight: '5px',
                color: '#ddd',
                fontWeight: '600',
                fontSize: '12px',
                textTransform: 'uppercase'
              }}
            >- &nbsp;{t('site')}&nbsp;{site.id}</span>
          </h3>
          <Button
            variant=""
            color="default"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteSite}
          >Löschen</Button>

          {
            site.text_blocks.length > 3 &&
            <Button
              variant=""
              color="default"
              startIcon={<SortIcon />}
              onClick={handleSetSortMode}
            >Textfelder sortieren</Button>
          }

          {/*
            { textBlock.history_count > 1 &&
              <Button
                variant=""
                color="default"
                title="History of textitem"
                startIcon={<HistoryIcon />}
                onClick={() => {
                  this.openHistory(textBlock.id);
                  handleMoreMenuClose();
                }}
              >
                History
              </Button>
            }

            <Button
              variant=""
              color="default"
              title="Delete textitem"
              startIcon={<DeleteIcon />}
              onClick={() => {
                this.openDiscardDialogRemoveTextItem();
              }}
            >
              Delete
            </Button>
            */}
        </MoreMenu>
      </>
    );
  };

  return (
    <div className={siteClassName} ref={refSite}>
      <div className={'inner'}>
        <Paper square={true} elevation={4} ref={refPaper}>
          <div className="siteTitle">
            <span>{t('site')}&nbsp;&nbsp;{site.collection}</span>
            {/* &nbsp;<span>({id})</span>*/}
            <div className="siteTitleBtnWrapper">
              {renderMoreMenu()}
            </div>
          </div>
          {/* <div>{props.children}</div>*/}

          <div style={{backgroundColor: 'white', color: 'black', padding: '0px'}}>
            <div className="flex-row">
              <div
                className="flex-column contents-and-title-wrapper"
                style={{
                  width: contentSize,
                  maxWidth: contentSize
                }}
              >
                <Title/>

                <div className='contents'>
                  <SortableContainerEl
                    onSortEnd={onSortEnd}
                    useDragHandle={!siteInMoveMode}
                    hideSortableGhost
                    axis='y'
                    lockAxis='y'
                    helperClass='dragged'
                  >
                    {
                      sorting.map((elem, i) => {
                        const index = contents.findIndex(item => {
                          return item.id === elem;
                        });
                        const item = contents[index];
                        if (index >= 0) {
                          return (
                            <SortableItemWithContentEditor
                              key={`item-${item.id}`} // wichtig für sortable!
                              index={i}               // wichtig für sortable!
                              value={item}            // wichtig für sortable!
                              textBlockIndex={index}
                              id={item.id}
                              siteId={props.siteId}
                              siteIndex={props.siteIndex}
                              // history={item.history ? item.history : null}
                              setTextFromHistory={setTextFromHistory}
                              deletetHistoryEntry={deleteHistoryEntry}
                              toolbarLocation={'relative'}
                              contentLength={contents.length}
                            />
                          );
                        }
                      })
                    }
                  </SortableContainerEl>
                </div>

                <div className="siteButtonWrapper">
                  <Tooltip title="add textitem" placement="bottom">
                    <Button
                      disabled={addingTextBlock === ProcessStatus.STARTED}
                      className="siteAddButton"
                      startIcon={
                        addingTextBlock === ProcessStatus.STARTED
                          ? <CachedIcon/>
                          : <AddIcon />
                      }
                      onClick={addTextItem}
                    >{t('textfield')}</Button>
                  </Tooltip>
                </div>
              </div>

              <div
                className="handleSiteSize"
                onMouseDown={onMouseDownOnHandle}
              />

              <div className="descriptionArea">
                <FoldingContainer
                  title={t('description')}
                  paper={false}
                  onSetSmall={() => {
                    setContentSize('inherit');
                  }}
                >
                  <Description/>
                </FoldingContainer>
              </div>

              <div
                ref={refGhostResize}
                className={showGhostRisize
                  ? 'ghostHandleSiteSize visible'
                  : 'ghostHandleSiteSize'}
                onMouseDown={onMouseDownOnHandle}
              />
            </div>
          </div>
        </Paper>
        {
          props.isLastSite &&
          <div className="siteOuterButtonWrapper">
            <Tooltip title="add site" placement="top">
              <Button
                disabled={addingSite === ProcessStatus.STARTED}
                className="siteAddButton"
                startIcon={
                  addingSite === ProcessStatus.STARTED
                    ? <CachedIcon/>
                    : <AddIcon />
                }
                onClick={handleClickOpenDialog}
              >{t('site')}</Button>
            </Tooltip>
          </div>
        }

        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">New Site</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte geben Sie einen Titel für die neue Seite ein:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Titel"
              type="text"
              fullWidth
              value={newSiteCollectionName}
              onChange={event => {setNewSiteCollectionName(event.target.value);}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={addSiteFromDialog} color="primary">
              Create***
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </div>
  );
}
