import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import PropTypes from 'prop-types';
import Switch from '../../general/SwitchComponent';

import {
  fetchAdditionalContents,
  updateAdditionalContents
} from '../../../actions/additionalContents';
import {ProcessStatus} from '../../../utils/Constants';
import styles from '../../../styles/projects/translationoverview/general.scss';

@withTranslation(['project', 'general'], {wait: true})
class Additional extends Component {
  static propTypes = {
    // Notice these three props. They are injected via the `ExpansionList` component
    // and are required to get correct styling and keyboard accessibility.
    focused: PropTypes.bool,
    overflown: PropTypes.bool,
    columnWidths: PropTypes.arrayOf(PropTypes.number)
  };

  constructor (props) {
    super(props);

    this.state = {
      additionalContentValues: [],
      disabled: false
    };
  }

  componentWillMount () {
    const {projectId, translationId} = this.props;
    this.props.fetchAdditionalContents(projectId, translationId);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      additionalContents,
      fetchingAdditionalContents,
      updatingAdditionalContents
    } = nextProps;

    if (updatingAdditionalContents === ProcessStatus.STARTED) {
      this.setState({disabled: true});
    }
    if (
      fetchingAdditionalContents === ProcessStatus.FINISHED &&
      fetchingAdditionalContents !== this.props.fetchingAdditionalContents
    ) {
      this.setState({
        additionalContentValues: additionalContents.toArray(),
        disabled: false
      });
    } else if (
      updatingAdditionalContents === ProcessStatus.FINISHED &&
      updatingAdditionalContents !== this.props.updatingAdditionalContents
    ) {
      let newACV = additionalContents.toArray();
      for (var i = 0; i < newACV.length; i++) {
        newACV[i].waiting = false;
      }
      this.setState({
        additionalContentValues: newACV,
        disabled: false
      });
    }
  }

  handleChange = (index, id, approved) => {
    const {projectId, translationId} = this.props;
    let newACV = this.state.additionalContentValues;
    newACV[index].waiting = true;
    this.setState({
      disabled: true,
      additionalContentValues: newACV
    });
    this.props.updateAdditionalContents(
      projectId,
      translationId,
      id,
      {status: approved ? 'not_approved' : 'approved'}
    );
  };

  render () {
    const {t} = this.props;
    const {additionalContentValues, disabled} = this.state;

    if (additionalContentValues.length === 0) {
      return <span style={{margin: '0', padding: '0'}}> </span>;
    }

    const switchStyle = {
      display: 'inline-block',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      height: 'auto'
    };

    const additional = additionalContentValues.map((additionalContent, index) => {
      let approved = false;
      if (additionalContent.status === 'approved' || additionalContent.status === 'not_approved') {
        if (additionalContent.status === 'approved') {
          approved = true;
        }
      }

      return (
        <div
          className={additionalContent.waiting ? styles.progressWithText + ' waiting' : styles.progressWithText}
          key={additionalContent.type}>
          <span>{ additionalContent.name  + ': ' + t('approved')}</span>
          <Switch
            style={switchStyle}
            key={additionalContent.id}
            id={additionalContent.id}
            name={additionalContent.name}
            label={''}
            labelBefore={true}
            checked={approved}
            value={approved}
            disabled={disabled}
            onChange={() => this.handleChange(index, additionalContent.id, approved)}
          />
        </div>
      );
    });

    return (
      <div>
        {
          additionalContentValues.length !== 0 &&
          <h4>{ t('additional_content') }</h4>
        }
        {additional}
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingAdditionalContents: state.getIn(['additionalContents', 'fetchingAdditionalContents']),
    updatingAdditionalContents: state.getIn(['additionalContents', 'updatingAdditionalContents']),
    additionalContents: state.getIn(['additionalContents', 'additionalContents'])
  };
}

export default connect(
  mapStateToProps,
  {fetchAdditionalContents, updateAdditionalContents}
)(Additional);
