import { Component } from 'react';
import {EditorState, Modifier} from 'draft-js';
import {OrderedSet as orderedSet} from 'immutable';

class ShyInject extends Component {
  componentDidMount () {
    const {
      editorState,
      // entityType,
      onComplete
    } = this.props;
    const text = '-';
    const selectionState = editorState.getSelection();
    if (selectionState.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'SHY',
        'IMMUTABLE',
        {time: new Date().getTime()}
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const modifiedContent = Modifier.insertText(
        contentState,
        selectionState,
        text,
        orderedSet(['INSERT']),
        entityKey);
      const nextState = EditorState.push(
        editorState,
        modifiedContent,
        editorState.getLastChangeType()
      );
      onComplete(nextState);
    } else {
      onComplete(editorState);
    }
  }

  render () {
    return null;
  }
}

export default ShyInject;
