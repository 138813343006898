import React, {
  useEffect,
  useState
} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import styles from '../../styles/storyboards/storyboard.scss';


export default function StoryboardExitDialog (props) {
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const {t} = useTranslation('translation_navigation');

  let wrapperClassName = styles.storyboardExitDialogWrapper;
  let className = 'storyboardExitDialog';

  if (show) {
    wrapperClassName = wrapperClassName + ' active';
  }

  const clickYes = () => {
    props.deactivate();
    history.push('/storyboards');
  };

  const clickNo = () => {
    props.deactivate();
  };

  return (
    <div className={wrapperClassName}>
      <div className="layer" onClick={clickNo}/>
      <div className={className}>
        <h2>{t('warning_title')}</h2>
        <p dangerouslySetInnerHTML={{__html: t('warning_text')}}/>
        <div>
          <button
            onClick={clickYes}
          >{t('warning_back_btn')}</button>
          <button
            onClick={clickNo}
          >{t('warning_stay_btn')}</button>
        </div>
      </div>
    </div>
  );
}
