import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import {Button} from 'react-md';
import {withTranslation} from 'react-i18next';
import {ProcessStatus} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import ProjectPrime from './ProjectPrime';
import ProjectTranslation from './ProjectTranslation';
import ProjectTimestamps from './ProjectTimestamps';
import ProjectProperties from './ProjectProperties';
import {editProject} from '../../../actions/projects';
import {fetchLanguages} from '../../../actions/languages';
import styles from '../../../styles/project_detail.scss';
import {aclProject} from '../../../utils/Permissions';
import {aclFilter} from '../../../utils/ACL';

@withTranslation(['project', 'general'], {wait: true})
class EditProject extends Component {
  constructor (props) {
    super(props);

    this.state = {
      editMode: false,
      translation: null
    };
  }

  handleSubmit = (data) => {
    data.contact_id_external = null;
    data.is_user_restricted = null;
    data.project_end = null;

    if (data.contact_id === 'no_contact') {
      data.contact_id = null;
    }
    this.props.editProject(data);

    this.switchEditMode();
  };

  switchEditMode = () => {
    this.setState({editMode: !this.state.editMode});
  };
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.project.contact_id === null) {
      nextProps.project.contact_id = 0;
    }
  }

  render () {
    const {project, editingProject, t} = this.props;
    const {editMode} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    // Please do not delete "translations" and "users",
    // they do not belong to the initialValues like the "rest"
    let {...rest} = project; // sort out "translations" and "users"
    if (!rest.contact_id) {
      rest.contact_id = 'no_contact';
    }
    return (
      <div className={'column'}>
        <Form
          mutators={{
            setFieldData,
            setCollectedInfos: (args, state) => {
              state.formState.values = {...state.formState.values, ...args[0]};
            },
            changeValue: (args, state, utils) => {
              utils.changeValue(state, args[0].name, () => args[0].value);
            },
            changeValues: (args, state, utils) => {
              args.map((arg) => utils.changeValue(state, arg.name, () => arg.value));
            }
          }}
          onSubmit={this.handleSubmit}
          initialValues={rest}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className={styles.formInTabWrapper}>
              <div className={'formContentWrapper'}>
                {false && editingProject == ProcessStatus.STARTED && (
                  <LoadingBar
                    show="true"
                    wrapperClassname={'loadingBarWrapper'}
                    message={t(`${generalLNS}:updating_project`)}
                  />
                )}
                {aclFilter(
                  <div className={'buttonsRightWrapper buttonsRightWrapperInTab'}>
                    <Button
                      title={t(`${generalLNS}:edit_mode`)}
                      className={'editButtonFilled'}
                      style={{display: !editMode ? 'block' : 'none'}}
                      onClick={this.switchEditMode}
                      icon
                      primary
                      swapTheming
                    >
                      mode_edit
                    </Button>

                    <Button
                      children={t(`${generalLNS}:save_all_edits`)}
                      type="submit"
                      style={{display: editMode ? 'block' : 'none'}}
                      className={'formSubmitButton'}
                      flat
                      primary
                      swapTheming
                    />

                    <Button
                      children={t(`${generalLNS}:cancel`)}
                      type="button"
                      style={{display: editMode ? 'block' : 'none'}}
                      className={'formCancelButton'}
                      onClick={this.switchEditMode}
                      flat
                      primary
                    />
                  </div>
                )([], [aclProject.update])}
                <div className={'flexHorizontalWrapper'}>
                  <div className={'column'} editmode={editMode ? 'true' : 'false'}>
                    <FormSpy subscription={{values: true}} editMode={editMode} component={ProjectPrime} />
                  </div>
                  <div className={'column'} editmode={editMode ? 'true' : 'false'}>
                    <FormSpy subscription={{values: true}} editMode={editMode} component={ProjectTimestamps} />
                  </div>
                  <div className={'column'} editmode={editMode ? 'true' : 'false'}>
                    <FormSpy subscription={{values: true}} editMode={editMode} component={ProjectTranslation} />
                  </div>
                  <div className={'column'} editmode={editMode ? 'true' : 'false'}>
                    <FormSpy subscription={{values: true}} editMode={editMode} component={ProjectProperties} />
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    project: state.getIn(['projects', 'selectedProject']),
    editingProject: state.getIn(['projects', 'editingProject'])
  };
}

export default connect(
  mapStateToProps,
  {editProject, fetchLanguages}
)(EditProject);
