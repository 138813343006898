import {ajax} from 'rxjs/observable/dom/ajax';
import {SnapshotsAction} from '../utils/ActionTypes';

import {
  fetchSnapshotsFulfilled,
  fetchSnapshotRejected,
  addSnapshotFulfilled,
  addSnapshotRejected,
  editSnapshotFulfilled,
  editSnapshotRejected,
  deleteSnapshotFulfilled,
  deleteSnapshotRejected
} from '../actions/snapshots';

import {baseApiUrl, getHttpAuthHeaders} from '../utils/AuthHelper';

const fetchSnapshotsEpic = (action$, store) => {
  return action$.ofType(SnapshotsAction.FETCH_SNAPSHOTS)
    .debounceTime(850)
    .mergeMap((action) => {
      let url = baseApiUrl + 'projects/' + action.projectId + '/translations/' + action.translationId + '/snapshots';
      let headers = getHttpAuthHeaders(store, url);
      return ajax.getJSON(url, headers)
        .map(res => res.data)
        .map(fetchSnapshotsFulfilled)
        .catch(fetchSnapshotRejected);
    });
};

const addSnapshotEpic = (action$, store) => {
  return action$
    .ofType(SnapshotsAction.ADD_SNAPSHOT)
    .debounceTime(500)
    .mergeMap(action => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/snapshots';
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'POST', headers: headers, body: action.payload})
        .map(res => res.response.data)
        .map(addSnapshotFulfilled)
        .catch(addSnapshotRejected);
    });
};

const editSnapshotEpic = (action$, store) => {
  return action$
    .ofType(SnapshotsAction.EDIT_SNAPSHOT)
    .debounceTime(500)
    .mergeMap(action => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/snapshots/' +
        action.snapshot.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.snapshot })
        .map(res => res.response.data)
        .map(editSnapshotFulfilled)
        .catch(editSnapshotRejected);
    });
};


const deleteSnapshotEpic = (action$, store) => {
  return action$
    .ofType(SnapshotsAction.DELETE_SNAPSHOT)
    .debounceTime(500)
    .mergeMap(action => {
      let url =
        baseApiUrl +
        'projects/' +
        action.projectId +
        '/translations/' +
        action.translationId +
        '/snapshots/' +
        action.snapshotId;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({url, method: 'DELETE', headers: headers})
        .map(res => res.data)
        .map(() => deleteSnapshotFulfilled(action.snapshotId))
        .catch(deleteSnapshotRejected);
    });
};


export {fetchSnapshotsEpic, addSnapshotEpic, editSnapshotEpic, deleteSnapshotEpic};
