import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'react-final-form';
import LoadingBar from '../../general/DumbComponent';
import {renderTextField, renderSelectField} from '../../general/BasicFormComponents';
import styles from '../../../styles/project_detail.scss';
import {withTranslation} from 'react-i18next';
import {fetchProjectEnumDefaults} from '../../../actions/enumDefaults';
import {fetchRoles} from '../../../actions/roles';


@withTranslation(['project', 'general', 'projectselection'], {wait: true})
class ProjectProperties extends Component {
  constructor (props) {
    super(props);
  }

  componentWillMount () {
    const {enumDefaults, roles} = this.props;

    if (enumDefaults.size == 0) {
      this.props.fetchProjectEnumDefaults();
    }

    if (roles.size == 0) {
      this.props.fetchRoles();
    }
  }

  render () {
    const {enumDefaults, roles, editMode, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    const projectselectionLNS = 'projectselection'; // projectselectionLanguageNamespaceSource

    if (enumDefaults.size == 0 || roles.size == 0) {
      return (
        <LoadingBar
          show="true"
          wrapperClassname={styles.loadingBarWrapper}/>
      );
    }

    let localeTypeLabels = null;
    let localeEngineTypeLabels = null;
    let localeStatusLabels = null;

    let typeLabels = enumDefaults.get('type');
    let engineTypeLabels = enumDefaults.get('engine_type');
    let statusLabels = enumDefaults.get('status');

    if (typeLabels) {
      localeTypeLabels = typeLabels.map(value => ({label: t(`${projectselectionLNS}:${value}`), value: value}));
      // console.log(localeTypeLabels);
    } else {
      console.log('No typeLabels in ProjectProperties!');
    }

    if (engineTypeLabels) {
      localeEngineTypeLabels = engineTypeLabels.map(value => (
        {label: t(`${projectselectionLNS}:${value}`), value: value}
      ));
    } else {
      console.log('No engineTypeLabels in ProjectProperties!');
    }

    if (statusLabels) {
      localeStatusLabels = statusLabels.map(value => ({label: t(`${projectselectionLNS}:${value}`), value: value}));
    } else {
      console.log('No statusLabels in ProjectProperties!');
    }

    return (
      <div>
        <div className={styles.header}>
          <h2 className={'headName'}>{ t(`${generalLNS}:properties`) }</h2>
        </div>
        <div className="editableElements">
          {localeTypeLabels &&
          <Field
            id="type"
            name="type"
            label={ t(`${generalLNS}:type`) }
            type="text"
            helpText={ t(`${generalLNS}:do_something`) }
            disabled={!editMode}
            className={styles.formField}
            menuItems={localeTypeLabels}
            component={renderSelectField}
          />
          }
          {localeEngineTypeLabels &&
          <Field
            id="engine_type"
            name="engine_type"
            label={ t(`${generalLNS}:engine_types`) }
            type="text"
            helpText={ t(`${generalLNS}:do_something`) }
            disabled={!editMode}
            className={styles.formField}
            menuItems={localeEngineTypeLabels}
            component={renderSelectField}
          />
          }
          {localeStatusLabels &&
          <Field
            id="status"
            name="status"
            label={ t(`${generalLNS}:status`) }
            type="text"
            disabled={!editMode}
            className={styles.formField}
            menuItems={localeStatusLabels}
            component={renderSelectField}
          />
          }
          <Field
            id="title_id"
            name="title_id"
            label={ t(`${generalLNS}:title_id`) }
            type="text"
            disabled={!editMode}
            className={styles.formField}
            component={renderTextField}
          />
        </div>
        <div className="notEditableElements"></div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    enumDefaults: state.getIn(['enumDefaults', 'projectEnumDefaults']),
    fetchingProjectEnumDefaults: state.getIn(['enumDefaults', 'fetchingProjectEnumDefaults']),
    roles: state.getIn(['roles', 'roles']),
    fetchingRoles: state.getIn(['roles', 'fetchingRoles'])
  };
}

export default connect(mapStateToProps, {fetchProjectEnumDefaults, fetchRoles})(ProjectProperties);

