import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { renderTextField } from '../../general/BasicFormComponents';
import { fetchPermissions } from '../../../actions/permissions';
import styles from '../../../styles/translation_detail.scss';
import { withTranslation } from 'react-i18next';

@withTranslation(['permissions', 'general'/* , 'types' */], {wait: true})
class PermissionPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      localName: ''
    };
  }

  componentWillMount () {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      values: {name},
      form: {
        mutators: {setDisplayName}
      }
    } = nextProps;
    const {localName} = this.state;

    if (name != localName) {
      this.setState({localName: name});
      setDisplayName();
    }
  }

  render () {
    const {
      form: {
        mutators: {setDisplayName}
      },
      t
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'md-grid ' + styles.translationPrime}>
        <div className={'translationFormHead'}>
          <div className={'translationHeadHeadlines'}>
            <h2>{ t('add_permission') }</h2>
          </div>
        </div>

        <div className={'formContentWrapper'}>
          <Field
            id="name"
            name="name"
            label={ t(`${generalLNS}:name`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="display_name"
            name="display_name"
            label={ t(`${generalLNS}:display_name`) }
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          <Field
            id="category"
            name="category"
            label={ t(`${generalLNS}:category`) }
            value={setDisplayName}
            type="text"
            className="md-cell md-cell--12"
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    permissions: state.getIn(['permissions', 'permissions'])
  };
}

export default connect(
  mapStateToProps,
  {fetchPermissions}
)(PermissionPrime);
