import { ajax } from 'rxjs/observable/dom/ajax';

import { ProfileAction } from '../utils/ActionTypes';
import { editProfileFulfilled, editProfileRejected } from '../actions/profile';

import { baseApiUrl, getHttpAuthHeaders } from '../utils/AuthHelper';

const editProfileEpic = (action$, store) => {
  return action$
    .ofType(ProfileAction.EDIT_PROFILE)
    .debounceTime(500)
    .mergeMap(action => {
      let url = baseApiUrl + 'users/' + action.user.id;
      let headers = getHttpAuthHeaders(store, url);
      return ajax({ url, method: 'PUT', headers: headers, body: action.user })
        .map(res => res.response.user)
        .map(editProfileFulfilled)
        .catch(editProfileRejected);
    });
};

export { editProfileEpic };
