// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n@keyframes kippen {\n  0% {\n    transform: rotate3d(1, 0, 0, 0deg) perspective(1500px);\n  }\n  70% {\n    transform: rotate3d(1, 0, 0, 0deg) perspective(1500px);\n  }\n  82% {\n    transform: rotate3d(1, 0, 0, 16deg) perspective(1500px);\n  }\n  88% {\n    transform: rotate3d(1, 0, 0, -10deg) perspective(1500px);\n  }\n  94% {\n    transform: rotate3d(1, 0, 0, 8deg) perspective(1500px);\n  }\n  100% {\n    transform: rotate3d(1, 0, 0, 0deg) perspective(1500px);\n  }\n}\n\n.home {\n  perspective: 1500px;\n  perspective-origin: center;\n}\n\n.home .iconInLink {\n  padding-right: 15px;\n  color: black;\n}\n\n.home .scrollWrapper {\n  max-height: 250px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.home .dashboardLink {\n  overflow: hidden;\n  height: 150px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: rotate3d(1, 0, 0, 0deg) perspective(1500px);\n  transform-origin: top center;\n  transform-style: preserve-3d;\n  transition: all 0.3s ease;\n  -webkit-font-smoothing: subpixel-antialiased;\n  -moz-osx-font-smoothing: auto;\n  animation-duration: 3s;\n  animation-timing-function: ease;\n  animation-delay: 0s;\n  animation-iteration-count: infinite;\n  animation-direction: normal;\n  animation-fill-mode: none;\n  animation-play-state: running;\n  cursor: pointer;\n}\n\n.home .dashboardLink:hover {\n  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);\n  animation-name: kippen;\n  border-radius: 0% 100% 0% 100% / 96% 0% 100% 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"home": "home",
	"iconInLink": "iconInLink",
	"scrollWrapper": "scrollWrapper",
	"dashboardLink": "dashboardLink",
	"kippen": "kippen"
};
module.exports = exports;
