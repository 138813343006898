import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Switch,
  MenuButton,
  Layover
} from 'react-md';

import {
  setAreTagsShown,
  setAreAllTextsShown,
  setAreOnlyEmptyShown,
  setAreOnlyDifferencesShown,
  setAreDifferencesHighlighted
} from '../../actions/translationTool';

import {withTranslation} from 'react-i18next';

import styles from '../../styles/translationTool/controlMenu.scss';


@withTranslation('control_menu', {wait: true})
class ControlMenu extends Component {
  state = {
    active: true
  };

  constructor (props) {
    super(props);
  }
  
  componentDidMount () {
    this.areFiltersActive();
    if (this.props.hideListItemTextOnStage && this.props.areAllTextsShown !== true) {
      this.toggleAreAllTextsShown();
    }
  }

  componentDidUpdate () {
    this.areFiltersActive();
  }


  toggleAreTagsShown = () => {
    this.props.setAreTagsShown(!this.props.areTagsShown);
  };

  toggleAreAllTextsShown = () => {
    this.props.setAreAllTextsShown(!this.props.areAllTextsShown);
  };

  toggleAreOnlyEmptyShown = () => {
    this.props.setAreOnlyEmptyShown(!this.props.areOnlyEmptyShown);
  };

  toggleAreOnlyDifferencesShown = () => {
    this.props.setAreOnlyDifferencesShown(!this.props.areOnlyDifferencesShown);
  };

  toggleAreDifferencesHighlighted = () => {
    this.props.setAreDifferencesHighlighted(!this.props.areDifferencesHighlighted);
  };
  /*
    handleSearchInput = value => {
      this.setState({searchTerm: value}, () => this.updateSections(this.props));
    };

    handleSearchReset = () => {
      this.setState({searchTerm: ''});
    };

    handleSourceSnapshotClick = () => {
      this.setState({isSourceSnapshotBarVisible: !this.state.isSourceSnapshotBarVisible});
    };
  */

  areFiltersActive = () => {
    const {
      areTagsShown,
      areAllTextsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      areOnlyEmptyShown
    } = this.props;

    const active = areTagsShown ||
      !areAllTextsShown ||
      areOnlyDifferencesShown ||
      areDifferencesHighlighted ||
      areOnlyEmptyShown;

    if (this.state.active !== active) {
      this.setState({active});
    }
  };

  createHeadline = (key, id) => {
    return (
      <div key={key} id={id} className={styles.controlMenuHeadline}>
        <span>{id}</span>
      </div>
    );
  };

  createListItem = (key, id, state, changeFunc) => {
    const {t} = this.props;

    const switchStyle = {
      display: 'inline-block',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      height: 'auto'
    };

    return (
      <div key={key} id={id} className={styles.switchWithText}>
        <span>{t(id)}</span>
        <Switch
          style={switchStyle}
          id={id + '_switch'}
          name={id}
          aria-label={id}
          checked={state}
          onChange={changeFunc}
        />
      </div>
    );
  };

  createListItems = () => {
    const {
      areAllTextsShown,
      areOnlyDifferencesShown,
      areOnlyEmptyShown,
      areTagsShown,
      areDifferencesHighlighted,
      hasSourceSnapshot,
      hideListItemTextOnStage
    } = this.props;

    let filter = this.createHeadline('hd_1', 'filter');
    let highlights = this.createHeadline('hd_2', 'highlights');
    let onStage = null;
    if (hideListItemTextOnStage) {
      onStage = this.createListItem(
        'fr_1',
        'filter_by_on_stage',
        areAllTextsShown,
        this.toggleAreAllTextsShown
      );
    } else {
      onStage = this.createListItem(
        'fr_1',
        'filter_by_on_stage',
        !areAllTextsShown,
        this.toggleAreAllTextsShown
      );
    }


    let changed = this.createListItem(
      'fr_2',
      'filter_by_changed',
      areOnlyDifferencesShown,
      this.toggleAreOnlyDifferencesShown
    );

    let empty = this.createListItem(
      'fr_3',
      'filter_by_empty',
      areOnlyEmptyShown,
      this.toggleAreOnlyEmptyShown
    );

    let tags = this.createListItem(
      'ht_1',
      'highlight_tags',
      areTagsShown,
      this.toggleAreTagsShown
    );

    let changes = this.createListItem(
      'ht_2',
      'highlight_changes',
      areDifferencesHighlighted,
      this.toggleAreDifferencesHighlighted
    );

    let legend =
      <div
        key='changesLegend'
        id=''
        className={`legend ${areDifferencesHighlighted ? ' visible' : ''}`}>
        {this.createHeadline('hd_3', 'legend')}
        <div className={'legendItem'}>
          <div className={'rectangle removed'}/>
          <span>removed words</span>
        </div>
        <div className={'legendItem'}>
          <div className={'rectangle added'}/>
          <span>added words</span>
        </div>
      </div>;

    if (hasSourceSnapshot) {
      return [
        filter,
        onStage,
        changed,
        empty,
        {divider: true},
        highlights,
        tags,
        changes,
        legend
      ];
    }

    return [
      filter,
      onStage,
      empty,
      {divider: true},
      highlights,
      tags
    ];
  };


  render () {
    const {
      areTagsShown,
      areAllTextsShown,
      areOnlyDifferencesShown,
      areDifferencesHighlighted,
      areOnlyEmptyShown,
      t
    } = this.props;


    const active = areTagsShown ||
      !areAllTextsShown ||
      areOnlyDifferencesShown ||
      areDifferencesHighlighted ||
      areOnlyEmptyShown;

    let listItems = this.createListItems();

    let activeFiltersText = t('title');
    if (active) {
      activeFiltersText += '\n\n' + t('title2');
      if (!areAllTextsShown) {
        activeFiltersText += '\n - ' + t('filter_by_on_stage');
      }
      if (areOnlyDifferencesShown) {
        activeFiltersText += '\n - ' + t('filter_by_changed');
      }
      if (areDifferencesHighlighted) {
        activeFiltersText += '\n - ' + t('highlights');
      }
      if (areOnlyEmptyShown) {
        activeFiltersText += '\n - ' + t('filter_by_empty');
      }
      if (areTagsShown) {
        activeFiltersText += '\n - ' + t('highlight_tags');
      }
    }


    return (
      <div className={styles.controlMenu}>
        <MenuButton
          id="menu-button-2"
          className={this.state.active ? styles.activeFilters : ''}
          anchor={{
            x: Layover.HorizontalAnchors.INNER_RIGHT,
            y: Layover.VerticalAnchors.BOTTOM
          }}
          position={Layover.Positions.TOP_RIGHT}
          icon
          menuItems={listItems}
          title={activeFiltersText}
        >tune</MenuButton>
      </div>
    );
  }
}


function mapStateToProps (state) {
  return {
    areTagsShown: state.getIn(['translationTool', 'areTagsShown']),
    areAllTextsShown: state.getIn(['translationTool', 'areAllTextsShown']),
    areOnlyDifferencesShown: state.getIn(['translationTool', 'areOnlyDifferencesShown']),
    areDifferencesHighlighted: state.getIn(['translationTool', 'areDifferencesHighlighted']),
    areOnlyEmptyShown: state.getIn(['translationTool', 'areOnlyEmptyShown'])
  };
}

export default connect(
  mapStateToProps,
  {
    setAreTagsShown,
    setAreAllTextsShown,
    setAreOnlyEmptyShown,
    setAreOnlyDifferencesShown,
    setAreDifferencesHighlighted
  })(ControlMenu);
