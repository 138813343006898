// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.hover-before-effect:before, .drawer header [class*=\"--action-left\"]:before {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  height: 100%;\n  width: 100%;\n  transform-origin: center center;\n  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(0);\n  background-color: rgba(0, 0, 0, 0.2);\n  opacity: 1;\n  border-radius: 50%;\n  z-index: 1;\n  transition: transform, border-radius;\n  transition-duration: 0.3s, 0s;\n  transition-timing-function: ease, ease;\n  transition-delay: 0s, 0s;\n}\n\n.hover-before-effect:hover:before, .drawer header [class*=\"--action-left\"]:hover:before {\n  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);\n  border-radius: 0;\n  transition: transform, border-radius;\n  transition-duration: 0.3s, 0.3s;\n  transition-timing-function: ease, ease;\n  transition-delay: 0s, 0.2s;\n}\n\n.drawer {\n  padding-left: 0;\n  padding-right: 0;\n  height: 100vh;\n  overflow-y: hidden;\n  z-index: 19;\n}\n\n.drawer header [class*=\"--action-left\"] {\n  height: 64px;\n  width: 64px;\n  margin: 0;\n  border-radius: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"hover-before-effect": "hover-before-effect",
	"hoverBeforeEffect": "hover-before-effect",
	"drawer": "drawer"
};
module.exports = exports;
