import React, { Component } from 'react';
import {EditorState} from 'draft-js';
import {withTranslation} from 'react-i18next';
import {FontIcon} from 'react-md';

@withTranslation(['translation_navigation'], {wait: true})
class UndoRedoComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      undoDeactive: false,
      redoDeactive: true
    };
  }

  componentDidMount () {
    this.update();
  }

  componentWillReceiveProps () {
    this.update();
  }

  update = () => {
    this.setState({
      undoDeactive: this.props.getEditorState().getUndoStack().isEmpty(),
      redoDeactive: this.props.getEditorState().getRedoStack().isEmpty()
    });
  };

  onClickUndo = e => {
    e.stopPropagation();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    setEditorState(EditorState.undo(editorState));
    this.update();
  };

  onClickRedo = e => {
    e.stopPropagation();
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    setEditorState(EditorState.redo(editorState));
    this.update();
  };

  render () {
    const {undoDeactive, redoDeactive} = this.state;
    return ([
      <span
        key={1}
        className="button"
        onMouseDown={this.onClickUndo}
        title={this.props.descriptionUndo}
        data-disabled={undoDeactive.toString()}
      ><FontIcon>undo</FontIcon></span>,
      <span
        key={2}
        className="button"
        onMouseDown={this.onClickRedo}
        title={this.props.descriptionRedo}
        data-disabled={redoDeactive.toString()}
      ><FontIcon>redo</FontIcon></span>
    ]);
  }
}

export default UndoRedoComponent;
