/**
 * Created by a.busse on 29.01.18.
 */

const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  REVIEWER: 'reviewer',
  EDITOR: 'editor',
  MANAGER: 'manager',
  TRANSLATOR: 'translator',
  VIEWER: 'viewer',
  SUPERADMIN: 'superadmin',
  SUPERMANAGER: 'supermanager'
};

const Scope = {
  GLOBAL: 'global',
  PROJECT: 'project',
  TRANSLATION: 'translation'
};

const Permissions = {
  CMS_COMMENTS_DELETE: 'cms_comments_delete',
  CMS_COMMENTS_WRITE: 'cms_comments_write',
  CMS_IMAGE_DOWNLOAD: 'cms_image_download',
  CMS_OBJECTIVES_EDIT: 'cms_objectives_edit',
  CMS_SHOW_CONTROLPANEL_BTN: 'cms_show_controlpanel_btn',
  CMS_SITE_BACKGROUNDIMAGE_EDIT: 'cms_site_backgroundimage_edit',
  CMS_SITE_EDIT: 'cms_site_edit',
  CMS_SITE_LINKAGE_EDIT: 'cms_site_linkage_edit',
  CMS_SITE_TEMPLATE_CUSTOMPAGE_PAGENAME_EDIT: 'cms_site_template_custompage_pagename_edit',
  CMS_SITE_TEMPLATE_CUSTOMPAGE_TEMPLATENAME_EDIT: 'cms_site_template_custompage_templatename_edit',
  CMS_SITE_TEMPLATE_CUSTOMPAGE_TEXTS_EDIT: 'cms_site_template_custompage_texts_edit',
  CMS_SITE_TEMPLATE_EDIT: 'cms_site_template_edit',
  CMS_SITE_TEMPLATE_LAYOUT_EDIT: 'cms_site_template_layout_edit',
  CMS_SITE_TEMPLATE_MENU_SETTINGS_QRCODE_EDIT: 'cms_site_template_menu_settings_qrcode_edit',
  CMS_SITE_TEMPLATE_SETTINGS_EDIT: 'cms_site_template_settings_edit',
  CMS_SITE_TEMPLATE_SETTINGS_TRACKCLICKS_EDIT: 'cms_site_template_settings_trackclicks_edit',
  CMS_STRUCTURE_CHAPTERS_CREATE: 'cms_structure_chapters_create',
  CMS_STRUCTURE_CHAPTERS_DELETE: 'cms_structure_chapters_delete',
  CMS_STRUCTURE_CHAPTERS_MOVE: 'cms_structure_chapters_move',
  CMS_STRUCTURE_CHAPTERS_RENAME: 'cms_structure_chapters_rename',
  CMS_STRUCTURE_POPUPS_CREATE: 'cms_structure_popups_create',
  CMS_STRUCTURE_POPUPS_DELETE: 'cms_structure_popups_delete',
  CMS_STRUCTURE_POPUPS_MOVE: 'cms_structure_popups_move',
  CMS_STRUCTURE_POPUPS_RENAME: 'cms_structure_popups_rename',
  CMS_STRUCTURE_SITES_CREATE: 'cms_structure_sites_create',
  CMS_STRUCTURE_SITES_DELETE: 'cms_structure_sites_delete',
  CMS_STRUCTURE_SITES_MOVE: 'cms_structure_sites_move',
  CMS_STRUCTURE_SITES_RENAME: 'cms_structure_sites_rename',
  MAIN_ACCESS_CONTROLPANEL: 'main_access_controlpanel',
  MAIN_VIEW_UNAPPROVED_TRANSLATIONS: 'main_view_unapproved_translations',
  PROJECT_ACCESS_ANALYTICS: 'project_access_analytics',
  PROJECT_ACCESS_APPROVAL_PANEL: 'project_access_approval_panel',
  PROJECT_ACCESS_EXPORT_PANEL: 'project_access_export_panel',
  PROJECT_EDIT_IMAGE: 'project_edit_image',
  PROJECT_VIEW_CATEGORIES: 'project_view_categories',
  PROJECT_VIEW_CONTACTS: 'project_view_contacts',
  PROJECT_VIEW_GO_LIVE: 'project_view_go_live',
  PROJECT_VIEW_JOB_NUMBER: 'project_view_job_number',
  PROJECT_VIEW_PROJECT_DEADLINE: 'project_view_project_deadline',
  PROJECT_VIEW_RESOLUTION: 'project_view_resolution',
  PROJECT_VIEW_STATUS: 'project_view_status',
  PROJECT_VIEW_TYPE: 'project_view_type',
  PROJECT_VIEW_VERSION: 'project_view_version',
  TRANSLATE_APPROVE_ADDITIONAL_CONTENTS: 'translate_approve_additional_contents',
  TRANSLATE_APPROVE_TRANSLATION: 'translate_approve_translation',
  TRANSLATE_EDIT_APPROVED_TEXT: 'translate_edit_approved_text',
  TRANSLATE_EDIT_TEXT: 'translate_edit_text',
  TRANSLATE_ITEM_TOGGLE_CHECKED: 'translate_item_toggle_checked',
  TRANSLATE_ITEM_TOGGLE_TRANSLATED: 'translate_item_toggle_translated',
  TRANSLATE_ITEM_TOGGLE_VISIBILITY: 'translate_item_toggle_visibility'
};

export { Roles, Scope, Permissions };
