import React from 'react';
import {connect} from 'react-redux';
import ImageBar from './ImageBar';
import ImageDisplay from './ImageDisplay';
import {Image} from '../../../utils/Constants';

import {
  fetchImage,
  fetchFullImage,
  fetchCroppedImage,
  setCropped,
  switchMode,
  switchPermanently
} from '../../../actions/image';

import styles from '../../../styles/image_input.scss';

class ImageInput extends React.Component {
  componentDidMount () {
    if (this.props.editMode) {
      this.props.switchMode();
    }
    const {projectImages, project, fetchImage, fetchCroppedImage, fetchFullImage, setCropped} = this.props;
    const index = projectImages.findIndex(elem => elem.projectId === project.id);
    fetchImage({projectId: project.id});
    fetchFullImage({projectId: project.id});
    if (index < 0) {
      this.props.setCropped(Image.DUMMY_IMAGE);
      // Fetch Cropped Image
      fetchCroppedImage({projectId: project.id});
    } else {
      // Set cropped image
      setCropped(projectImages.get(index).cropped);
    }
  }

  componentWillUnmount () {
    const {permanently, switchPermanently} = this.props;
    if (permanently) {
      switchPermanently();
    }
  }

  render = () => {
    return (
      <div className={styles.imageInputContainer}>
        <ImageBar/>
        <ImageDisplay src={this.props.cropped} alt={this.props.alt}/>
      </div>
    );
  };
}

function mapStateToProps (state) {
  return {
    projectImages: state.getIn(['image', 'projectImages']),
    cropped: state.getIn(['image', 'croppedImage']),
    project: state.getIn(['projects', 'selectedProject']),
    editMode: state.getIn(['image', 'editMode']),
    permanently: state.getIn(['image', 'permanently'])
  };
}

export default connect(
  mapStateToProps,
  {
    fetchImage,
    fetchFullImage,
    fetchCroppedImage,
    setCropped,
    switchMode,
    switchPermanently
  }
)(ImageInput);
