import {BLOCK_TYPE, ENTITY_TYPE} from 'draftail';
import React from 'react';

const importerConfig = {
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity(ENTITY_TYPE.LINK, 'MUTABLE', { url: node.href });
    }
    if (nodeName === 'span' && node.classList.contains('shy')) {
      return createEntity('SHY', 'IMMUTABLE', {});
    }
    if (nodeName === 'span' && node.classList.contains('zwsp')) {
      return createEntity('ZWSP', 'IMMUTABLE', {});
    }
    if (nodeName === 'img') {
      return createEntity(ENTITY_TYPE.IMAGE, 'IMMUTABLE', {
        src: node.src
      });
    }
    // if (nodeName === 'hr') {
    //   return createEntity(ENTITY_TYPE.HORIZONTAL_RULE, 'IMMUTABLE', {});
    // }
    return null;
  },
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === 'sup') {
      return currentStyle.add('SUPERSCRIPT');
    }
    if (nodeName === 'sub') {
      return currentStyle.add('SUBSCRIPT');
    }
    if (nodeName === 'span' && node.classList.contains('nobreak')) {
      return currentStyle.add('NOBREAK');
    }
    return currentStyle;
  },
  htmlToBlock: (nodeName) => {
    if (nodeName === 'h1') {return 'header-one';}
    if (nodeName === 'h2') {return 'header-two';}
    if (nodeName === 'h3') {return 'header-three';}
    if (nodeName === 'hr' || nodeName === 'img') {
      // 'atomic' blocks is how Draft.js structures block-level entities.
      return 'atomic';
    }
    return null;
  }
};


const exporterConfig = {
  styleToHTML: style => {
    if (style === 'NOBREAK') {
      return <span className="nobreak" title="nb"/>;
    }
    if (style === 'SUPERSCRIPT') {
      return <sup />;
    }
    if (style === 'SUBSCRIPT') {
      return <sub />;
    }
  },
  blockToHTML: (block) => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return <blockquote />;
    }
    if (block.type === BLOCK_TYPE.HEADER_ONE) {
      return <h1 />;
    }
    if (block.type === BLOCK_TYPE.HEADER_TWO) {
      return <h2 />;
    }
    if (block.type === BLOCK_TYPE.HEADER_THREE) {
      return <h3 />;
    }
    // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return {
        start: '',
        end: ''
      };
    }
    return null;
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === ENTITY_TYPE.LINK) {
      return <a href={entity.data.url} title={entity.data.url}>{originalText}</a>;
    }
    if (entity.type === 'SHY') {
      return <span className="shy">-</span>;
    }
    if (entity.type === 'ZWSP') {
      return <span className="zwsp">|</span>;
    }
    if (entity.type === ENTITY_TYPE.IMAGE) {
      return <img src={entity.data.src} alt={entity.data.alt} />;
    }
    // if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
    //   return <hr />;
    // }
    return originalText;
  }
};

export {importerConfig, exporterConfig};
