// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.form {\n  height: calc(100vh - 64px);\n  padding-right: 0;\n  padding-left: 0;\n  padding-top: 2rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.form .formHeader {\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  position: relative;\n  padding: 0;\n  display: flex;\n  width: 100%;\n  flex-wrap: nowrap;\n}\n\n.form .formHeader h2 {\n  font-size: 32px;\n  font-weight: bold;\n}\n\n.form .formContentWrapper {\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: auto;\n  order: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding-top: 2rem;\n}\n\n.form .formContentWrapper [class*=\"--select-field\"],\n.form .formContentWrapper [class*=\"-field-container\"] {\n  margin-left: 0;\n  margin-right: 0;\n}\n\n.form .formContentWrapper [class*=\"md-grid\"] {\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.formHeaderHeadlines {\n  flex-grow: 2;\n  flex-shrink: 1;\n  flex-basis: auto;\n}\n\n.formHeaderHeadlines h2 {\n  font-size: 32px;\n  font-weight: bold;\n}\n\n.buttonsRightWrapper {\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  width: auto;\n  display: flex;\n}\n\n.buttonsRightWrapper button {\n  margin-right: 1rem;\n}\n\n.buttonsRightWrapper button:last-of-type {\n  margin-right: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "form",
	"formHeader": "formHeader",
	"formContentWrapper": "formContentWrapper",
	"formHeaderHeadlines": "formHeaderHeadlines",
	"buttonsRightWrapper": "buttonsRightWrapper"
};
module.exports = exports;
