// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* copy pasted from the flat ui color palette website */\n/* warum? */\n/* Start overriding react-md variables */\n/* When the primary or secondary colors are not part of material design, you will need to set\n some fallback colors for the the following components: */\n/* only need to set this if using the dark theme. */\n/* MANGOOLU_VARIABLEN */\n.checkboxWithSuperIcon > div {\n  position: relative;\n  display: inline-block;\n  margin: 0;\n}\n\n.checkboxWithSuperIcon > div > div {\n  opacity: 0;\n  z-index: 4;\n}\n\n.checkboxWithSuperIcon > div > i.material-icons.md-icon {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  z-index: 5;\n  font-size: 50px !important;\n  pointer-events: none;\n  transform: translateY(11%);\n  opacity: 0.3;\n}\n\n.checkboxWithSuperIcon > div > i.material-icons.md-icon.filled {\n  opacity: 1;\n  color: #ff5900 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"checkboxWithSuperIcon": "checkboxWithSuperIcon",
	"material-icons": "material-icons",
	"materialIcons": "material-icons",
	"md-icon": "md-icon",
	"mdIcon": "md-icon",
	"filled": "filled"
};
module.exports = exports;
