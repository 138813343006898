import {List, fromJS} from 'immutable';

import {UsersAction} from '../utils/ActionTypes';
import {ProcessStatus} from '../utils/Constants';
import {generateGuid} from '../utils/HelperFunctions';


const initialState = fromJS({
  users: List(),
  selectedUser: null,
  fetchingUsers: ProcessStatus.INITIAL,
  addingUser: ProcessStatus.INITIAL,
  fetchingUser: ProcessStatus.INITIAL,
  editingUser: ProcessStatus.INITIAL,
  deletingUser: ProcessStatus.INITIAL,
  invitingUser: ProcessStatus.INITIAL,
  linkingUserToProject: ProcessStatus.INITIAL,
  linkingUserToTranslation: ProcessStatus.INITIAL,
  linkingUserToClient: ProcessStatus.INITIAL,
  updateToken: '',
  error: null
});
let index = 0;
let user = null;
let users = null;
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UsersAction.FETCH_USERS:
      return state.set('fetchingUsers', ProcessStatus.STARTED);
    case UsersAction.FETCH_USERS_FULFILLED:
      return state.set('fetchingUsers', ProcessStatus.FINISHED).set('users', List(action.users));
    case UsersAction.FETCH_USERS_REJECTED:
      return state.set('fetchingUsers', ProcessStatus.FAILED).set('error', action.payload);
    case UsersAction.FETCH_USER:
      return state.set('fetchingUser', ProcessStatus.STARTED).set('selectedUser', null);
    case UsersAction.FETCH_USER_FULFILLED:
      return state.set('fetchingUser', ProcessStatus.FINISHED).set('selectedUser', action.user);
    case UsersAction.FETCH_USER_REJECTED:
      return state.set('fetchingUser', ProcessStatus.FAILED).set('error', action.payload);

    case UsersAction.ADD_USER:
      return state.set('addingUser', ProcessStatus.STARTED);


    case UsersAction.ADD_USER_FULFILLED:
      if (state.get('users').size !== 0) {
        return state.set('addingUser', ProcessStatus.FINISHED).set('users', state.get('users').push(action.user));
      }
      return state.set('addingUser', ProcessStatus.FINISHED);


    case UsersAction.ADD_USER_REJECTED:
      console.log('reducer UsersAction.ADD_USER_REJECTED', action.payload);
      console.log('state', state);
      return state.set('addingUser', ProcessStatus.FAILED).set('error', action.payload);
    case UsersAction.DELETE_USER:
      return state.set('deletingUser', ProcessStatus.STARTED);
    case UsersAction.DELETE_USER_FULFILLED:
      state = state.set('deletingUser', ProcessStatus.FINISHED);
      index = state.get('users').findIndex(function (obj) {
        return obj.id === action.userId;
      });
      if (index > -1) {
        state = state.set('users', state.get('users').delete(index));
        if (state.get('selectedUser') !== null && state.get('selectedUser').id === action.userId) {
          state = state.set('selectedUser', null);
        }
      }
      return state;
    case UsersAction.DELETE_USER_REJECTED:
      return state.set('deletingUser', ProcessStatus.FAILED).set('error', action.payload);
    case UsersAction.EDIT_USER:
      return state.set('editingUser', ProcessStatus.STARTED);
    case UsersAction.EDIT_USER_FULFILLED:
      users = state.get('users');
      index = users.findIndex(user => user.id === action.user.id);

      if (index > -1) {
        users = users.set(index, action.user);
        state = state.set('users', users)
          .set('selectedUser', action.user)
          .set('updateToken', generateGuid());
      }
      return state.set('editingUser', ProcessStatus.FINISHED);
    case UsersAction.EDIT_USER_REJECTED:
      return state.set('editingUser', ProcessStatus.FAILED).set('error', action.payload);
    case UsersAction.LINK_USER_TO_PROJECT: {
      return state.set('linkingUserToProject', ProcessStatus.STARTED);
    }
    case UsersAction.LINK_USER_TO_PROJECT_FULFILLED: {
      user = state.get('selectedUser');

      if (user) {
        user.projects = action.data;
      }

      return state.set('linkingUserToProject', ProcessStatus.FINISHED)
        .set('selectedUser', user)
        .set('updateToken', generateGuid());
    }
    case UsersAction.LINK_USER_TO_PROJECT_REJECTED: {
      return state.set('linkingUserToProject', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case UsersAction.LINK_USER_TO_TRANSLATION: {
      return state.set('linkingUserToTranslation', ProcessStatus.STARTED);
    }
    case UsersAction.LINK_USER_TO_TRANSLATION_FULFILLED: {
      return state.set('linkingUserToTranslation', ProcessStatus.FINISHED)
        .set('updateToken', generateGuid());
    }
    case UsersAction.LINK_USER_TO_TRANSLATION_REJECTED: {
      return state.set('linkingUserToTranslation', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case UsersAction.LINK_USER_TO_CLIENT: {
      return state.set('linkingUserToClient', ProcessStatus.STARTED);
    }
    case UsersAction.LINK_USER_TO_CLIENT_FULFILLED: {
      user = state.get('selectedUser');
      user.clients = action.data;
      return state.set('linkingUserToClient', ProcessStatus.FINISHED)
        .set('selectedUser', user)
        .set('updateToken', generateGuid());
    }
    case UsersAction.LINK_USER_TO_CLIENT_REJECTED: {
      return state.set('linkingUserToClient', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    case UsersAction.INVITE_USER: {
      return state.set('invitingUser', ProcessStatus.STARTED);
    }
    case UsersAction.INVITE_USER_FULFILLED: {
      index = state.get('users').findIndex(function (user) {
        return user.id === action.user.id;
      });
      if (index > -1) {
        return state.set('invitingUser', ProcessStatus.FINISHED)
          .set('users', state.get('users').set(index, action.user));
      }
      return state.set('invitingUser', ProcessStatus.FINISHED);
    }
    case UsersAction.INVITE_USER_REJECTED: {
      return state.set('invitingUser', ProcessStatus.FAILED)
        .set('error', action.payload);
    }
    default:
      return state;
  }
};

// function update (current, coming) {
//   if (!current) {
//     return coming;
//   }
//   if (!coming) {
//     return current;
//   }
//   for (let property in coming) {
//     if (current.hasOwnProperty(property)) {
//       switch (typeof coming[property]) {
//         case 'string':
//         case 'number':
//           if (coming[property] !== current[property]) {
//             // console.log(1, coming[property], current[property]);
//
//             current[property] = coming[property];
//           }
//           break;
//         default:
//           break;
//       }
//     } else {
//       console.log(2, coming[property], current[property]);
//       current[property] = coming[property];
//     }
//   }
//   return current;
// }

export {initialState, reducer as users};
