import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Card, Button, Switch} from 'react-md';
import {Row, Col} from 'react-grid-system';
import {withTranslation} from 'react-i18next';
import {aclFilter} from '../../../utils/ACL';
import {aclTranslation} from '../../../utils/Permissions';
import {ProcessStatus, Scope} from '../../../utils/Constants';
import LoadingBar from '../../general/DumbComponent';
import LayoutContainer from '../../layout/LayoutContainer';
import DeleteTranslationWarning from '../dialog/DeleteTranslationWarning';
import TranslationSelect from './TranslationSelect';
import SourceLanguage from './SourceLanguage';
import Approval from './Approval';
import Additional from './Additional';
import TranslationUsers from './TranslationUsers';
import TranslationSnapshots from './TranslationSnapshots';
import {deleteTranslation, editTranslation} from '../../../actions/projects';
import {showWarning} from '../../../actions/globalDialog';
import styles from '../../../styles/projects/translationoverview/general.scss';
import TranslationAdditionalContent from './TranslationAdditionalContent';
import TranslationStatistics from './TranslationStatistics';

const Modus = {
  REVIEW: 'REVIEW',
  TRANSLATION: 'TRANSLATE',
  ADAPTION: 'ADAPT',
  CREATION: 'CREATE',
  OPEN: 'OPEN',
  OPEN_IN_NEW_TAB: 'OPEN IN NEW TAB'
};

@withTranslation(['project', 'general'], {wait: true})
class TranslationOverview extends Component {
  constructor (props) {
    super(props);
    this.state = {
      baseUrl: '',
      translationsForSelect: null,
      sourceLanguageElements: null
    };
  }

  componentDidMount () {
    const { translations } = this.props;
    if (translations && (translations.length === 1)) {
      const projectId = this.props.match.params.projectId;
      this.props.history.push(`/projects/${projectId}/translations/${translations[0].id}`);
    }
  }

  componentWillMount () {
    const sources = this.props.translation.get('sources');
    this.setBaseUrl(this.props);

    if (sources) {
      this.setState({
        sourceLanguageElements: this.getSourceLanguages(sources)
      });
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      match: {
        params: {projectId, translationId}
      }
    } = nextProps;
    const sources = nextProps.translation.get('sources');

    if (nextProps.translation) {
      if (nextProps.translation.get('id') != this.props.translation.get('id')) {
        if (sources) {
          this.setState({
            sourceLanguageElements: this.getSourceLanguages(sources)
          });
        }
      }
      if (nextProps.translation.get('status') != this.props.translation.get('status')) {
        this.setState({
          sourceLanguageElements: this.getSourceLanguages(sources)
        });
      }
    }
    this.setBaseUrl(nextProps);
  }

  toggleActive = () => {
    let data = {};
    const {translation} = this.props;
    data.active = translation.active === 1 ? 0 : 1;
    this.props.editTranslation(translation.project_id, translation.id, data);
  };

  getSourceLanguages = (sources) => {
    let languages = [];

    if (sources) {
      languages = sources.map((source) => {
        return {
          text: source.languagecode,
          value: source.id
        };
      });
    } else {
      console.warn('no sources!!');
    }
    return languages;
  };

  getLanguagesForSelect = (translations) => {
    let selectedTranslationId = this.props.translation.get('id');
    const sortedArray = translations.sort((a, b) => {
      if (a.languagecode === 'de') {
        return -1;
      }
      if (b.languagecode === 'de') {
        return 1;
      }
      if (a.languagecode === 'en') {
        return -1;
      }
      if (b.languagecode === 'en') {
        return 1;
      }
      if (a.languagecode < b.languagecode) {
        return -1;
      }
      if (a.languagecode > b.languagecode) {
        return 1;
      }
      return 0;
    });
    const engInt = sortedArray.find((elem) => elem.languagecode === 'en');
    const groupLength = engInt ? 2 : 1;
    let languages = sortedArray.map((translation) => {
      return {
        label: translation.languagecode + ' - ' + this.getTranslationName(translation.id),
        value: translation.id,
        selected: selectedTranslationId ? translation.id === selectedTranslationId : false
      };
    });
    const groupedOptions = [
      {options: languages.slice(0, groupLength)},
      {options: languages.slice(groupLength, languages.length)}
    ];
    return groupedOptions;
  };

  setBaseUrl = (props) => {
    const {
      match: {
        params: {projectId, translationId, sourceLanguageId}
      }
    } = props;

    let projectsPart = '/projects/' + projectId;
    let translationsPart = '/translations/' + translationId;
    let sourceLanguagePart = sourceLanguageId ? '/' + sourceLanguageId : '';

    this.setState({baseUrl: projectsPart + translationsPart + sourceLanguagePart});
  };

  handleEdit = () => {
    this.props.history.push(this.state.baseUrl + '/edit-translation');
  };

  handleImport = () => {
    this.props.history.push(this.state.baseUrl + '/import-translation');
  };

  handleExport = () => {
    this.props.history.push(this.state.baseUrl + '/export-translation');
  };

  showDeleteWarning = () => {
    const {translation, showWarning, t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let title = t(`${generalLNS}:warning`);
    let text = t('warning_delete_translation_for_project') + ' ' + translation.get('languagecode') + '?';

    let component = (
      <DeleteTranslationWarning projectId={this.props.project.id} translationId={this.props.translation.get('id')} />
    );

    showWarning({title: title, text: text, actions: [], component: component, isCancellable: false});
  };

  radioBarChange = (event) => {
    this.props.history.push(
      '/projects/' + this.props.project.id + '/translations/' + this.props.translation.get('id') + '/' + event
    );
  };

  handleSelectChange = (changeEvent) => {
    const projectId = this.props.match.params.projectId;
    this.props.history.push('/projects/' + projectId + '/translations/' + changeEvent.value);
  };

  getTranslationName = (id) => {
    for (let i = 0; i < this.props.translations.length; i++) {
      if (this.props.translations[i].id === id) {
        return this.props.translations[i].name;
      }
    }
    return null;
  };

  openRoute = () => {
    this.props.history.push(this.props.route);
  };

  render () {
    const {
      project,
      translation,
      translations,
      fetchingTranslation,
      route,
      t,
      projectIdentifier,
      match: {
        params: {sourceLanguageId}
      }
    } = this.props;

    const {sourceLanguageElements, baseUrl, translationActive} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    let isLoadingExternally = fetchingTranslation == ProcessStatus.STARTED;

    let cardTitle;
    if (fetchingTranslation == ProcessStatus.STARTED) {
      cardTitle = '...';
    } else if (translation.size === 0) {
      cardTitle = t('select_translation');
    } else {
      cardTitle = translation.get('languagecode') + ' - ' + translation.get('name');
    }

    const translationsForSelect = this.getLanguagesForSelect(translations);

    const users = translation.get('users');

    let parentId = translation.get('parent_id');
    // let sourceLanguageIdOrMasterTranslation = sourceLanguageId ? sourceLanguageId : project.master_translation;
    let detailedInfo = (translation.size !== 0) && (fetchingTranslation !== ProcessStatus.STARTED);
    const switchStyle = {
      display: 'inline-block',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      height: 'auto',
      transform: 'translateY(3px)'
    };

    return (
      <Card className={styles.translationOverview}>
        <div className={'innerWrapperBorderTop'}>
          <Row>
            <Col xs={7} className={'borderRight noPaddingRight'}>
              <div className={'translationOverviewRowWrapper'}>
                <div className={'titleSelectWrapper'}>
                  {/* <div className={'projOverTitle'}>*/}
                  {/* <h3 title="choosed translation, click to change...">*/}
                  {/* /!* <span className={styles.innerTitleSmall}>Translation: </span> *!/*/}
                  {/* <span className={'innerTitleClick'}>{cardTitle}</span>*/}
                  {/* </h3>*/}
                  {/* </div>*/}

                  <TranslationSelect
                    cardTitle={cardTitle}
                    handleSelectChange={this.handleSelectChange}
                    isLoadingExternally={isLoadingExternally}
                    translationsForSelect={translationsForSelect}
                  />
                </div>
              </div>
            </Col>
            {detailedInfo && (
              <Col xs={5}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {aclFilter(
                    <div>
                      <Switch
                        style={switchStyle}
                        id={'translation-active-switch'}
                        name={'translation-active-switch'}
                        labelBefore={true}
                        label={''}
                        checked={translationActive}
                        onChange={() => this.toggleActive()}
                      />
                      <span>{t(`${generalLNS}:active`)}</span>
                    </div>
                  )([], [aclTranslation.activate], Scope.TRANSLATION)}
                  <div className={'buttonRow noPaddingLeft'}>
                    {project.master_translation != translation.get('id') &&
                      aclFilter(
                        <Button
                          title={t('delete_translation')}
                          className={'hover-round-button red-skin'}
                          onClick={this.showDeleteWarning}
                          icon
                        >
                          delete
                        </Button>
                      )([], [aclTranslation.delete], Scope.TRANSLATION)}
                    {translation.get('type') == 'translation' &&
                      aclFilter(
                        <Button
                          title={t('import_translation')} // before change: "Import this translation"
                          className={'hover-round-button'}
                          onClick={this.handleImport}
                          icon
                        >
                          file_upload
                        </Button>
                      )([], [aclTranslation.import], Scope.TRANSLATION)}
                    {aclFilter(
                      <Button
                        title={t('export_translation')}
                        className={'hover-round-button'}
                        onClick={this.handleExport}
                        icon
                      >
                        archive
                      </Button>
                    )([], [aclTranslation.export], Scope.TRANSLATION)}
                    {aclFilter(
                      <Button
                        title={t('edit_translation')}
                        className={'hover-round-button'}
                        onClick={this.handleEdit}
                        icon
                      >
                        mode_edit
                      </Button>
                    )([], [aclTranslation.update])}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
        { (fetchingTranslation === ProcessStatus.STARTED) && (
          <div>
            <div className={'innerWrapperBorderTop borderTop'}>
              <Row>
                <Col xs={12}>
                  <LoadingBar show='true' />
                </Col>
              </Row>
            </div>
          </div>
        )}
        {detailedInfo && (
          <div>
            <div className={'innerWrapperBorderTop'}>
              <Row>
                <Col xs={7} className={'borderRight noPaddingRight'}>
                  {sourceLanguageElements && (
                    <SourceLanguage
                      radioBarChange={this.radioBarChange}
                      sources={sourceLanguageElements}
                      radioName="radioBarParentId"
                      parentId={parentId}
                      sourceLanguageId={sourceLanguageId}
                    />
                  )}
                  <div className={'borderTop'}>
                    <div className={'buttonWrapper'}>
                      <a className={'openLinkBtn'} title={t('open_project_info')} href={route}>
                        {t('open')}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={5} className={'noPaddingLeft'}>
                  <div className={'approvalWrapper innerWrapperBorderTop borderTop optional'} style={{height: '100%'}}>
                    <div className={'innerBoxHeadline'} help-tool="6">
                      <TranslationStatistics projectId={project.id} translationId={translation.get('id')} />
                    </div>
                    <div className={'innerBoxHeadline'} style={{paddingTop: '0'}}>
                      <Additional key="secAddConPanel" projectId={project.id} translationId={translation.get('id')} />
                    </div>
                    <Approval
                      projectId={project.id}
                      translationId={translation.get('id')}
                      translationName={translation.get('name')}
                      status={translation.get('status')}
                      translation={translation.toObject()}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {aclFilter(
              <div className={'innerWrapperBorderTop borderTop optional'}>
                <Row>
                  <Col xs={12}>
                    <TranslationSnapshots
                      key="secSnapshotPanel"
                      onChange={null}
                      projectId={project.id}
                      translationId={translation.get('id')}
                    />
                  </Col>
                </Row>
              </div>
            )([], [aclTranslation.read_snapshots], Scope.TRANSLATION)}
            {aclFilter(
              <div className={'innerWrapperBorderTop borderTop optional'}>
                <Row>
                  <Col xs={12}>
                    <TranslationUsers key="secUserPanel" onChange={null} users={users} />
                  </Col>
                </Row>
              </div>
            )([], [aclTranslation.read_users], Scope.TRANSLATION)}
            {aclFilter(
              <TranslationAdditionalContent
                key="secAdditionalPanel"
                onChange={null}
                projectId={project.id}
                translationId={translation.get('id')}
                translationCode={translation.get('languagecode')}
                translations={project.translations}
                projectIdentifier={projectIdentifier}
                baseUrl={baseUrl}
              />
            )([], [aclTranslation.read_additional_content], Scope.TRANSLATION)}
            <LoadingBar
              show={isLoadingExternally}
              wrapperClassname={'translationLoader'}
              progressClassName={'translationProgress'}
            />
          </div>
        )}
      </Card>
    );
  }
}

function mapStateToProps (state) {
  return {
    fetchingTranslation: state.getIn(['projects', 'fetchingTranslation']),
    translation: state.getIn(['projects', 'translation']),
    updateToken: state.getIn(['projects', 'updateToken'])
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      deleteTranslation,
      showWarning,
      editTranslation
    }
  )(TranslationOverview)
);
