/* eslint-disable brace-style */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Editor, Value } from '@gitbook/slate-react';
import editList from '@gitbook/slate-edit-list';
import Html from '@gitbook/slate-html-serializer';
import { TextField, Button } from 'react-md';
import rules from './rules';
import {Scope} from '../../utils/Constants';
import {aclFilter} from '../../utils/ACL';
import {aclTranslationTool} from '../../utils/Permissions';
import styles from '../../styles/translationTool/translationTool.scss';
import INITIAL_VALUE from './value';
import RawEditor from './RawEditor';

const DEFAULT_NODE = 'paragraph';

function wrapLink (change, href) {
  change.wrapInline({
    type: 'link',
    data: {href}
  });
  change.collapseToEnd();
}

function unwrapLink (change) {
  change.unwrapInline('link');
}

function wrapMailLink (change, mail) {
  change.wrapInline({
    type: 'mail',
    data: {mail}
  });
  change.collapseToEnd();
}

function unwrapMailLink (change) {
  change.unwrapInline('mail');
}

@withTranslation(['translation_navigation', 'general'], {wait: true})
class RichTextEditor extends React.Component {
  constructor (props) {
    super(props);
    this.html = this.props.html || new Html({rules});
    const { t } = this.props;
    this.initialValue = INITIAL_VALUE;
    this.editListPlugin = editList({
      types: ['ul_list'],
      typeItem: 'list_item',
      typeDefault: 'paragraph'
    });
    this.plugins = [
      this.editListPlugin
    ];
    this.contentChanged = false;

    // const v = Value;
    // v.document = this.html.deserialize(this.props.initialText).document;

    this.state = {
      isIE11: true,
      value: INITIAL_VALUE,
      showLinkDialog: false,
      setLinkCallback: null,
      showMailLinkDialog: false,
      setMailLinkCallback: null,
      href: '',
      mail: '',
      chars: 0,
      words: 0,
      descriptions: {
        bold: t('bold_text'),
        superscript: t('superscript_text'),
        subscript: t('subscript_text'),
        nobreak: t('nobreak_text'),
        quote: t('quote_text'),
        list: t('list_text'),
        indent: t('indent_text'),
        decrease: t('decrease_text'),
        link: t('link_text'),
        mail: t('mail_text'),
        shy: t('shy_text'),
        zwsp: t('zwsp_text'),
        undo: t('undo'),
        redo: t('redo')
      },
      rawEditorOpen: false
    };
  }

  componentDidMount = () => {
    this.updateMenu();
  };

  componentDidUpdate = () => {
    this.updateMenu();
  };

  componentWillMount = () => {
    //  Manuelles Parsing: (Test)
    // let neuerTestHTML = this.props.initialText
    //   .replace(/<p>/g, '<paragraph>')
    //   .replace(/<\/p>/g, '</paragraph>')
    //   .replace(/<b>|<strong>/g, '<strong>')
    //   .replace(/<\/b>|<\/strong>/g, '</strong>')
    //   .replace(/<br>|<br\/>|<br \/>/g, '\n')
    //   .replace(/<sub>/g, '<sub>')
    //   .replace(/<\/sub>/g, '</sub>')
    //   .replace(/<sup>/g, '<sup>')
    //   .replace(/<\/sup>/g, '</sup>')
    //   .replace(/<ul>/g, '<ul_list>')
    //   .replace(/<\/ul>/g, '</ul_list>')
    //   .replace(/<ol>/g, '<ol_list>')
    //   .replace(/<\/ol>/g, '</ol_list>')
    //   .replace(/<li>/g, '<list_item>')
    //   .replace(/<\/li>/g, '</list_item>')
    //   .replace(/<blockquote>/g, '<blockquote>')
    //   .replace(/<\/blockquote>/g, '</blockquote>')
    //   .replace(/(.*)<span class="nobreak"(.*)>(.*)<\/span>(.*)/g, '$1<span_nobreak>$3</span_nobreak>$4')
    //   .replace(/(.*)<span class="zwsp(.*)"(.*)>(.*)<\/span>(.*)/g, '$1<zwsp class="zwsp">|</zwsp>$5')
    //   .replace(/(.*)<span class="shy(.*)"(.*)>(.*)<\/span>(.*)/g, '$1<shy class="shy">-</shy>$5')
    //   .replace(/(.*)<a href="(?!mailto:)(.*)">(.*)<\/a>(.*)/g, '$1<a href="$2">$3</a>$4')
    //   .replace(/(.*)<a href="mailto:(.*)">(.*)<\/a>(.*)/g, '$1<a href="mailto:$2">$3</a>$4')
    // ;

    let regexLiStart = /<li>/g;
    let regexLiUlGlobal = /<li>(.*)<ul>/g;
    let regexLiStartAndBreak = /\n<li>/g;
    let regexUlEnd = /<\/ul>/g;
    let regexLiInnerGlobal = /<li>(.*)<\/li>/g;
    let regexUlEndAndBreak = /<\/ul>\n/g;
    let test = this.props.initialText
      .replace(regexLiStart, '\n<li>')        // vorbereiten auf parsen
      .replace(regexUlEnd, '</ul>\n')         // vorbereiten auf parsen
      .replace(regexLiUlGlobal, '<li><p>$1</p><ul>')      // alles zwischen li und neuem ul in p
      .replace(regexLiInnerGlobal, '<li><p>$1</p></li>')  // alles in li => p
      .replace(regexLiStartAndBreak, '<li>')  // vorbereiten rückgängig
      .replace(regexUlEndAndBreak, '</ul>')   // vorbereiten rückgängig
    ;

    /* REGEX TESTINGS:
    let test1 = this.props.initialText.replace(regexLiStart, '\n<li>'); // vorbereiten auf parsen;
    let test2 = test1.replace(regexUlEnd, '</ul>\n');
    let test3 = test2.replace(regexLiUlGlobal, '<li><p>$1</p><ul>');  // alles zwischen li und neuem ul in p;
    let test4 = test3.replace(regexLiInnerGlobal, '<li><p>$1</p></li>');  // alles in li => p;
    let test5 = test4.replace(regexLiStartAndBreak, '<li>');
    let test6 = test5.replace(regexUlEndAndBreak, '</ul>');
    console.log('test1', test1);
    console.log('test2', test2);
    console.log('test3', test3);
    console.log('test4', test4);
    console.log('test5', test5);
    console.log('test6', test6);
    */

    this.setState({value: this.html.deserialize(test)});
  };

  showRawEditor = () => {
    this.setState({rawEditorOpen: true});
  };
  hideRawEditor = () => {
    this.setState({rawEditorOpen: false});
  };

  hasMark = type => {
    const {value} = this.state;
    return value.activeMarks.some(mark => mark.type == type);
  };

  hasBlock = type => {
    const {value} = this.state;
    return value.blocks.some(node => node.type == type);
  };

  // hasInlines = (type) => {
  //     // console.log('hasInlines');
  //     const {value} = this.state;
  //     // console.log('value.inlines.toJSON()', value.inlines.toJSON());
  //     return value.inlines.some(node => node.type == type)
  // };

  hasLinks = () => {
    const {value} = this.state;
    // console.log('hasLinks', value.inlines.some(inline => inline.type == 'link'));
    return value.inlines.some(inline => inline.type === 'link');
  };

  hasMailLinks = () => {
    const {value} = this.state;
    // console.log('hasMailLinks', value.inlines.some(inline => inline.type == 'mail'));
    return value.inlines.some(inline => inline.type === 'mail');
  };

  onChange = e => {
    const {value} = e;
    if (value.document) {
      if (this.state.value && this.state.value.document) {
        if (value.document != this.state.value.document) {
          const string = this.html.serialize(value);
          // console.log('--- value', JSON.stringify(value.document.toJSON()));
          // console.log('content', string);
          this.props.handleContentChange(string);
        }
      }
    }
    this.setState({
      value: value,
      words: value.document
        .getBlocks()
        .reduce((memo, b) => memo + b.text.trim().split(/\s+/).length, 0),
      chars: value.document.text.length
    });
  };


  onBackspace = event => {
    const {value} = this.state;
    const change = value.change();
    const { startOffset, selection } = value;
    if (selection.isExpanded) {return undefined;}
    if (startOffset > 0) {return undefined;}
    const currentItem = this.editListPlugin.utils.getCurrentItem(value);
    if (!currentItem) {return undefined;}
    if (!selection.isAtStartOf(currentItem)) {return undefined;}
    event.preventDefault();
    this.onChange(this.editListPlugin.changes.unwrapList(change));
  };


  onEnter = event => {
    const {value} = this.state;
    let change = value.change();
    if (event.shiftKey) {
      this.onChange(change.insertText('\n'));
      return;
    }
    const currentItem = this.editListPlugin.utils.getCurrentItem(value);
    if (!currentItem) {return undefined;}
    event.preventDefault();
    if (value.isExpanded) {
      change = change.delete();
    }
    if (currentItem.isEmpty) {
      if (this.editListPlugin.utils.getItemDepth(value) > 1) {
        this.onChange(
          this.editListPlugin.changes.decreaseItemDepth(change)
        );
        return;
      }
      this.onChange(
        this.editListPlugin.changes.unwrapList(change)
      );
      return;
    }
    this.onChange(
      this.editListPlugin.changes.splitListItem(change)
    );
  };

  onTab = event => {
    const {value} = this.state;
    const change = value.change();
    const { isCollapsed } = value;
    if (!isCollapsed || !this.editListPlugin.utils.getCurrentItem(value)) {
      return undefined;
    }
    if (event.shiftKey) {
      event.preventDefault();
      this.onChange(
        this.editListPlugin.changes.decreaseItemDepth(change)
      );
      // console.log('decreaseItemDepth ###################################');
      return undefined;
    }
    event.preventDefault();
    // console.log('increaseItemDepth ###################################');
    this.onChange(
      this.editListPlugin.changes.increaseItemDepth(change)
    );
  };

  onKeyDown = event => {
    console.log('onKeyDown', event.keyCode);
    // if (event.keyCode === 13) {// ENTER
    //   const {value} = change;
    //   let marks = value.marks;
    //   if (marks.size > 0 && marks.first().get('type') === 'span_nobreak') {
    //     console.log('skipping because nobreak');
    //     event.preventDefault();
    //     return true;
    //   }
    //
    //   next();
    //   // window.rtechange = editor.change;
    //   // console.log('window.rtechange', window.rtechange);
    //   // if (event.shiftkey) {
    //   //     event.preventDefault();
    //   //     this.props.saveTranslation();
    //   // }
    // } else

    // if (event.keyCode === 27) { // ESC
    //   event.preventDefault();
    //   this.props.cancelContentChange();
    //   return;
    // }
    // if (event.shiftKey) { // SHIFT
    //   if (event.keyCode === 13) { // ENTER
    //     event.preventDefault();
    //     this.onChange(
    //       this.state.value.change()
    //         .insertText('\n')
    //     );
    //     return;
    //   }
    // } else {
    //   if (event.keyCode === 13) { // enter
    //
    //   }
    // }

    const KEY_ENTER = 'Enter';
    const KEY_TAB = 'Tab';
    const KEY_BACKSPACE = 'Backspace';
    // const args = [event, change, editor, opts];

    switch (event.key) {
      case KEY_ENTER:
        return this.onEnter(event);
      case KEY_TAB:
        return this.onTab(event);
      case KEY_BACKSPACE:
        return this.onBackspace(event);
      default:
        return undefined;
    }

    // next();

    // Modifikation Alexander - nobreak nicht umbrechen
    // if (event.keyCode === 13) { // ENTER
    //     event.preventDefault();
    //     this.state.setLinkCallback(this.state.href);
    // }
    // if (isBoldHotkey(event)) {
    //     mark = 'bold';
    // } else if (isItalicHotkey(event)) {
    //     mark = 'italic';
    // } else if (isUnderlinedHotkey(event)) {
    //     mark = 'underlined';
    // } else if (isCodeHotkey(event)) {
    //     mark = 'code';
    // } else {
    //     return;
    // }
    // event.preventDefault();
    // change.toggleMark(mark);
    // next();
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    const {value} = this.state;
    let selectionLength =
      value.get('selection').endOffset - value.get('selection').startOffset;
    if (selectionLength) {
      const change = value.change().toggleMark(type);
      this.onChange(change);
    }
  };

  onClickBlock = (event, type) => {
    event.preventDefault();
    const {value} = this.state;

    // Handle everything but list buttons.
    if (
      type != 'bulleted-list' &&
      type != 'ul_list' &&
      type != 'numbered-list' &&
      type != 'list-item' &&
      type != 'list_item' &&
      type != 'indent' &&
      type != 'decrease' &&
      type != 'list-item-numbered'
    ) {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        this.onChange(
          value.change()
            .setBlocks(isActive ? DEFAULT_NODE : type)
            .unwrapBlock('bulleted-list')
            .unwrapBlock('numbered-list')
        );
      } else {
        this.onChange(
          value.change()
            .setBlocks(isActive ? DEFAULT_NODE : type)
        );
      }
    }

    // Handle the extra wrapping required for list buttons.
    else {
      const isList = this.hasBlock('list-item');
      // const isType = change.value.blocks.some((block) => {
      //     return !!document.getClosest(block.key, parent => parent.type == type)
      // });

      // console.log(isList && isType);
      // if (isList && isType) {
      if (isList) {
        if (type == 'indent') {
          // console.log('----------------- indent');
          this.onChange(
            value.change()
              .wrapBlock('list-item')
              .wrapBlock('bulleted-list')
          );
        } else if (type == 'decrease') {
          // console.log('----------------- decrease');
          this.onChange(
            value.change()
              .unwrapBlock('list-item')
              .unwrapBlock('bulleted-list')
          );
        } else {
          this.onChange(
            value.change()
              .setBlocks(DEFAULT_NODE)
              .unwrapBlock('bulleted-list')
              .unwrapBlock('numbered-list')
          );
        }
      }
      // else if (isList) {
      //     console.log('2');
      //     change
      //         // .unwrapBlock(type == 'bulleted-list' ? 'numbered-list' : 'bulleted-list')
      //         .unwrapBlock('bulleted-list')
      //         .unwrapBlock('numbered-list')
      //         .wrapBlock(type)
      // }
      else {
        if (type === 'list-item-numbered') {
          this.onChange(
            value.change()
              .setBlocks('list-item')
              .wrapBlock('numbered-list')
          );
        } else {
          // normale Liste
          if (type == 'indent') {
            // console.log('----------------- indent hier');
          } else if (type == 'decrease') {
            // console.log('----------------- decrease');
          } else {
            this.onChange(
              value.change()
                .setBlocks('list-item')
                .wrapBlock('bulleted-list')
            );
          }
        }
      }
    }
  };

  onClickLink = event => {
    event.preventDefault();
    const {value} = this.state;
    const hasLinks = this.hasLinks();
    const change = value.change();
    if (hasLinks) {
      change.call(unwrapLink);
      this.onChange(change);
    } else if (value.selection.isExpanded) {
      const callback = href => {
        this.setState({showLinkDialog: false});
        setTimeout(() => {
          change.call(wrapLink, href);
          this.onChange(change);
        }, 0);
      };
      this.setState({
        showLinkDialog: true,
        setLinkCallback: callback
      }, () => {
        let input = document.querySelector('#rich-text-editor-link-input');
        let root = document.querySelector('.root');
        root.scrollTop = input.getBoundingClientRect().top + 20;
        input.focus();
      });
    }
  };

  onClickMailLink = event => {
    event.preventDefault();
    const {value} = this.state;
    const hasMLinks = this.hasMailLinks();
    const change = value.change();
    if (hasMLinks) {
      change.call(unwrapMailLink);
      this.onChange(change);
    } else if (value.selection.isExpanded) {
      const callback = mail => {
        this.setState({showMailLinkDialog: false});
        setTimeout(() => {
          change.call(wrapMailLink, mail);
          this.onChange(change);
        }, 0);
      };
      this.setState({
        showMailLinkDialog: true,
        setMailLinkCallback: callback
      }, () => {
        let input = document.querySelector('#rich-text-editor-link-input');
        let root = document.querySelector('.root');
        root.scrollTop = input.getBoundingClientRect().top + 20;
        input.focus();
      });
    }
  };

  onClickShy = event => {
    const {value} = this.state;
    event.preventDefault();
    if (window.getSelection().type === 'Caret') {
      this.onChange(
        value.change()
          .insertInline({
            type: 'shy',
            data: {},
            isVoid: true
          })
      );
    }
  };

  onClickZwsp = event => {
    const {value} = this.state;
    event.preventDefault();
    if (window.getSelection().type === 'Caret') {
      this.onChange(
        value.change()
          .insertInline({
            type: 'zwsp',
            data: {},
            isVoid: true
          })
      );
    }
  };

  onCancelLink = () => {
    this.toggleLinkDialog();
    this.setState({href: ''});
  };

  onCancelMailLink = () => {
    this.toggleMailLinkDialog();
    this.setState({mail: ''});
  };

  toggleLinkDialog = () => {
    this.setState({showLinkDialog: !this.state.showLinkDialog});
  };

  toggleMailLinkDialog = () => {
    this.setState({showMailLinkDialog: !this.state.showMailLinkDialog});
  };

  renderMarkButton = (type, icon, classNames, title) => {
    const isActive = this.hasMark(type);

    const onMouseDown = event => this.onClickMark(event, type);
    let className = 'button';
    if (classNames) {
      className += ' ' + classNames;
    }

    return (
      <span
        className={className + ' toolbar-button'}
        onMouseDown={onMouseDown}
        data-nobreak={icon === 'wrap_text' ? 'true' : false}
        title={title}
        data-active={isActive ? 'true' : 'false'}
      >
        <span className="material-icons">{icon}</span>
      </span>
    );
  };

  renderBlockButton = (type, icon, title) => {
    const onMouseDown = event => this.onClickBlock(event, type);
    let listTypes = ['list-item', 'indent', 'decrease'];
    const isActive = this.hasBlock(listTypes.indexOf(type) === -1 ? type : 'list-item');

    return (
      <span
        className="button"
        onMouseDown={onMouseDown}
        title={title}
        data-is-inner-list-button={listTypes.indexOf(type) === -1 ? 'false' : 'true'}
        data-active={isActive}
      >
        <span className="material-icons">{icon}</span>
      </span>
    );
  };

  renderNode = (props) => {
    const {
      attributes,
      children,
      node
      // ,
      // editor
    } = props;

    let childrenCopy = null;

    switch (node.type) {
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>;

      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'ul_list':
        return <ul {...attributes}>{children}</ul>;

      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'ol_list':
        return <ol {...attributes}>{children}</ol>;

      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'list_item':
        return <li {...attributes}>{children}</li>;

      case 'link':
        return <a {...attributes} href={node.data.get('href')}>{children}</a>;
      case 'mail':
        return <a {...attributes} href={'mailto:' + node.data.get('mail')}>{children}</a>;

      case 'shy':
        childrenCopy = children;
        childrenCopy = '';
        return <span className={'shy '} {...attributes}>-{childrenCopy}</span>;

      case 'zwsp':
        childrenCopy = '';
        return <span className={'zwsp '} {...attributes}>|{childrenCopy}</span>;

      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  renderMark = (props, editor, next) => {
    const {attributes, children, mark} = props;
    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'superscript':
        return <sup {...attributes}>{children}</sup>;
      case 'subscript':
        return <sub {...attributes}>{children}</sub>;
      case 'span_nobreak':
        return <span {...attributes} className="nobreak">{children}</span>;
      case 'span_shy':
        return <span {...attributes} className="shy">{children}</span>;
      case 'span_zwsp':
        return <span {...attributes} className="zwsp">{children}</span>;
      default:
        return next();
    }
  };

  renderInline = (props, editor, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'link':
        /* eslint-disable */
        const { data } = node;
        const href = data.get('href');
        /* eslint-enable */
        return (
          <a href={href} {...attributes}>
            {children}
          </a>
        );
      default:
        return next();
    }
  };


  updateMenu = () => {
    const menu = this.hoverMenu;
    if (!menu) return;

    const {value} = this.state;
    const {fragment, selection} = value;

    if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
      if (menu.hasAttribute('style')) {
        menu.removeAttribute('style');
      }
      return;
    }

    const native = window.getSelection();
    const range = native.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    menu.style.opacity = 1;
    menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;
    menu.style.left = `${rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2}px`;
  };

  onClickRedo = event => {
    event.preventDefault();
    this.onChange(
      this.state.value.change()
        .redo()
    );
  };

  onClickUndo = event => {
    event.preventDefault();
    // this.state.value.change().undo();
    this.onChange(
      this.state.value.change()
        .undo()
    );
  };

  renderHoverMenubar = () => {
    const hasLinks = this.hasLinks();
    const hasMailLinks = this.hasMailLinks();
    return (
      <div className={'hoverMenu'} ref={div => (this.hoverMenu = div)}>
        {/*
                    Mark-Operationen
                */}

        {aclFilter(
          this.renderMarkButton('bold', 'format_bold', '', this.state.descriptions.bold)
        )([], [aclTranslationTool.bold], Scope.TRANSLATION)}
        {aclFilter(
          this.renderMarkButton('superscript', 'format_size', '', this.state.descriptions.superscript)
        )([], [aclTranslationTool.superscript], Scope.TRANSLATION)}
        {aclFilter(
          this.renderMarkButton('subscript', 'text_fields', '', this.state.descriptions.subscript)
        )([], [aclTranslationTool.subscript], Scope.TRANSLATION)}
        {aclFilter(
          this.renderMarkButton('span_nobreak', 'wrap_text', '', this.state.descriptions.nobreak)
        )([], [aclTranslationTool.nobreak], Scope.TRANSLATION)}


        {aclFilter(
          <span
            className="button"
            onMouseDown={this.onClickLink}
            title={this.state.descriptions.link}
            data-active={hasLinks}
          >
            <span className="material-icons">link</span>
          </span>
        )([], [aclTranslationTool.link], Scope.TRANSLATION)}
        {aclFilter(
          <span
            className="button"
            onMouseDown={this.onClickMailLink}
            title={this.state.descriptions.mail}
            data-active={hasMailLinks}
          >
            <span className="material-icons">alternate_email</span>
          </span>
        )([], [aclTranslationTool.mail], Scope.TRANSLATION)}
      </div>
    );
  };

  renderToolbar = () => {
    console.log('ALTER EDITOR: renderToolbar');
    const {value} = this.state;
    const {history} = value;
    const undos = history.get('undos');
    const redos = history.get('redos');

    this.contentChanged = undos.size > 1;

    const {
      wrapInList,
      unwrapList,
      increaseItemDepth,
      decreaseItemDepth
    } = this.editListPlugin.changes;
    const inList = this.editListPlugin.utils.isSelectionInList(this.state.value);

    // console.log('inList', inList);

    return (
      <div className={'menu toolbar-menu toolbarMenu'}>
        {/*
                    ~~~~~~~~~~~~~~ PARAGRAF-OPERATIONEN ~~~~~~~~~~~~~~
                */}
        {aclFilter(
          this.renderBlockButton('quote', 'format_quote', this.state.descriptions.quote)
        )([], [aclTranslationTool.quote], Scope.TRANSLATION)}

        {/* List-OPERATIONEN*/}
        <span className={'separator'}> </span>
        {aclFilter(
          <button
            className={inList ? 'active button' : ' button'}
            onClick={() => this.call(inList ? unwrapList : wrapInList)}
          >
            <span className="material-icons">format_list_bulleted</span>
          </button>
        )([], [aclTranslationTool.list], Scope.TRANSLATION)}
        {aclFilter(
          <button
            className={inList ? 'button' : 'button disabled'}
            onClick={() => this.call(increaseItemDepth)}
          >
            <span className="material-icons">format_indent_increase</span>
          </button>
        )([], [aclTranslationTool.indent_list], Scope.TRANSLATION)}
        {aclFilter(
          <button
            className={inList ? 'button' : 'button disabled'}
            onClick={() => this.call(decreaseItemDepth)}
          >
            <span className="material-icons">format_indent_decrease</span>
          </button>
        )([], [aclTranslationTool.decrease_list], Scope.TRANSLATION)}

        <span className={'separator'}> </span>
        {/*  zusätzliche Möglichkeiten vom Edit-List-Plugin:
         *  wrapInList ==> liste in listitem erzwingen
         *  unwrapList ==> liste entfernen erzwingen
        <button className={'button'} onClick={() => this.call(wrapInList)}>W</button>
        <button className={'button'} onClick={() => this.call(unwrapList)}>U</button>
        <span className={'separator'}> </span>
        */}

        {/*
                    ~~~~~~~~~~~~~~ Element-Einsetzen-OPERATIONEN ~~~~~~~~~~~~~~
                */}
        {aclFilter(
          <span
            className="button shy-button"
            onMouseDown={e => this.onClickShy(e)}
            title={this.state.descriptions.shy}
            data-active={''}
          >
            <span className="material-icons">wrap_text</span>
          </span>
        )([], [aclTranslationTool.shy], Scope.TRANSLATION)}
        {aclFilter(
          <span
            className="button zwsp-button"
            onMouseDown={this.onClickZwsp}
            title={this.state.descriptions.zwsp}
            data-active={''}
          >
            <span className="material-icons">space_bar</span>
          </span>
        )([], [aclTranslationTool.zero_width_space], Scope.TRANSLATION)}
        <span className={'separator'}> </span>

        {/*
                    ~~~~~~~~~~~~~~ UNDO - REDO ~~~~~~~~~~~~~~
                */}
        <span
          className="button undoRedo"
          onMouseDown={this.onClickUndo}
          title={this.state.descriptions.undo}
          data-active={undos && undos.size > 1 ? 'true' : 'false'}
        >
          <span className="material-icons">undo</span>
        </span>
        {/* <span>{undos ? undos.size : 0}</span> */}
        <span
          className="button undoRedo"
          onMouseDown={this.onClickRedo}
          title={this.state.descriptions.redo}
          data-active={redos && redos.size > 0 ? 'true' : 'false'}
        >
          <span className="material-icons">redo</span>
        </span>
        {/* <span>{redos ? redos.size : 0}</span> */}
      </div>
    );
  };

  call (change) {
    this.setState({
      value: this.state.value.change().call(change).value
    });
  }

  renderLinkDialog = () => {
    const {t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource
    return (
      <div className={'linkDialog'}>
        <TextField
          id="rich-text-editor-link-input"
          label={t('link_input_label')}
          ref={TextField => (this.textfieldLink = TextField)}
          value={this.state.href}
          onChange={value => this.setState({href: value})}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              // ENTER
              event.preventDefault();
              this.state.setLinkCallback(this.state.href);
            } else if (event.keyCode === 27) {
              // ESC
              event.preventDefault();
              this.onCancelLink();
            }
          }}
        />
        <div className={'linkDialogButtonBar'}>
          <Button flat onClick={this.onCancelLink}>
            { t(`${generalLNS}:cancel`) }
          </Button>
          <Button
            flat
            swapTheming
            primary
            onClick={() => this.state.setLinkCallback(this.state.href)}
          >
            {t('set_link')}
          </Button>
        </div>
      </div>
    );
  };

  renderMailLinkDialog = () => {
    const {t} = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    return (
      <div className={'linkDialog'} is-mail="true">
        <TextField
          id="rich-text-editor-link-input"
          label={t('mail_input_label')}
          ref={TextField => (this.textfieldMail = TextField)}
          value={this.state.mail}
          onChange={value => this.setState({mail: value})}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              // ENTER
              event.preventDefault();
              this.state.setMailLinkCallback(this.state.mail);
            } else if (event.keyCode === 27) {
              // ESC
              event.preventDefault();
              this.onCancelMailLink();
            }
          }}
        />
        <div className={'linkDialogButtonBar'}>
          <Button flat onClick={this.onCancelMailLink}>
            { t(`${generalLNS}:cancel`) }
          </Button>
          <Button
            flat
            swapTheming
            primary
            onClick={() => this.state.setMailLinkCallback(this.state.mail)}
          >
            {t('set_mail')}
          </Button>
        </div>
      </div>
    );
  };

  renderWordcount = () => {
    return (
      <div className={'wordcount'}>
        {this.state.words} word{this.state.words > 1 ? 's' : ''}, {this.state.chars} char
        {this.state.chars > 1 ? 's' : ''}
      </div>
    );
  };

  renderEditor = () => {
    const { t } = this.props;
    const { value } = this.state;
    return (
      <div
        className="editor"
        onKeyDown={this.onKeyDown}
        contentchanged={this.contentChanged.toString()}
      >
        <Editor
          placeholder={ t('enter_richtext') }
          value={value}
          plugins={this.plugins}
          onChange={this.onChange}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          spellCheck={false}
          readOnly={false}
        />
      </div>
    );
  };

  renderRawEditor = () => {
    const {rawCode} = this.props;
    return (
      <RawEditor
        rawCode={rawCode}
        hideRawEditor={this.hideRawEditor}
        saveTranslation={this.props.saveTranslation}
        handleContentChange={this.props.handleContentChange}
      />
    );
  };

  render () {
    const {showLinkDialog, showMailLinkDialog, isIE11, rawEditorOpen} = this.state;
    return (
      <div>
        <div
          className={styles.rteWrapper + ' oldEditor'}
          data-invisible={showLinkDialog || showMailLinkDialog}
        >
          {aclFilter(
            <span
              className="button-start-rawEditor old"
              onMouseDown={this.showRawEditor}
              title={this.state.descriptions.code}
            ><span className="material-icons">code</span></span>
          )(['admin'])}
          {this.renderToolbar()}
          {this.renderEditor()}
          {!isIE11 && this.renderWordcount()}
          {this.renderHoverMenubar()}
          {rawEditorOpen && this.renderRawEditor()}
        </div>
        {showLinkDialog && this.renderLinkDialog()}
        {showMailLinkDialog && this.renderMailLinkDialog()}
      </div>
    );
  }
}

/* eslint-enable max-len */

export default RichTextEditor;
