import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import {withTranslation} from 'react-i18next';

import LoadingBar from '../../general/DumbComponent';
import {renderTextField, renderSelectField, renderStarSwitch} from '../../general/BasicFormComponents';

import styles from '../../../styles/project_detail.scss';
import { formatRoleOptions, sortAlphabetically } from '../../../utils/ChipHelperFunctions';
import { fetchRoles } from '../../../actions/roles';
import {ProcessStatus} from '../../../utils/Constants';


@withTranslation(['users', 'general'], {wait: true})
class UserPrime extends Component {
  constructor (props) {
    super(props);

    this.state = {
      max: 400,
      roleOptions: null
    };
  }

  componentWillMount () {
    this.props.fetchRoles();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {fetchingRoles, values} = nextProps;
    if (values != this.props.values) {
      this.safeUpdate(nextProps);
    }

    if (fetchingRoles === ProcessStatus.FINISHED) {
      this.safeUpdate(nextProps);
    }
  }

  safeUpdate = props => {
    const {roles} = props;
    if (roles) {
      if (roles.size > 0) {
        let roleOptions = formatRoleOptions(roles)
          .toArray()
          .sort((a, b) => sortAlphabetically(a, b));

        this.setState({
          roleOptions: roleOptions
        });
      }
    }
  };

  render () {
    const {editMode, t, isSuperIsAvailable} = this.props;
    const {roleOptions} = this.state;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    if (roleOptions == null) {
      return <LoadingBar show="true" message={ t(`${generalLNS}:loading_bar_information`)}/>;
    }

    return (
      <div className={'column'} editmode={editMode ? 'true' : 'false'}>
        <div className={styles.header}>
          <h2 className={'headName'}>{ t(`${generalLNS}:information`)}</h2>
        </div>
        <div className="editableElements">
          <Field
            id="name"
            name="name"
            label={ t(`${generalLNS}:name`)}
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id="email"
            name="email"
            label={ t(`${generalLNS}:email`)}
            type="text"
            className={styles.formField}
            resize={{max: this.state.max}}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id="languagecode"
            name="languagecode"
            label={ t('application_language') }
            type="text"
            className={styles.formField}
            disabled={!editMode}
            required
            component={renderTextField}
          />
          <Field
            id="default_permissions"
            name="default_permissions"
            label={ t('default_permissions') }
            // label="Default permissions"
            type="text"
            className={styles.formField}
            disabled={!editMode}
            component={renderTextField}
          />
          <div className="superRole">
            <Field
              id="globalrole"
              name="globalrole"
              label={ t('global_role') }
              type="text"
              className="md-cell md-cell--12"
              menuItems={roleOptions}
              disabled={!editMode}
              required
              component={renderSelectField}
              errorText={ t('global_role_error') }
            />
            { isSuperIsAvailable &&
              <Field
                id="is_super"
                name="is_super"
                label="Is super"
                className="md-cell md-cell--12"
                disabled={!editMode}
                component={renderStarSwitch}
              />
            }
          </div>
          {/*
          <Field
            id="default_permissions"
            name="default_permissions"
            label="Default permissions"
            type="text"
            disabled={!editMode}
            className="md-cell md-cell--12"
            component={renderTextField}
          />
          */}

        </div>

        <div className="notEditableElements">
          <Field
            id="username"
            name="username"
            label={ t(`${generalLNS}:username`)}
            type="text"
            helpText={ t(`${generalLNS}:not_editable`)}
            className={styles.formField}
            required
            disabled
            component={renderTextField}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    roles: state.getIn(['roles', 'roles']),
    fetchingRoles: state.getIn(['roles', 'fetchingRoles'])
  };
}

export default connect(
  mapStateToProps,
  {fetchRoles, formatRoleOptions}
)(UserPrime);
