import React, { Component } from 'react';
import { FontIcon } from 'react-md';
import styles from '../../styles/wbtViewer/wbtViewer.scss';
import { withTranslation } from 'react-i18next';

@withTranslation('translation_navigation', {wait: true})
class StructureNode extends Component {
  constructor (props) {
    super(props);

    this.state = {
      collapsed: true
    };

    this.expand = this.expand.bind(this);
    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // const {isSelected, expandParent} = nextProps;
    // if (isSelected && typeof expandParent === 'function') {
    //   expandParent();
    // }
    const {
      foldAll
    } = nextProps;
    this.setState({collapsed: foldAll});
  }

  expand () {
    this.setState({collapsed: false});
  }

  handleArrowClick () {
    this.setState({collapsed: !this.state.collapsed});
  }

  handleNodeClick (event, node, hasChildren, isSite) {
    event.preventDefault();

    if (isSite || node.content_type == 'popup') {
      this.props.onNodeSelect(node);
    } else {
      this.handleArrowClick();
    }
  }

  render () {
    const {
      node,
      isSelected,
      isInCourseSelected,
      level,
      childHandler,
      t,
      refName
    } = this.props;
    const generalLNS = 'general'; // generalLanguageNamespaceSource

    const collapsed = this.state.collapsed;
    const unfoldTransform = !collapsed
      ? 'rotate(180deg) translateX(0) translateY(2px)'
      : 'rotate(90deg) translateX(-2px) translateY(0)';
    const arrow = (
      <FontIcon
        style={{transform: unfoldTransform, transition: 'all 0.3s ease'}}
        onClick={this.handleArrowClick}
      >
        keyboard_arrow_up
      </FontIcon>
    );

    let hasChildren = typeof node.childs !== 'undefined' && node.childs.length > 0;
    let nodeClassName = 'node';
    if (isSelected) {
      nodeClassName += ' selectedNode';
    } else if (isInCourseSelected) {
      nodeClassName += ' selectedCourseNode';
    }

    const shClassName = collapsed
      ? 'chapterHeadline chapterHeadlineCollapsed'
      : 'chapterHeadline';

    const nodeHeadline = (
      <span className={shClassName}>
        {t('chapter')}&nbsp;&nbsp;{node.name}
      </span>
    );

    let approved = false;
    let translated = false;
    let visible = true; // default: visible

    if (node.status) {
      approved = node.status.approved;
      translated = node.status.translated;
      visible = node.status.visible;
    }
    let statusIcon = <FontIcon title={ t(`${generalLNS}:check`) }>check</FontIcon>;
    if (!approved) {
      statusIcon = translated ? (
        <FontIcon title={ t(`${generalLNS}:translate`) }>translate</FontIcon>
      ) : null;
    }

    // const approvalIcon = (approved) && <FontIcon title='check' value='check' onClick={null} />;
    // const translationIcon = (translated) ? <FontIcon title='translate' value='translate' onClick={null} /> : null;

    // const settingStatusItemLoader = (settingItemStatus == ProcessStatus.STARTED) && <span>Loading ...</span>

    const isSite = node.content_type == 'site';

    const nodeNameWrapper = (
      <span className={styles.nodeNameWrapper}>
        <span className={'nodeText'}>
          <span>{node.external_id}</span>
          {isSite && (
            <span
              style={{display: this.props.showingHeadlines ? 'block' : 'none'}}
              className={'nodeTextHeadline'}
            >
              {node.name}
            </span>
          )}
        </span>
        {isSite && [
          <span key={node.id + '_0'} className={'nodeTextLine'}>
            &nbsp;
          </span>,
          <span key={node.id + '_1'} className={'nodeTextIcons'}>
            {!visible && <FontIcon title={ t('visibility_off') }>visibility_off</FontIcon>}
            {statusIcon}
          </span>
        ]}
      </span>
    );
    const nodeContent = level === 0 ? nodeHeadline : nodeNameWrapper;

    let isSelectedChapter = false;
    if (hasChildren && node.isActiveChapter) {
      isSelectedChapter = node.isActiveChapter;
    }

    return (
      <li
        className={nodeClassName}
        structurelevel={level.toString()}
        collapsed={collapsed.toString()}
        isselectedchapter={isSelectedChapter.toString()}>
        <button
          structure-node-refname={refName}  // wird zum scrollen in translationView genutzt
          className={'nodeInnerButton'}
          title={node.external_id + ' - ' + node.name}
          haschildren={hasChildren.toString()}
          structurelevel={level.toString()}
          isincourseselected={isInCourseSelected.toString()}
          isselected={isSelected.toString()}
          onClick={event => {
            this.handleNodeClick(event, node, hasChildren, isSite);
          }}
        >
          {hasChildren ? arrow : null}
          {nodeContent}
        </button>
        {childHandler(node.childs, collapsed, level + 1, collapsed ? this.expand : null)}
      </li>
    );
  }
}

export default StructureNode;
