import {Routes} from '../../../utils/Constants';

// eslint-disable
const helpTexts = [
  {
    route: Routes.LOGIN,
    textCode: 'login',
    steps: [
      {helpId: 1},
      {helpId: 2, padding: 1},
      {helpId: 3, padding: 0}
    ]
  },
  {
    route: Routes.HOME,
    textCode: 'home',
    steps: [
      {helpId: 1},
      {helpId: 2},
      {helpId: 20, padding: 0},
      {helpId: 21, padding: 0},
      {helpId: 22, padding: 1},
      {helpId: 23, padding: 0}
    ]
  },
  {
    route: Routes.PROJECTS.TRANSLATIONS.VIEW,
    textCode: 'project',
    steps: [
      {helpId: 1},
      {helpId: 2},
      {helpId: 3, padding: 0},
      {helpId: 4, padding: 0},
      {helpId: 5, padding: 0.5},
      {helpId: 6, padding: 0}
    ]
  },
  {
    route: Routes.PROJECTS.TRANSLATION_VIEW,
    textCode: 'translationview',
    steps: [
      {helpId: 1, padding: 0},
      {helpId: 2, padding: 0},
      {helpId: 3, padding: 0},
      {helpId: 4, padding: 0},
      {helpId: 5, padding: 0},
      {helpId: 6, padding: 0},
      {helpId: 7},
      {helpId: 8, padding: 0},
      {helpId: 9, padding: 0},
      {helpId: 10, padding: 0},
      {helpId: 11, padding: 0},
      {helpId: 12, padding: 0},
      {helpId: 13, padding: 0},
      {helpId: 14, padding: 0},
      {helpId: 15, padding: 0},
      {helpId: 16, padding: 0},
      {helpId: 17, padding: 0},
      {helpId: 18, padding: 0}
    ]
  }
];

export default helpTexts;
